import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getJobApplicationList, } from "../../../redux/actions/JobActions"
import { CircularProgress } from '@material-ui/core';
// import darkblue from '../../../assets/images/bg/dark-blue.jpg';
import { Link, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from 'moment';
import 'moment/locale/fr';
import ReactDatatable from '@ashvin27/react-datatable';
import siteService from '../../../services/siteService';

let columns = [
    {
        key: "jobNumber",
        text: "Numéro",
        // width: "10%",
        className: "jobNumber",
        align: "right",
        sortable: true,
    },
    {
        key: "title",
        text: "Titre",
        // width: "45%",
        className: "title",
        align: "left",
        sortable: true,
    },
    {
        key: "employer",
        text: "Employeur",
        // width: "25%",
        className: "emplyer",
        align: "left",
        sortable: true,
    },
    {
        key: "postedDate",
        text: "Date",
        // width: "15%",
        className: "postedDate",
        align: "left",
        sortable: true,
    },
    {
        key: "action",
        text: "",
        className: "action",
        // width: "5%",
        align: "left",
        sortable: false,
        cell: job => {
            return (
                <>
                    <ul className="list-unstyled mb-0 d-flex justify-content-end">
                        <li><Link to={"/admin/applications/details/" + job.id}><i className="fas fa-folder-open"></i></Link></li>
                    </ul>
                </>
            );
        }
    }
]

let config = {
    page_size: 25,
    length_menu: [25, 50, 100],
    button: {
        excel: false,
        print: false,
        extra: false,
    }
}

let ApplicationListing = (props) => {
    const { getJobApplicationList, storeJob = {} } = props;

    const [jobsList, setJobsList] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [archiveJobsList, setArchiveJobsList] = React.useState([]);
    const [initialJobsList, setInitialJobsList] = React.useState([]);
    const currentSite = siteService.getCurrentSite();

    useEffect(() => {
        getJobApplicationList().then(response => {
            setJobsList(response);
            setIsLoading(false);
        });
    }, []);

    const handleSearchBoxChange = (event) => {
        let searchTerm = event.target.value;
        let filteredData = initialJobsList.filter(value => {
            return (
                value.jobNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                value.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                value.employer?.companyName?.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });
        setJobsList(filteredData);
    }

    return (
        <React.Fragment>
            <Helmet>
                <title> Administration | Offres d'emploi  | {currentSite.fullName}</title>
            </Helmet>
            {isLoading ? (
                <div className="row bg-white pt-4 mt-lg-0">
                    <div className="col-lg-12">
                        <div className="loading-container bg-white">
                            <CircularProgress />
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="row bg-white pt-0 mt-lg-0">
                        <div className="col-lg-12 mt-4 mt-lg-0">
                            <div className="row mb-4">
                                <div className="col-md-7 col-sm-5 d-flex align-items-center">
                                    <div className="section-title-02 mb-0 ">
                                        <h4 className="mb-0" style={{ marginLeft: "10px", marginTop: "30px" }}>{jobsList?.length + archiveJobsList?.length} offres d'emploi</h4>
                                    </div>
                                </div>
                            </div>
                            <ReactDatatable
                                config={config}
                                records={jobsList}
                                columns={columns}
                                extraButtons={[]}
                            />
                        </div>
                    </div>
                </>
            )
            }
        </React.Fragment >
    );

}
ApplicationListing.propTypes = {
    getJobApplicationList: PropTypes.func.isRequired,
    storeJob: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    getJobList: PropTypes.func.isRequired,
    getJobApplicationList: PropTypes.func.isRequired,
    storeJob: state.job,
});

// export default connect(mapStateToProps, { getJobList, getJobApplicationList })(ApplicationListing)
export default withRouter(connect(mapStateToProps, { getJobApplicationList })(ApplicationListing));