import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getJobList, getArchiveJob } from "../../redux/actions/JobActions"
import { CircularProgress } from '@material-ui/core';
import { Helmet } from "react-helmet";
import { getAdsList } from "../../redux/actions/admin/AdsActions";
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { CategoryBannerHelper } from '../../views/shared/CategoryBanners';
import moment from 'moment';
import 'moment/locale/fr';
import Sidebar from '../shared/views/Sidebar';
import CustomGoogleAdsense from '../shared/views/CustomGoogleAdsense';
import siteService from '../../services/siteService';

export const JobArchiveDetail = (props) => {
    const {
        getArchiveJob,
        getAdsList,
        storeAds,
        storeJob = {}
    } = props;
    const [isLoading, setIsLoading] = React.useState(true);
    const [archiveJob, setArchiveJob] = React.useState(null);
    const [jobNumber, setJobNumber] = React.useState("");
    const [displayingActiveAdsList, setDisplayingActiveAdsList] = React.useState([]);
    const currentSite = siteService.getCurrentSite();

    useEffect(() => {
        let match = props.match;
        if (match !== undefined) {
            let params = match.params;
            if (params !== undefined && params.jobNumber !== undefined) {
                setJobNumber(params.jobNumber);
            }
        };
        if (storeAds.adsList.length == 0) {
            getAdsList(false).then(resp => {
                setDisplayingActiveAdsList(resp.adsList);
            })
        }
        else {
            setDisplayingActiveAdsList(storeAds.adsList);
        }
    }, []);

    useEffect(() => {
        if (jobNumber != "") {
            getArchiveJob(jobNumber).then(response => {
                if (response) {
                    setArchiveJob(response);
                }
            })
        }
    }, [jobNumber]);

    useEffect(() => {
        if (archiveJob !== null && isLoading) {
            setIsLoading(false);
            window.scrollTo(0, 0)
        }
    }, [archiveJob]);

    return (
        <>
            <React.Fragment>

                {isLoading ? (
                    <div className="loading-container">
                        <CircularProgress />
                        <div>Chargement de l'offre d'emploi en cours...</div>
                    </div>
                ) : (
                    <>
                        <Helmet>
                            <title>{archiveJob.title} | {currentSite.fullName}</title>
                        </Helmet>
                        <div className={"header-inner category-header category-" + archiveJob.category} style={{ background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${CategoryBannerHelper.getLevel(archiveJob.category)})` }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h2 style={{ color: "white" }}>{archiveJob.title}</h2>
                                        <ol className="breadcrumb mb-0 p-0">
                                            <li className="breadcrumb-item"><NavLink tag={Link} to="/">Accueil</NavLink></li>
                                            <li className="breadcrumb-item"><i className="fas fa-chevron-right"></i><NavLink tag={Link} to="/jobs/archive/list">Archives - Offres d'emploi</NavLink></li>
                                            <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> {archiveJob.jobNumber} </span></li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className="space-ptb" style={{ paddingTop: "40px" }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 p-4">
                                        <div className="row pt-4 bg-light" style={{ marginTop: "-25px" }}>
                                            <div className="col-md-4 col-sm-6 mb-4">
                                                <div className="d-flex">
                                                    <i className="font-xll text-primary align-self-center flaticon-apartment"></i>
                                                    <div className="feature-info-content pl-3">
                                                        <label className="mb-0">Entreprise</label>
                                                        <span className="mb-0 font-weight-bold d-block text-dark">{archiveJob?.employer?.companyName}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 mb-4">
                                                <div className="d-flex">
                                                    <i className="font-xll text-primary align-self-center flaticon-briefcase"></i>
                                                    <div className="feature-info-content pl-3">
                                                        <label className="mb-0">Catégorie</label>
                                                        <span className="mb-0 font-weight-bold d-block text-dark">{archiveJob.category}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6">
                                                <div className="d-flex">
                                                    <i className="font-xll text-primary align-self-center flaticon-map"></i>
                                                    <div className="feature-info-content pl-3">
                                                        <label className="mb-0">Ville</label>
                                                        <span className="mb-0 font-weight-bold d-block text-dark">{archiveJob.location}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 mb-md-0 mb-4">
                                                <div className="d-flex">
                                                    <i className="font-xll text-primary align-self-center flaticon-appointment"></i>
                                                    <div className="feature-info-content pl-3">
                                                        <label className="mb-0">Date de publication</label>
                                                        <span className="mb-0 font-weight-bold d-block text-dark">{moment(archiveJob?.postedDate).format('YYYY-MM-DD')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 mb-sm-0 mb-4">
                                                <div className="d-flex">
                                                    <i className="font-xll text-primary align-self-center flaticon-appointment"></i>
                                                    <div className="feature-info-content pl-3">
                                                        <label className="mb-0">Date limite de dépot</label>
                                                        <span className="mb-0 font-weight-bold d-block text-dark">{moment(archiveJob?.closingDate).format('YYYY-MM-DD')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 mb-4">
                                                <div className="d-flex">
                                                    <i className="font-xll text-primary align-self-center flaticon-bar-chart"></i>
                                                    <div className="feature-info-content pl-3">
                                                        <label className="mb-0">Type de contrat</label>
                                                        <span className="mb-0 font-weight-bold d-block text-dark">{archiveJob.type}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row bg-light">
                                            <div className="col-lg-12">
                                                <div className="my-4">
                                                    <h5 className="mb-3 mb-md-4">Description</h5>
                                                    <div dangerouslySetInnerHTML={{ __html: archiveJob.description }} />
                                                </div>
                                                <CustomGoogleAdsense location="Wide2" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="sidebar mb-0">
                                            <div className="widget">
                                                <div className="company-address widget-box">
                                                    <div className="company-address-map text-center">
                                                        <img className="img-fluid" src={archiveJob?.employer?.logoUrl != "" ? archiveJob?.employer?.logoUrl : "assets/images/default-job.png"} alt="" />
                                                    </div>
                                                    <ul className="list-unstyled mt-3">
                                                        <li><i className="fas fa-building fa-flip-horizontal fa-fw"></i><span className="pl-2">{archiveJob?.employer?.companyName}</span></li>
                                                        {archiveJob?.showPhoneNumber && (
                                                            <li><a href={`tel:${archiveJob?.employer?.companyPhoneNumber1Contact}`}><i className="fas fa-phone fa-flip-horizontal fa-fw"></i><span className="pl-2">{archiveJob?.employer?.companyPhoneNumber1Contact} {archiveJob?.employer?.companyPhoneNumber2Contact}</span></a></li>
                                                        )}
                                                        {archiveJob?.showEmailAddress && (
                                                            <li><a href={`mailto:${archiveJob?.employer?.email}`}><i className="fas fa-envelope fa-fw"></i><span className="pl-2">{archiveJob?.employer?.email}</span></a></li>
                                                        )}
                                                        <li>
                                                            <Link to={"/jobs/search?companyId=" + archiveJob?.employer?.id}><i className="fas fa-link fa-fw"></i><span className="pl-2">Afficher toutes les offres</span></Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="widget border-0" style={{ marginTop: "30px" }}>
                                                <div className="jobster-company-view">
                                                    <ul className="list-unstyled">
                                                        <Sidebar />
                                                    </ul>
                                                </div>
                                                <CustomGoogleAdsense location="Side1" />
                                                {/* <CustomGoogleAdsense location="Side2" />
                                                <CustomGoogleAdsense location="Side3" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                )}

            </React.Fragment>

        </>
    )
}


JobArchiveDetail.propTypes = {
    getJobList: PropTypes.func.isRequired,
    getArchiveJob: PropTypes.func.isRequired,
    storeJob: PropTypes.object.isRequired,
    storeAds: PropTypes.object.isRequired,
    getAdsList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    getJobList: PropTypes.func.isRequired,
    getArchiveJob: PropTypes.func.isRequired,
    storeJob: state.archiveJob,
    getAdsList: PropTypes.func.isRequired,
    storeAds: state.ads,
});

export default connect(mapStateToProps, { getJobList, getArchiveJob, getAdsList })(JobArchiveDetail)