import React, { useEffect, Fragment } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getArchiveJobsList } from "../../redux/actions/JobActions"
import { CircularProgress } from '@material-ui/core';
import defaultBanner from '../../../assets/images/bg/default-banner.jpg';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactDatatable from '@ashvin27/react-datatable';
import { useHistory } from 'react-router-dom';
import siteService from '../../services/siteService';

let columns = [
    {
        key: "jobNumber",
        text: "Numéro",
        className: "jobNumber",
        align: "left",
        sortable: true,
    },
    {
        key: "title",
        text: "Titre",
        className: "title",
        align: "left",
        sortable: true,
    },    
    {
        key: "postedDate",
        text: "Date de publication",
        className: "postedDate",
        align: "left",
        sortable: true,
    },    
    {
        key: "employer",
        text: "Employeur",
        className: "employer",
        align: "left",
        sortable: true,
    }    
]

let config = {
    page_size: 25,
    length_menu: [25, 50, 100],
    button: {
        excel: false,
        print: false,
        extra: false,
    }
}


let JobArchiveListing = (props) => {

    const {
        getArchiveJobsList,
        storeJob
    } = props;

    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState(true);
    const [archiveJobsList, setArchiveJobsList] = React.useState([]);
    const currentSite = siteService.getCurrentSite();

    useEffect(() => {
        window.scrollTo(0, 0);

        if (storeJob.archiveJobsList?.length > 0 && archiveJobsList?.length == 0) {
            setArchiveJobsList(storeJob.archiveJobsList);
            setIsLoading(false);
        }
        else {
            getArchiveJobsList().then(response => {
                setArchiveJobsList(response);
                setIsLoading(false);
            });
        }
    }, []);

    let rowClickedHandler = (event, data, rowIndex) => {        
        history.push(`/jobs/archive/details/${data.jobNumber}`);
    }

    return (
        <>
            <Helmet>
                <title> Archives - Offres d'emploi  | {currentSite.fullName}</title>
            </Helmet>
            {isLoading ?
                (
                    <div className="loading-container">
                        <CircularProgress />
                        <div>Chargement des archives d'offres d'emploi en cours...</div>
                    </div>
                ) :
                (
                    <>
                        <div className="header-inner" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${defaultBanner})` }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h2 style={{ color: "white" }}>Archives - Offres d'emploi</h2>
                                        <ol className="breadcrumb mb-0 p-0">
                                            <li className="breadcrumb-item"><Link to={"/"}>Accueil</Link>  </li>
                                            <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> Archives - Offres d'emploi </span></li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className="space-ptb" style={{ paddingTop: "40px" }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div>
                                            <ReactDatatable
                                                config={config}
                                                records={archiveJobsList}
                                                columns={columns}
                                                extraButtons={[]}
                                                onRowClicked={rowClickedHandler}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                )
            }
        </>
    );
}

JobArchiveListing.propTypes = {
    getArchiveJobsList: PropTypes.func.isRequired,
    storeJob: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    getArchiveJobsList: PropTypes.func.isRequired,
    storeJob: state.job
});

export default connect(mapStateToProps, { getArchiveJobsList })(JobArchiveListing)