import { combineReducers } from "redux";
import JobReducer from "./JobReducer";
import TenderReducer from "./TenderReducer";
import CourseReducer from "./CourseReducer";
import TrusetdEmployerReducer from "./TrusetdEmployerReducer";
import userReducer from "./admin/UserReducer";
import roleReducer from "./admin/RoleReducer";
import EmployerReducer from "./admin/EmployerReducer";
import AdsReducer from "./admin/AdsReducer";
import SmsNotificationReducer from "./admin/SmsNotificationReducer";
import SidebarReducer from "./admin/SidebarReducer";
import CandidateReducer from "./admin/CandidateReducer";
import DashboardReducer from "./admin/DashboardReducer";


const RootReducer = combineReducers({
    job: JobReducer,
    tender: TenderReducer,
    course: CourseReducer,
    users: userReducer,
    role: roleReducer,
    employer: EmployerReducer,
    ads: AdsReducer,
    smsNotifications: SmsNotificationReducer,
    sidebar: SidebarReducer,
    candidate: CandidateReducer,
    dashboard: DashboardReducer,
    trustedEmployer: TrusetdEmployerReducer,
});

export default RootReducer;
