import {
    ACTION_LOADING,
    ACTION_SUCCESS,
    ACTION_ERROR,
    ACTION_SUBMITING,
    ACTION_SUBMITED,
    GET_JOB,
    GET_JOB_LIST,
    GET_JOB_LIST_INCLUDED_DESCRIPTION,
    JOB_SEARCH,
    GET_ARCHIVEJOBS_LIST,
} from "../actions/JobActions";

const initialState = {
    currentJob: {},
    jobList: [],
    archiveJobsList: [],
    jobSearchResult: {},
    jobListIncludedDescription: [],
    loading: false,
    success: false,
    failed: false,
    submitting: false
};

const JobReducer = function (state = initialState, action) {
    switch (action.type) {

        case ACTION_LOADING: {
            return {
                ...state,
                loading: true,
                success: false,
                failed: false
            };
        }
        case ACTION_SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                failed: false,
            };
        }
        case ACTION_ERROR: {
            return {
                ...state,
                failed: true,
                success: false,
                loading: false,
                showAlert: true,
                alertMessage: action.data,
            };
        }
        case ACTION_SUBMITING: {
            return {
                ...state,
                submitting: true
            };
        }
        case ACTION_SUBMITED: {
            return {
                ...state,
                submitting: false
            };
        }
        case GET_JOB_LIST: {
            return {
                ...state,
                jobList: [...action.data]
            };
        }
        case GET_ARCHIVEJOBS_LIST: {
            return {
                ...state,
                archiveJobsList: [...action.data]
            };
        }
        case JOB_SEARCH: {
            return {
                ...state,
                jobSearchResult: { ...action.data }
            };
        }
        case GET_JOB_LIST_INCLUDED_DESCRIPTION: {
            return {
                ...state,
                jobListIncludedDescription: [...action.data]
            };
        }
        case GET_JOB: {
            return {
                ...state,
                jobList: { ...action.data }
            };
        }
        default: {
            return state;
        }
    }
};

export default JobReducer;
