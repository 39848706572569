import React, { useEffect, useRef } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { changePassword } from "../../redux/actions/admin/EmployerActions"
import { CircularProgress } from '@material-ui/core';
import defaultBanner from '../../../assets/images/bg/default-banner.jpg';
import CustomAlert from '../shared/views/CustomAlert';
import { getAdsList } from "../../redux/actions/admin/AdsActions";
import 'moment/locale/fr';
import localStorageService from '../../services/localStorageService';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import siteService from '../../services/siteService';

const useStyles = makeStyles(theme => createStyles({
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
}));

let EmployerChangePassword = (props) => {
    const descriptionHtmlEditor = useRef();
    const {
        storeAds,
        getAdsList,
        changePassword
    } = props;

    const currentSite = siteService.getCurrentSite();
    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [areNotSame, setAreNotSame] = React.useState(false);
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [changed, setChanged] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertType, setAlertType] = React.useState("");
    const [displayingActiveAdsList, setDisplayingActiveAdsList] = React.useState([]);
    const [changePasswordRequest, setChangePasswordRequest] = React.useState(
        {
            id: "",
            currentPassword: "",
            newPassword: "",
            newPasswordConfirmation: ""
        });

    useEffect(() => {
        let match = props.match;
        if (match != undefined) {
            let params = match.params;
            if (params !== undefined && params.t != undefined) {
                setChangePasswordRequest(prevState => { return { ...prevState, id: params.t } });
            }
        };
        let employer = localStorageService.getItem("malibaara_auth_employer");
        if (employer) {
            setChangePasswordRequest(prevState => { return { ...prevState, id: employer?.id } });
        }
        if (storeAds.adsList.length == 0) {
            getAdsList(false).then(resp => {
                setDisplayingActiveAdsList(resp.adsList);
            })
        }
        else {
            setDisplayingActiveAdsList(storeAds.adsList);
        }
    }, []);

    useEffect(() => {

        if (changePasswordRequest.newPassword == ""
            || changePasswordRequest.newPassword == undefined
            || changePasswordRequest.newPasswordConfirmation == ""
            || changePasswordRequest.newPasswordConfirmation == undefined
            || changePasswordRequest.currentPassword == ""
            || changePasswordRequest.currentPassword == undefined
        ) {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);
        }
    }, [changePasswordRequest])

    const handleSubmit = () => {
        setIsUpdating(true);
        changePassword(changePasswordRequest).then(data => {
            if (data) {
                setAlertMessage("<strong>Félicitations!</strong> votre mot de passe a été changé avec succès.")
                setAlertType("alert-success");
                setChangePasswordRequest({
                    id: "",
                    currentPassword: "",
                    newPassword: "",
                    newPasswordConfirmation: ""
                });
            }
            else {
                setAlertMessage("L'ancien mot de passe est incorrect.");
                setAlertType("alert-danger");
            }
            setChanged(true);
            setIsUpdating(false);
        });
    }

    return (
        <React.Fragment>
            <div className="header-inner" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${defaultBanner})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 style={{ color: "white" }}>Profil</h2>
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><a href="/"> Accueil </a></li>
                                <li className="breadcrumb-item"><i className="fas fa-chevron-right"></i><a href="index.html"> Candidat </a></li>
                                <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> Mot de passe </span></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>


            <section className="space-ptb" style={{ padding: "50px 0" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8">
                            <div className="row">
                                {/* Informations de base */}
                                <div className="col-xl-12">
                                    <div className="bg-light-3 p-4">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="section-title">
                                                    <h2 className="title"><span>Changer le mot de passe</span></h2>
                                                    {/* <p></p> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div _lpchecked="1">
                                            <div className="form-row">
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label>Ancien mot de passe *</label>
                                                        <input
                                                            value={changePasswordRequest?.currentPassword}
                                                            type="password"
                                                            className="form-control"
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                let val = e.target?.value;
                                                                setChangePasswordRequest(prevState => { return { ...prevState, currentPassword: val } });
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <label>Nouveau mot de passe *</label>
                                                        <input
                                                            value={changePasswordRequest?.newPassword}
                                                            type="password"
                                                            className="form-control"
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                let val = e.target?.value;
                                                                if (changePasswordRequest.newPasswordConfirmation != "" && changePasswordRequest.conformPassword != undefined) {
                                                                    setAreNotSame(changePasswordRequest.newPasswordConfirmation.normalize() !== val.normalize());
                                                                }
                                                                setChangePasswordRequest(prevState => { return { ...prevState, newPassword: val } });
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <label> Confirmer le nouveau mot de passe *</label>
                                                        <input
                                                            value={changePasswordRequest?.newPasswordConfirmation}
                                                            type="password"
                                                            className="form-control"
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                let val = e.target?.value;
                                                                setAreNotSame(changePasswordRequest.newPassword.normalize() !== val.normalize());
                                                                setChangePasswordRequest(prevState => { return { ...prevState, newPasswordConfirmation: val } });
                                                            }}
                                                        />
                                                        {areNotSame && (
                                                            <div style={{ color: "red", marginTop: "8px" }}>Les deux mots de passe sont différents.</div>
                                                        )}
                                                    </div>
                                                    <div className="col-12 mt-4">
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={handleSubmit}
                                                            disabled={isUpdating || hasMissingValue || areNotSame}
                                                        >
                                                            Soumettre &nbsp; {isUpdating ? <CircularProgress size={15} /> : <span></span>}
                                                        </button>
                                                    </div>
                                                    {changed && (
                                                        <div className="col-12 mt-4">
                                                            <CustomAlert content={alertMessage} type={alertType} />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 mt-4 mt-xl-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="sidebar mb-0">
                                        <div className="widget border-0">
                                            <div className="jobster-company-view">
                                                <ul className="list-unstyled">
                                                    {
                                                        displayingActiveAdsList.map((ads, index) => (
                                                            ads.attachments.map((attachment, index) => (
                                                                <li>
                                                                    <div className="widget-box">
                                                                        <div className="d-flex">
                                                                            <div>
                                                                                <a href={ads.link} target={ads.openInNewTab ? "_blank" : ""}>

                                                                                    <img className="img-fluid" src={attachment.url} alt="" />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

EmployerChangePassword.propTypes = {
    storeAds: PropTypes.object.isRequired,
    getAdsList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    changePassword: PropTypes.func.isRequired,
    getAdsList: PropTypes.func.isRequired,
    storeAds: state.ads,
});

export default connect(mapStateToProps,
    {
        getAdsList,
        changePassword
    }
)(EmployerChangePassword)