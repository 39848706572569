import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CircularProgress } from '@material-ui/core';
import CustomAlert from '../../shared/views/CustomAlert';
import { getAdsList, updateAds, addAttachmentFromAds, deleteAttachmentFromAds } from "../../../redux/actions/admin/AdsActions"
import { DropzoneArea } from 'material-ui-dropzone';

let AdsDetail = (props) => {

    const { getAdsList, addAttachmentFromAds, updateAds, deleteAttachmentFromAds, storeAds = {} } = props;
    const [isLoading, setIsLoading] = React.useState(true);
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [isUploading, setIsUploading] = React.useState(false);
    const [ads, setAds] = React.useState({ name: "", link: "", openInNewTab: false, order: "" });
    const [adsId, setAdsId] = React.useState("");
    const [selectedFile, setSelectedFile] = React.useState()
    const [preview, setPreview] = React.useState();
    const [currentFile, setCurrentFile] = React.useState(undefined);
    const [attachmentToDelete, setAttachmentToDelete] = React.useState(null);
    const closeFileUploadModal = React.useRef();
    const closeFileDeleteModal = React.useRef();
    const [isDeletting, setIsDeletting] = React.useState(false);
    const [published, setPublished] = React.useState(false);
    const [hasMissingValue, setHasMissingValue] = React.useState(true);


    useEffect(() => {
        let match = props.match;
        if (match !== undefined) {
            let params = match.params;
            if (params !== undefined && params.id !== undefined) {
                setAdsId(params.id);
            }
        };
    }, []);

    useEffect(() => {
        if (ads != null) {
            setIsLoading(false);
        }
        if (ads.name === "" || ads.name === undefined) {
            setHasMissingValue(true);
        }
        else if (ads.link?.length > 50) {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);
        }
    }, [ads])

    useEffect(() => {
        if (adsId != "") {
            if (storeAds.adsList.length === 0 || !storeAds.adsList.some(a => a.id == adsId)) {
                getAdsList(true).then(resp => {
                    let queryResponse = resp.adsList.find(c => c.id == adsId);
                    if (queryResponse) {
                        setAds(queryResponse)
                    }
                });
            }
            else if (storeAds.adsList.length > 0) {
                let queryResponse = storeAds.adsList.find(c => c.id == adsId);
                if (queryResponse) {
                    setAds(queryResponse)
                }
            }
        }
    }, [adsId]);

    useEffect(() => {
        if (selectedFile) {
            handleAddAttachment();
        }
    }, [selectedFile]);

    const handleAddAttachment = () => {
        setIsUploading(true);
        let currentFile = selectedFile;
        addAttachmentFromAds(ads?.id, currentFile).then(resp => {
            setSelectedFile(undefined)
            let adsFromStore = resp.adsList.find(ads => ads.id === adsId);
            if (adsFromStore !== undefined) {
                setAds(adsFromStore);
                setIsUploading(false);
            }
        })
    }

    const handleDeleteSubmit = (e, id, value) => {
        setIsDeletting(true);
        deleteAttachmentFromAds(id, value).then(resp => {
            let adsFromStore = resp.adsList.find(ads => ads.id === adsId);
            if (adsFromStore !== undefined) {
                setAds(adsFromStore);
                setCurrentFile(adsFromStore.attachments);
            }
            setAttachmentToDelete("");
            closeFileDeleteModal.current.click();
            setIsDeletting(false);
        });
    }

    const handleUpdateSubmit = () => {
        setIsUpdating(true);
        setPublished(false);
        let adsCopy = ads;
        if (!adsCopy.order || adsCopy.order == "") {
            adsCopy.order = 0;
        }
        updateAds(adsCopy).then(res => {
            if (res.success) {
                let queryAds = res.adsList.find(ads => ads.id == adsId);
                if (queryAds) {
                    setAds(queryAds);
                    setPublished(true);
                }
            }
            setIsUpdating(false);
        });
    }

    return (
        <>
            {isLoading ? (
                <div className="row bg-white pt-4 mt-lg-0">
                    <div className="col-lg-12">
                        <div className="loading-container bg-white">
                            <CircularProgress />
                        </div>
                    </div>
                </div>
            ) :
                (
                    <>
                        <div className="row">
                            <div className="col-lg-12 mt-4 bg-white pt-0 mt-lg-0">
                                <div className="bg-light-2 p-4">
                                    <div className="row mb-4">
                                        <h2 className="mb-0"><span>{ads.name}</span></h2>
                                    </div>
                                    <div style={{ marginLeft: "-12px" }}>
                                        <div className="form-row">
                                            <div className="form-group col-12">
                                                <label htmlFor="Email2">Nom</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="name"
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setAds(prevState => {
                                                            return { ...prevState, name: val }
                                                        });
                                                    }}
                                                    value={ads.name}
                                                />
                                            </div>
                                            <div className="form-group col-12">
                                                <label htmlFor="Email2">Lien</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="link"
                                                    value={ads.link}
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setAds(prevState => { return { ...prevState, link: val } });
                                                    }}
                                                />
                                                {ads.link?.length > 200 && (
                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                )}
                                            </div>
                                            <div className="form-group col-12">
                                                <label htmlFor="Email3">Ordre d'affichage</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="order"
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setAds(prevState => {
                                                            return { ...prevState, order: val }
                                                        });
                                                    }}
                                                    value={ads.order}
                                                />
                                            </div>
                                            <div className="form-group col-12">
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="accepts-02"
                                                        defaultChecked={ads.isActive}
                                                        onClick={(e) => {
                                                            let val = e.target?.checked;
                                                            setAds(prevState => {
                                                                return { ...prevState, isActive: val }
                                                            });
                                                        }}
                                                    />
                                                    <label className="custom-control-label" htmlFor="accepts-02">Actif</label>
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="accepts-01"
                                                        defaultChecked={ads.openInNewTab}
                                                        onChange={(e) => {
                                                            let val = e.target.checked;
                                                            setAds(prevState => {
                                                                return { ...prevState, openInNewTab: val }
                                                            });
                                                        }}
                                                    />
                                                    <label className="custom-control-label" htmlFor="accepts-01">Ouvrir dans un nouvel onglet?</label>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-4">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={handleUpdateSubmit}
                                                    disabled={isUpdating || hasMissingValue}
                                                >
                                                    Sauvegarder &nbsp; {isUpdating ? <CircularProgress size={15} /> : <span></span>}
                                                </button>
                                            </div>
                                            {published && (
                                                <div className="col-12 mt-4">
                                                    <CustomAlert content="La publicité a été modifiée avec succès." />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-4 bg-white mt-lg-10">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title" style={{ marginLeft: "12px" }}>
                                            <h4 className="title" style={{ paddingBottom: "15px" }}><span>Images</span></h4>
                                            <p style={{ marginRight: "12px" }}>
                                                Veuillez déposer dans cette section les images de la publicité
                                                <strong> en cliquant à l'intérieur de la bande blanche ci-dessous</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div _lpchecked="2" style={{ marginLeft: "12px", marginRight: "20px" }}>
                                    {isUploading ?
                                        (
                                            <div className="loading-container">
                                                <CircularProgress />
                                                <div>Chargement des documents en cours...</div>
                                            </div>
                                        )
                                        :
                                        (
                                            <div className="form-group mb-5">
                                                <DropzoneArea
                                                    clearOnUnmount={true}
                                                    dropzoneText={isUploading ? "Chargement en cours..." : "Glisser ou cliquer ici pour déposer vos documents"}
                                                    showPreviewsInDropzone={false}
                                                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                    showAlerts={false}
                                                    filesLimit={1}
                                                    onChange={(files) => {
                                                        setSelectedFile(files[0])
                                                    }}
                                                />
                                            </div>
                                        )}
                                    <table className="table manage-candidates-top mb-4">
                                        <thead>
                                            <tr>
                                                <th>Nom</th>
                                                <th>Date</th>
                                                <th className="action text-right"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <>
                                                {ads?.attachments?.map((file, index) => (
                                                    <tr key={index}>
                                                        <td>{file.name}</td>
                                                        <td>{file.dateCreated}</td>
                                                        <td style={{ textAlign: "right" }}>
                                                            <a href={file.url} className="text-primary" target="_blank">
                                                                <i className="fas fa-cloud-download-alt"></i>
                                                            </a>
                                                            <a href="assets/#" className="text-danger" style={{ marginLeft: "10px" }} data-toggle="modal" title="" data-target="#deleteAdsModal" data-original-title="Delete">
                                                                <i className="far fa-trash-alt" onClick={() => setAttachmentToDelete(file)}></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                        {/* Start Delete file modale */}
                        <div className="modal fade" id="deleteAdsModal" tabIndex="-1" role="dialog" aria-modal="true" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header p-4">
                                        <h4 className="mb-0 text-center">{"Suppression d'un fichier: " + attachmentToDelete?.name}</h4>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>

                                    <form className="mt-2">
                                        <div className="modal-body">
                                            <div>
                                                <div className="form-row">
                                                    <p>Êtes-vous sûr de vouloir supprimer ce fichier ?</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                data-dismiss="modal"
                                                className="btn btn-primary"
                                                ref={closeFileDeleteModal}
                                            >
                                                NON
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={(e) => handleDeleteSubmit(e, adsId, attachmentToDelete.id)}
                                                disabled={isDeletting}
                                            >
                                                OUI &nbsp; {isDeletting ? <CircularProgress size={15} /> : <span></span>}
                                            </button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                        {/* End Delete file modale */}
                    </>
                )
            }

        </>
    )

}


AdsDetail.propTypes = {
    getAdsList: PropTypes.func.isRequired,
    addAttachmentFromAds: PropTypes.func.isRequired,
    updateAds: PropTypes.func.isRequired,
    deleteAttachmentFromAds: PropTypes.func.isRequired,
    storeAds: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    getAdsList: PropTypes.func.isRequired,
    addAttachmentFromAds: PropTypes.func.isRequired,
    updateAds: PropTypes.func.isRequired,
    deleteAttachmentFromAds: PropTypes.func.isRequired,
    storeAds: state.ads,
});

export default connect(mapStateToProps, { getAdsList, updateAds, addAttachmentFromAds, deleteAttachmentFromAds })(AdsDetail)