
import axios from "axios";
import { api } from "../../../services/apiHelpers";
import authentificationService from "../../../services/authentificationService"


export const CANDIDATE_LOGGED_OUT = "CANDIDATE_LOGGED_OUT";
export const SET_CANDIDATE_DATA = "SET_CANDIDATE_DATA";
export const REMOVE_CANDIDATE_DATA = "REMOVE_CANDIDATE_DATA";
export const GET_CANDIDATE_LIST = "GET_CANDIDATE_LIST";
export const CANDIDATE_ACTION_LOADING = "CANDIDATE_ACTION_LOADING";
export const CANDIDATE_ACTION_SUCCESS = "CANDIDATE_ACTION_SUCCESS";
export const CANDIDATE_ACTION_ERROR = "CANDIDATE_ACTION_ERROR";
export const CANDIDATE_ACTION_SUBMITING = "CANDIDATE_ACTION_SUBMITING"; //
export const CANDIDATE_ACTION_SUBMITED = "CANDIDATE_ACTION_SUBMITED";
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";
export const CANDIDATE_TABLE_CONTENT_LOADING = "CANDIDATE_TABLE_CONTENT_LOADING";
export const CANDIDATE_TABLE_CONTENT_LOADED = "CANDIDATE_TABLE_CONTENT_LOADED";
export const GET_CANDIDATE_LIST_INCLUDED_INACTIVE = "GET_CANDIDATE_LIST_INCLUDED_INACTIVE";


export const getCandidateList = () => dispatch => {
    dispatch({
        type: CANDIDATE_ACTION_LOADING
    });
    return new Promise((resolve, reject) => {
        axios.get(api + "/Candidates/list").then(res => {
            dispatch({
                type: GET_CANDIDATE_LIST,
                data: res.data.candidatesList
            });
            dispatch({
                type: CANDIDATE_ACTION_SUCCESS
            });
            resolve(res.data.candidatesList);
        }).catch(error => {
            return dispatch({
                type: CANDIDATE_ACTION_ERROR,
                data: error
            });
        });

    })

};

export const createCandidate = (candidate) => dispatch => {
    dispatch({
        type: CANDIDATE_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {

        axios.post(api + "/Candidates/signup", candidate).then(res => {
            if (res.data.success) {
                dispatch({
                    type: GET_CANDIDATE_LIST,
                    data: res.data.CandidateList
                });
            }
            resolve(res.data);
        }).catch(error => {
            dispatch({
                type: CANDIDATE_ACTION_ERROR,
                data: error
            });
            resolve({ candidate: null, candidatesList: null, success: false, message: "error.message" })
        });
    }).then(data => {
        dispatch({
            type: CANDIDATE_ACTION_SUBMITED
        });
        return data;
    });
};

export const updateCandidate = (candidate) => dispatch => {

    dispatch({
        type: CANDIDATE_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {

        axios.post(api + "/Candidates/update", candidate).then(res => {
            if (res.data.success) {
                dispatch({
                    type: SET_CANDIDATE_DATA,
                    data: res.data.candidate
                });
                authentificationService.setSession(res.data.candidate.id);
                authentificationService.setCandidate(res.data.candidate);
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: CANDIDATE_ACTION_ERROR,
                data: error
            });
        })
    }).then(data => {
        dispatch({
            type: CANDIDATE_ACTION_SUBMITED
        });
        return data;
    });
};

export const deleteCandidate = (candidateId) => dispatch => {
    dispatch({
        type: CANDIDATE_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        axios.get(api + "/Candidates/delete/" + candidateId).then(res => {
            if (res.data.success) {
                dispatch({
                    type: GET_CANDIDATE_LIST,
                    data: res.data.candidatesList
                });
                resolve(res.data);
            }
            else {
                resolve(null);
            }
        }).catch(error => {
            return dispatch({
                type: CANDIDATE_ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        if (data != null) {
            dispatch({
                type: CANDIDATE_ACTION_SUBMITED
            });
            dispatch({
                type: SHOW_ALERT,
                data: "Un utilisateur a été supprimé avec succès"
            });
        }
        else {
            dispatch({
                type: CANDIDATE_ACTION_ERROR,
                data: data.message
            });
        }
        return data;
    });
};

export const hideAlert = () => dispatch => {
    dispatch({
        type: HIDE_ALERT
    });
};

export const loginCandidate = (loginData) => dispatch => {
    dispatch({ // informe le reducer de user qu'un formulaire le concernant est en cours de soumission 
        type: CANDIDATE_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        axios.post(api + "/Candidates/login", loginData).then(res => {
            if (res.data.success) { // Vérifie que le service a fournit une réponse souhaitée
                dispatch({ // Fournit la nouvelle liste d'utilisateur au reducer pour sa mise à jour 
                    type: SET_CANDIDATE_DATA,
                    data: res.data.candidate
                });
                dispatch({ // informe le reducer de user que le formulaire le concernant est traité
                    type: CANDIDATE_ACTION_SUBMITED
                });
                authentificationService.setSession(res.data.candidate.id);
                authentificationService.setCandidate(res.data.candidate);
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: CANDIDATE_ACTION_ERROR,
                data: error
            });
        });
    })
};

export const forgotPassword = (email) => dispatch => {
    dispatch({
        type: CANDIDATE_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        axios.post(api + "/Candidates/forgotPassword", email).then(res => {
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: CANDIDATE_ACTION_ERROR,
                data: error
            });
        });
    })
};

export const changePassword = (requestData) => dispatch => {
    dispatch({
        type: CANDIDATE_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        axios.post(api + "/Candidates/changePassword", requestData).then(res => {
            resolve(res.data);
        }).catch(error => {
            resolve(false);
            return dispatch({
                type: CANDIDATE_ACTION_ERROR,
                data: error
            });

        });
    })
};

export const resetPassword = (request) => dispatch => {
    dispatch({
        type: CANDIDATE_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        axios.post(api + "/Candidates/resetPassword", request).then(res => {
            resolve(res.data);
        }).catch(error => {
            resolve(false);
            // return dispatch({
            //     type: CANDIDATE_ACTION_ERROR,
            //     data: error
            // });
        });
    })
};

export const candidateLogout = () => dispatch => {
    dispatch({
        type: REMOVE_CANDIDATE_DATA
    });

};


export const addCandidateAttachments = (id, files) => dispatch => {
    dispatch({
        type: CANDIDATE_ACTION_SUBMITING
    });
    let formData = new FormData();
    files.map((file) => (
        formData.append('files', file)
    ));
    // formData.append("files", files);
    formData.append("candidateId", id);
    return new Promise((resolve, reject) => {
        axios.post(api + "/Candidates/attachments/add", formData).then(res => {
            if (res.data.success) {
                dispatch({
                    type: SET_CANDIDATE_DATA,
                    data: res.data.candidate
                });
                dispatch({
                    type: CANDIDATE_ACTION_SUBMITED
                });
                authentificationService.setSession(res.data.candidate.id);
                authentificationService.setCandidate(res.data.candidate);
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: CANDIDATE_ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({
            type: CANDIDATE_ACTION_SUBMITED
        });
        return data;
    });
}

export const deleteAttachment = (id, attachmentId) => dispatch => {
    dispatch({
        type: CANDIDATE_ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        axios.get(api + "/Candidates/attachments/delete/" + id + "/" + attachmentId).then(res => {
            if (res.data.success) {
                dispatch({
                    type: SET_CANDIDATE_DATA,
                    data: res.data.candidate
                });
                dispatch({
                    type: CANDIDATE_ACTION_SUBMITED
                });
                authentificationService.setSession(res.data.candidate.id);
                authentificationService.setCandidate(res.data.candidate);
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: CANDIDATE_ACTION_ERROR,
                data: error
            });
        });
    })
};