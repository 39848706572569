import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getJobList, jobSearch, apply, getAdditionalQuestions } from "../../redux/actions/JobActions"
import { CircularProgress } from '@material-ui/core';
import { Helmet } from "react-helmet";
import { getAdsList } from "../../redux/actions/admin/AdsActions";
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { DropzoneArea } from 'material-ui-dropzone';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CustomAlert from './../shared/views/CustomAlert';
import { CategoryBannerHelper } from '../../views/shared/CategoryBanners';
import isMatch from 'date-fns/isMatch'
import validator from 'validator'
import Select from 'react-select';
import siteService from '../../services/siteService';

const useStyles = makeStyles(theme => createStyles({
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
}));

export const JobDetail = (props) => {
    const {
        jobSearch,
        getAdsList,
        apply,
        getAdditionalQuestions,
        storeAds,
        storeJob = {},

    } = props;

    const classes = useStyles();

    const [isLoading, setIsLoading] = React.useState(true);
    const [isSaving, setIsSaving] = React.useState(false);
    const [postulated, setPostulated] = React.useState(false);
    const [hasAdditionalQuestions, setHasAdditionalQuestions] = React.useState(false);
    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [job, setJob] = React.useState(null);
    const [jobNumber, setJobNumber] = React.useState("");
    const [displayingActiveAdsList, setDisplayingActiveAdsList] = React.useState([]);
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [candidateInformation, setCandidateInformation] = React.useState({
        CandidateLastName: "",
        CandidateFirstName: "",
        CandidateEmail: "",
        CandidatePhoneNumber1: "",
        CandidatePhoneNumber2: "",
        JobId: "",
        AdditionalQuestionsAnswers: []
    });
    const [invalideEmail, setInvalideEmail] = React.useState(false);
    const [additionalQuestions, setAdditionalQuestions] = React.useState([]);
    const currentSite = siteService.getCurrentSite();

    useEffect(() => {
        let match = props.match;
        if (match !== undefined) {
            let params = match.params;
            if (params !== undefined && params.jobNumber !== undefined) {
                setJobNumber(params.jobNumber);
            }
        };
        if (storeAds.adsList.length == 0) {
            getAdsList(false).then(resp => {
                setDisplayingActiveAdsList(resp.adsList);
            })
        }
        else {
            setDisplayingActiveAdsList(storeAds.adsList);
        }
    }, []);

    useEffect(() => {
        if (jobNumber != "") {
            if (storeJob.jobListIncludedDescription.length == 0 && job === null) {
                jobSearch(true).then(response => {
                    if (response) {
                        let jobFromrequest = response.documents.find(d => d.jobNumber == jobNumber);
                        if (jobFromrequest) {
                            setJob(jobFromrequest);
                            if (jobFromrequest?.hasAdditionalQuestions == true) {
                                setHasAdditionalQuestions(true);
                            }
                        }
                    }
                })
            }
            else if (storeJob.jobListIncludedDescription.length > 0 && job === null) {
                let jobFromrequest = storeJob.jobListIncludedDescription.find(d => d.jobNumber == jobNumber);
                if (jobFromrequest) {
                    setJob(jobFromrequest);
                    if (jobFromrequest?.hasAdditionalQuestions == true) {
                        setHasAdditionalQuestions(true);
                    }
                }
            }
        }
    }, [jobNumber]);

    useEffect(() => {
        if (job !== null && isLoading) {
            setIsLoading(false);
        }
    }, [job]);

    useEffect(() => {
        if (additionalQuestions?.length == 0 && hasAdditionalQuestions == true) {
            getAdditionalQuestions(job?.jobId).then(resp => {
                if (resp) {
                    setAdditionalQuestions(resp);
                    let result = [];
                    resp.forEach(element => {
                        result.push({ questionId: element.id, answer: "", answers: [], required: element.required });
                    });
                    setCandidateInformation(prevState => {
                        return { ...prevState, AdditionalQuestionsAnswers: result }
                    });
                }
            });
        }
    }, [hasAdditionalQuestions])


    useEffect(() => {
        if (candidateInformation.CandidateFirstName === undefined
            || candidateInformation.CandidateFirstName == ""
            || candidateInformation.CandidateLastName === undefined
            || candidateInformation.CandidateLastName == ""
            || candidateInformation.CandidateEmail === undefined
            || candidateInformation.CandidateEmail == "" ||
            selectedFiles === undefined ||
            selectedFiles.length == 0
        ) {
            setHasMissingValue(true);
        }
        else if (candidateInformation?.CandidateFirstName?.length > 50
            || candidateInformation?.CandidateLastName?.length > 50
            || candidateInformation?.CandidatePhoneNumber1?.length > 50
            || candidateInformation?.CandidatePhoneNumber2?.length > 50
            || candidateInformation?.CandidateEmail?.length > 50
            || (candidateInformation?.CandidatePhoneNumber1 != "" && !(new RegExp(/^[0-9\b]+$/)).test(candidateInformation?.CandidatePhoneNumber1))
            || (candidateInformation?.CandidatePhoneNumber2 != "" && !(new RegExp(/^[0-9\b]+$/)).test(candidateInformation?.CandidatePhoneNumber2))
            || (candidateInformation?.CandidateEmail != "" && !validator.isEmail(candidateInformation?.CandidateEmail))
        ) {
            setHasMissingValue(true);
        }
        else if (candidateInformation?.AdditionalQuestionsAnswers?.some(item => item.required == true && (item.answer == "" || item.answer == undefined))) {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);
        }
    }, [candidateInformation, selectedFiles])

    let handleSubmit = () => {
        setIsSaving(true);
        let value = candidateInformation;
        value.JobId = job.jobId;
        apply(selectedFiles, value).then(data => {
            if (data) {
                setIsSaving(false);
                setPostulated(true);
            }
        });
    }


    return (
        <>
            <React.Fragment>

                {isLoading ? (
                    <div className="loading-container">
                        <CircularProgress />
                        <div>Chargement du formulaire de postulation en cours...</div>
                    </div>
                ) : (
                    <>
                        <Helmet>
                            <title>Postulation à une offre d'emploi | {job.title}  | {currentSite.fullName}</title>
                        </Helmet>
                        <div className={"header-inner category-header category-" + job.category} style={{ background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${CategoryBannerHelper.getLevel(job.category)})` }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h2 style={{ color: "white" }}>{job.title}</h2>
                                        <ol className="breadcrumb mb-0 p-0">
                                            <li className="breadcrumb-item"><NavLink tag={Link} to="/">Accueil</NavLink></li>
                                            <li className="breadcrumb-item"><i className="fas fa-chevron-right"></i><NavLink tag={Link} to="/jobs/search">Offres d'emploi</NavLink></li>
                                            <li className="breadcrumb-item"><i className="fas fa-chevron-right"></i><NavLink tag={Link} to={"/jobs/details/" + job.jobNumber}>{job.jobNumber}</NavLink></li>
                                            <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> Postulation </span></li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className="space-ptb" style={{ paddingTop: "40px" }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-8">
                                        <div className="bg-light-3 p-4">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="section-title">
                                                        <h2 className="title"><span>Formulaire de postulation</span></h2>
                                                        <p>Veuillez remplir le formulaire ci-dessous pour postuler à cette offre d'emploi:</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div _lpchecked="1">
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label>Prénom *</label>
                                                        <input
                                                            value={candidateInformation?.CandidateFirstName}
                                                            type="text"
                                                            className="form-control"
                                                            name="CandidateFirstName"
                                                            onChange={(e) => {
                                                                let val = e.target?.value;
                                                                setCandidateInformation(prevState => { return { ...prevState, CandidateFirstName: val } });
                                                            }}
                                                            placeholder=""
                                                            disabled={postulated}
                                                        />
                                                        {candidateInformation?.CandidateFirstName.length > 50 && (
                                                            <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <label>Nom *</label>
                                                        <input
                                                            value={candidateInformation?.CandidateLastName}
                                                            type="text"
                                                            className="form-control"
                                                            name="CandidateLastName"
                                                            onChange={(e) => {
                                                                let val = e.target?.value;
                                                                setCandidateInformation(prevState => { return { ...prevState, CandidateLastName: val } });
                                                            }}
                                                            placeholder=""
                                                            disabled={postulated}
                                                        />
                                                        {candidateInformation?.CandidateLastName.length > 50 && (
                                                            <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <label>Addresse email *</label>
                                                        <input
                                                            value={candidateInformation?.CandidateEmail}
                                                            type="text"
                                                            className="form-control"
                                                            name="CandidateEmail"
                                                            onChange={(e) => {
                                                                let val = e.target?.value;
                                                                let isValide = validator.isEmail(e.target.value);
                                                                setCandidateInformation(prevState => { return { ...prevState, CandidateEmail: val } });
                                                                if (invalideEmail) {
                                                                    setInvalideEmail(!isValide);
                                                                }
                                                            }}
                                                            onBlur={(e) => {
                                                                let val = validator.isEmail(e.target.value);
                                                                setInvalideEmail(!val);
                                                            }}
                                                            placeholder=""
                                                            disabled={postulated}
                                                        />
                                                        {candidateInformation?.CandidateEmail.length > 50 && (
                                                            <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                        )}
                                                        {(candidateInformation?.CandidateEmail != "" && invalideEmail) && (
                                                            <p style={{ color: "red" }}>Format d'email invalide</p>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-md-6" >
                                                        <label>Numéro de téléphone</label>
                                                        <input
                                                            value={candidateInformation?.CandidatePhoneNumber1}
                                                            type="text"
                                                            className="form-control"
                                                            name="CandidatePhoneNumber1"
                                                            onChange={(e) => {
                                                                let val = e.target?.value;
                                                                setCandidateInformation(prevState => { return { ...prevState, CandidatePhoneNumber1: val } });
                                                            }}
                                                            disabled={postulated}
                                                        />
                                                        {candidateInformation?.CandidatePhoneNumber1.length > 50 && (
                                                            <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                        )}
                                                        {(candidateInformation?.CandidatePhoneNumber1 != "" && !(new RegExp(/^[0-9\b]+$/)).test(candidateInformation?.CandidatePhoneNumber1)) && (
                                                            <p style={{ color: "red" }}>Format invalide</p>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>Autre numéro de téléphone</label>
                                                        <input
                                                            value={candidateInformation?.CandidatePhoneNumber2}
                                                            type="text"
                                                            className="form-control"
                                                            name="CandidatePhoneNumber2"
                                                            onChange={(e) => {
                                                                let val = e.target?.value;
                                                                setCandidateInformation(prevState => { return { ...prevState, CandidatePhoneNumber2: val } });
                                                            }}
                                                            disabled={postulated}
                                                        />
                                                        {candidateInformation?.CandidatePhoneNumber2.length > 50 && (
                                                            <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                        )}
                                                        {(candidateInformation?.CandidatePhoneNumber2 != "" && !(new RegExp(/^[0-9\b]+$/)).test(candidateInformation?.CandidatePhoneNumber2)) && (
                                                            <p style={{ color: "red" }}>Format invalide</p>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <label>Document *</label>
                                                        <DropzoneArea
                                                            dropzoneText="Glisser déposer un fichier ou cliquer"
                                                            showPreviews={true}
                                                            showPreviewsInDropzone={false}
                                                            useChipsForPreview
                                                            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                            previewChipProps={{ classes: { root: classes.previewChip } }}
                                                            previewText="Fichiers sélectionnés"
                                                            showAlerts={false}
                                                            maxFileSize={5000000}
                                                            filesLimit={10}
                                                            onChange={(files) => {
                                                                setSelectedFiles(files)
                                                            }}
                                                            onDelete={(file) => {
                                                                let filteredFiles = [...selectedFiles.filter(fi => fi?.name != file?.name)];
                                                                setSelectedFiles(filteredFiles);
                                                            }}
                                                        // dropzoneProps={{ disabled: true }}
                                                        />
                                                        <p style={{marginTop:"9px", marginBottom:"0px", fontStyle:"italic"}}>Vous pouvez ajouter jusqu'à 10 fichiers dont la taille ne dépasse pas 5MB</p>
                                                    </div>                                                    
                                                    {job?.hasAdditionalQuestions == true && (
                                                        additionalQuestions?.map((quiz, index) => (
                                                            <>
                                                                <div className="col-12 mt-4">
                                                                    <p>Questions supplémentaires</p>
                                                                </div>
                                                                <div className="form-group col-md-12">
                                                                    <label>{quiz.label} {quiz.required == true ? "*" : ""} </label>
                                                                    {quiz.type == "FreeText" ?
                                                                        <input
                                                                            id={quiz.id}
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder=""
                                                                            onChange={(e) => {
                                                                                let val = e.target?.value;
                                                                                let additionalQuestionsAnswersCopy = candidateInformation.AdditionalQuestionsAnswers;
                                                                                let indexOf = additionalQuestionsAnswersCopy?.findIndex(item => item.questionId == quiz.id);
                                                                                additionalQuestionsAnswersCopy[indexOf].answer = val;
                                                                                setCandidateInformation(prevState => {
                                                                                    return { ...prevState, AdditionalQuestionsAnswers: additionalQuestionsAnswersCopy }
                                                                                });
                                                                            }}
                                                                        /> : quiz.type == "SingleValue" ?
                                                                            <Select
                                                                                id={quiz.id}
                                                                                options={quiz?.options?.split("\n")?.map(item => ({ label: item, value: item }))}
                                                                                loadingMessage="En cours de chargement"
                                                                                onChange={(option) => {
                                                                                    let val = option.label;
                                                                                    let additionalQuestionsAnswersCopy = candidateInformation.AdditionalQuestionsAnswers;
                                                                                    let indexOf = additionalQuestionsAnswersCopy?.findIndex(item => item.questionId == quiz.id);
                                                                                    additionalQuestionsAnswersCopy[indexOf].answer = val;
                                                                                    setCandidateInformation(prevState => {
                                                                                        return { ...prevState, AdditionalQuestionsAnswers: additionalQuestionsAnswersCopy }
                                                                                    });
                                                                                }}
                                                                            /> : quiz.type == "MultiValue" ?
                                                                                <Select
                                                                                    isMulti
                                                                                    id={quiz.id}
                                                                                    value={candidateInformation.AdditionalQuestionsAnswers[candidateInformation.AdditionalQuestionsAnswers?.findIndex(item => item.questionId == quiz.id)]?.answers?.map(item => ({ label: item, value: item }))}
                                                                                    options={quiz?.options?.split("\n")?.map(item => ({ label: item, value: item }))}
                                                                                    loadingMessage="En cours de chargement"
                                                                                    onChange={(option) => {
                                                                                        let val = [...new Set(option.map(item => item.label))];  //option.label;
                                                                                        let additionalQuestionsAnswersCopy = candidateInformation.AdditionalQuestionsAnswers;
                                                                                        let indexOf = additionalQuestionsAnswersCopy?.findIndex(item => item.questionId == quiz.id);
                                                                                        additionalQuestionsAnswersCopy[indexOf].answers = val;
                                                                                        additionalQuestionsAnswersCopy[indexOf].answer = val.join("\n");
                                                                                        setCandidateInformation(prevState => {
                                                                                            return { ...prevState, AdditionalQuestionsAnswers: additionalQuestionsAnswersCopy }
                                                                                        });
                                                                                    }}
                                                                                /> : quiz.type == "DateTime" ?
                                                                                    <input
                                                                                        type="date"
                                                                                        className="form-control"
                                                                                        placeholder=""
                                                                                        onChange={(e) => {
                                                                                            let val = e.target?.value;
                                                                                            let additionalQuestionsAnswersCopy = candidateInformation.AdditionalQuestionsAnswers;
                                                                                            let indexOf = additionalQuestionsAnswersCopy?.findIndex(item => item.questionId == quiz.id);
                                                                                            additionalQuestionsAnswersCopy[indexOf].answer = val;
                                                                                            setCandidateInformation(prevState => {
                                                                                                return { ...prevState, AdditionalQuestionsAnswers: additionalQuestionsAnswersCopy }
                                                                                            });
                                                                                        }}
                                                                                    /> : quiz.type == "Numeric" ?
                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control"
                                                                                            placeholder=""
                                                                                            onChange={(e) => {
                                                                                                let val = e.target?.value;
                                                                                                let additionalQuestionsAnswersCopy = candidateInformation.AdditionalQuestionsAnswers;
                                                                                                let indexOf = additionalQuestionsAnswersCopy?.findIndex(item => item.questionId == quiz.id);
                                                                                                additionalQuestionsAnswersCopy[indexOf].answer = val;
                                                                                                setCandidateInformation(prevState => {
                                                                                                    return { ...prevState, AdditionalQuestionsAnswers: additionalQuestionsAnswersCopy }
                                                                                                });
                                                                                            }}
                                                                                        /> :
                                                                                        <>
                                                                                            <div class="form-group col-sm-6">
                                                                                                <div class="custom-control custom-radio d-inline pl-2">
                                                                                                    <input
                                                                                                        class="custom-control-input"
                                                                                                        type="radio"
                                                                                                        id={quiz.id + "yes"}
                                                                                                        name={quiz.id}
                                                                                                        value="Oui"
                                                                                                        onClick={e => {
                                                                                                            let val = e.target?.value;
                                                                                                            let additionalQuestionsAnswersCopy = candidateInformation.AdditionalQuestionsAnswers;
                                                                                                            let indexOf = additionalQuestionsAnswersCopy?.findIndex(item => item.questionId == quiz.id);
                                                                                                            additionalQuestionsAnswersCopy[indexOf].answer = val;
                                                                                                            setCandidateInformation(prevState => {
                                                                                                                return { ...prevState, AdditionalQuestionsAnswers: additionalQuestionsAnswersCopy }
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                    <label class="custom-control-label" for={quiz.id + "yes"}>Oui</label>
                                                                                                </div>
                                                                                                <div class="custom-control custom-radio d-inline ml-3">
                                                                                                    <input
                                                                                                        class="custom-control-input"
                                                                                                        type="radio"
                                                                                                        id={quiz.id + "no"}
                                                                                                        name={quiz.id}
                                                                                                        // checked="checked"
                                                                                                        value="Non"
                                                                                                        onClick={e => {
                                                                                                            let val = e.target?.value;
                                                                                                            let additionalQuestionsAnswersCopy = candidateInformation.AdditionalQuestionsAnswers;
                                                                                                            let indexOf = additionalQuestionsAnswersCopy?.findIndex(item => item.questionId == quiz.id);
                                                                                                            additionalQuestionsAnswersCopy[indexOf].answer = val;
                                                                                                            setCandidateInformation(prevState => {
                                                                                                                return { ...prevState, AdditionalQuestionsAnswers: additionalQuestionsAnswersCopy }
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                    <label class="custom-control-label" for={quiz.id + "no"}>Non</label>
                                                                                                </div>
                                                                                            </div>


                                                                                            {/* <div>
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    id={quiz.id + "yes"}
                                                                                                    name={quiz.id}
                                                                                                    value="Oui"
                                                                                                    onClick={e => {
                                                                                                        let val = e.target?.value;
                                                                                                        let additionalQuestionsAnswersCopy = candidateInformation.AdditionalQuestionsAnswers;
                                                                                                        let indexOf = additionalQuestionsAnswersCopy?.findIndex(item => item.questionId == quiz.id);
                                                                                                        additionalQuestionsAnswersCopy[indexOf].answer = val;
                                                                                                        setCandidateInformation(prevState => {
                                                                                                            return { ...prevState, AdditionalQuestionsAnswers: additionalQuestionsAnswersCopy }
                                                                                                        });
                                                                                                    }}
                                                                                                />
                                                                                                <label for={quiz.id + "yes"}>Oui</label>
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    id={quiz.id + "no"}
                                                                                                    name={quiz.id}
                                                                                                    value="Non"
                                                                                                    onClick={e => {
                                                                                                        let val = e.target?.value;
                                                                                                        let additionalQuestionsAnswersCopy = candidateInformation.AdditionalQuestionsAnswers;
                                                                                                        let indexOf = additionalQuestionsAnswersCopy?.findIndex(item => item.questionId == quiz.id);
                                                                                                        additionalQuestionsAnswersCopy[indexOf].answer = val;
                                                                                                        setCandidateInformation(prevState => {
                                                                                                            return { ...prevState, AdditionalQuestionsAnswers: additionalQuestionsAnswersCopy }
                                                                                                        });
                                                                                                    }}
                                                                                                />
                                                                                                <label for={quiz.id + "no"}>Non</label>
                                                                                            </div> */}
                                                                                        </>

                                                                    }

                                                                    {candidateInformation?.CandidateLastName.length > 50 && (
                                                                        <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                                    )}
                                                                </div>
                                                            </>
                                                        ))

                                                    )}
                                                    <div className="col-12 mt-4">
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={handleSubmit}
                                                            disabled={isSaving || hasMissingValue || postulated}
                                                        >
                                                            Postuler à ce poste &nbsp;
                                                            {isSaving ? <CircularProgress size={15} /> : <span></span>}
                                                        </button>
                                                    </div>
                                                    {postulated && (
                                                        <div className="col-12 mt-4">
                                                            <CustomAlert content="<strong>Félicitations!</strong> Votre candidature a été envoyée avec succès." />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="sidebar mb-0">
                                            <div className="widget">
                                                <div className="company-address widget-box">
                                                    <div className="company-address-map text-center">
                                                        <img className="img-fluid" src={job.imageUrl != "" ? job.imageUrl : "assets/images/default-job.png"} alt="" />
                                                    </div>
                                                    <ul className="list-unstyled mt-3">
                                                        <li><a href={`tel:${job.companyPhoneNumber1Contact}`}><i className="fas fa-phone fa-flip-horizontal fa-fw"></i><span className="pl-2">{job.companyPhoneNumber1Contact} {job.companyPhoneNumber2Contact}</span></a></li>
                                                        <li><a href={`mailto:${job.companyEmailContact}`}><i className="fas fa-envelope fa-fw"></i><span className="pl-2">{job.companyEmailContact}</span></a></li>
                                                        <li><a href="#"><i className="fas fa-link fa-fw"></i><span className="pl-2">Afficher toutes les offres</span></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="widget border-0" style={{ marginTop: "30px" }}>
                                                <div className="jobster-company-view">
                                                    <ul className="list-unstyled">
                                                        {
                                                            displayingActiveAdsList.map((ads, index) => (
                                                                ads.attachments.map((attachment, index) => (
                                                                    <li>
                                                                        <div className="widget-box">
                                                                            <div className="d-flex">
                                                                                <div>
                                                                                    <a href={ads.link} target={ads.openInNewTab ? "_blank" : ""}>
                                                                                        {/* <p>{attachment.name}</p> */}
                                                                                        <img className="img-fluid" src={attachment.url} alt="" />
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            ))
                                                        }

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                )}

            </React.Fragment>

        </>
    )
}


JobDetail.propTypes = {
    getJobList: PropTypes.func.isRequired,
    jobSearch: PropTypes.func.isRequired,
    storeJob: PropTypes.object.isRequired,
    storeAds: PropTypes.object.isRequired,
    getAdsList: PropTypes.func.isRequired,
    apply: PropTypes.func.isRequired,
    getAdditionalQuestions: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    getJobList: PropTypes.func.isRequired,
    jobSearch: PropTypes.func.isRequired,
    apply: PropTypes.func.isRequired,
    getAdditionalQuestions: PropTypes.func.isRequired,
    storeJob: state.job,
    getAdsList: PropTypes.func.isRequired,
    storeAds: state.ads,
});

export default connect(mapStateToProps, { getJobList, jobSearch, getAdsList, apply, getAdditionalQuestions })(JobDetail)