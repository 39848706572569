import React, { useEffect, useRef } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CircularProgress } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import { getRoleList } from "../../../redux/actions/admin/RoleActions";
import { getUserList, createUser, updateUser, deleteUser } from "../../../redux/actions/admin/UserActions";
import { Helmet } from "react-helmet";
import isMatch from 'date-fns/isMatch';
import validator from 'validator';
import Select from 'react-select';
import $ from 'jquery';
import siteService from '../../../services/siteService';

export const UserListing = (props) => {

  const {
    getUserList,
    createUser,
    updateUser,
    deleteUser,
    getRoleList,
    storeUsers = {},
    storeRoles = {}
  } = props;


  const [user, setUser] = React.useState(
    {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      phoneNumber1: "",
      roles: []
    }
  );
  const [userToUpdate, setUserToUpdate] = React.useState(
    {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      phoneNumber1: "",
      roles: []
    }
  );
  const [userId, setUserId] = React.useState("");
  const [hasMissingValue, setHasMissingValue] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(true);
  const [defaultSelectedRole, setDefaultSelectedRole] = React.useState([]);
  const [userList, setUserList] = React.useState(null);
  const [userToDelete, setUserToDelete] = React.useState(null);
  const [roleList, setRoleList] = React.useState(null);
  const [userRoleSelectListOptions, setUserRoleSelectListOptions] = React.useState([]);
  const [selectedRole, setSelectedRole] = React.useState([]);
  const closeUserCreateModal = React.useRef();
  const closeUserUpdateModal = React.useRef();
  const [isDeletting, setIsDeletting] = React.useState(false);
  const [isCreatting, setIsCreatting] = React.useState(false);
  const [isUpdatting, setIsUpdatting] = React.useState(false);
  const closeDeleteUserModal = React.useRef();
  const [areNotSame, setareNotSame] = React.useState(false);
  const [invalideEmail, setInvalideEmail] = React.useState(false);
  const currentSite = siteService.getCurrentSite();

  useEffect(() => {
    if (userList != null && isLoading == true) {
      setIsLoading(false);
    }
    if (Object.keys(storeUsers.userList).length != 0 && storeUsers.userList.constructor == Object && userList == null) {
      userList(storeUsers.userList);
      setUser(storeUsers.userList.documents);
    }
  }, [userList]);

  useEffect(() => {
    if (storeUsers.userList.length > 0) {
      setUserList(storeUsers.userList);
    }
    else if (storeUsers.userList.length == 0) {
      getUserList().then(response => {
        if (response) {
          setUserList(response);
        }
      })
    }

    if (storeRoles.roleList.length > 0) {
      setRoleList(storeRoles.roleList);
    }
    else if (storeRoles.roleList.length == 0) {
      getRoleList().then(response => {
        if (response && response.rolesList) {
          setRoleList(response.rolesList);
        }
      })
    }
  }, [])

  useEffect(() => {
    if (user.firstName == ""
      || user.firstName == undefined
      || user.lastName == ""
      || user.lastName == undefined
      || user.email == ""
      || user.email == undefined
      || user.password == ""
      || user.password == undefined
      || user.confirmPassword == ""
      || user.confirmPassword == undefined
      || !user.roles.length
      || user.roles == undefined
    ) {
      setHasMissingValue(true);
    }
    else if (user.firstName?.length > 50
      || user.lastName?.length > 50
      || user.email?.length > 50
      || (user.email != "" && !validator.isEmail(user.email))
      || user.phoneNumber1?.length > 50
      || (user.phoneNumber1 != undefined && user.phoneNumber1 != "" && !(new RegExp(/^[0-9\b]+$/)).test(user.phoneNumber1))
    ) {
      setHasMissingValue(true);
    }
    else {
      setHasMissingValue(false);
    }
  }, [user])

  useEffect(() => {
    if (userToUpdate.firstName == ""
      || userToUpdate.firstName == undefined
      || userToUpdate.lastName == ""
      || userToUpdate.lastName == undefined
      || userToUpdate.email == ""
      || userToUpdate.email == undefined
      || !userToUpdate.roles?.length
      || userToUpdate.roles == undefined
    ) {
      setHasMissingValue(true);
    }
    else if (userToUpdate.firstName?.length > 50
      || userToUpdate.lastName?.length > 50
      || userToUpdate.email?.length > 50
      || (userToUpdate.email != "" && !validator.isEmail(userToUpdate.email))
      || userToUpdate.phoneNumber1?.length > 50
      || (userToUpdate.phoneNumber1 != undefined && userToUpdate.phoneNumber1 != "" && !(new RegExp(/^[0-9\b]+$/)).test(userToUpdate.phoneNumber1))
    ) {
      setHasMissingValue(true);
    }
    else {
      setHasMissingValue(false);
    }
  }, [userToUpdate])

  useEffect(() => {
    if (roleList && roleList.length) {
      let result = []
      roleList.forEach(role => {
        result.push({ label: role.name, id: role.id, value: role.id });
      })
      setUserRoleSelectListOptions(result);
    }
  }, [roleList])

  useEffect(() => {
    if (userToUpdate && userToUpdate.roles && userToUpdate.roles.length) {
      let result = [];
      userToUpdate.roles.forEach(element => {
        result.push(element.name);
      });
      setDefaultSelectedRole(result);
    }
  }, [userToUpdate])

  useEffect(() => {
    // if (userList && storeUsers.userList) {
    //   if (!(Object.keys(storeUsers.userList).length === Object.keys(userList).length
    //     && Object.keys(storeUsers.userList).every(p => storeUsers.userList[p] === userList[p]))) {
    //     setUserList(storeUsers.userList);
    //   }
    // }
  }, [storeUsers])


  let handleCreateUser = (event) => {
    setIsCreatting(true);
    let userCopy = user;
    let result = [];
    user.roles?.forEach(item => {
      result.push({ id: item });
    })
    userCopy.roles = result;
    createUser(userCopy).then(response => {
      if (response.success) {
        closeUserCreateModal.current.click();
        setUserList(response.usersList);
        setIsCreatting(false);
        setUser(
          {
            id: "",
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            phoneNumber1: "",
            roles: []
          }
        )
      }
    });
  }

  let handleUpdateUser = (event) => {
    setIsUpdatting(true);
    let userCopy = userToUpdate;
    let result = [];
    let roles = [...new Set(userToUpdate.roles?.map(item => item.id ? item.id : item))];
    roles?.forEach(item => {
      result.push({ id: item });
    })
    userCopy.roles = result;
    updateUser(userCopy).then(response => {

      if (response.success) {
        closeUserUpdateModal.current.click();
        setUserList(response.usersList);
        setIsUpdatting(false);
      }
    });
  }

  let handleDeleteUser = (event) => {
    setIsDeletting(true);
    deleteUser(userId).then(response => {
      if (response.success) {
        setUserToDelete(null);
        closeDeleteUserModal.current.click();
        setUserList(response.usersList);
        setIsDeletting(false);

      }


    });
  }

  // Start keydown mechanism
  let onKeydown = event => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      if ($('#createUserModal').attr('class')?.includes("show")) {
        if (!hasMissingValue) {
          handleCreateUser();
        }
      }
      else if ($('#updateUserModal').attr('class')?.includes("show")) {
        if (!hasMissingValue) {
          handleUpdateUser();
        }
      }
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeydown);
    return () => {
      document.removeEventListener("keydown", onKeydown);
    };
  }, [onKeydown]);
  //End keydown mechanism

  return (

    <React.Fragment>
      <Helmet>
        <title> Administration | Utilisateurs | {currentSite.fullName}</title>
      </Helmet>

      {isLoading ? (
        <div className="row bg-white pt-4 mt-lg-0">
          <div className="col-lg-12">
            <div className="loading-container bg-white">
              <CircularProgress />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="row bg-white pt-4 mt-lg-0">
            <div className="col-lg-12 mt-4 mt-lg-0">
              <div className="row mb-4">
                <div className="col-md-7 col-sm-5 d-flex align-items-center">
                  <div className="section-title-02 mb-0 ">
                    <h4 className="mb-0" style={{ marginLeft: "10px" }}>Liste des utilisateurs</h4>
                  </div>
                </div>
                <div className="col-md-5 col-sm-7 mt-3 mt-sm-0" style={{ textAlign: "right" }}>
                  <a className="btn btn-md ml-sm-auto btn-primary" data-toggle="modal" data-target="#createUserModal">
                    <i className="fas fa-pencil-alt" onClick={(e) => {
                      setUser(user);
                    }}>
                    </i>
                    Ajouter un utilisateur</a>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">

                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Prénom</th>
                        <th>Nom</th>
                        <th>Email</th>
                        <th>Téléphone</th>
                        <th>Rôles</th>
                        <th className="action text-right"></th>
                      </tr>
                    </thead>
                    <tbody>

                      {userList?.map((user, index) => (
                        <tr className="candidates-list" key={user.id}>
                          <td>{user.firstName}</td>
                          <td>{user.lastName}</td>
                          <td>{user.email}</td>
                          <td>{user.phoneNumber1}</td>

                          <td>{user.roles?.map((role, index) => (
                            role.name + " "
                          ))}</td>
                          <td>
                            <ul className="list-unstyled mb-0 d-flex justify-content-end">
                              <li>
                                <a href="javascript:void(0)" className="text-info" data-toggle="modal" title="" data-original-title="Edit" data-target="#updateUserModal">
                                  <i className="fas fa-pencil-alt" onClick={(e) => {
                                    if (user.roles && user.roles?.length) {
                                      let result = [];
                                      user.roles.forEach(role => {
                                        result.push(role.id);
                                      });
                                      setSelectedRole(result)
                                    }
                                    setUserToUpdate(user);
                                  }}></i>
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0)" className="text-danger" data-toggle="modal" title="" data-original-title="Delete" data-target="#deleteUserModal">
                                  <i className="far fa-trash-alt" onClick={(e) => {
                                    setUserId(user.id);
                                    setUserToDelete(user);
                                  }}></i>
                                </a></li>
                            </ul>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Start Create user modal */}
              <div className="modal fade" id="createUserModal" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                  <div className="modal-content">
                    <div className="modal-header p-4">
                      <h4 className="mb-0 text-center">Création d'un nouvel utilisateur</h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div>
                        <div className="form-row">
                          <div className="form-group col-12">
                            <label htmlFor="firstName">Prénom *</label>
                            <input
                              type="text"
                              className="form-control"
                              value={user.firstName}
                              required
                              onChange={(e) => {
                                let val = e.target?.value;
                                setUser(prevState => { return { ...prevState, firstName: val } });
                              }}
                            />
                            {user.firstName?.length > 50 && (
                              <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                            )}
                          </div>
                          <div className="form-group col-12">
                            <label htmlFor="lastName">Nom *</label>
                            <input
                              type="text"
                              className="form-control"
                              value={user.lastName}
                              required
                              onChange={(e) => {
                                let val = e.target?.value;
                                setUser(prevState => { return { ...prevState, lastName: val } });
                              }}
                            />
                            {user.lastName?.length > 50 && (
                              <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                            )}
                          </div>
                          <div className="form-group col-12">
                            <label htmlFor="email">Adresse email *</label>
                            <input
                              type="text"
                              className="form-control"
                              value={user.email}
                              required
                              onChange={(e) => {
                                let val = e.target?.value;
                                let isValide = validator.isEmail(e.target.value);
                                setUser(prevState => { return { ...prevState, email: val } });
                                if (invalideEmail) {
                                  setInvalideEmail(!isValide);
                                }
                              }}
                              onBlur={(e) => {
                                let val = validator.isEmail(e.target.value);
                                setInvalideEmail(!val);
                              }}
                            />
                            {user.email?.length > 50 && (
                              <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                            )}
                            {(user.email != "" && invalideEmail) && (
                              <p style={{ color: "red" }}>Format d'email invalide</p>
                            )}
                          </div>
                          <div className="form-group col-12">
                            <label htmlFor="password">Mot de passe *</label>
                            <input
                              type="password"
                              className="form-control"
                              onChange={(e) => {
                                let val = e.target?.value;
                                if (user.confirmPassword != '' && user.conformPassword != undefined) {
                                  setareNotSame(user.confirmPassword?.normalize() !== val.normalize());
                                }
                                setUser(prevState => { return { ...prevState, password: val } });
                              }}
                              value={user.password}
                              required
                            />
                          </div>
                          <div className="form-group col-12">
                            <label htmlFor="confirmPassword">Confirmation du mot de passe *</label>
                            <input
                              type="password"
                              className="form-control"
                              id="password32"
                              onChange={(e) => {
                                let val = e.target?.value;
                                setareNotSame(user.password?.normalize() !== val.normalize());
                                setUser(prevState => { return { ...prevState, confirmPassword: val } });
                              }}
                              value={user.confirmPassword} />
                            {areNotSame && (
                              <div style={{ color: "red", marginTop: "8px" }}>Les deux mots de passe sont différents.</div>
                            )}
                          </div>
                          <div className="form-group col-12">
                            <label htmlFor="phoneNumber1">Numéro de téléphone</label>
                            <input
                              type="text"
                              className="form-control"
                              value={user.phoneNumber1}
                              required
                              onChange={(e) => {
                                let val = e.target?.value;
                                setUser(prevState => { return { ...prevState, phoneNumber1: val } });
                              }}
                            />
                            {user.phoneNumber1?.length > 50 && (
                              <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                            )}
                            {(user.phoneNumber1 != "" && !(new RegExp(/^[0-9\b]+$/)).test(user.phoneNumber1)) && (
                              <p style={{ color: "red" }}>Format invalide</p>
                            )}
                          </div>
                          <div className="form-group col-12">
                            <label htmlFor="exampleFormControlSelect1">Rôles *</label>
                            <Select
                              value={userRoleSelectListOptions.filter(opt => user?.roles?.some(r => r == opt.value))}
                              options={userRoleSelectListOptions}
                              loadingMessage="En cours de chargement"
                              isMulti
                              onChange={(option) => {
                                let unique = [...new Set(option.map(item => item.value))];
                                setUser(prevState => {
                                  return { ...prevState, roles: unique }
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        ref={closeUserCreateModal}
                      >
                        Annuler
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={handleCreateUser}
                        disabled={isCreatting || hasMissingValue || areNotSame}
                      >
                        Soumettre &nbsp; {isCreatting ? <CircularProgress size={15} /> : <span></span>}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Create user modale */}

              {/* Start Delete user modale */}
              <div className="modal fade" id="deleteUserModal" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                  <div className="modal-content">
                    <div className="modal-header p-4">
                      <h4 className="mb-0 text-center">Suppression d'un utlisateur: {userToDelete?.firstName + " " + userToDelete?.lastName}</h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <form className="mt-2">
                      <div className="modal-body">
                        <div>
                          <div className="form-row">
                            <p>Voulez vous supprimer cet utilisateur?</p>

                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          ref={closeDeleteUserModal}
                        >
                          Non
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleDeleteUser}
                          disabled={isDeletting}
                        >
                          Oui &nbsp; {isDeletting ? <CircularProgress size={15} /> : <span></span>}
                        </button>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
              {/* End Delete user modale */}

              {/* Start update user modale */}
              <div className="modal fade" id="updateUserModal" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                  <div className="modal-content">
                    <div className="modal-header p-4">
                      <h4 className="mb-0 text-center">Modification d'un utilisateur</h4>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div>
                        <div className="form-row">
                          <div className="form-group col-12">
                            <label htmlFor="firstName">Prénom *</label>
                            <input
                              name="firstName"
                              type="text"
                              className="form-control"
                              value={userToUpdate.firstName}
                              required
                              onChange={(e) => {
                                let val = e.target?.value;
                                setUserToUpdate(prevState => { return { ...prevState, firstName: val } });
                              }}
                            />
                            {userToUpdate.firstName?.length > 50 && (
                              <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                            )}
                          </div>
                          <div className="form-group col-12">
                            <label htmlFor="lastName">Nom *</label>
                            <input
                              name="lastName"
                              type="text"
                              className="form-control"
                              value={userToUpdate.lastName}
                              required
                              onChange={(e) => {
                                let val = e.target?.value;
                                setUserToUpdate(prevState => { return { ...prevState, lastName: val } });
                              }}
                            />
                            {userToUpdate.lastName?.length > 50 && (
                              <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                            )}
                          </div>
                          <div className="form-group col-12">
                            <label htmlFor="email">Adresse email *</label>
                            <input
                              name="email"
                              type="text"
                              className="form-control"
                              value={userToUpdate.email}
                              required
                              onChange={(e) => {
                                let val = e.target?.value;
                                let isValide = validator.isEmail(e.target.value);
                                setUserToUpdate(prevState => { return { ...prevState, email: val } });
                                if (invalideEmail) {
                                  setInvalideEmail(!isValide);
                                }
                              }}
                              onBlur={(e) => {
                                let val = validator.isEmail(e.target.value);
                                setInvalideEmail(!val);
                              }}
                            />
                            {userToUpdate.email?.length > 50 && (
                              <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                            )}
                            {(userToUpdate.email != "" && invalideEmail) && (
                              <p style={{ color: "red" }}>Format d'email invalide</p>
                            )}
                          </div>
                          <div className="form-group col-12">
                            <label htmlFor="phoneNumber">Numéro de téléphone</label>
                            <input
                              name="phoneNumber"
                              type="text"
                              className="form-control"
                              value={userToUpdate.phoneNumber1}
                              required
                              onChange={(e) => {
                                let val = e.target?.value;
                                setUserToUpdate(prevState => { return { ...prevState, phoneNumber1: val } });
                              }}
                            />
                            {userToUpdate.phoneNumber1?.length > 50 && (
                              <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                            )}
                            {(userToUpdate.phoneNumber1 != undefined && userToUpdate.phoneNumber1 != "" && !(new RegExp(/^[0-9\b]+$/)).test(userToUpdate.phoneNumber1)) && (
                              <p style={{ color: "red" }}>Format invalide</p>
                            )}
                          </div>
                          <div className="form-group col-12">
                            <label htmlFor="exampleFormControlSelect2">Rôles *</label>
                            <Select
                              value={userRoleSelectListOptions.filter(opt => [...new Set(userToUpdate.roles?.map(item => item.id ? item.id : item))]?.some(r => r == opt.value))}
                              options={userRoleSelectListOptions}
                              loadingMessage="En cours de chargement"
                              isMulti
                              onChange={(option) => {
                                let unique = [...new Set(option.map(item => item.value))];
                                setUserToUpdate(prevState => {
                                  return { ...prevState, roles: unique }
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        ref={closeUserUpdateModal}
                      >
                        Annuler
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleUpdateUser}
                        disabled={isUpdatting || hasMissingValue}
                      >
                        Soumettre &nbsp; {isUpdatting ? <CircularProgress size={15} /> : <span></span>}
                      </button>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>

        </>
      )
      }
    </React.Fragment>


  )
}

UserListing.propTypes = {
  getUserList: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  storeUsers: PropTypes.object.isRequired,
  getRoleList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  getUserList: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  getRoleList: PropTypes.func.isRequired,
  storeUsers: state.users,
  storeRoles: state.role,
});

// export default connect(mapStateToProps, {
//   getUserList, getRoleList, createUser, updateUser, deleteUser
// })(UserListing)

export default withRouter(connect(mapStateToProps, { getUserList, getRoleList, createUser, updateUser, deleteUser })(UserListing));