import axios from "axios";
import { api } from "../../services/apiHelpers";

export const ACTION_LOADING = "ACTION_LOADING";
export const ACTION_SUCCESS = "ACTION_SUCCESS";
export const ACTION_ERROR = "ACTION_ERROR";
export const ACTION_SUBMITING = "ACTION_SUBMITING";
export const ACTION_SUBMITED = "ACTION_SUBMITED";

export const GET_JOB_LIST = "GET_JOB_LIST";
export const GET_ARCHIVEJOBS_LIST = "GET_ARCHIVEJOBS_LIST";
export const GET_JOB_LIST_INCLUDED_DESCRIPTION = "GET_JOB_LIST_INCLUDED_DESCRIPTION";
export const GET_JOB = "GET_JOB";
export const JOB_SEARCH = "JOB_SEARCH";
// export const ACTION_SUBMITED = "ACTION_SUBMITED"; 

export const getJobList = (includeInactive) => dispatch => {
    dispatch({
        type: ACTION_LOADING
    });
    return new Promise((resolve, reject) => {
        axios.get(api + "/Jobs/list?includeInactive=" + includeInactive).then(res => {
            if (res.data) {
                dispatch({
                    type: GET_JOB_LIST,
                    data: res.data.jobsList
                });
                dispatch({
                    type: ACTION_SUCCESS
                });
                resolve(res.data);
            }
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });

    })

};

export const jobSearch = (includeDescription, searchTerm) => dispatch => {
    dispatch({
        type: ACTION_LOADING
    });

    return new Promise((resolve, reject) => {
        let url = searchTerm != undefined ? "/Jobs/search?includeDescription=" + includeDescription + "&searchTerm="+ searchTerm : "/Jobs/search?includeDescription=" + includeDescription;
        axios.get(api + url).then(res => {
            if (res.data != null) {
                if (includeDescription) {
                    dispatch({
                        type: GET_JOB_LIST_INCLUDED_DESCRIPTION,
                        data: res.data.documents
                    });
                    dispatch({
                        type: ACTION_SUCCESS
                    });
                }
                else {
                    dispatch({
                        type: JOB_SEARCH,
                        data: res.data
                    });
                    dispatch({
                        type: ACTION_SUCCESS
                    });
                }
                resolve(res.data);
            }
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    })
};

export const getArchiveJobsList = () => dispatch => {
    dispatch({
        type: ACTION_LOADING
    });
    return new Promise((resolve, reject) => {
        axios.get(api + "/Jobs/archive/list").then(res => {
            if (res.data != null) {
                dispatch({
                    type: GET_ARCHIVEJOBS_LIST,
                    data: res.data.archiveJobsList
                });
                dispatch({
                    type: ACTION_SUCCESS
                });
                resolve(res.data.archiveJobsList);
            }
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    })
};

export const getJob = (jobId) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            axios.get(api + "account/deleteuser/" + jobId).then(res => {
                if (res.data.Success) {
                    dispatch({
                        type: GET_JOB_LIST,
                        data: res.data.jobList
                    });
                    dispatch({
                        type: GET_JOB,
                        data: res.data.Job
                    });
                }
                resolve(res.data);
            }).catch(error => {
                return dispatch({
                    type: ACTION_ERROR,
                    data: error
                });
            });
        }, 500);
    }).then(data => {
        dispatch({
            type: ACTION_SUCCESS
        });
        return data;
    });
};

export const getAdditionalQuestions = (jobId) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            axios.get(api + "/Jobs/additionalQuestions/" + jobId).then(res => {
                resolve(res.data);
            }).catch(error => {
                return dispatch({
                    type: ACTION_ERROR,
                    data: error
                });
            });
        }, 500);
    }).then(data => {
        return data;
    });
};

export const getArchiveJob = (jobId) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            axios.get(api + "/Jobs/archive/details/" + jobId).then(res => {
                resolve(res.data);
            }).catch(error => {
                return dispatch({
                    type: ACTION_ERROR,
                    data: error
                });
            });
        }, 500);
    }).then(data => {
        dispatch({
            type: ACTION_SUCCESS
        });
        return data;
    });
};

export const createJob = (job) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {

        axios.post(api + "/Jobs/post", job).then(res => {
            if (res.data.success) {
                // dispatch({
                //     type: GET_JOB_LIST,
                //     data: res.data.JobList
                // });
            }
            resolve(res.data);
        }).catch(error => {
            dispatch({
                type: ACTION_ERROR,
                data: error
            });
            resolve({ job: null, jobsList: null, success: false, message: "error.message" })
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;
    });
};

export const updateJob = (jobs) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {

        axios.post(api + "/Jobs/update", jobs).then(res => {
            if (res.data.Success) {
                dispatch({
                    type: GET_JOB_LIST,
                    data: res.data.jobList
                });
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;
    });
};

export const deleteJob = (id) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });

    return new Promise((resolve, reject) => {
        axios.get(api + "/Jobs/delete/" + id).then(res => {
            if (res.data.Success) {
                dispatch({
                    type: GET_JOB_LIST,
                    data: res.data.JobList
                });
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;
    });
};

export const downloadJobPdf = (id) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });

    return new Promise((resolve, reject) => {
        axios.post(api + "/Jobs/export/" + id).then(res => {
            if (res.data.success) {
            }
            resolve(res.data);
        }).catch(error => {
            resolve({ job: null, jobsList: null, success: false, message: "error.message" })
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;
    });
};

export const refrechJobSearchIndex = () => dispatch => {
    return new Promise((resolve, reject) => {
        axios.get(api + "/Jobs/search/refreshIndex").then(res => {
            if (res.data) {
                resolve(res.data);
            }
        });
    })
};

export const apply = (files, candidateInformation) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    
    let formData = new FormData();
    files.map((file) => (
        formData.append('files', file)
    ));
   
    formData.append("data", JSON.stringify(candidateInformation));
    return new Promise((resolve, reject) => {

        axios.post(api + "/Jobs/apply", formData).then(res => {
            
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;
    });
};

export const getJobApplicationList = () => dispatch => {
    return new Promise((resolve, reject) => {
        axios.get(api + "/Jobs/applications/list").then(res => {
            if (res.data) {
                resolve(res.data.jobApplicationsList);
            }
        }).catch(error => {
        });
    })
};

export const getJobApplicationDetails = (jobId) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.get(api + "/Jobs/applications/details/" + jobId).then(res => {
            if (res.data) {
                resolve(res.data);
            }
        }).catch(error => {
        });
    })
};

export const addAttachmentFromJob = (id, currentFile) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    let formData = new FormData();
    formData.append("file", currentFile);
    formData.append("jobId", id);
    return new Promise((resolve, reject) => {
        axios.post(api + "/Jobs/attachments/add", formData).then(res => {
            if (res.data.Success) {
                dispatch({
                    type: GET_JOB_LIST,
                    data: res.data.jobsList
                });
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;

    });
}

export const deleteAttachmentFromJob = (id, attachment) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        axios.get(api + "/Jobs/attachments/delete/" + id + "/" + attachment).then(res => {
            if (res.data) {
                dispatch({
                    type: GET_JOB_LIST,
                    data: res.data.jobsList
                });

                resolve(res.data);
            }
            else {
                resolve(null);
            }

        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;
    });
}
