import React, { useEffect, useRef } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getJobApplicationDetails } from "../../../redux/actions/JobActions"
import { CircularProgress, RefreshIcon } from '@material-ui/core';
// import darkblue from '../../../assets/images/bg/dark-blue.jpg';
import { Link, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from 'moment';
import 'moment/locale/fr';
import Select from 'react-select';
import { isAfter, isBefore, parse } from 'date-fns';
import siteService from '../../../services/siteService';

let ApplicationDetail = (props) => {
    const { getJobApplicationDetails, storeJob = {} } = props;

    const selectInputRef = React.useRef();

    const currentSite = siteService.getCurrentSite();
    const [applications, setApplications] = React.useState([]);
    const [searchResult, setSearchResult] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [canAddFilterOption, setCanAddFilterOption] = React.useState(false);
    const [hasAdditionalQuestions, setHasAdditionalQuestions] = React.useState(false);
    const [additionalQuestions, setAdditionalQuestions] = React.useState([]);
    const [filterOptions, setFilterOptions] = React.useState([]);
    const [operatorOptions, setOperatorOptions] = React.useState([
        { label: "=", value: "=" },
        { label: "<", value: "<" },
        { label: ">", value: ">" },
        { label: "<=", value: "<=" },
        { label: ">=", value: ">=" },
        { label: "Contient", value: "include" }
    ])
    const [selectedfilterOptions, setSelectedFilterOptions] = React.useState(
        [
            { label: "", type: "", required: false, value: "", optionsValue: [], id: "", selectedOperator: "", operators: [] }
        ]
    );

    const [jobApplications, setJobApplications] = React.useState("");

    useEffect(() => {
        let match = props.match;
        if (match !== undefined) {
            let params = match.params;
            if (params !== undefined && params.id !== undefined) {
                getJobApplicationDetails(params.id).then(response => {
                    setApplications(response.applications);
                    setSearchResult(response.applications);
                    setHasAdditionalQuestions(response.hasAdditionalQuestions);
                    setAdditionalQuestions(response.additionalQuestions);
                    let unique = [];
                    unique.push({ label: "Nom", type: "FreeText", require: false, value: "candidateLastName", isDisabled: false, optionsValue: [] });
                    unique.push({ label: "Prénom", type: "FreeText", require: false, value: "candidateFirstName", isDisabled: false, optionsValue: [] });
                    unique.push({ label: "Email", type: "FreeText", require: false, value: "candidateEmail", isDisabled: false, optionsValue: [] });
                    unique.push({ label: "Numéro de téléphone", type: "FreeText", require: false, value: "candidatePhoneNumber", isDisabled: false, optionsValue: [] });
                    if (response.additionalQuestions?.length) {
                        response.additionalQuestions?.forEach(element => {
                            unique.push(
                                {
                                    label: element.label,
                                    type: element.type,
                                    required: element.required,
                                    value: element.id,
                                    isDisabled: false,
                                    optionsValue: element.options?.split('\n')?.map((item, index) => ({ label: item, value: item }))
                                });
                        });
                    }
                    setFilterOptions(unique);

                    // setJobApplications(response.data);
                    setIsLoading(false);
                });
            }
        };
    }, []);

    useEffect(() => {
        disabledOptions();
        handleCanAddFilterOption();
    }, [selectedfilterOptions]);

    const disabledOptions = () => {
        let options = [...filterOptions];
        options.forEach(element => {
            if (selectedfilterOptions.some(f => f.id == element.value)) {
                let indexOf = options.findIndex(o => o.value == element.value);
                if (indexOf > -1) {
                    options[indexOf].isDisabled = true;
                }
               
            }
            else {
                let indexOf = options.findIndex(o => o.value == element.value);
                if (indexOf > -1) {
                    options[indexOf].isDisabled = false;
                }
            }
        });
        setFilterOptions(options);
    }
    const handleCanAddFilterOption = () => {
        if (selectedfilterOptions?.some(f => f.id == "" || f.value == "")) {
            setCanAddFilterOption(false);
        }
        else {
            setCanAddFilterOption(true);
        }
    }

    const getOperatorOptions = (type, index) => {
        let options = [];
        let array = [];
        switch (type) {
            case "DateTime":
                options = [
                    { label: "=", value: "=" },
                    { label: "<", value: "<" },
                    { label: ">", value: ">" },
                    { label: "<=", value: "<=" },
                    { label: ">=", value: ">=" }
                ]
                array = [...selectedfilterOptions];
                array[index].operators = options;
                array[index].selectedOperator = "=";
                setSelectedFilterOptions(array);
                break;
            case "Numeric":
                options = [
                    { label: "=", value: "=" },
                    { label: "<", value: "<" },
                    { label: ">", value: ">" },
                    { label: "<=", value: "<=" },
                    { label: ">=", value: ">=" }
                ]
                array = [...selectedfilterOptions];
                array[index].operators = options;
                array[index].selectedOperator = "=";
                setSelectedFilterOptions(array);
                break;
            case "FreeText":
                options = [
                    { label: "=", value: "=" },
                    { label: "Contient", value: "include" }
                ]
                array = [...selectedfilterOptions];
                array[index].operators = options;
                array[index].selectedOperator = "include";
                setSelectedFilterOptions(array);
                break;
            case "MultiValue":
                options = [
                    { label: "=", value: "=" },
                    { label: "Contient", value: "include" }
                ]
                array = [...selectedfilterOptions];
                array[index].operators = options;
                array[index].selectedOperator = "include";
                setSelectedFilterOptions(array);
                break;
            default:
                break;
        }
    }
    const search = () => {
        let result = applications;
        selectedfilterOptions.forEach(filter => {
            if (filter.id == "candidateFirstName") {
                if (filter.selectedOperator == "") {
                    result = result.filter(a => a.candidateFirstName?.toLowerCase().includes(filter.value.toLowerCase()));
                }
                else {
                    if (filter.selectedOperator == "=") {
                        result = result.filter(a => a.candidateFirstName.toLowerCase() == filter.value.toLowerCase());
                    }
                    else if (filter.selectedOperator == "include") {
                        result = result.filter(a => a.candidateFirstName?.toLowerCase().includes(filter.value.toLowerCase()));
                    }
                }
            }
            else if (filter.id == "candidateLastName") {
                if (filter.selectedOperator == "") {
                    result = result.filter(a => a.candidateLastName?.toString().includes(filter.value));
                }
                else {
                    if (filter.selectedOperator == "=") {
                        result = result.filter(a => a.candidateLastName.toLowerCase() == filter.value.toLowerCase());
                    }
                    else if (filter.selectedOperator == "include") {
                        result = result.filter(a => a.candidateLastName?.toString().toLowerCase().includes(filter.value.toLowerCase()));
                    }
                }
            }
            else if (filter.id == "candidateEmail") {
                if (filter.selectedOperator == "") {
                    result = result.filter(a => a.candidateEmail?.toString().toLowerCase().includes(filter.value.toLowerCase()));
                }
                else {
                    if (filter.selectedOperator == "=") {
                        result = result.filter(a => a.candidateEmail.toLowerCase() == filter.value.toLowerCase());
                    }
                    else if (filter.selectedOperator == "include") {
                        result = result.filter(a => a.candidateEmail?.toString().toLowerCase().includes(filter.value.toLowerCase()));
                    }
                }
            }
            else if (filter.id == "candidatePhoneNumber") {
                if (filter.selectedOperator == "") {
                    result = result.filter(a => a.candidatePhoneNumber1?.toString().includes(filter.value) || a.candidatePhoneNumber2?.toString().includes(filter.value));
                }
                else {
                    if (filter.selectedOperator == "=") {
                        result = result.filter(a => a.candidatePhoneNumber1 == filter.value || a.candidatePhoneNumber2 == filter.value);
                    }
                    else if (filter.selectedOperator == "include") {
                        result = result.filter(a => a.candidatePhoneNumber1?.toString().includes(filter.value) || a.candidatePhoneNumber2?.toString().includes(filter.value));
                    }
                }
            }
            else if (hasAdditionalQuestions) {
                if (filter.selectedOperator == "=" || filter.type == "SingleValue" || filter?.type == "Boolean") {
                    result = result.filter(a => a.additionalQuestionsAnswers?.some(resp => resp.questionId == filter.id && resp.answer == filter.value));
                }
                else if (filter.selectedOperator == "include") {
                    if (filter.type == "MultiValue") {
                        result = result.filter(a => a.additionalQuestionsAnswers?.some(resp =>
                            resp.questionId == filter.id
                            && (parseInt(resp.answer) == parseInt(filter.value))));
                        // result = result.filter(a => a.additionalQuestionsAnswers?.includes(filter.value));
                    }
                    else {
                        result = result.filter(a => a.additionalQuestionsAnswers?.some(resp => resp.questionId == filter.id && resp.answer.includes(filter.value)));
                    }
                }
                else if (filter.selectedOperator == "<") {
                    if (filter.type == "Numeric") {
                        result = result.filter(a => a.additionalQuestionsAnswers?.some(resp => resp.questionId == filter.id && (parseInt(resp.answer) < parseInt(filter.value))));
                    }
                    else if (filter.type == "DateTime") {
                        result = result.filter(a => a.additionalQuestionsAnswers?.some(resp => resp.questionId == filter.id && (moment(resp.answer).isBefore(filter.value))));
                    }
                }
                else if (filter.selectedOperator == ">") {
                    if (filter.type == "Numeric") {
                        result = result.filter(a => a.additionalQuestionsAnswers?.some(resp => resp.questionId == filter.id && (parseInt(resp.answer) > parseInt(filter.value))));
                    }
                    else if (filter.type == "DateTime") {
                        result = result.filter(a => a.additionalQuestionsAnswers?.some(resp => resp.questionId == filter.id && (moment(resp.answer).isAfter(filter.value))));
                    }
                }
                else if (filter.selectedOperator == "<=") {
                    if (filter.type == "Numeric") {
                        result = result.filter(a => a.additionalQuestionsAnswers?.some(resp => resp.questionId == filter.id && (parseInt(resp.answer) <= parseInt(filter.value))));
                    }
                    else if (filter.type == "DateTime") {
                        result = result.filter(a => a.additionalQuestionsAnswers?.some(resp => resp.questionId == filter.id && (moment(resp.answer).isSameOrBefore(filter.value))));
                    }
                }
                else if (filter.selectedOperator == ">=") {
                    if (filter.type == "Numeric") {
                        result = result.filter(a => a.additionalQuestionsAnswers?.some(resp => resp.questionId == filter.id && (parseInt(resp.answer) >= parseInt(filter.value))));
                    }
                    else if (filter.type == "DateTime") {
                        result = result.filter(a => a.additionalQuestionsAnswers?.some(resp => resp.questionId == filter.id && (moment(resp.answer).isSameOrAfter(filter.value))));
                    }
                }
            }
        });
        setSearchResult(result);
    }

    const getOperatorOptionValue = (operator) => {
        if (operator === "include") {
            return { label: "Contient", value: operator };
        }
        else {
            return { label: operator, value: operator };
        }
    }

    return (
        <>
            <React.Fragment>
                <Helmet>
                    <title> Administration | Offres d'emploi  | {currentSite.fullName}</title>
                </Helmet>
                {isLoading ? (
                    <div className="row bg-white pt-4 mt-lg-0">
                        <div className="col-lg-12">
                            <div className="loading-container bg-white">
                                <CircularProgress />
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="row bg-white pt-4 mt-lg-0 mb-4">
                            <div className="col-lg-12 mt-4 mt-lg-0">
                                <div className="form-row">
                                    <div className="form-group col-lg-9">
                                        <h2 className="title" style={{ marginLeft: "10px", marginTop: "-25px" }}><span>Recherche avancée</span></h2>
                                    </div>
                                    <div style={{ textAlign: "right", marginTop: "-15px" }} className="form-group pt-4 col-lg-3">
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-sm"
                                            onClick={e => {
                                                setSelectedFilterOptions(
                                                    [
                                                        {
                                                            label: "",
                                                            type: "",
                                                            required: false,
                                                            value: "",
                                                            optionsValue: [],
                                                            id: "",
                                                            selectedOperator: "",
                                                            operators: []
                                                        }
                                                    ]
                                                );
                                                selectInputRef.current?.clearValue();
                                                setSearchResult(applications);
                                            }}
                                        > Réinitialiser
                                        </button>
                                    </div>
                                    {selectedfilterOptions?.map((filter, index) => (
                                        <>
                                            <div className="form-group col-md-5">
                                                <label>Option de recherche</label>
                                                <Select
                                                    value={
                                                        {
                                                            label: filter.label,
                                                            value: filter.id,
                                                            optionsValue: filter.optionsValue,
                                                            type: filter.type,
                                                            required: filter.required
                                                        }
                                                    }
                                                    options={filterOptions}
                                                    loadingMessage="En cours de chargement"
                                                    onChange={(option) => {
                                                        if (selectedfilterOptions?.length == 1) {
                                                            let array = [...selectedfilterOptions];
                                                            array[0].id = option.value;
                                                            array[0].label = option.label;
                                                            array[0].optionsValue = option.optionsValue;
                                                            array[0].required = option.required;
                                                            array[0].selectedOperator = "";
                                                            array[0].value = "";
                                                            array[0].type = option.type;
                                                            setSelectedFilterOptions(array);
                                                            getOperatorOptions(option.type, 0);
                                                        }
                                                        else {
                                                            let indexOf = selectedfilterOptions.findIndex(x => x.id == filter.id);
                                                            if (indexOf > -1) {
                                                                let array = [...selectedfilterOptions];
                                                                array[indexOf].id = option.value;
                                                                array[indexOf].label = option.label;
                                                                array[indexOf].optionsValue = option.optionsValue;
                                                                array[indexOf].required = option.required;
                                                                array[indexOf].selectedOperator = "";
                                                                array[indexOf].value = "";
                                                                array[indexOf].type = option.type;
                                                                setSelectedFilterOptions(array);
                                                                getOperatorOptions(option.type, indexOf);
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Opérateurs</label>
                                                <Select
                                                    value={getOperatorOptionValue(filter.selectedOperator)}
                                                    options={filter.operators}
                                                    isDisabled={(filter?.type == "SingleValue" || filter?.type == "Boolean") ? true : false}
                                                    loadingMessage="En cours de chargement"
                                                    onChange={(option) => {
                                                        //setApplications(prevState => { return { ...prevState, category: option?.label } });
                                                        let indexOf = selectedfilterOptions.findIndex(x => x.id == filter.id);
                                                        if (indexOf > -1) {
                                                            let array = [...selectedfilterOptions];
                                                            array[indexOf].selectedOperator = option.value;
                                                            setSelectedFilterOptions(array);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            {
                                                filter.type == "MultiValue" ?
                                                    (
                                                        <>
                                                            <div className="form-group col-md-4">
                                                                <label>Choisissez une valeur</label>
                                                                <Select
                                                                    key={filter.id}
                                                                    isMulti
                                                                    options={filter.optionsValue}
                                                                    loadingMessage="En cours de chargement"
                                                                    onChange={(option) => {
                                                                        let indexOf = selectedfilterOptions.findIndex(x => x.id == filter.id);
                                                                        if (indexOf > -1) {
                                                                            let unique = [...new Set(option.map(item => item.value))];
                                                                            let array = [...selectedfilterOptions];
                                                                            array[indexOf].value = unique.join('\n');
                                                                            setSelectedFilterOptions(array);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </>
                                                    ) :
                                                    filter.type == "SingleValue" ?
                                                        (
                                                            <>
                                                                <div className="form-group col-md-4">
                                                                    <label>Choisissez une valeur</label>
                                                                    <Select
                                                                        key={filter.id}
                                                                        options={filter.optionsValue}
                                                                        loadingMessage="En cours de chargement"
                                                                        onChange={(option) => {
                                                                            let indexOf = selectedfilterOptions.findIndex(x => x.id == filter.id);
                                                                            if (indexOf > -1) {
                                                                                let array = [...selectedfilterOptions];
                                                                                array[indexOf].value = option.value;
                                                                                setSelectedFilterOptions(array);
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </>
                                                        ) :
                                                        filter.type == "Numeric" ?
                                                            (
                                                                <div className="form-group col-md-4">
                                                                    <label>Mettez un nombre</label>
                                                                    <input
                                                                        value={filter.value}
                                                                        type="number"
                                                                        className="form-control"
                                                                        name="title"
                                                                        onChange={(e) => {
                                                                            let indexOf = selectedfilterOptions.findIndex(x => x.id == filter.id);
                                                                            if (indexOf > -1) {
                                                                                let array = [...selectedfilterOptions];
                                                                                array[indexOf].value = e.target.value;
                                                                                setSelectedFilterOptions(array);
                                                                            }
                                                                        }}
                                                                        placeholder=""
                                                                    // disabled={published}
                                                                    />
                                                                </div>
                                                            ) :
                                                            filter.type == "Boolean" ?
                                                                (
                                                                    <div className="form-group col-md-4">
                                                                        <label>Choisissez par oui ou non</label>
                                                                        <div style={{ padding: "14px 0px 14px 0px" }} className="form-group">
                                                                            <div class="custom-control custom-radio d-inline">
                                                                                <input
                                                                                    class="custom-control-input"
                                                                                    type="radio"
                                                                                    id={"yes"}
                                                                                    name={filter.id}
                                                                                    value="Oui"
                                                                                    onClick={e => {
                                                                                        let val = e.target?.value;
                                                                                        let indexOf = selectedfilterOptions.findIndex(x => x.id == filter.id);
                                                                                        if (indexOf > -1) {
                                                                                            let array = [...selectedfilterOptions];
                                                                                            array[indexOf].value = val;
                                                                                            setSelectedFilterOptions(array);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                <label class="custom-control-label" for={"yes"}>Oui</label>
                                                                            </div>
                                                                            <div class="custom-control custom-radio d-inline ml-3">
                                                                                <input
                                                                                    class="custom-control-input"
                                                                                    type="radio"
                                                                                    id={"no"}
                                                                                    name={filter.id}
                                                                                    value="Non"
                                                                                    onClick={e => {
                                                                                        let val = e.target?.value;
                                                                                        let indexOf = selectedfilterOptions.findIndex(x => x.id == filter.id);
                                                                                        if (indexOf > -1) {
                                                                                            let array = [...selectedfilterOptions];
                                                                                            array[indexOf].value = val;
                                                                                            setSelectedFilterOptions(array);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                <label class="custom-control-label" for={"no"}>Non</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                ) :
                                                                filter.type == "DateTime" ?
                                                                    (
                                                                        <div className="form-group col-sm-4">
                                                                            <label>Choisissez une date</label>
                                                                            <div className="input-group date">
                                                                                <input
                                                                                    // value={newJob?.closingDate}
                                                                                    type="date"
                                                                                    className="form-control"
                                                                                    name="closingDate"
                                                                                    onChange={(e) => {
                                                                                        let indexOf = selectedfilterOptions.findIndex(x => x.id == filter.id);
                                                                                        if (indexOf > -1) {
                                                                                            let array = [...selectedfilterOptions];
                                                                                            array[indexOf].value = e.target?.value;
                                                                                            setSelectedFilterOptions(array);
                                                                                        }
                                                                                    }}
                                                                                // disabled={published}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    ) :
                                                                    (
                                                                        <div className="form-group col-sm-4">
                                                                            <label>Ajouter une information</label>
                                                                            <input
                                                                                value={filter.value}
                                                                                type="text"
                                                                                className="form-control"
                                                                                name="closingDate"
                                                                                onChange={(e) => {
                                                                                    let val = e.target?.value;
                                                                                    let indexOf = selectedfilterOptions.findIndex(x => x.id == filter.id);
                                                                                    if (indexOf > -1) {
                                                                                        let array = [...selectedfilterOptions];
                                                                                        array[indexOf].value = val;
                                                                                        setSelectedFilterOptions(array);
                                                                                    }
                                                                                }}
                                                                            // disabled={published}
                                                                            />
                                                                        </div>
                                                                    )}

                                            <div className="form-group col-md-1">
                                                {index >= 1 && (
                                                    <a style={{ float: "right" }} href="assets/#" className="text-danger" data-toggle="modal" title="" data-target="#deleteJob" data-original-title="Delete">
                                                        <i style={{ textAlign: "center", marginTop: "45px" }}
                                                            className="far fa-trash-alt"
                                                            onClick={() => {
                                                                let array = [...selectedfilterOptions];
                                                                let result = array?.filter(f => f.id != filter.id);
                                                                setSelectedFilterOptions(result);
                                                            }}>
                                                        </i>
                                                    </a>
                                                )}
                                            </div>
                                        </>

                                    ))}
                                    <div className="form-group col-md-2"></div>
                                    <div style={{ textAlign: "right" }} className="form-group col-md-10">
                                        <button
                                            type="button"
                                            className="btn btn-secondary btn-sm"
                                            disabled={""}
                                            onClick={e => {
                                                search()
                                                // let array = [...selectedfilterOptions];
                                                // array.push({ label: "", type: "", required: false, value: "", optionsValue: [], id: "" });
                                                // setSelectedFilterOptions(array);
                                            }}
                                        > Lancer la recherche
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-sm"
                                            disabled={!filterOptions?.some(o => o.isDisabled == false) || !canAddFilterOption}
                                            onClick={e => {
                                                let array = [...selectedfilterOptions];
                                                array.push({ label: "", type: "", required: false, value: "", optionsValue: [], id: "", operators: "" });
                                                setSelectedFilterOptions(array);
                                            }}
                                        >
                                            Ajouter une option de recherche
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row bg-white pt-4 mt-lg-0">
                            <div className="col-lg-12 mt-4 mt-lg-0 mb-3">
                                <div className="row">
                                    <div className="col-md-7 col-sm-5 d-flex align-items-center">
                                        <div className="section-title-02 mb-0 ">
                                            <h4 className="mb-4" style={{ marginLeft: "10px" }}>{searchResult?.length} postulations</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-style" id="accordion-Two">
                                    {searchResult?.map((application, index) => (
                                        <div className="card">
                                            <div className="card-header" id={"heading" + index}>
                                                <h5 className="accordion-title mb-0">
                                                    <button
                                                        className="btn btn-link d-flex align-items-center ml-auto"
                                                        data-toggle="collapse"
                                                        data-target={"#collapse" + index}
                                                        aria-expanded="false"
                                                        aria-controls={"collapse" + index}
                                                    >
                                                        {application?.candidateFirstName + " " + application?.candidateLastName + " | " + application?.candidateEmail + (application?.candidatePhoneNumber1 !== "" ? " | " + application?.candidatePhoneNumber1 : "")}
                                                        <i className="fas fa-chevron-down fa-xs"></i>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div
                                                id={"collapse" + index}
                                                className="collapse accordion-content"
                                                aria-labelledby={"heading" + index}
                                                data-parent="#accordion-Two"
                                            >
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div style={{ marginBottom: "20px" }}>
                                                                <h4><span>Documents</span></h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <table className="table manage-candidates-top mb-4" style={{ width: "100%" }}>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: "50%" }}>Nom</th>
                                                                <th style={{ width: "20%" }}>Date</th>
                                                                <th style={{ width: "25%" }} className="action text-right"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <>
                                                                {application?.attachments?.map((file, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <a href={file?.url} target="_blank">
                                                                                {file?.name}
                                                                            </a>
                                                                        </td>
                                                                        <td>
                                                                            {moment(file?.lastModifiedDate).format('Do MM YYYY')}
                                                                        </td>
                                                                        <td style={{ textAlign: "right" }}>
                                                                            <a href={file.url} className="text-primary" target="_blank">
                                                                                <i className="fas fa-cloud-download-alt"></i>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </>
                                                        </tbody>
                                                    </table>

                                                    {/* Questions supplémentaires */}

                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div style={{ marginBottom: "20px" }}>
                                                                <h4><span>Questionnaire</span></h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <table className="table manage-candidates-top mb-0" style={{ width: "100%" }}>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: "50%" }}>Questions</th>
                                                                <th style={{ width: "50%" }}>Réponses</th>
                                                                {/* <th style={{ width: "25%" }} className="action text-right"></th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <>
                                                                {application?.additionalQuestionsAnswers?.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            {item.questionLabel}
                                                                        </td>
                                                                        <td>
                                                                            {item.answer}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </React.Fragment>
        </>
    );
}
ApplicationDetail.propTypes = {
    getJobApplicationDetails: PropTypes.func.isRequired,
    storeJob: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    getJobApplicationDetails: PropTypes.func.isRequired,
    storeJob: state.job,
});

export default withRouter(connect(mapStateToProps, { getJobApplicationDetails })(ApplicationDetail));