import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import Pdf from "react-to-pdf";
import { getJobList, jobSearch, downloadJobPdf } from "../../redux/actions/JobActions";
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactLoading from "react-loading";

const ref = React.createRef();

export const JobExport = (props) => {
    const {
        jobSearch,
        storeJob = {},
        job = {},
        downloadJobPdf,
    } = props;
    const [jobToExport, setJobToExport] = React.useState(null);
    const [isExporting, setIsExporting] = React.useState(false);
    const closeExportJobsModal = React.useRef();
    const [jobNumber, setJobNumber] = React.useState("");

    const downloadPdf = () => {
        downloadJobPdf(job.jobId).then(resp => {
            closeExportJobsModal.current.click();
            window.open(resp.url, '_blank');
        })
    }

    return (
        <>
            <div>
                <li>
                    <a
                        href="assets/#"
                        data-toggle="modal"
                        title=""
                        data-target="#exportJob"
                        data-original-title="Export"
                        onClick={downloadPdf}
                    >
                        <i className="fas fa-download fa-fw"></i><span className="pl-2">Télécharger cette offre</span>
                    </a>
                </li>
            </div>

            {/* Start Export job modale */}
            <div className="modal fade mt-5" id="exportJob" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header p-4" hidden>
                            <button
                                ref={closeExportJobsModal}
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="mt-2">
                            <div className="modal-body">
                                <div ref={ref}>
                                    <div className="container">
                                        <div className="row align-items-center justify-content-center">
                                            <div className="col-">
                                                <h4>
                                                    Téléchargement en cours
                                                </h4>
                                            </div>
                                            <div className="col-2">
                                                <ReactLoading type="bubbles" color="#007cb5" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Export job modale */}
        </>
    );
}

JobExport.propTypes = {
    getJobList: PropTypes.func.isRequired,
    jobSearch: PropTypes.func.isRequired,
    downloadJobPdf: PropTypes.func.isRequired,
    storeJob: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    getJobList: PropTypes.func.isRequired,
    jobSearch: PropTypes.func.isRequired,
    downloadJobPdf: PropTypes.func.isRequired,
    storeJob: state.job,
});

export default connect(mapStateToProps, { getJobList, jobSearch, downloadJobPdf })(JobExport)
