export const api = getApiUrl();

function getApiUrl() {
    var host = window.location.hostname;
    switch (host.toLowerCase()) {
        case "localhost":
            //return "http://malibaara-001-site4.htempurl.com/";
            return "https://localhost:44316";

        //case "malibaara-demo.s3-website.eu-west-3.amazonaws.com":
            //return "http://malibaaraservices-demo.eba-rzpk9xgf.eu-west-3.elasticbeanstalk.com/";

        case "malibaara-001-site5.htempurl.com":
            return "http://malibaara-001-site4.htempurl.com/";
            //return "https://localhost:44316";

        case "malibaara-001-site1.htempurl.com":
            return "http://malibaara-001-site2.htempurl.com";

        case "malibaara.com":
            return "https://malibaara-services.com";

        case "guineebaara-001-site1.htempurl.com":
            return "http://guineebaara-001-site2.htempurl.com";

        case "guineebaara.com":
            return "https://guineebaara-services.com";


        default:
            //do nothing
    }
}