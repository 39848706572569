import {
    ACTION_LOADING,
    ACTION_SUCCESS,
    ACTION_ERROR,
    ACTION_SUBMITING,
    ACTION_SUBMITED,
    GET_TENDER_LIST,
    TENDER_SEARCH,
    GET_TENDER_LIST_INCLUDED_DESCRIPTION,
    GET_ARCHIVETENDERS_LIST
} from "../actions/TenderActions";

const initialState = {
    tenderList: [],
    archiveTendersList: [],
    tenderListIncludedDescription: [],
    tenderSearchResult: {},
    loading: false,
    success: false,
    failed: false,
    submitting: false
};

const TenderReducer = function (state = initialState, action) {
    switch (action.type) {

        case ACTION_LOADING: {
            return {
                ...state,
                loading: true,
                success: false,
                failed: false
            };
        }
        case ACTION_SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                failed: false,
            };
        }
        case ACTION_ERROR: {
            return {
                ...state,
                failed: true,
                success: false,
                loading: false,
                showAlert: true,
                alertMessage: action.data,
            };
        }
        case ACTION_SUBMITING: {
            return {
                ...state,
                submitting: true
            };
        }
        case ACTION_SUBMITED: {
            return {
                ...state,
                submitting: false
            };
        }
        case GET_TENDER_LIST: {
            return {
                ...state,
                tenderList: [...action.data]
            };
        }
        case GET_ARCHIVETENDERS_LIST: {
            return {
                ...state,
                archiveTendersList: [...action.data]
            };
        }
        case TENDER_SEARCH: {
            return {
                ...state,
                tenderSearchResult: { ...action.data }
            };
        }
        case GET_TENDER_LIST_INCLUDED_DESCRIPTION: {
            return {
                ...state,
                tenderListIncludedDescription: [...action.data]
            };
        }
        default: {
            return state;
        }
    }
};

export default TenderReducer;
