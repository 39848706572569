import axios from "axios";
import { api } from "../../../services/apiHelpers";

export const contactUs = (value) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(api + "/pages/contact/send", value).then(res => {
            resolve(res.data);
        }).catch(error => {
            resolve(error);
        });
    });
};