import React, {  useEffect } from 'react';
import { Helmet } from "react-helmet";
import defaultBanner from '../../../assets/images/bg/default-banner.jpg';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { contactUs } from '../../redux/actions/pages/ContactActions';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CustomAlert from './../shared/views/CustomAlert';
import ContactInformations from './../shared/views/ContactInformations';
import siteService from '../../services/siteService';

const Contact = (props) => {

    const { contactUs } = props;

    const currentSite = siteService.getCurrentSite();
    const [hasMissingValue, setHasMissingValue] = React.useState(false);
    const [sent, setSent] = React.useState(false);
    const [contact, setContact] = React.useState({
        FirstName: "",
        LastName: "",
        Email: "",
        PhoneNumber: "",
        Message: "",
        CaptchaResponse: ""
    });

    useEffect(() => {
        if (contact.FirstName === null
            || contact.FirstName == ""
            || contact.LastName === null
            || contact.LastName == ""
            || contact.Email === null
            || contact.Email == ""
            || contact.PhoneNumber === null
            || contact.PhoneNumber == ""
            || contact.Message === null
            || contact.Message == ""
            || contact.CaptchaResponse === null
            || contact.CaptchaResponse == ""
        ) {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);
        }
    }, [contact])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let handleSubmit = () => {
        contactUs(contact).then(response => {
            setSent(true);
        });
    }

    return (
        <>
            <React.Fragment>
                <Helmet>
                    <title> Contact | {currentSite.fullName}</title>
                </Helmet>
                <div className="header-inner" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${defaultBanner})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 style={{ color: "white" }}>Contact</h2>
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item"><NavLink tag={Link} to="/">Accueil</NavLink></li>
                                    <li className="breadcrumb-item"><i className="fas fa-chevron-right"></i>Pages</li>
                                    <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> Contact </span></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>                
                <section  className="space-ptb" style={{ padding: "50px 0" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8">
                                <div className="bg-light-3 p-4">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="section-title">
                                                <h2 className="title"><span>Formulaire de contact</span></h2>
                                                <p>Remplissez le formulaire ci-dessous un membre de notre équipe vous repondra dans les plus brefs délais.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <input
                                                    value={contact?.FirstName}
                                                    type="text"
                                                    className="form-control"
                                                    id="FirstName"
                                                    placeholder="Prénom"
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setContact(prevState => { return { ...prevState, FirstName: val } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <input
                                                    value={contact?.LastName}
                                                    type="text"
                                                    className="form-control"
                                                    id="LastName"
                                                    placeholder="Nom"
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setContact(prevState => { return { ...prevState, LastName: val } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <input
                                                    value={contact?.Email}
                                                    type="text"
                                                    className="form-control"
                                                    id="Email"
                                                    placeholder="Adresse email"
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setContact(prevState => { return { ...prevState, Email: val } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <input
                                                    value={contact?.PhoneNumber}
                                                    type="text"
                                                    className="form-control"
                                                    id="PhoneNumber"
                                                    placeholder="Numéro de téléphone"
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setContact(prevState => { return { ...prevState, PhoneNumber: val } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-12">
                                                <textarea
                                                    value={contact?.Message}
                                                    rows="5"
                                                    className="form-control"
                                                    id="Message"
                                                    placeholder="Message"
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setContact(prevState => { return { ...prevState, Message: val } });
                                                    }}
                                                >
                                                </textarea>
                                            </div>
                                            <div className="form-group col-12">
                                                <ReCAPTCHA
                                                    sitekey={currentSite.googleCaptchaSiteKey}
                                                    onChange={(value) => {
                                                        setContact(prevState => { return { ...prevState, CaptchaResponse: value } });
                                                       
                                                    }}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={handleSubmit}
                                                    disabled={hasMissingValue}
                                                >
                                                    Envoyer
                                                </button>
                                            </div>
                                            {sent && (
                                                <div className="col-12 mt-4">
                                                    <CustomAlert content="Votre message a été envoyé avec succès." />
                                                </div>
                                            )}
                                            <div className="col-12 mt-4 justify">
                                                <i>Si vous nous contactez parce que vous êtes à la recherche d’un emploi, nous vous conseillons de vous enregistrer sur le site à partir de la page ‘candidats’. Malibaara n’offre pas d’emploi mais aide les candidats à obtenir un emploi en publiant les offres d’emploi (avis de recrutement) et en les leur acheminant par SMS et e-mail.</i>
                                            </div>                                                                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 mt-4 mt-xl-0">                                
                                <div className="row">
                                    <div className="col-lg-12">
                                        <ContactInformations />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>                
            </React.Fragment>
        </>
    )
}

Contact.propTypes = {
    contactUs: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    contactUs: PropTypes.func.isRequired
});

export default connect(mapStateToProps, { contactUs })(Contact)

