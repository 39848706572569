import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getJobList, jobSearch } from "../../redux/actions/JobActions"
import { CircularProgress } from '@material-ui/core';
import { Helmet } from "react-helmet";
import { getAdsList } from "../../redux/actions/admin/AdsActions";
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { CategoryBannerHelper } from '../../views/shared/CategoryBanners';
import Sidebar from '../shared/views/Sidebar';
import JobExport from '../jobs/JobExport';
import createAccountImage from '../../../assets/images/pourquoi-creer-un-compte.jpg';
import CustomGoogleAdsense from '../shared/views/CustomGoogleAdsense';
import siteService from '../../services/siteService';
//import "./styles.css";
import Pdf from "react-to-pdf";

export const JobDetail = (props) => {
    const {
        jobSearch,
        storeJob = {},
    } = props;
    const [isLoading, setIsLoading] = React.useState(true);
    const [job, setJob] = React.useState(null);
    const [jobNumber, setJobNumber] = React.useState("");
    const ref = React.createRef();
    const currentSite = siteService.getCurrentSite();

    useEffect(() => {
        let match = props.match;
        if (match !== undefined) {
            let params = match.params;
            if (params !== undefined && params.jobNumber !== undefined) {
                setJobNumber(params.jobNumber);
            }
        };
    }, []);

    useEffect(() => {
        if (jobNumber != "") {
            if (storeJob.jobListIncludedDescription.length == 0 && job === null) {
                jobSearch(true).then(response => {
                    if (response) {
                        let jobFromrequest = response.documents.find(d => d.jobNumber == jobNumber);
                        if (jobFromrequest) {
                            setJob(jobFromrequest);
                        }
                    }
                })
            }
            else if (storeJob.jobListIncludedDescription.length > 0 && job === null) {
                let jobFromrequest = storeJob.jobListIncludedDescription.find(d => d.jobNumber == jobNumber);
                if (jobFromrequest) {
                    setJob(jobFromrequest);
                }
            }
        }
    }, [jobNumber]);

    useEffect(() => {
        if (job !== null && isLoading) {
            setIsLoading(false);
            window.scrollTo(0, 0)
        }
    }, [job]);

    return (
        <>
            <React.Fragment>

                {isLoading ? (
                    <div className="loading-container">
                        <CircularProgress />
                        <div>Chargement de l'offre d'emploi en cours...</div>
                    </div>
                ) : (
                    <>
                        <Helmet>
                            <title>{job.title} | {job.jobNumber} | {currentSite.fullName}</title>
                        </Helmet>

                        <div className={"header-inner category-header category-" + job.category} style={{ background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${CategoryBannerHelper.getLevel(job.category)})` }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h2 style={{ color: "white" }}>{job.title}</h2>
                                        <ol className="breadcrumb mb-0 p-0">
                                            <li className="breadcrumb-item"><NavLink tag={Link} to="/">Accueil</NavLink></li>
                                            <li className="breadcrumb-item"><i className="fas fa-chevron-right"></i><NavLink tag={Link} to="/jobs/search">Offres d'emploi</NavLink></li>
                                            <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> {job.jobNumber} </span></li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="space-ptb" style={{ paddingTop: "40px" }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 p-4">
                                        <div className="row pt-4 bg-light" style={{ marginTop: "-25px" }}>
                                            <div className="col-md-4 col-sm-6 mb-4">
                                                <div className="d-flex">
                                                    <i className="font-xll text-primary align-self-center flaticon-apartment"></i>
                                                    <div className="feature-info-content pl-3">
                                                        <label className="mb-0">Entreprise</label>
                                                        <span className="mb-0 font-weight-bold d-block text-dark">{job.companyName}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 mb-4">
                                                <div className="d-flex">
                                                    <i className="font-xll text-primary align-self-center flaticon-briefcase"></i>
                                                    <div className="feature-info-content pl-3">
                                                        <label className="mb-0">Catégorie</label>
                                                        <span className="mb-0 font-weight-bold d-block text-dark">{job.categoryDisplayName}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6">
                                                <div className="d-flex">
                                                    <i className="font-xll text-primary align-self-center flaticon-map"></i>
                                                    <div className="feature-info-content pl-3">
                                                        <label className="mb-0">Ville</label>
                                                        <span className="mb-0 font-weight-bold d-block text-dark">{job.location}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 mb-md-0 mb-4">
                                                <div className="d-flex">
                                                    <i className="font-xll text-primary align-self-center flaticon-appointment"></i>
                                                    <div className="feature-info-content pl-3">
                                                        <label className="mb-0">Date de publication</label>
                                                        <span className="mb-0 font-weight-bold d-block text-dark">{job.postedDateInFrench}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 mb-sm-0 mb-4">
                                                <div className="d-flex">
                                                    <i className="font-xll text-primary align-self-center flaticon-appointment"></i>
                                                    <div className="feature-info-content pl-3">
                                                        <label className="mb-0">Date limite de dépot</label>
                                                        <span className="mb-0 font-weight-bold d-block text-dark">{job.closingDateInFrench}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-6 mb-4">
                                                <div className="d-flex">
                                                    <i className="font-xll text-primary align-self-center flaticon-bar-chart"></i>
                                                    <div className="feature-info-content pl-3">
                                                        <label className="mb-0">Type de contrat</label>
                                                        <span className="mb-0 font-weight-bold d-block text-dark">{job.type}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row bg-light">
                                            <div className="col-lg-12">
                                                <div className="my-4">
                                                    <h5 className="mb-3 mb-md-4">Description</h5>
                                                    <div dangerouslySetInnerHTML={{ __html: job.description }} />
                                                </div>
                                                {job?.receiveEmailNotification && (
                                                    <div>
                                                        <div className="widget border-0 mb-4">
                                                            <a className="btn btn-primary btn-block" href={"/jobs/apply/" + jobNumber}><i className="far fa-paper-plane"></i>Postuler</a>
                                                        </div>
                                                    </div>
                                                )}
                                                <CustomGoogleAdsense location="Wide2" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="sidebar mb-0">
                                            <div className="widget">
                                                <div className="company-address widget-box">
                                                    <div className="company-address-map text-center">
                                                        <img className="img-fluid" src={job.imageUrl != "" ? job.imageUrl : "assets/images/default-job.png"} alt="" />
                                                    </div>
                                                    <ul className="list-unstyled mt-3">
                                                        <li><i className="fas fa-building fa-flip-horizontal fa-fw"></i><span className="pl-2">{job.companyName}</span></li>
                                                        {job?.showPhoneNumber && (
                                                            <li><a href={`tel:${job.companyPhoneNumber1Contact}`}><i className="fas fa-phone fa-flip-horizontal fa-fw"></i><span className="pl-2">{job.companyPhoneNumber1Contact} {job.companyPhoneNumber2Contact}</span></a></li>
                                                        )}
                                                        {job?.showEmailAddress && (
                                                            <li><a href={`mailto:${job.applicationEmail}`}><i className="fas fa-envelope fa-fw"></i><span className="pl-2">{job.applicationEmail}</span></a></li>
                                                        )}
                                                        <li>
                                                            <Link to={"/jobs/search?companyId=" + job.companyId}><i className="fas fa-link fa-fw"></i><span className="pl-2">Afficher toutes les offres</span></Link>
                                                        </li>
                                                        <div>< JobExport job={job} /></div>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="widget border-0" style={{ marginTop: "30px" }}>
                                                <div className="jobster-company-view">
                                                    <ul className="list-unstyled">
                                                        <Sidebar />
                                                        <div className="widget border-0">
                                                            <div className="jobster-company-view">
                                                                <ul className="list-unstyled">
                                                                    <li>
                                                                        <div className="widget-box">
                                                                            <div className="d-flex">
                                                                                <div>
                                                                                    <Link to={"/candidates/signup"}>
                                                                                        <img className="img-fluid" src={createAccountImage} alt="" />
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <CustomGoogleAdsense location="Side1" />
                                                        {/* <CustomGoogleAdsense location="Side2" />
                                                        <CustomGoogleAdsense location="Side3" /> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </>
                )}

            </React.Fragment>

        </>
    )
}


JobDetail.propTypes = {
    getJobList: PropTypes.func.isRequired,
    jobSearch: PropTypes.func.isRequired,
    storeJob: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    getJobList: PropTypes.func.isRequired,
    jobSearch: PropTypes.func.isRequired,
    storeJob: state.job,
});

export default connect(mapStateToProps, { getJobList, jobSearch })(JobDetail)