import React, { useEffect, Fragment } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getArchiveTendersList } from "../../redux/actions/TenderActions"
import { CircularProgress } from '@material-ui/core';
import defaultBanner from '../../../assets/images/bg/default-banner.jpg';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactDatatable from '@ashvin27/react-datatable';
import { useHistory } from 'react-router-dom';
import siteService from '../../services/siteService';
import moment from 'moment';
import 'moment/locale/fr';

let columns = [
    {
        key: "tenderNumber",
        text: "Numéro",
        className: "tenderNumber",
        align: "left",
        sortable: true,
    },
    {
        key: "title",
        text: "Titre",
        className: "title",
        align: "left",
        sortable: true,
    },
    {
        key: "postedDate",
        text: "Date de publication",
        className: "postedDate",
        align: "left",
        sortable: true,
    },
    {
        key: "employer",
        text: "Employeur",
        className: "employer",
        align: "left",
        sortable: true,
    }
]

let config = {
    page_size: 25,
    length_menu: [25, 50, 100],
    button: {
        excel: false,
        print: false,
        extra: false,
    }
}


let TenderArchiveListing = (props) => {

    const {
        getArchiveTendersList,
        storeTender
    } = props;

    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState(true);
    const [archiveTendersList, setArchiveTendersList] = React.useState([]);
    const currentSite = siteService.getCurrentSite();

    useEffect(() => {
        window.scrollTo(0, 0);

        if (storeTender.archiveTendersList?.length > 0 && archiveTendersList?.length == 0) {
            setArchiveTendersList(storeTender.archiveTendersList);
            setIsLoading(false);
        }
        else {
            getArchiveTendersList().then(response => {
                setArchiveTendersList(response);
                setIsLoading(false);
            });
        }
    }, []);

    let rowClickedHandler = (event, data, rowIndex) => {
        history.push(`/tenders/archive/details/${data.tenderNumber}`);
    }

    return (
        <>
            <Helmet>
                <title> Archives - Appel d'offre  | {currentSite.fullName}</title>
            </Helmet>
            {isLoading ?
                (
                    <div className="loading-container">
                        <CircularProgress />
                        <div>Chargement des archives d'appel d'offre en cours...</div>
                    </div>
                ) :
                (
                    <>
                        <div className="header-inner" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${defaultBanner})` }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h2 style={{ color: "white" }}>Archives - Appel d'offre</h2>
                                        <ol className="breadcrumb mb-0 p-0">
                                            <li className="breadcrumb-item"><Link to={"/"}>Accueil</Link>  </li>
                                            <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> Archives - Appel d'offre </span></li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className="space-ptb" style={{ paddingTop: "40px" }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div>
                                            <ReactDatatable
                                                config={config}
                                                records={archiveTendersList}
                                                columns={columns}
                                                extraButtons={[]}
                                                onRowClicked={rowClickedHandler}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                )
            }
        </>
    );
}

TenderArchiveListing.propTypes = {
    getArchiveTendersList: PropTypes.func.isRequired,
    storeTender: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    getArchiveTendersList: PropTypes.func.isRequired,
    storeTender: state.tender
});

export default connect(mapStateToProps, { getArchiveTendersList })(TenderArchiveListing)