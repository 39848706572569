import React from 'react';
import Header from './Header';
import Footer from './Footer';
import CookieConsent from "react-cookie-consent";

export const Layout = (props) => {
  return (
    <>
      <Header />
      <React.Fragment>
        {props.children}
      </React.Fragment>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="OK!"
        cookieName="MalibaaraV3CookieConsent"
        style={{ background: "rgb(102, 173, 212)", fontSize: "16px" }}
        buttonStyle={{ color: "white", background: "rgb(38, 38, 38)", fontSize: "16px", padding: "10px 60px 10px 60px" }}      
        buttonWrapperClasses="cookie-consent-button-wrapper"  
      >
        Ce site utilise des cookies pour vous offrir une meilleure expérience de navigation.{" "}
        <a href="/pages/privacypolicy" style={{color: "white", textDecoration: "underline"}}>En savoir Plus</a>
      </CookieConsent>
    </>
  );
}


// export class Layout extends Component {
//   static displayName = Layout.name;

//   render() {
//     return (
//       <div>
//         <Header />
//         <React.Fragment>
//           {this.props.children}
//         </React.Fragment>
//         <Footer />
//       </div>
//     );
//   }
// }
