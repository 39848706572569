import {
  GET_EMPLOYER_LIST,
  GET_EMPLOYER_JOB_LIST,
  GET_EMPLOYER_TENDER_LIST,
  EMPLOYER_ACTION_LOADING,
  EMPLOYER_ACTION_SUCCESS,
  EMPLOYER_ACTION_ERROR,
  EMPLOYER_ACTION_SUBMITING,
  EMPLOYER_ACTION_SUBMITED,
  SHOW_ALERT,
  HIDE_ALERT,
  EMPLOYER_TABLE_CONTENT_LOADING,
  EMPLOYER_TABLE_CONTENT_LOADED,
  GET_EMPLOYER_LIST_INCLUDED_INACTIVE,
  SET_EMPLOYER_DATA,
  REMOVE_EMPLOYER_DATA,
  EMPLOYER_LOGGED_OUT



} from "../../actions/admin/EmployerActions";

const initialState = {
  currentEmployer: {},
  employersList: [],
  employerJobList: [],
  employerTenderList: [],
  employerListIncludedInactive: [],
  loading: false,
  success: false,
  failed: false,
  submitting: false,
  errorMessage: "",
  showAlert: false,
  alertMessage: "",
  alertVariant: "",
  employerListTableContentLoading: false
};

const EmployerReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_EMPLOYER_DATA: {
      return {
        ...state,
        currentEmployer: { ...action.data }
      };
    }
    case REMOVE_EMPLOYER_DATA: {
      return {
        ...state,
        currentEmployer: null
      };
    }
    case EMPLOYER_LOGGED_OUT: {
      return state;
    }
    case EMPLOYER_ACTION_LOADING: {
      return {
        ...state,
        loading: true,
        success: false,
        failed: false
      };
    }
    case EMPLOYER_ACTION_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
        failed: false,
      };
    }
    case EMPLOYER_ACTION_ERROR: {
      return {
        ...state,
        failed: true,
        success: false,
        loading: false,
        showAlert: true,
        alertMessage: action.data,
      };
    }
    case EMPLOYER_ACTION_SUBMITING: {
      return {
        ...state,
        submitting: true
      };
    }
    case EMPLOYER_ACTION_SUBMITED: {
      return {
        ...state,
        submitting: false
      };
    }
    case GET_EMPLOYER_LIST_INCLUDED_INACTIVE: {
      return {
        ...state,
        employerListIncludedInactive: [...action.data]
      };
    }
    case GET_EMPLOYER_LIST: {
      return {
        ...state,
        employersList: [...action.data]
      };
    }
    case GET_EMPLOYER_JOB_LIST: {
      return {
        ...state,
        employerJobList: [...action.data]
      };
    }
    case GET_EMPLOYER_TENDER_LIST: {
      return {
        ...state,
        employerTenderList: [...action.data]
      };
    }
    // Alert action
    case SHOW_ALERT: {
      return {
        ...state,
        showAlert: true,
        alertMessage: action.data,
        alertVariant: "success"
      };
    }
    case HIDE_ALERT: {
      return {
        ...state,
        showAlert: false,
        alertMessage: "",
        alertVariant: ""
      };
    }
    case EMPLOYER_TABLE_CONTENT_LOADING: {
      return {
        ...state,
        employerListTableContentLoading: true
      };
    }
    case EMPLOYER_TABLE_CONTENT_LOADED: {
      return {
        ...state,
        employerListTableContentLoading: false
      };
    }

    default: {
      return state;
    }
  }
};



export default EmployerReducer;


