import React, { useEffect, useRef } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { tenderSearch, updateTender, getTenderList, addAttachmentFromTender, deleteAttachmentFromTender } from "../../../redux/actions/TenderActions"
import { CircularProgress } from '@material-ui/core';
import { getEmployerList } from "../../../redux/actions/admin/EmployerActions";
import moment from 'moment';
import 'moment/locale/fr';
import CustomAlert from '../../shared/views/CustomAlert';
import { categories } from "../../../views/shared/Helper";
import CustomHtmlEditor from '../../shared/views/CustomHtmlEditor';
import $ from 'jquery';
import Select from 'react-select';
import validator from 'validator';
import { DropzoneArea } from 'material-ui-dropzone';

const TenderDetail = (props) => {
    const descriptionHtmlEditor = useRef();
    const {
        getTenderList,
        updateTender,
        storeTender = {},
        storeEmployer = {},
        getEmployerList,
        addAttachmentFromTender,
        deleteAttachmentFromTender,
    } = props;
    const [isLoading, setIsLoading] = React.useState(true);
    const [tender, setTender] = React.useState(null);
    const [tenderNumber, setTenderNumber] = React.useState("");
    const [tenderId, setTenderId] = React.useState("");
    const [validateDscription, setValidateDescription] = React.useState("");
    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [isSubmetting, setIsSubmetting] = React.useState(false);
    const [published, setPublished] = React.useState(false);
    const [invalideEmail, setInvalideEmail] = React.useState(false);
    const [employersListOptions, setEmployersListOptions] = React.useState([]);
    const [attachmentToDelete, setAttachmentToDelete] = React.useState(null);
    const [isUploading, setIsUploading] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState();
    const [isDeletting, setIsDeletting] = React.useState(false);
    const closeFileDeleteModal = React.useRef();
    const [currentFile, setCurrentFile] = React.useState(undefined);
    const [updateNewTender, setUpdateNewTender] = React.useState(
        {
            id: "",
            title: "",
            companyName: "",
            employerId: "",
            category: "",
            type: "",
            count: "",
            location: "",
            closingDate: new Date(),
            startingDate: new Date(),
            description: "",
            showEmailAddress: false,
            showPhoneNumber: false,
            receiveEmailNotification: false,
            postedDateInFrench: "",
            isActive: false,
            // receiveEmailNotification: "",
        });

    useEffect(() => {
        let match = props.match;
        if (match != undefined) {
            let params = match.params;
            if (params != undefined && params.id != undefined) {
                setTenderId(params.id);

            }
        };
        if (storeEmployer.employersList.length > 0 && employersListOptions.length == 0) {
            getEmployersListOptions(storeEmployer.employersList);
        }
        else if (storeEmployer.employersList.length == 0 && employersListOptions.length == 0) {
            getEmployerList(false).then(response => {
                if (response.success) {
                    getEmployersListOptions(response.employersList);

                };
            });
        }
    }, [])

    useEffect(() => {
        if (tenderId != "") {
            if (storeTender.tenderList.length == 0) {
                getTenderList(true).then(resp => {
                    let queryResponse = resp.tendersList.find(c => c.id == tenderId);
                    if (queryResponse) {
                        setTender(queryResponse)
                    }
                });
            }
            else if (storeTender.tenderList.length > 0) {
                let queryResponse = storeTender.tenderList.find(c => c.id == tenderId);
                if (queryResponse) {
                    setTender(queryResponse)
                }
            }
        }
    }, [tenderId])

    useEffect(() => {
        if (selectedFile) {
            handleAddAttachment();
        }
    }, [selectedFile]);

    const handleAddAttachment = () => {
        setIsUploading(true);
        let currentFile = selectedFile;
        addAttachmentFromTender(tender?.id, currentFile).then(resp => {
            setSelectedFile(undefined)
            let tenderFromStore = resp.tendersList.find(tender => tender.id === tenderId);
            if (tenderFromStore !== undefined) {
                setTender(tenderFromStore);
                setIsUploading(false);
            }
        })
    }

    const handleDeleteSubmit = (e, id, value) => {
        setIsDeletting(true);
        deleteAttachmentFromTender(id, value).then(resp => {
            let tenderFromStore = resp.tendersList.find(tender => tender.id === tenderId);
            if (tenderFromStore !== undefined) {
                setTender(tenderFromStore);
                setCurrentFile(tenderFromStore.attachments);
            }
            setAttachmentToDelete("");
            closeFileDeleteModal.current.click();
            setIsDeletting(false);
        });
    }

    useEffect(() => {
        if (tender != null) {
            setUpdateNewTender(tender)
            setValidateDescription(tender?.description)
            setUpdateNewTender(prevState => {
                return { ...prevState, companyName: tender?.employer?.companyName, employerId: tender?.employer?.id }
            });
            setIsLoading(false);
        }
    }, [tender]);

    useEffect(() => {
        if (updateNewTender.title == ""
            || updateNewTender.title == undefined
            || updateNewTender.companyName == ""
            || updateNewTender.companyName == undefined
            || updateNewTender.category == ""
            || updateNewTender.category == undefined
            || updateNewTender.location == ""
            || updateNewTender.location == undefined
            || validateDscription == ""
            || validateDscription == undefined
        ) {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);
        }        
    }, [updateNewTender, validateDscription])

    useEffect(() => {
        var select = $(".basic-select");
        if (select.length > 0) {
            /*eslint-disable no-undef*/
            intializeCustomSelect();
            /*eslint-enable no-undef*/
        }
    }, [isLoading])

    const handleSubmit = (value) => {
        setIsSubmetting(true)
        setPublished(false);
        let tender = updateNewTender;
        tender.description = descriptionHtmlEditor.current.getContent();
        updateTender(tender).then(data => {
            if (data) {
                setIsSubmetting(false);
                setPublished(true);
            }
        });
    }

    const getEmployersListOptions = (employers) => {
        let result = [];
        employers.forEach(employer => {
            result.push({ label: employer.companyName, id: employer.id, value: employer.id });
        });
        setEmployersListOptions(result);
    }

    // Start keydown mechanism
    let onKeydown = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            if (!hasMissingValue) {
                handleSubmit();
            }
            event.preventDefault();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", onKeydown);
        return () => {
            document.removeEventListener("keydown", onKeydown);
        };
    }, [onKeydown]);
    //End keydown mechanism


    return (
        <>
            <React.Fragment>
                {isLoading ? (
                    <div className="row bg-white pt-4 mt-lg-0">
                        <div className="col-lg-12">
                            <div className="loading-container bg-white">
                                <CircularProgress />
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="row">
                            <div className="col-lg-12 mt-4 bg-white pt-0 mt-lg-0">
                                <div className="bg-light-2 p-4">
                                    <div className="row mb-4">
                                        <h2 className="mb-0"><span>{tender.title} - {tender.tenderNumber}</span></h2>
                                    </div>
                                    <div style={{ marginLeft: "-12px" }}>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label>Titre du poste *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="title"
                                                    value={updateNewTender?.title}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setUpdateNewTender(prevState => { return { ...prevState, title: val } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Nom de la société *</label>
                                                <Select
                                                    value={employersListOptions.find(opt => opt?.value == updateNewTender?.employerId)}
                                                    options={employersListOptions}
                                                    loadingMessage="En cours de chargement"
                                                    onChange={(option) => {
                                                        setUpdateNewTender(prevState => { return { ...prevState, employerId: option?.value, companyName: option.label } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Email </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="applicationEmail"
                                                    value={updateNewTender?.applicationEmail}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        let isValide = validator.isEmail(e.target.value);
                                                        setUpdateNewTender(prevState => { return { ...prevState, applicationEmail: val } });
                                                        if (invalideEmail) {
                                                            setInvalideEmail(!isValide);
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        let val = validator.isEmail(e.target.value);
                                                        setInvalideEmail(!val);
                                                    }}
                                                />
                                                {(updateNewTender.applicationEmail != undefined && updateNewTender.applicationEmail != "" && invalideEmail) && (
                                                    <p style={{ color: "red" }}>Format d'email invalide</p>
                                                )}
                                            </div>
                                            <div className="form-group col-sm-6 col-xl-6 select-border">
                                                <label>Catégorie *</label>
                                                <Select
                                                    value={categories.find(opt => opt?.value == updateNewTender?.category)}
                                                    options={categories}
                                                    loadingMessage="En cours de chargement"
                                                    onChange={(option) => {
                                                        setUpdateNewTender(prevState => { return { ...prevState, category: option?.label } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <label>Date limite de dépot</label>
                                                <div className="input-group date">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={moment(updateNewTender?.closingDate).format('YYYY-MM-DD')}
                                                        onChange={(e) => {
                                                            let val = e.target?.value;
                                                            setUpdateNewTender(prevState => { return { ...prevState, closingDate: val } });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Ville *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="location"
                                                    value={updateNewTender.location}
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setUpdateNewTender(prevState => { return { ...prevState, location: val } });
                                                    }}
                                                />
                                                {updateNewTender.location?.length > 200 && (
                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                )}
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Description *</label>
                                                <CustomHtmlEditor
                                                    ref={descriptionHtmlEditor}
                                                    initialValue={updateNewTender?.description}
                                                    onChange={(value, editor) => {
                                                        if (value == "") {
                                                            setValidateDescription("");
                                                        }
                                                        else {
                                                            setValidateDescription(value);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-12 mt-3">
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        defaultChecked={updateNewTender?.isActive}
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="accepts-04"
                                                        onChange={(e) => {
                                                            let val = e.target?.checked;
                                                            setUpdateNewTender(prevState => { return { ...prevState, isActive: val } });
                                                        }}
                                                    />
                                                    <label className="custom-control-label" htmlFor="accepts-04">Actif</label>
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        defaultChecked={updateNewTender?.showEmailAddress}
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="accepts-02"
                                                        onClick={(e) => {
                                                            let val = e.target?.checked;
                                                            setUpdateNewTender(prevState => {
                                                                return { ...prevState, showEmailAddress: val }
                                                            });
                                                        }}
                                                    />
                                                    <label className="custom-control-label" htmlFor="accepts-02">Afficher mon adresse email sur la description de l'appel d'offre</label>
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        defaultChecked={updateNewTender?.showPhoneNumber}
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="accepts-03"
                                                        onClick={(e) => {
                                                            let val = e.target?.checked;
                                                            setUpdateNewTender(prevState => {
                                                                return { ...prevState, showPhoneNumber: val }
                                                            });
                                                        }}
                                                    />
                                                    <label className="custom-control-label" htmlFor="accepts-03">Afficher mon numéro de téléphone sur la description de l'appel d'oofre</label>
                                                </div>

                                            </div>
                                            <div className="col-12 mt-4">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={handleSubmit}
                                                    disabled={isSubmetting || hasMissingValue}
                                                >
                                                    Sauvegarder &nbsp; {isSubmetting ? <CircularProgress size={15} /> : <span></span>}
                                                </button>
                                            </div>
                                            {published && (
                                                <div className="col-12 mt-4">
                                                    <CustomAlert content="L'appel d'offre a été modifié avec succès." />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-4 bg-white mt-lg-10">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title" style={{ marginLeft: "12px" }}>
                                            <h4 className="title" style={{ paddingBottom: "15px" }}><span>Documents</span></h4>
                                            <p style={{ marginRight: "12px" }}>
                                                Veuillez déposer dans cette section les documents de l'appel d'offres
                                                <strong> en cliquant à l'intérieur de la bande blanche ci-dessous</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div _lpchecked="2" style={{ marginLeft: "12px", marginRight: "20px" }}>
                                    {isUploading ?
                                        (
                                            <div className="loading-container">
                                                <CircularProgress />
                                                <div>Chargement des documents en cours...</div>
                                            </div>
                                        )
                                        :
                                        (
                                            <div className="form-group mb-5">
                                                <DropzoneArea
                                                    clearOnUnmount={true}
                                                    dropzoneText={isUploading ? "Chargement en cours..." : "Glisser ou cliquer ici pour déposer vos documents"}
                                                    showPreviewsInDropzone={false}
                                                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                    showAlerts={false}
                                                    filesLimit={1}
                                                    onChange={(files) => {
                                                        setSelectedFile(files[0])
                                                    }}
                                                />
                                            </div>
                                        )}
                                    <table className="table manage-candidates-top mb-4">
                                        <thead>
                                            <tr>
                                                <th>Nom</th>
                                                <th>Date</th>
                                                <th className="action text-right"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <>
                                                {tender?.attachments?.map((file, index) => (
                                                    <tr key={index}>
                                                        <td>{file.name}</td>
                                                        <td>{file.dateCreated}</td>
                                                        <td style={{ textAlign: "right" }}>
                                                            <a href={file.url} className="text-primary" target="_blank">
                                                                <i className="fas fa-cloud-download-alt"></i>
                                                            </a>
                                                            <a style={{ marginLeft: "10px" }} className="text-primary" target="_blank">
                                                                <i class="far fa-copy" onClick={() => { navigator.clipboard.writeText(file.url) }}></i>
                                                            </a>
                                                            <a href="assets/#" className="text-danger" style={{ marginLeft: "10px" }} data-toggle="modal" title="" data-target="#deleteAdsModal" data-original-title="Delete">
                                                                <i className="far fa-trash-alt" onClick={() => setAttachmentToDelete(file)}></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        {/* Start Delete file modale */}
                        <div className="modal fade" id="deleteAdsModal" tabIndex="-1" role="dialog" aria-modal="true" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header p-4">
                                        <h4 className="mb-0 text-center">{"Suppression d'un fichier: " + attachmentToDelete?.name}</h4>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>

                                    <form className="mt-2">
                                        <div className="modal-body">
                                            <div>
                                                <div className="form-row">
                                                    <p>Êtes-vous sûr de vouloir supprimer ce fichier ?</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                data-dismiss="modal"
                                                className="btn btn-primary"
                                                ref={closeFileDeleteModal}
                                            >
                                                NON
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={(e) => handleDeleteSubmit(e, tenderId, attachmentToDelete.id)}
                                                disabled={isDeletting}
                                            >
                                                OUI &nbsp; {isDeletting ? <CircularProgress size={15} /> : <span></span>}
                                            </button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                        {/* End Delete file modale */}

                    </>
                )}
            </React.Fragment>
        </>
    )
}

TenderDetail.propTypes = {
    tenderSearch: PropTypes.func.isRequired,
    updateTender: PropTypes.func.isRequired,
    storeTender: PropTypes.object.isRequired,
    getEmployerList: PropTypes.func.isRequired,
    storeEmployer: PropTypes.object.isRequired,
    getJobList: PropTypes.func.isRequired,
    addAttachmentFromTender: PropTypes.func.isRequired,
    deleteAttachmentFromTender: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    tenderSearch: PropTypes.func.isRequired,
    updateTender: PropTypes.func.isRequired,
    storeTender: state.tender,
    getEmployerList: PropTypes.func.isRequired,
    storeEmployer: state.employer,
    getJobList: PropTypes.func.isRequired,
    addAttachmentFromTender: PropTypes.func.isRequired,
    deleteAttachmentFromTender: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, { getTenderList, updateTender, tenderSearch, getEmployerList, addAttachmentFromTender, deleteAttachmentFromTender })(TenderDetail)