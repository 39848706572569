import { GET_SIDEBAR_ITEMS_LIST } from "../../actions/admin/SidebarActions";

const initialState = {
    sidebarItemsList: [],
};

const AdsReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_SIDEBAR_ITEMS_LIST: {
            return {
                ...state,
                sidebarItemsList: [...action.data]
            };
        }
        // case ACTION_ERROR: {
        //     return {
        //         ...state,
        //         failed: true,
        //         success: false,
        //         loading: false,
        //         showAlert: true,
        //         alertMessage: action.data,
        //     };
        // }
        default: {
            return state;
        }
    }
};

export default AdsReducer;
