import React, { useEffect, useRef } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CircularProgress } from '@material-ui/core';
import { addAttachmentFromEmployer, deleteAttachmentFromEmployer, updateEmployer, getEmployerList } from "../../../redux/actions/admin/EmployerActions"
import validator from 'validator';
import $ from 'jquery';
import { DropzoneArea } from 'material-ui-dropzone';
import CustomAlert from '../../shared/views/CustomAlert';

let EmployerDetail = (props) => {
  const descriptionHtmlEditor = useRef();
  const { updateEmployer, addAttachmentFromEmployer, deleteAttachmentFromEmployer, getEmployerList, storeEmployer = {} } = props;
  const [isLoading, setIsLoading] = React.useState(true);
  const [employer, setEmployer] = React.useState(
    {
      companyName: "",
      companyPhoneNumber1Contact: "",
      email: "",
      companyPhoneNumber2Contact: "",
      companyEmailContact: "",
      description: "",
      order: ""
    });
  const [employerId, setEmployerId] = React.useState("");
  const [selectedFile, setSelectedFile] = React.useState()
  const [preview, setPreview] = React.useState();
  const [checked, setChecked] = React.useState(false);
  const [attachmentToDelete, setAttachmentToDelete] = React.useState(null);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const closeFileUploadModal = React.useRef();
  const [isUploading, setIsUploading] = React.useState(false);
  const [isDeletting, setIsDeletting] = React.useState(false);
  const closeFileDeleteModal = React.useRef();
  const [hasMissingValue, setHasMissingValue] = React.useState(true);
  const [published, setPublished] = React.useState(false);
  const [invalideEmail1, setInvalideEmail1] = React.useState(false);
  const [invalideEmail2, setInvalideEmail2] = React.useState(false);

  useEffect(() => {
    let match = props.match;
    if (match != undefined) {
      let params = match.params;
      if (params !== undefined && params.employerId != undefined && employerId == "") {
        setEmployerId(params.employerId);
      }
    };

  }, []);

  useEffect(() => {
    if (employer != null) {
      setIsLoading(false);
    }
    if (employer.companyName == ""
      || employer.companyName == undefined
      || employer.companyPhoneNumber1Contact == ""
      || employer.companyPhoneNumber1Contact == undefined
      || employer.email == ""
      || employer.email == undefined
    ) {
      setHasMissingValue(true);
    }
    else if (employer.companyName?.length > 200
      || employer.companyPhoneNumber1Contact?.length > 50
      || employer.companyPhoneNumber2Contact?.length > 50
      || (employer.email != "" && !validator.isEmail(employer.email))
      || (employer.companyEmailContact != "" && !validator.isEmail(employer.companyEmailContact))
      || (employer?.companyPhoneNumber1Contact != "" && !(new RegExp(/^[0-9\b]+$/)).test(employer?.companyPhoneNumber1Contact))
      || (employer?.companyPhoneNumber2Contact != "" && !(new RegExp(/^[0-9\b]+$/)).test(employer?.companyPhoneNumber2Contact))
    ) {
      setHasMissingValue(true);
    }
    else {
      setHasMissingValue(false);
    }
  }, [employer])

  useEffect(() => {

    if (employerId != "") {
      if (storeEmployer.employersList.length == 0) {
        getEmployerList(true).then(resp => {
          let queryResponse = resp.employersList.find(e => e.id == employerId);
          if (queryResponse) {
            setEmployer(queryResponse)
          }
        });
      }
      else if (storeEmployer.employersList.length > 0) {
        let queryResponse = storeEmployer.employersList.find(e => e.id == setEmployerId);
        if (queryResponse) {
          setEmployerId(queryResponse)
        }
      }
    }
  }, [employerId]);

  useEffect(() => {
    if (selectedFile) {
      handleAddAttachment();
    }
  }, [selectedFile])

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length != 0) {
      setSelectedFile(e.target.files[0]);
    }
  }

  const handleAddAttachment = () => {
    setIsUploading(true);
    addAttachmentFromEmployer(employerId, selectedFile).then(resp => {
      setSelectedFile(undefined)
      let employerFromStore = resp.employersList.find(employer => employer.id == employerId);
      if (employerFromStore !== undefined) {
        setEmployer(employerFromStore);
        setIsUploading(false);
      }
    })
  }

  const handleDeleteSubmit = (e, id, value) => {
    setIsDeletting(true);
    deleteAttachmentFromEmployer(id, value).then(resp => {
      let employerFromStore = resp.employersList.find(employer => employer.id == employerId);
      if (employerFromStore != undefined) {
        setEmployer(employerFromStore);
      }
      setAttachmentToDelete(null);
      closeFileDeleteModal.current.click();
      setIsDeletting(false);
    });
  }

  const handleUpdateSubmit = () => {
    setIsUpdating(true);
    setPublished(false);
    let employerCopy = employer;
    if (!employerCopy.order || employerCopy.order == "") {
      employerCopy.order = 0;
    }
    updateEmployer(employerCopy).then(res => {
      if (res.success) {
        let queryEmployer = res.employersList.find(employer => employer.id == employerId);
        if (queryEmployer) {
          setEmployer(queryEmployer);
          setPublished(true);
        }
      }
      setIsUpdating(false);
    });
  }

  // Start keydown mechanism
  let onKeydown = event => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      if (!hasMissingValue) {
        handleUpdateSubmit();
      }
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeydown);
    return () => {
      document.removeEventListener("keydown", onKeydown);
    };
  }, [onKeydown]);
  //End keydown mechanism



  return (
    <>
      <React.Fragment>
        {isLoading ? (
          <div className="row bg-white pt-4 mt-lg-0">
            <div className="col-lg-12">
              <div className="loading-container bg-white">
                <CircularProgress />
                <div>Chargement de l'employeur en cours...</div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-lg-12 mt-4 bg-white pt-0 mt-lg-0">
                <div className="bg-light-2 p-4">
                  <div className="row mb-4">
                    <h2 className="mb-0"><span>{employer.companyName}</span></h2>
                  </div>
                  <div style={{ marginLeft: "-12px" }}>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label>Nom de la société *</label>
                        <input
                          type="text"
                          className="form-control"
                          name="companyName"
                          value={employer.companyName}
                          onChange={(e) => {
                            let val = e.target?.value;
                            setEmployer(prevState => {
                              return { ...prevState, companyName: val }
                            })
                          }}
                        />
                        {employer.companyName?.length > 200 && (
                          <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                        )}
                      </div>
                      <div className="form-group col-md-12">
                        <label>Adresse email de la personne à contacter</label>
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          value={employer.companyEmailContact}
                          onChange={(e) => {
                            let val = e.target?.value;
                            let isValide = validator.isEmail(e.target.value);
                            setEmployer(prevState => { return { ...prevState, companyEmailContact: val } });
                            if (invalideEmail1) {
                              setInvalideEmail1(!isValide);
                            }
                          }}
                          onBlur={(e) => {
                            let val = validator.isEmail(e.target.value);
                            setInvalideEmail1(!val);
                          }}
                        />
                        {(employer.companyEmailContact != "" && invalideEmail1) && (
                          <p style={{ color: "red" }}>Format d'email invalide</p>
                        )}
                      </div>
                      <div className="form-group col-md-6">
                        <label>Numéro de téléphone *</label>
                        <input
                          type="text"
                          className="form-control"
                          name="companyPhoneNumber1Contact"
                          value={employer.companyPhoneNumber1Contact}
                          onChange={(e) => {
                            let val = e.target?.value;
                            setEmployer(prevState => {
                              return { ...prevState, companyPhoneNumber1Contact: val }
                            })
                          }}
                        />
                        {employer.companyPhoneNumber1Contact?.length > 50 && (
                          <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                        )}
                        {(employer?.companyPhoneNumber1Contact != "" && !(new RegExp(/^[0-9\b]+$/)).test(employer?.companyPhoneNumber1Contact)) && (
                          <p style={{ color: "red" }}>Format invalide</p>
                        )}
                      </div>
                      <div className="form-group col-md-6">
                        <label>Autre numéro de téléphone </label>
                        <input
                          type="text"
                          className="form-control"
                          name="companyPhoneNumber2Contact"
                          value={employer.companyPhoneNumber2Contact}
                          onChange={(e) => {
                            let val = e.target?.value;
                            setEmployer(prevState => {
                              return { ...prevState, companyPhoneNumber2Contact: val }
                            })
                          }}
                        />
                        {employer.companyPhoneNumber2Contact?.length > 50 && (
                          <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                        )}
                        {(employer?.companyPhoneNumber2Contact != "" && !(new RegExp(/^[0-9\b]+$/)).test(employer?.companyPhoneNumber2Contact)) && (
                          <p style={{ color: "red" }}>Format invalide</p>
                        )}
                      </div>
                      <div className="form-group col-md-12">
                        <label>Adresse email pour accéder au site *</label>
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          value={employer.email}
                          onChange={(e) => {
                            let val = e.target?.value;
                            let isValide = validator.isEmail(e.target.value);
                            setEmployer(prevState => { return { ...prevState, email: val } });
                            if (invalideEmail2) {
                              setInvalideEmail2(!isValide);
                            }
                          }}
                          onBlur={(e) => {
                            let val = validator.isEmail(e.target.value);
                            setInvalideEmail2(!val);
                          }}
                        />
                        {(employer.email != "" && invalideEmail2) && (
                          <p style={{ color: "red" }}>Format d'email invalide</p>
                        )}
                      </div>
                      <div className="form-group col-md-12">
                        <label>Description </label>
                        <textarea
                          value={employer?.description}
                          type="text"
                          className="form-control"
                          id="description"
                          placeholder=""
                          onChange={(e) => {
                            let val = e.target?.value;
                            setEmployer(prevState => { return { ...prevState, description: val } });
                          }}
                        ></textarea>
                      </div>
                      <div className="form-group col-md-12">
                        <label>Ordre d'affichage (Ils nous font confiance)</label>
                        <input
                          type="text"
                          className="form-control"
                          name="order"
                          value={employer.order}
                          onChange={(e) => {
                            let val = e.target?.value;
                            setEmployer(prevState => {
                              return { ...prevState, order: val }
                            })
                          }}
                        />
                      </div>
                      <div className="form-group col-12 mt-3">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="accepts-101"
                            defaultChecked={employer.isActive}
                            onClick={(e) => {
                              let val = e.target?.checked;
                              setEmployer(prevState => {
                                return { ...prevState, isActive: val }
                              });
                            }}
                          />
                          <label className="custom-control-label" htmlFor="accepts-101">Actif</label>
                        </div>
                      </div>
                      <div className="col-12 mt-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleUpdateSubmit}
                          disabled={isUpdating || hasMissingValue}
                        >
                          Sauvegarder &nbsp; {isUpdating ? <CircularProgress size={15} /> : <span></span>}
                        </button>
                      </div>
                      {published && (
                        <div className="col-12 mt-4">
                          <CustomAlert content="L'employeur a été modifiée avec succès." />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mt-4 bg-white mt-lg-10">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title" style={{ marginLeft: "12px" }}>
                      <h4 className="title" style={{ paddingBottom: "15px" }}><span>Logo</span></h4>
                      {employer.attachments?.length == 0 ?
                        (
                          <p style={{ marginRight: "12px" }}>
                            Veuillez déposer dans cette section le logo de la société
                            <strong> en cliquant à l'intérieur de la bande blanche ci-dessous</strong>
                          </p>
                        ) :
                        (
                          <p style={{ marginRight: "12px" }}>
                            Veuillez supprimer le fichier existant pour pouvoir ajouter un autre logo.
                          </p>
                        )}
                    </div>
                  </div>
                </div>
                <div _lpchecked="2" style={{ marginLeft: "12px", marginRight: "20px" }}>
                  {isUploading ?
                    (
                      <div className="loading-container">
                        <CircularProgress />
                        <div>Chargement des documents en cours...</div>
                      </div>
                    )
                    :
                    (employer.attachments?.length == 0 && (
                      <div className="form-group mb-5">
                        <DropzoneArea
                          clearOnUnmount={true}
                          dropzoneText={isUploading ? "Chargement en cours..." : "Glisser ou cliquer ici pour déposer vos documents"}
                          showPreviewsInDropzone={false}
                          previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                          showAlerts={false}
                          filesLimit={1}
                          onChange={(files) => {
                            setSelectedFile(files[0])
                          }}
                        />
                      </div>
                    ))}
                  <table className="table manage-candidates-top mb-4">
                    <thead>
                      <tr>
                        <th>Nom</th>
                        <th>Date</th>
                        <th className="action text-right"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        {employer?.attachments?.map((file, index) => (
                          <tr key={index}>
                            <td>{file.name}</td>
                            <td>{file.dateCreated}</td>
                            <td style={{ textAlign: "right" }}>
                              <a href={file.url} className="text-primary" target="_blank">
                                <i className="fas fa-cloud-download-alt"></i>
                              </a>
                              <a href="assets/#" className="text-danger" style={{ marginLeft: "10px" }} data-toggle="modal" title="" data-target="#deleteattachmentModal" data-original-title="Delete">
                                <i className="far fa-trash-alt" onClick={() => setAttachmentToDelete(file)}></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Start Delete file modale */}
            <div className="modal fade" id="deleteattachmentModal" tabIndex="-1" role="dialog" aria-modal="true" data-backdrop="static" data-keyboard="false">
              <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header p-4">
                    <h4 className="mb-0 text-center">{"Suppression du fichier: " + attachmentToDelete?.fullName}</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <form className="mt-2">
                    <div className="modal-body">
                      <div>
                        <div className="form-row">
                          <p>Êtes-vous sûr de vouloir supprimer ce fichier ?</p>

                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-dismiss="modal"
                        ref={closeFileDeleteModal}
                      >
                        NON
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        disabled={isDeletting}
                        onClick={(e) => handleDeleteSubmit(e, employerId, attachmentToDelete?.id)}
                      >
                        OUI &nbsp; {isDeletting ? <CircularProgress size={15} /> : <span></span>}
                      </button>
                    </div>
                  </form>

                </div>
              </div>
            </div>
            {/* End Delete file modale */}
          </>
        )}
      </React.Fragment>
    </>
  )

}


EmployerDetail.propTypes = {
  updateEmployer: PropTypes.func.isRequired,
  addAttachmentFromEmployer: PropTypes.func.isRequired,
  deleteAttachmentFromEmployer: PropTypes.func.isRequired,
  getEmployerList: PropTypes.func.isRequired,
  storeEmployer: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  updateEmployer: PropTypes.func.isRequired,
  addAttachmentFromEmployer: PropTypes.func.isRequired,
  deleteAttachmentFromEmployer: PropTypes.func.isRequired,
  getEmployerList: PropTypes.func.isRequired,
  storeEmployer: state.employer,
});

export default connect(mapStateToProps, { updateEmployer, deleteAttachmentFromEmployer, addAttachmentFromEmployer, getEmployerList })(EmployerDetail)