import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getCandidateList, deleteCandidate } from "../../../redux/actions/admin/CandidateActions"
import { CircularProgress } from '@material-ui/core';
import { Helmet } from "react-helmet";
import ReactDatatable from '@ashvin27/react-datatable';
import { Link, withRouter } from "react-router-dom";
import CustomAlert from '../../shared/views/CustomAlert';
import siteService from '../../../services/siteService';

let CandidateListing = (props) => {

    let columns = [
        {
            key: "firstName",
            text: "Prénom",
            className: "firstName",
            align: "left",
            sortable: true,
        },
        {
            key: "lastName",
            text: "Nom",
            className: "lastName",
            align: "left",
            sortable: true,
        },
        {
            key: "email",
            text: "Email",
            className: "email",
            align: "left",
            sortable: true,
        },
        {
            key: "phoneNumber1",
            text: "Téléphone",
            className: "phoneNumber1",
            align: "left",
            sortable: true,
        },
        {
            key: "action",
            text: "",
            className: "action",
            // width: "5%",
            align: "left",
            sortable: false,
            cell: candidate => {
                return (
                    <>
                        <ul className="list-unstyled mb-0 d-flex justify-content-end">
                            <li>
                                <a href="assets/#" className="" data-toggle="modal" title="" data-target="#deleteCandidate" data-original-title="Delete">
                                    <Link to={"assets/#"}><i className="text-danger far fa-trash-alt" onClick={() => setCandidateTodelete(candidate)}></i></Link>
                                </a>
                            </li>
                        </ul>
                    </>
                );
            }

        },
    ]

    let config = {
        page_size: 25,
        length_menu: [25, 50, 100],
        button: {
            excel: false,
            print: false,
            extra: false,
        }
    }

    const {
        getCandidateList,
        storeCandidate,
        deleteCandidate
    } = props;
    const [candidateTodelete, setCandidateTodelete] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [candidatesList, setCandidatesList] = React.useState([]);
    const [initialCandidatesList, setInitialCandidatesList] = React.useState([]);
    const [isDeletting, setIsDeletting] = React.useState(false);
    const [isDeleted, setIsDeleted] = React.useState(false);
    const closeDeleteCandidatesModal = React.useRef();
    const currentSite = siteService.getCurrentSite();

    useEffect(() => {
        if (candidatesList.length == 0 && storeCandidate.candidateList.length == 0) {
            getCandidateList().then(resp => {
                setCandidatesList(resp);
                setInitialCandidatesList(resp.candidatesList);
                setIsLoading(false);
            });
        }
        else if (candidatesList.length == 0 && storeCandidate.candidateList.length > 0) {
            setCandidatesList(storeCandidate.candidateList);
            setInitialCandidatesList(storeCandidate.candidatesList);
            setIsLoading(false);
        }
        window.scrollTo(0, 0)
    }, [])

    const handleDeleteSubmit = () => {
        setIsDeleted(false);
        setIsDeletting(true);
        deleteCandidate(candidateTodelete.id).then(resp => {
            setCandidatesList(resp.candidatesList);
            setInitialCandidatesList(resp.candidateList);
            setCandidateTodelete(undefined);
            closeDeleteCandidatesModal.current.click();
            setIsDeletting(false);
            setIsDeleted(true);

        });
    }

    return (
        <>
            <Helmet>
                <title> Administration | Candidats  | {currentSite.fullName}</title>
            </Helmet>
            {isLoading ?
                (
                    <>
                        <div className="row bg-white pt-4 mt-lg-0">
                            <div className="col-lg-12">
                                <div className="loading-container bg-white">
                                    <CircularProgress />
                                </div>
                            </div>
                        </div>
                    </>
                )
                :
                (
                    <>
                        <div className="row bg-white pt-4 mt-lg-0">
                            <div className="col-lg-12 mt-4 mt-lg-0">
                                <div className="row mb-4">
                                    <div className="col-md-7 col-sm-5 d-flex align-items-center">
                                        <div className="section-title-02 mb-0 ">
                                            <h4 className="mb-0">Liste des candidats</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {isDeleted && (
                                        <div className="col-12">
                                            <CustomAlert content="Le candidat à bien été supprimé !!!" />
                                        </div>
                                    )}
                                    <div className="col-md-12">
                                        <div className="user-dashboard-info-box table-responsive mt-1 bg-white">
                                            <div>
                                                <ReactDatatable
                                                    config={config}
                                                    records={candidatesList}
                                                    columns={columns}
                                                    extraButtons={[]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Start Delete user modale */}
                        <div className="modal fade" id="deleteCandidate" tabIndex="-1" role="dialog" aria-modal="true">
                            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header p-4">
                                        <h4 className="mb-0 text-center">Suppression d'un Candidat: {candidateTodelete?.firstName + " " + candidateTodelete?.lastName}</h4>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>

                                    <form className="mt-2">
                                        <div className="modal-body">
                                            <div>
                                                <div className="form-row">
                                                    <p>Voulez vous supprimer cet candidat?</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                data-dismiss="modal"
                                                className="btn btn-primary"
                                                ref={closeDeleteCandidatesModal}
                                            >
                                                NON
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={handleDeleteSubmit}
                                                disabled={isDeletting}
                                            >
                                                OUI &nbsp; {isDeletting ? <CircularProgress size={15} /> : <span></span>}
                                            </button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                        {/* End Delete user modale */}
                    </>
                )
            }
        </>
    )
}

CandidateListing.propTypes = {
    getCandidateList: PropTypes.func.isRequired,
    storeCandidate: PropTypes.object.isRequired,
    deleteCandidate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    getCandidateList: PropTypes.func.isRequired,
    storeCandidate: state.candidate,
    deleteCandidate: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, { getCandidateList, deleteCandidate })(CandidateListing)