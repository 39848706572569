import React, { useEffect, useRef } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getCourseList, deleteCourse, createCourse, updateCourse } from "../../../redux/actions/CourseActions"
import { CircularProgress } from '@material-ui/core';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from 'moment';
import 'moment/locale/fr';
import CustomHtmlEditor from '../../shared/views/CustomHtmlEditor';
import { getEmployerList } from "../../../redux/actions/admin/EmployerActions"
import { categories } from "../../../views/shared/Helper";
import Select from 'react-select';
import validator from 'validator';
import isMatch from 'date-fns/isMatch';
import $ from 'jquery';
import siteService from '../../../services/siteService';

let CourseListing = (props) => {
    const descriptionHtmlEditor = useRef();
    const {
        getCourseList,
        getEmployerList,
        deleteCourse,
        storeEmployer = {},
        storeCourse,
        createCourse
    } = props;

    const companyNameSelectInputRef = React.useRef();
    const categorySelectInputRef = React.useRef();

    const currentSite = siteService.getCurrentSite();
    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(true);
    const [coursesList, setCoursesList] = React.useState([]);
    const [courseTodelete, setCourseTodelete] = React.useState(null);
    const [isDeletting, setIsDeletting] = React.useState(false);
    const [isCreatting, setIsCreatting] = React.useState(false);
    const closeDeleteCoursesModal = React.useRef();
    const [selectedFile, setSelectedFile] = React.useState();
    const [preview, setPreview] = React.useState();
    const closeCourseCreateModal = React.useRef();
    const [employersListOptions, setEmployersListOptions] = React.useState([]);
    const [newCourse, setNewCourse] = React.useState({
        title: "",
        employerId: "",
        category: "",
        applicationEmail: "",
        startDate: "",
        endDate: "",
        location: "",
        description: ""
    });
    const [invalideDate1, setInvalideDate1] = React.useState(false);
    const [invalideDate2, setInvalideDate2] = React.useState(false);
    const [invalideEmail, setInvalideEmail] = React.useState(false);



    useEffect(() => {
        if (coursesList.length == 0 && storeCourse.courseList.length == 0) {
            getCourseList(true).then(resp => {
                setCoursesList(resp.coursesList);
                setIsLoading(false);
            });
        }
        else if (coursesList.length == 0 && storeCourse.courseList.length > 0) {
            setCoursesList(storeCourse.courseList);
            setIsLoading(false);
        }
        if (storeEmployer.employersList.length > 0 && employersListOptions.length == 0) {
            getEmployersListOptions(storeEmployer.employersList);
        }
        else if (storeEmployer.employersList.length == 0 && employersListOptions.length == 0) {
            getEmployerList(false).then(response => {
                if (response.success) {
                    getEmployersListOptions(response.employersList);
                };
            });
        }
        // window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (newCourse.title == ""
            || newCourse.title === undefined
            || newCourse.companyName == ""
            || newCourse.companyName === undefined
            || newCourse.applicationEmail == ""
            || newCourse.applicationEmail === undefined
            || newCourse.category == ""
            || newCourse.category === undefined
            || newCourse.location == ""
            || newCourse.location === undefined
            || newCourse.description == ""
            || newCourse.description === undefined
        ) {
            setHasMissingValue(true);
        }
        else if (newCourse.location?.length > 200
            || (newCourse.applicationEmail != "" && !validator.isEmail(newCourse.applicationEmail))
            || (newCourse?.startDate != "" && !isMatch(newCourse?.startDate, 'yyyy-MM-dd'))
            || (newCourse?.endDate != "" && !isMatch(newCourse?.endDate, 'yyyy-MM-dd'))
        ) {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);
        }

    }, [newCourse])

    useEffect(() => {
        if (storeCourse.loading != isLoading) {
            setIsLoading(storeCourse.loading);
        }
    }, [storeCourse]);

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const handleSubmit = () => {
        setIsCreatting(true);
        let course = newCourse;
        if (course.startDate == "") {
            course.startDate = null;
        }
        if (course.endDate == "") {
            course.endDate = null;
        }
        createCourse(course).then(data => {
            if (data) {
                setCoursesList(data.coursesList);
                clearCourseValue();
                closeCourseCreateModal.current.click();
                setIsCreatting(false);

            }
        })
    }

    const handleDeleteSubmit = () => {
        setIsDeletting(true)
        deleteCourse(courseTodelete.id).then(data => {
            setCoursesList(data.coursesList)
            setCourseTodelete(undefined);
            closeDeleteCoursesModal.current.click();
            setIsDeletting(false)
        });
    }

    const getEmployersListOptions = (employers) => {
        let result = [];
        employers.forEach(employer => {
            result.push({ label: employer.companyName, value: employer.id, id: employer.id, email: employer.companyEmailContact });
        });
        setEmployersListOptions(result);
    }

    let clearCourseValue = () => {
        companyNameSelectInputRef.current?.clearValue();
        categorySelectInputRef.current?.clearValue();
        descriptionHtmlEditor.current.reset();
        setNewCourse(
            {
                title: "",
                employerId: "",
                category: "",
                applicationEmail: "",
                startDate: "",
                endDate: "",
                location: "",
                description: ""
            }
        );
    }

    // Start keydown mechanism
    let onKeydown = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            if ($('#createCourseModal').attr('class')?.includes("show")) {
                if (!hasMissingValue) {
                    handleSubmit();
                }
            }
            event.preventDefault();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", onKeydown);
        return () => {
            document.removeEventListener("keydown", onKeydown);
        };
    }, [onKeydown]);
    //End keydown mechanism

    return (
        <React.Fragment>
            <Helmet>
                <title> Administration | Offres d'emploi  | {currentSite.fullName}</title>
            </Helmet>
            {isLoading ? (
                <div className="row bg-white pt-4 mt-lg-0">
                    <div className="col-lg-12">
                        <div className="loading-container bg-white">
                            <CircularProgress />
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="row bg-white pt-4 mt-lg-0">
                        <div className="col-lg-12 mt-4 mt-lg-0">
                            <div className="row mb-4">
                                <div className="col-md-7 col-sm-5 d-flex align-items-center">
                                    <div className="section-title-02 mb-0 ">
                                        <h4 className="mb-0" style={{ marginLeft: "10px" }}>{coursesList?.length} Formations </h4>
                                    </div>
                                </div>
                                <div className="col-md-5 col-sm-7 mt-3 mt-sm-0" style={{ textAlign: "right" }}>
                                    <a className="btn btn-md ml-sm-auto btn-primary" data-toggle="modal" data-target="#createCourseModal">Ajouter une Formation</a>
                                </div>
                            </div>
                            <table className="table table-striped" id="table"
                                data-toggle="table"
                                data-height="460"
                                data-pagination="true"
                                data-pagination-h-align="left"
                                data-pagination-detail-h-align="right">
                                <thead>
                                    <tr>
                                        <th scope="col">Numéro</th>
                                        <th scope="col">Titre</th>
                                        <th scope="col">Employeur</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Statut</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {coursesList.map((course, index) => (
                                        <tr key={index}>
                                            <td>{course.courseNumber}</td>
                                            <td>
                                                {course.title}
                                            </td>
                                            <td>
                                                {course.employer?.companyName}
                                            </td>
                                            <td>
                                                {moment(course.postedDate).format('Do MMMM YYYY')}
                                            </td>
                                            <td>
                                                {course.isActive ? (<div className="active">Actif</div>) : (<div className="inactive">Inactif</div>)}
                                            </td>
                                            <td>
                                                <ul className="list-unstyled mb-0 d-flex justify-content-end">
                                                    <li><Link to={"/admin/course/details/" + course.id}><i className="fas fa-pencil-alt"></i></Link></li>
                                                    <li><a href="assets/#" className="text-danger" data-toggle="modal" title="" data-target="#deleteJob" data-original-title="Delete">
                                                        <i className="far fa-trash-alt" onClick={() => setCourseTodelete(course)}></i>
                                                    </a>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>

                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>


                    {/* Start Delete course modale */}
                    <div className="modal fade" id="deleteJob" tabIndex="-1" role="dialog" aria-modal="true">
                        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header p-4">
                                    <h4 className="mb-0 text-center">Suppression d'une formation: {courseTodelete?.title} </h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <form className="mt-2">
                                    <div className="modal-body">
                                        <div>
                                            <div className="form-row">
                                                <p>Voulez vous supprimer cette offre?</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            data-dismiss="modal"
                                            className="btn btn-primary"
                                            ref={closeDeleteCoursesModal}
                                        >
                                            NON
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={handleDeleteSubmit}
                                            disabled={isDeletting}
                                        >
                                            OUI &nbsp; {'isDeletting' ? <CircularProgress size={15} /> : <span></span>}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* End Delete course modale */}

                    {/* Start create course modale */}
                    <div className="modal fade" id="createCourseModal" tabIndex="-1" role="dialog" aria-modal="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header p-4">
                                    <h4 className="mb-0 text-center">Création d'une nouvelle formation</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" disabled={storeCourse.submitting}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body">
                                    <div>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label>Titre de la formation *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="title"
                                                    value={newCourse.title}
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setNewCourse(prevState => {
                                                            return { ...prevState, title: val }
                                                        });
                                                    }}
                                                />
                                                {newCourse?.title?.length > 200 && (
                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                )}
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Nom de la société *</label>
                                                <Select
                                                    ref={companyNameSelectInputRef}
                                                    options={employersListOptions}
                                                    loadingMessage="En cours de chargement"
                                                    onChange={(option) => {
                                                        setNewCourse(prevState => { return { ...prevState, employerId: option?.value, companyName: option?.label, applicationEmail: option?.email } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Email *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="applicationEmail"
                                                    value={newCourse.applicationEmail}
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        let isValide = validator.isEmail(e.target.value);
                                                        setNewCourse(prevState => { return { ...prevState, applicationEmail: val } });
                                                        if (invalideEmail) {
                                                            setInvalideEmail(!isValide);
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        let val = validator.isEmail(e.target.value);
                                                        setInvalideEmail(!val);
                                                    }}
                                                />
                                                {(newCourse.email != "" && invalideEmail) && (
                                                    <p style={{ color: "red" }}>Format d'email invalide</p>
                                                )}
                                                {newCourse?.applicationEmail?.length > 200 && (
                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                )}
                                            </div>
                                            <div className="form-group col-md-12 select-border">
                                                <label>Catégorie *</label>
                                                <Select
                                                    ref={categorySelectInputRef}
                                                    options={categories}
                                                    loadingMessage="En cours de chargement"
                                                    onChange={(option) => {
                                                        setNewCourse(prevState => { return { ...prevState, category: option?.value } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-sm-6 datetimepickers">
                                                <label>Date de début</label>
                                                <div className="input-group date" name="datetimepicker-01" data-target-input="nearest">
                                                    <input
                                                        type="date"
                                                        className="form-control datetimepicker-input"
                                                        value={newCourse.startDate}
                                                        onChange={(e) => {
                                                            let val = e.target?.value;
                                                            let match = isMatch(e.target.value, 'yyyy-MM-dd');
                                                            setNewCourse(prevState => { return { ...prevState, startDate: val } });
                                                            if (match && val != "") {
                                                                setInvalideDate1(!match);
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            let val = isMatch(e.target.value, 'yyyy-MM-dd');
                                                            if (e.target.value != "") {
                                                                setInvalideDate1(!val);
                                                            }
                                                            if (!val && e.target.value != "") {
                                                                setNewCourse(prevState => { return { ...prevState, startDate: null } });
                                                            }
                                                        }}
                                                    />
                                                    {invalideDate1 && (
                                                        <p style={{ color: "red" }}>Format invalide</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-6 datetimepickers">
                                                <label>Date de fin</label>
                                                <div className="input-group date" name="datetimepicker-02" data-target-input="nearest">
                                                    <input
                                                        type="date"
                                                        className="form-control datetimepicker-input"
                                                        value={newCourse.endDate}
                                                        onChange={(e) => {
                                                            let val = e.target?.value;
                                                            let match = isMatch(e.target.value, 'yyyy-MM-dd');
                                                            setNewCourse(prevState => { return { ...prevState, endDate: val } });
                                                            if (match && val != "") {
                                                                setInvalideDate2(!match);
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            let val = isMatch(e.target.value, 'yyyy-MM-dd');
                                                            if (e.target.value != "") {
                                                                setInvalideDate2(!val);
                                                            }
                                                            if (!val && e.target.value != "") {
                                                                setNewCourse(prevState => { return { ...prevState, endDate: null } });
                                                            }
                                                        }}
                                                    />
                                                    {invalideDate2 && (
                                                        <p style={{ color: "red" }}>Format invalide</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Ville *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="location"
                                                    value={newCourse?.location}
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setNewCourse(prevState => { return { ...prevState, location: val } });
                                                    }}
                                                />
                                                {newCourse.location.length > 200 && (
                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                )}
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Description *</label>
                                                <CustomHtmlEditor
                                                    ref={descriptionHtmlEditor}
                                                    initialValue=""
                                                    onChange={(value, editor) => {
                                                        setNewCourse(prevState => { return { ...prevState, description: descriptionHtmlEditor.current.getContent() } });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                        // disabled={isCreatting}
                                        ref={closeCourseCreateModal}
                                    >
                                        Annuler
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={handleSubmit}
                                        disabled={isCreatting || hasMissingValue}
                                    >
                                        Soumettre &nbsp; {isCreatting ? <CircularProgress size={15} /> : <span></span>}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </>
            )
            }
        </React.Fragment >
    );

}
CourseListing.propTypes = {
    getCourseList: PropTypes.func.isRequired,
    deleteCourse: PropTypes.func.isRequired,
    storeCourse: PropTypes.object.isRequired,
    createCourse: PropTypes.func.isRequired,
    getEmployerList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    getCourseList: PropTypes.func.isRequired,
    deleteCourse: PropTypes.func.isRequired,
    createCourse: PropTypes.func.isRequired,
    storeCourse: state.course,
    storeEmployer: state.employer,
    getEmployerList: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, { getCourseList, deleteCourse, createCourse, getEmployerList, })(CourseListing)