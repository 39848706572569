import React from 'react';
import siteService from '../../../services/siteService';

function ContactInformations(props) {    
    const currentSite = siteService.getCurrentSite();
    return (
        <React.Fragment>  
        
            <div className="border align-items-center p-4">
                <h4>Informations</h4>
                <ul className="contact-info no-margin">
                    <li>
                        <i className="fas fa-map-marker-alt text-theme-color"></i>
                        {currentSite.address}
                    </li>
                    <li>
                        <i className="fas fa-phone text-theme-color"></i>
                        <a href={`tel:${currentSite.phoneNumber1}`}>{currentSite.phoneNumber1}</a> 
                        {currentSite.phoneNumber2 && (<> / <a href={`tel:${currentSite.phoneNumber2}`}>{currentSite.phoneNumber2}</a></>)}
                        
                    </li>
                    <li>
                        <i className="fas fa-envelope text-theme-color"></i>
                        <a href={`mailto:${currentSite.emailAddress}`}>{currentSite.emailAddress}</a>
                    </li>
                    <li>
                        <i className="fas fa-globe text-theme-color"></i>
                        <a href="https://www.d3kinc.com" target="_blank">www.d3kinc.com</a>
                    </li>
                </ul>
            </div>
        </React.Fragment>
    )
}

export default ContactInformations;