import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getArchiveTender } from "../../redux/actions/TenderActions"
import { CircularProgress } from '@material-ui/core';
import defaultBanner from '../../../assets/images/bg/default-banner.jpg';
import { Helmet } from "react-helmet";
import { getAdsList } from "../../redux/actions/admin/AdsActions";
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fr';
import Sidebar from '../shared/views/Sidebar';
import CustomGoogleAdsense from '../shared/views/CustomGoogleAdsense';
import siteService from '../../services/siteService';

export const TenderArchiveDetail = (props) => {
    const {
        getArchiveTender,
        storeTender,
        getAdsList,
        storeAds
    } = props;
    const [isLoading, setIsLoading] = React.useState(true);
    const [tender, setTender] = React.useState(null);
    const [tenderNumber, setTenderNumber] = React.useState("");
    const [displayingActiveAdsList, setDisplayingActiveAdsList] = React.useState([]);
    const currentSite = siteService.getCurrentSite();

    useEffect(() => {
        let match = props.match;
        if (match !== undefined) {
            let params = match.params;
            if (params !== undefined && params.tenderNumber !== undefined) {
                setTenderNumber(params.tenderNumber);
            }
        };
        if (storeAds.adsList.length == 0) {
            getAdsList(false).then(resp => {
                setDisplayingActiveAdsList(resp.adsList);
            })
        }
        else {
            setDisplayingActiveAdsList(storeAds.adsList);
        }
    }, []);

    useEffect(() => {
        if (tenderNumber != "") {
            getArchiveTender(tenderNumber).then(response => {
                if (response) {
                    setTender(response);
                }
            })
        }
    }, [tenderNumber]);

    useEffect(() => {
        if (tender !== null && isLoading) {
            setIsLoading(false);
            window.scrollTo(0, 0)
        }
    }, [tender]);


    return (
        <React.Fragment>
            {isLoading ? (
                <div className="loading-container">
                    <CircularProgress />
                    <div>Chargement de l'appel d'offre en cours...</div>
                </div>
            ) : (
                <>
                    <Helmet>
                        <title> Détail de l'appel d'offre:  {tender.employer ? tender.employer?.companyName : ""} | {tenderNumber}  | {currentSite.fullName}</title>
                    </Helmet>
                    <div className="header-inner" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${defaultBanner})` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h2 style={{ color: "white" }}>{tender.title}</h2>
                                    <ol className="breadcrumb mb-0 p-0">
                                        <li className="breadcrumb-item"><NavLink tag={Link} to="/">Accueil</NavLink></li>
                                        <li className="breadcrumb-item"><i className="fas fa-chevron-right"></i><NavLink tag={Link} to="/tenders/archive/list">Archives - Appel d'offre</NavLink></li>
                                        <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> {tender.tenderNumber} </span></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="space-ptb" style={{ paddingTop: "40px" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 p-4">
                                    <div className="row pt-4 bg-light" style={{ marginTop: "-25px" }}>
                                        <div className="col-md-4 col-sm-6 mb-4">
                                            <div className="d-flex">
                                                <i className="font-xll text-primary align-self-center flaticon-apartment"></i>
                                                <div className="feature-info-content pl-3">
                                                    <label className="mb-0">Entreprise</label>
                                                    <span className="mb-0 font-weight-bold d-block text-dark">{tender?.employer?.companyName}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 mb-4">
                                            <div className="d-flex">
                                                <i className="font-xll text-primary align-self-center flaticon-briefcase"></i>
                                                <div className="feature-info-content pl-3">
                                                    <label className="mb-0">Catégorie</label>
                                                    <span className="mb-0 font-weight-bold d-block text-dark">{tender?.category}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <div className="d-flex">
                                                <i className="font-xll text-primary align-self-center flaticon-map"></i>
                                                <div className="feature-info-content pl-3">
                                                    <label className="mb-0">Ville</label>
                                                    <span className="mb-0 font-weight-bold d-block text-dark">{tender?.location}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 mb-md-0 mb-4">
                                            <div className="d-flex">
                                                <i className="font-xll text-primary align-self-center flaticon-appointment"></i>
                                                <div className="feature-info-content pl-3">
                                                    <label className="mb-0">Date de publication</label>
                                                    <span className="mb-0 font-weight-bold d-block text-dark">{moment(tender.postedDate).format('YYYY-MM-DD')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 mb-4">
                                            <div className="d-flex">
                                                <i className="font-xll text-primary align-self-center flaticon-appointment"></i>
                                                <div className="feature-info-content pl-3">
                                                    <label className="mb-0">Date limite de dépot</label>
                                                    <span className="mb-0 font-weight-bold d-block text-dark">{tender.closingDateInFrench}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row bg-light">
                                        <div className="col-lg-12">
                                            <div className="my-4">
                                                <h5 className="mb-3 mb-md-4">Description</h5>
                                                <div dangerouslySetInnerHTML={{ __html: tender.description }} />
                                                <CustomGoogleAdsense location="Wide2" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="sidebar mb-0">
                                        <div className="widget">
                                            <div className="company-address widget-box">
                                                <div className="company-address-map text-center">
                                                    <img className="img-fluid" src={tender?.employer?.logoUrl != "" ? tender?.employer?.logoUrl : "assets/images/default-job.png"} alt="" />
                                                </div>
                                                <ul className="list-unstyled mt-3">
                                                    <li><i className="fas fa-building fa-flip-horizontal fa-fw"></i><span className="pl-2">{tender?.employer?.companyName}</span></li>
                                                    {tender?.showPhoneNumber && (
                                                        <li><a href={`tel:${tender?.employer?.companyPhoneNumber1Contact}`}><i className="fas fa-phone fa-flip-horizontal fa-fw"></i><span className="pl-2">{tender?.employer?.companyPhoneNumber1Contact} {tender?.employer?.companyPhoneNumber2Contact}</span></a></li>
                                                    )}
                                                    {tender?.showEmailAddress && (
                                                        <li><a href={`mailto:${tender?.employer?.companyEmailContact}`}><i className="fas fa-envelope fa-fw"></i><span className="pl-2">{tender?.employer?.companyEmailContact}</span></a></li>
                                                    )}
                                                    <li>
                                                        <Link to={"/tenders/search?companyId=" + tender?.employer?.id}><i className="fas fa-link fa-fw"></i><span className="pl-2">Afficher toutes les offres</span></Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="widget border-0" style={{ marginTop: "30px" }}>
                                            <div className="jobster-company-view">
                                                <ul className="list-unstyled">
                                                    <Sidebar />
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}

        </React.Fragment>
    )
}

TenderArchiveDetail.propTypes = {
    getArchiveTender: PropTypes.func.isRequired,
    storeTender: PropTypes.object.isRequired,
    storeAds: PropTypes.object.isRequired,
    getAdsList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    getArchiveTender: PropTypes.func.isRequired,
    storeTender: state.tender,
    getAdsList: PropTypes.func.isRequired,
    storeAds: state.ads,
});
export default connect(mapStateToProps, { getArchiveTender, getAdsList })(TenderArchiveDetail)
