import axios from "axios";
import { api } from "../../../services/apiHelpers";

export const ACTION_LOADING = "ACTION_LOADING";
export const ACTION_SUCCESS = "ACTION_SUCCESS";
export const ACTION_ERROR = "ACTION_ERROR";
export const ACTION_SUBMITING = "ACTION_SUBMITING";
export const ACTION_SUBMITED = "ACTION_SUBMITED";

export const GET_SMSNOTIFICATIONS_LIST = "GET_SMSNOTIFICATIONS_LIST";
export const GET_SMSNOTIFICATIONLIST_INCLUDED_INACTIVE = "GET_SMSNOTIFICATIONLIST_INCLUDED_INACTIVE";
export const GET_SMSNOTIFICATIONS = "GET_SMSNOTIFICATIONS";
export const SMSNOTIFICATIONS_SEARCH = "SMSNOTIFICATIONS_SEARCH";
// export const ACTION_SUBMITED = "ACTION_SUBMITED";

export const getSmsNotificationList = () => dispatch => {
    dispatch({
        type: ACTION_LOADING
    });
    return new Promise((resolve, reject) => {
        axios.get(api + "/Notifications/sms/list").then(res => {
            if (res.data.Success) {
                dispatch({
                    type: GET_SMSNOTIFICATIONS_LIST,
                    data: res.data.smsNotificationList
                });
            }
            resolve(res.data);

        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({ // informe le reducer de user que le formulaire le concernant est traité
            type: ACTION_SUCCESS
        });
        return data
    })
};

export const createSmsNotification = (newSmsNotification) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        axios.post(api + "/Notifications/sms/create", newSmsNotification).then(res => {
            if (res.data.Success) {
                dispatch({
                    type: GET_SMSNOTIFICATIONS_LIST,
                    data: res.data.smsNotificationList
                });
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        if (data) {
            dispatch({ // informe le reducer de user que le formulaire le concernant est traité
                type: ACTION_SUBMITED
            });
            dispatch({ // informe le reducer de user qu'une alerte doit être affiché'
                type: ACTION_ERROR,
                data: "Un utilisateur a été supprimé avec succès"
            });
        }
        else {
            dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
                type: ACTION_ERROR,
                data: data.message
            });
        }
        return data;
    });
};

export const updateSmsNotification = (smsNotifications) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });

    return new Promise((resolve, reject) => {
        axios.post(api + "/Notifications/sms/update", smsNotifications).then(res => {
            if (res.data.Success) {
                dispatch({
                    type: GET_SMSNOTIFICATIONS_LIST,
                    data: res.data.smsNotificationList
                });
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;
    });
};

export const deleteSmsNotification = (id) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        axios.get(api + "/Notifications/sms/delete/" + id).then(res => {
            if (res.data.Success) {
                dispatch({
                    type: GET_SMSNOTIFICATIONS_LIST,
                    data: res.data.smsNotificationList
                });
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;
    });
};

export const restartSmsNotification = (id) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        axios.get(api + "/Notifications/sms/restart/" + id).then(res => {
            if (res.data.Success) {
                dispatch({
                    type: GET_SMSNOTIFICATIONS_LIST,
                    data: res.data.smsNotificationList
                });
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;
    });
};