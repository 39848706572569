import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { Editor } from '@tinymce/tinymce-react';

function CustomHtmlEditor(props, ref) {
  const editorRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getContent() {
      return editorRef.current.getContent();
    },
    reset() {
      return editorRef.current.setContent("");
    }
  }), [])

  return (
    <React.Fragment>
      <Editor
        onEditorChange={(value, editor) => {
          props.onChange(value, editor);
        }}
        apiKey="kw5fg57gqr5ksih74bnbyd3vfcoz6zy92l21a8glme7qlp7n"
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue={props.initialValue}
        disabled={props.disabled}
        init={{
          height: 300,
          menubar: true,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
      />
    </React.Fragment>
  )
}

export default forwardRef(CustomHtmlEditor)