import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CircularProgress } from '@material-ui/core';
import { updateRole } from "../../../redux/actions/admin/RoleActions";
import { Helmet } from "react-helmet";
import siteService from '../../../services/siteService';

let RoleListing = (props) => {
  const { updateRole } = props;

  const currentSite = siteService.getCurrentSite();
  const [isLoading, setIsLoading] = React.useState(false);
  const [aRoleIsEditing, setARoleIsEditing] = React.useState(false);
  const [editingPermission, setEditingPermission] = React.useState({ permissionId: "", roleIds: [] });
  const [Permissions, setPermissions] = React.useState([]);
  const [roles, setroles] = React.useState([]);



  useEffect(() => {
    if (editingPermission.permissionId != "") {
      // setARoleIsEditing(true);
    }
    else if (editingPermission.permissionId == "") {
      // setARoleIsEditing(false);
    }
    
  }, [editingPermission]);

  const handleInputChange = (event, permissionId, roleId) => {
    let editingPermissionCopy = editingPermission;
    if (editingPermissionCopy.permissionId == "") {
      editingPermissionCopy.permissionId = permissionId;
      editingPermissionCopy.roleIds.push(roleId);
      setEditingPermission(editingPermissionCopy);
      setARoleIsEditing(true);

    }
    else if (editingPermissionCopy.permissionId != "") {
      if (editingPermissionCopy.roleIds.some(r => r == roleId)) {
        editingPermissionCopy.roleIds = editingPermissionCopy.roleIds.filter(r => r != roleId);
      }
      else {
        editingPermissionCopy.roleIds.push(roleId);
      }
      if (editingPermissionCopy.roleIds.length == 0) {
        editingPermissionCopy.permissionId = "";
        // setARoleIsEditing(false);
      }

      setEditingPermission(editingPermissionCopy);
      setARoleIsEditing(false);

      
    }
  }

  const handleSubmit = () => {
    // alert("submitted");

    updateRole(editingPermission).then(data => {
      if (data) {
       
      }

    })

  }
  return (
    <>
      <Helmet>
        <title> Administration | Rôles | {currentSite.fullName}</title>
      </Helmet>
      {isLoading ?
        (
          <div style={{ margin: "auto", textAlign: "center", fontSize: "18px", width: "50%", height: "50%", marginTop: "180px" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {/* <div class="header-inner" style={{ backgroundImage: `url(${darkblue})` }}>
              <div class="container">
                <div class="row">
                  <div class="col-12">
                    <h2 style={{ color: "white" }}>Admin : Rôles</h2>
                    <ol class="breadcrumb mb-0 p-0">
                      <li class="breadcrumb-item"><a href="index.html"> Tableau de bord </a></li>

                      <li class="breadcrumb-item active"> <i class="fas fa-chevron-right"></i> <span> Rôles </span></li>
                    </ol>
                  </div>
                </div>
              </div>
            </div> */}
            <div class="row">
              <div class="col-lg-12 mt-4 mt-lg-0">
                <div class="row mb-4">
                  <div class="col-md-7 col-sm-5 d-flex align-items-center">
                    <div class="section-title-02 mb-0 ">
                      <h4 class="mb-0">Liste des rôles</h4>
                    </div>
                  </div>

                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="user-dashboard-info-box table-responsive mt-1 bg-white">
                      <table class="table manage-candidates-top mb-4">
                        <thead>
                          <tr>
                            <th style={{ width: "30%" }}></th>
                            <th style={{ width: "15%" }} class="text-center">Administrator</th>
                            <th style={{ width: "15%" }} class="text-center">Editor</th>
                            <th style={{ width: "15%" }} class="text-center">Candidate</th>
                            <th style={{ width: "15%" }} class="text-center">Employer</th>
                            <th class="action text-right" style={{ width: "10%" }} ></th>
                          </tr>
                        </thead>
                        <tbody>
                          {Permissions.map((permission, index) => (
                            <tr class="candidates-list">
                              <td>{permission.ShortName}</td>
                              <td class="text-center">
                                <div class="custom-control custom-checkbox">
                                  <input
                                    onChange={(e) => handleInputChange(e, permission.PermissionId, roles.find(r => r.Name == "Administrator").RoleId)}
                                    type="checkbox"
                                    class="custom-control-input"
                                    id={permission.PermissionId + "-" + roles.find(r => r.Name == "Administrator").RoleId}
                                    defaultChecked={roles.find(r => r.Name == "Administrator").Permissions.some(p => p.Name == permission.Name)}
                                    disabled={editingPermission.permissionId != "" && permission.PermissionId != editingPermission.permissionId}
                                  />
                                  <label class="custom-control-label" for={permission.PermissionId + "-" + roles.find(r => r.Name == "Administrator").RoleId}></label>
                                </div>
                              </td>
                              <td class="text-center">
                                <div class="custom-control custom-checkbox">
                                  <input
                                    onChange={(e) => handleInputChange(e, permission.PermissionId, roles.find(r => r.Name == "Editor").RoleId)}
                                    type="checkbox" class="custom-control-input" id={permission.PermissionId + "-" + roles.find(r => r.Name == "Editor").RoleId}
                                    defaultChecked={roles.find(r => r.Name == "Editor").Permissions.some(p => p.Name == permission.Name)}
                                    disabled={editingPermission.permissionId != "" && permission.PermissionId != editingPermission.permissionId}
                                  />
                                  <label class="custom-control-label" for={permission.PermissionId + "-" + roles.find(r => r.Name == "Editor").RoleId}></label>
                                </div>
                              </td>
                              <td class="text-center">
                                <div class="custom-control custom-checkbox">
                                  <input
                                    onChange={(e) => handleInputChange(e, permission.PermissionId, roles.find(r => r.Name == "Candidate").RoleId)}
                                    type="checkbox" class="custom-control-input" id={permission.PermissionId + "-" + roles.find(r => r.Name == "Candidate").RoleId}
                                    defaultChecked={roles.find(r => r.Name == "Candidate").Permissions.some(p => p.Name == permission.Name)}
                                    disabled={editingPermission.permissionId != "" && permission.PermissionId != editingPermission.permissionId}
                                  />
                                  <label class="custom-control-label" for={permission.PermissionId + "-" + roles.find(r => r.Name == "Candidate").RoleId}></label>
                                </div>
                              </td>
                              <td class="text-center">
                                <div class="custom-control custom-checkbox">
                                  <input
                                    onChange={(e) => handleInputChange(e, permission.PermissionId, roles.find(r => r.Name == "Employer").RoleId)}
                                    type="checkbox" class="custom-control-input" id={permission.PermissionId + "-" + roles.find(r => r.Name == "Employer").RoleId}
                                    defaultChecked={roles.find(r => r.Name == "Employer").Permissions.some(p => p.Name == permission.Name)}
                                    disabled={editingPermission.permissionId != "" && permission.PermissionId != editingPermission.permissionId}
                                  />
                                  <label class="custom-control-label" for={permission.PermissionId + "-" + roles.find(r => r.Name == "Employer").RoleId}></label>
                                </div>
                              </td>
                              <td>
                                <ul class="list-unstyled mb-0 d-flex justify-content-end">
                                  <li>
                                    <button type="submit" style={{ border: "none", background: "none" }} disabled={editingPermission.permissionId != "" && permission.PermissionId != editingPermission.permissionId} onClick={handleSubmit} >
                                      <i class="fas fa-save" style={{ marginTop: "-5px", fontSize: "20px" }}></i>
                                    </button>
                                    {/* <a style={{ pointerEvents: "none" }} type="button" disabled="disabled" onClick={handleSubmit} class="text-info" data-toggle="tooltip" title="" data-original-title="Edit" >
                                          <i class="fas fa-save" style={{ marginTop: "-5px", fontSize: "20px", color: "gray" }}></i>
                                        </a> */}
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          ))}

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </>
        )}
    </>
  );
}

RoleListing.propTypes = {
  updateRole: PropTypes.func.isRequired,
  storeUsers: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  storeUsers: state.users,
  updateRole: PropTypes.func.isRequired
});

export default connect(mapStateToProps, {
  updateRole
})(RoleListing)