import banner10000 from '../../../assets/images/bg/categories/default.jpg';
import banner10001 from '../../../assets/images/bg/categories/default.jpg';
import banner10002 from '../../../assets/images/bg/categories/mines.jpg';
import banner10003 from '../../../assets/images/bg/categories/default.jpg';
import banner10004 from '../../../assets/images/bg/categories/default.jpg';
import banner10005 from '../../../assets/images/bg/categories/default.jpg';
import banner10006 from '../../../assets/images/bg/categories/default.jpg';
import banner10007 from '../../../assets/images/bg/categories/default.jpg';
import banner10008 from '../../../assets/images/bg/categories/default.jpg';
import banner10009 from '../../../assets/images/bg/categories/informatique.jpg';
import banner10010 from '../../../assets/images/bg/categories/default.jpg';
import banner10011 from '../../../assets/images/bg/categories/default.jpg';
import banner10012 from '../../../assets/images/bg/categories/default.jpg';
import banner10013 from '../../../assets/images/bg/categories/default.jpg';
import banner10014 from '../../../assets/images/bg/categories/default.jpg';
import banner10015 from '../../../assets/images/bg/categories/default.jpg';
import banner10016 from '../../../assets/images/bg/categories/default.jpg';
import banner10017 from '../../../assets/images/bg/categories/default.jpg';
import banner10018 from '../../../assets/images/bg/categories/default.jpg';

export const banners = [
    {
        id: "10000", url: banner10000
    },
    {
        id: "10001", url: banner10001
    },
    {
        id: "10002", url: banner10002
    },
    {
        id: "10003", url: banner10003
    },
    {
        id: "10004", url: banner10004
    },
    {
        id: "10005", url: banner10005
    },
    {
        id: "10006", url: banner10006
    },
    {
        id: "10007", url: banner10007
    },
    {
        id: "10008", url: banner10008
    },
    {
        id: "10009", url: banner10009
    },
    {
        id: "10010", url: banner10010
    },
    {
        id: "10011", url: banner10011
    },
    {
        id: "10012", url: banner10012
    },
    {
        id: "10013", url: banner10013
    },
    {
        id: "10014", url: banner10014
    },
    {
        id: "10015", url: banner10015
    },
    {
        id: "10016", url: banner10016
    },
    {
        id: "10017", url: banner10017
    },
    {
        id: "10018", url: banner10018
    }
];

export class CategoryBannerHelper {
    static getLevel(value) {
      if (!value) {
        return value;
      }
      var userGroupName = banners.find((x) => x.id === value);
      if (userGroupName !== undefined) {
        return userGroupName.url;
      }
    }
  
  }