import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { tenderSearch } from "../../redux/actions/TenderActions"
import { CircularProgress } from '@material-ui/core';
import defaultBanner from '../../../assets/images/bg/default-banner.jpg';
import { Helmet } from "react-helmet";
import { getAdsList } from "../../redux/actions/admin/AdsActions";
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Sidebar from '../shared/views/Sidebar';
import createAccountImage from '../../../assets/images/pourquoi-creer-un-compte.jpg';
import CustomGoogleAdsense from '../shared/views/CustomGoogleAdsense';
import TenderExport from '../tenders/TenderExport';
import siteService from '../../services/siteService';

export const TenderDetail = (props) => {
    const {
        tenderSearch,
        storeTender,
    } = props;
    const [isLoading, setIsLoading] = React.useState(true);
    const [tender, setTender] = React.useState(null);
    const [tenderNumber, setTenderNumber] = React.useState("");
    const currentSite = siteService.getCurrentSite();

    useEffect(() => {
        let match = props.match;
        if (match !== undefined) {
            let params = match.params;
            if (params !== undefined && params.tenderNumber !== undefined) {
                setTenderNumber(params.tenderNumber);
            }
        };
    }, []);

    useEffect(() => {
        if (tenderNumber != "") {
            if (storeTender.tenderListIncludedDescription.length == 0 && tender === null) {
                tenderSearch(true).then(response => {
                    if (response) {
                        let tenderFromStore = response.documents.find(d => d.tenderNumber == tenderNumber);
                        if (tenderFromStore) {
                            setTender(tenderFromStore);
                        }
                    }
                })
            }
            else if (storeTender.tenderListIncludedDescription.length > 0 && tender === null) {
                let tenderFromStore = storeTender.tenderListIncludedDescription.find(d => d.tenderNumber == tenderNumber);
                if (tenderFromStore) {
                    setTender(tenderFromStore);
                }
            }
        }
    }, [tenderNumber]);

    useEffect(() => {
        if (tender !== null && isLoading) {
            setIsLoading(false);
            window.scrollTo(0, 0)
        }
    }, [tender]);


    return (
        <React.Fragment>
            {isLoading ? (
                <div className="loading-container">
                    <CircularProgress />
                    <div>Chargement de l'appel d'offre en cours...</div>
                </div>
            ) : (
                <>
                    <Helmet>
                        <title> Détail de l'appel d'offre:  {tender.companyName} | {tenderNumber}  | {currentSite.fullName}</title>
                    </Helmet>
                    <div className="header-inner" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${defaultBanner})` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h2 style={{ color: "white" }}>{tender.title}</h2>
                                    <ol className="breadcrumb mb-0 p-0">
                                        <li className="breadcrumb-item"><NavLink tag={Link} to="/">Accueil</NavLink></li>
                                        <li className="breadcrumb-item"><i className="fas fa-chevron-right"></i><NavLink tag={Link} to="/tenders/search">Appels d'offres</NavLink></li>
                                        <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> {tender.tenderNumber} </span></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="space-ptb" style={{ paddingTop: "40px" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 p-4">
                                    <div className="row pt-4 bg-light" style={{ marginTop: "-25px" }}>
                                        <div className="col-md-4 col-sm-6 mb-4">
                                            <div className="d-flex">
                                                <i className="font-xll text-primary align-self-center flaticon-apartment"></i>
                                                <div className="feature-info-content pl-3">
                                                    <label className="mb-0">Entreprise</label>
                                                    <span className="mb-0 font-weight-bold d-block text-dark">{tender.companyName}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 mb-4">
                                            <div className="d-flex">
                                                <i className="font-xll text-primary align-self-center flaticon-briefcase"></i>
                                                <div className="feature-info-content pl-3">
                                                    <label className="mb-0">Catégorie</label>
                                                    <span className="mb-0 font-weight-bold d-block text-dark">{tender.categoryDisplayName}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <div className="d-flex">
                                                <i className="font-xll text-primary align-self-center flaticon-map"></i>
                                                <div className="feature-info-content pl-3">
                                                    <label className="mb-0">Ville</label>
                                                    <span className="mb-0 font-weight-bold d-block text-dark">{tender.location}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 mb-md-0 mb-4">
                                            <div className="d-flex">
                                                <i className="font-xll text-primary align-self-center flaticon-appointment"></i>
                                                <div className="feature-info-content pl-3">
                                                    <label className="mb-0">Date de publication</label>
                                                    <span className="mb-0 font-weight-bold d-block text-dark">{tender.postedDateInFrench}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 mb-4">
                                            <div className="d-flex">
                                                <i className="font-xll text-primary align-self-center flaticon-appointment"></i>
                                                <div className="feature-info-content pl-3">
                                                    <label className="mb-0">Date limite de dépot</label>
                                                    <span className="mb-0 font-weight-bold d-block text-dark">{tender.closingDateInFrench}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row bg-light">
                                        <div className="col-lg-12">
                                            <div className="my-4">
                                                <h5 className="mb-3 mb-md-4">Description</h5>
                                                <div dangerouslySetInnerHTML={{ __html: tender.description }} />
                                                <CustomGoogleAdsense location="Wide2" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="sidebar mb-0">
                                        <div className="widget">
                                            <div className="company-address widget-box">
                                                <div className="company-address-map text-center">
                                                    <img className="img-fluid" src={tender.imageUrl != "" ? tender.imageUrl : "assets/images/default-job.png"} alt="" />
                                                </div>
                                                <ul className="list-unstyled mt-3">
                                                    <li><i className="fas fa-building fa-flip-horizontal fa-fw"></i><span className="pl-2">{tender.companyName}</span></li>
                                                    {tender?.showPhoneNumber && (
                                                        <li><a href={`tel:${tender.companyPhoneNumber1Contact}`}><i className="fas fa-phone fa-flip-horizontal fa-fw"></i><span className="pl-2">{tender.companyPhoneNumber1Contact} {tender.companyPhoneNumber2Contact}</span></a></li>
                                                    )}
                                                    {tender?.showEmailAddress && (
                                                        <li><a href={`mailto:${tender.applicationEmail}`}><i className="fas fa-envelope fa-fw"></i><span className="pl-2">{tender.applicationEmail}</span></a></li>
                                                    )}
                                                    <li>
                                                        <Link to={"/tenders/search?companyId=" + tender.companyId}><i className="fas fa-link fa-fw"></i><span className="pl-2">Afficher toutes les offres</span></Link>
                                                    </li>
                                                    <div>< TenderExport tender={tender} /></div>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="widget border-0" style={{ marginTop: "30px" }}>
                                            <div className="jobster-company-view">
                                                <ul className="list-unstyled">
                                                    <Sidebar />
                                                    <div className="widget border-0">
                                                        <div className="jobster-company-view">
                                                            <ul className="list-unstyled">
                                                                <li>
                                                                    <div className="widget-box">
                                                                        <div className="d-flex">
                                                                            <div>
                                                                                <Link to={"/candidates/signup"}>
                                                                                    <img className="img-fluid" src={createAccountImage} alt="" />
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <CustomGoogleAdsense location="Side1" />
                                                    {/*<CustomGoogleAdsense location="Side2" />
                                                     <CustomGoogleAdsense location="Side3" /> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}

        </React.Fragment>
    )
}

TenderDetail.propTypes = {
    tenderSearch: PropTypes.func.isRequired,
    storeTender: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    tenderSearch: PropTypes.func.isRequired,
    storeTender: state.tender,
});
export default connect(mapStateToProps, { tenderSearch })(TenderDetail)
