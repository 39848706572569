import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CircularProgress } from '@material-ui/core';
import { Helmet } from "react-helmet";
import { resetPassword } from "../../../redux/actions/admin/UserActions";
import { withRouter } from "react-router";
import CustomAlert from '../../shared/views/CustomAlert';

const ResetPassword = (props) => {

    const { resetPassword, } = props;

    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    // const [areNotSame, setAreNotSame] = React.useState(false);
    // const [isLoading, setIsLoading] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    // const [adminForgotPassword, setAdminForgotPassword] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertType, setAlertType] = React.useState("");
    const [resetPasswordRequest, setResetPasswordRequest] = React.useState(
        {
            token: "",
            email: "",
            password: "",
            confirmPassword: ""
        });

    useEffect(() => {
        let match = props.match;
        if (match !== undefined) {
            let params = match.params;
            if (params !== undefined && params.t !== undefined) {
                setResetPasswordRequest(prevState => { return { ...prevState, token: params.t } });
            }
        };
    }, []);

    useEffect(() => {

        if (resetPasswordRequest.password === ""
            || resetPasswordRequest.password === undefined
            || resetPasswordRequest.confirmPassword === ""
            || resetPasswordRequest.confirmPassword === undefined
            || resetPasswordRequest.email === ""
            || resetPasswordRequest.email === undefined
        ) {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);
        }
    }, [resetPasswordRequest]);

    let handleResetPassword = () => {
        setIsSubmitting(true);
        resetPassword(resetPasswordRequest).then(response => {
            if (response) {
                setAlertType("alert-success");
                setAlertMessage("<strong>Félicitations!</strong> votre mot de passe a été réinitialisé avec succès. Veuillez cliquer< Link to={'/admin/signin'}> ici</Link > pour vous connecter")
            }
            else {
                setAlertMessage("Email est incorrect.");
                setAlertType("alert-danger");
            }
            setIsSubmitting(false);
            setShowAlert(true)
        });
    }

    return (
        <React.Fragment>
            <Helmet>
                <title> Mot de passe oublié</title>
            </Helmet>
            <>
                <section className="space-ptb ">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-6 col-md-12">
                                <div className="login-register bg-light-2 p-4 admin-login-box-container">
                                    <div className="section-title center text-center">
                                        <h2 className="title"><span>Mot de passe oublié</span></h2>
                                    </div>
                                    <fieldset className="border-redush-0">
                                        <div className="mt-4">
                                            <div className="form-row">
                                                <div className="form-group col-12">
                                                    <label for="email">Adresse email</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="email"
                                                        value={resetPasswordRequest.email}
                                                        required
                                                        onChange={(e) => {
                                                            let val = e.target?.value;
                                                            setResetPasswordRequest(prevState => { return { ...prevState, email: val } });
                                                        }} />
                                                </div>
                                                <div className="form-group col-12">
                                                    <label for="password2">Nouveau mot de passe</label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="password"
                                                        onChange={(e) => {
                                                            let val = e.target?.value;
                                                            setResetPasswordRequest(prevState => { return { ...prevState, password: val } });
                                                        }}
                                                        value={resetPasswordRequest.password}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-12">
                                                    <label for="password2">Confirmer le nouveau mot de passe</label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="password"
                                                        onChange={(e) => {
                                                            let val = e.target?.value;
                                                            setResetPasswordRequest(prevState => { return { ...prevState, confirmPassword: val } });
                                                        }}
                                                        value={resetPasswordRequest.confirmPassword}
                                                        required
                                                    />
                                                    {/* {areNotSame && (
                                                            <div style={{ color: "red", marginTop: "8px" }}>Les deux mots de passe sont différents.</div>
                                                        )} */}
                                                </div>
                                                {(showAlert) && (
                                                    <>
                                                        <CustomAlert content={alertMessage} type={alertType} />
                                                    </>
                                                )}
                                            </div>
                                            <div className="form-row">
                                                <div className="col-md-12">
                                                    <button className="btn btn-primary btn-block" onClick={(e) => {
                                                        handleResetPassword();
                                                    }} disabled={isSubmitting || hasMissingValue}>
                                                        Soumettre &nbsp; {isSubmitting ? <CircularProgress size={15} /> : <span></span>}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </React.Fragment>
    )

}


ResetPassword.propTypes = {
    resetPassword: PropTypes.func.isRequired,
    storeUsers: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    resetPassword: PropTypes.func.isRequired,
    storeUsers: state.users
});

export default withRouter(connect(mapStateToProps, { resetPassword })(ResetPassword));