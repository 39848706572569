import React, { Component, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getJobList, createJob } from "../../redux/actions/JobActions"
import { getEmployerList } from "../../redux/actions/admin/EmployerActions"
import { CircularProgress } from '@material-ui/core';
import defaultBanner from '../../../assets/images/bg/default-banner.jpg';
import { categories, types } from "../../views/shared/Helper";
import CustomHtmlEditor from './../shared/views/CustomHtmlEditor';
import CustomAlert from './../shared/views/CustomAlert';
import { getAdsList } from "../../redux/actions/admin/AdsActions";
import Select from 'react-select';
import { Helmet } from "react-helmet";
import localStorageService from '../../services/localStorageService';
import validator from 'validator'
import { Link } from 'react-router-dom';
import postBannerImage from '../../../assets/images/pourquoi-deposer-une-offre.jpg';
import siteService from '../../services/siteService';

let JobCreate = (props) => {
    const descriptionHtmlEditor = useRef();
    const confirmationContainerRef = useRef()
    const {
        storeEmployer = {},
        createJob,
        getEmployerList,
        storeAds,
        getAdsList
    } = props;

    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [creationFailed, setCreationFailed] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isSaving, setIsSaving] = React.useState(false);
    const [published, setPublished] = React.useState(false);
    const [currentEmployer, setCurrentEmployer] = React.useState(null);
    const [employersListOptions, setEmployersListOptions] = React.useState([]);
    const [displayingActiveAdsList, setDisplayingActiveAdsList] = React.useState([]);
    const [quizzesTodelete, setQuizzesTodelete] = React.useState(null);
    const [newJob, setNewJob] = React.useState(
        {
            title: "",
            companyName: "",
            employerId: "",
            applicationEmail: "",
            category: "",
            type: "",
            count: "",
            location: "",
            closingDate: new Date(),
            startingDate: new Date(),
            description: "",
            showEmailAddress: false,
            showPhoneNumber: false,
            additionalQuestions: []
        });
    const [invalideEmail, setInvalideEmail] = React.useState(false);
    const [createdJobNumber, setCreatedJobNumber] = React.useState("");
    const currentSite = siteService.getCurrentSite();


    useEffect(() => {
        if (storeEmployer.employersList.length > 0 && employersListOptions.length == 0) {
            getEmployersListOptions(storeEmployer.employersList);
        }
        else if (storeEmployer.employersList.length == 0 && employersListOptions.length == 0) {
            getEmployerList(false).then(response => {
                if (response.success) {
                    getEmployersListOptions(response.employersList);
                };
            });
        }
        if (storeAds.adsList.length == 0) {
            getAdsList(false).then(resp => {
                setDisplayingActiveAdsList(resp.adsList);
            })
        }
        else {
            setDisplayingActiveAdsList(storeAds.adsList);
        }
        let employer = localStorageService.getItem("malibaara_auth_employer");
        if (employer) {
            setCurrentEmployer(employer);
            setNewJob(prevState => { return { ...prevState, employerId: employer.id, companyName: employer.companyName, applicationEmail: employer.email } });
        }
    }, [])

    useEffect(() => {
        if (newJob.title == ""
            || newJob.title === undefined
            || newJob.companyName == ""
            || newJob.companyName === undefined
            || newJob.applicationEmail == ""
            || newJob.applicationEmail === undefined
            || newJob.category == ""
            || newJob.category === undefined
            || newJob.location == ""
            || newJob.location === undefined
            | newJob.description == ""
            || newJob.description === undefined
        ) {
            setHasMissingValue(true);
        }
        else if (newJob.location?.length > 200
            || (newJob.applicationEmail != "" && !validator.isEmail(newJob.applicationEmail) && newJob.applicationEmail > 200)
            || newJob?.title?.length > 200
        ) {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);
        }
    }, [newJob])

    useEffect(() => {
        if (employersListOptions.length > 0 && isLoading) {
            setIsLoading(false);
        }
    }, [employersListOptions])

    const handleSubmit = () => {
        setIsSaving(true);
        createJob(newJob).then(data => {
            if (data !== undefined && data !== null) {
                setCreatedJobNumber(data);
                setIsSaving(false);
                setPublished(true);
                setCreationFailed(false);
                window.scrollTo({ behavior: 'smooth', top: confirmationContainerRef.current.offsetTop })
            }
            else {
                setCreationFailed(true);
                setIsSaving(false);
            }
        });
    }


    const getEmployersListOptions = (employers) => {
        let result = [];
        employers.forEach(employer => {
            result.push({ label: employer.companyName, id: employer.id, value: employer.id, email: employer.companyEmailContact });
        });
        setEmployersListOptions(result);
    }

    // Start keydown mechanism
    let onKeydown = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            if (!hasMissingValue) {
                handleSubmit();
            }
            event.preventDefault();
        }
    };

    // useEffect(() => {
    //     document.addEventListener("keydown", onKeydown);
    //     return () => {
    //         document.removeEventListener("keydown", onKeydown);
    //     };
    // }, [onKeydown]);
    //End keydown mechanism

    return (
        <React.Fragment>
            <Helmet>
                <title>Publication d'une offre d'emploi | {currentSite.fullName}</title>
            </Helmet>
            <div className="header-inner" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${defaultBanner})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 style={{ color: "white" }}>Publication</h2>
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><a href="/"> Accueil </a></li>
                                <li className="breadcrumb-item"><i className="fas fa-chevron-right"></i><a href="index.html"> Employeur </a></li>
                                <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> Publication d'une offre d'emploi </span></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading ? (
                <div className="loading-container">
                    <CircularProgress />
                    <div>Chargement du formulaire en cours...</div>
                </div>
            ) : (
                <>
                    <section className="space-ptb" style={{ padding: "50px 0" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-8">
                                    <div className="bg-light-3 p-4">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="section-title">
                                                    <h2 className="title"><span>Publication d'une offre d'emploi</span></h2>
                                                    <p>Remplissez le formulaire ci-dessous afin de publier votre offre d'emploi. Cette offre sera visible sur le site une fois qu'elle sera approuvée par un modérateur.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div _lpchecked="1">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label>Titre du poste *</label>
                                                    <input
                                                        value={newJob?.title}
                                                        type="text"
                                                        className="form-control"
                                                        name="title"
                                                        onChange={(e) => {
                                                            let val = e.target?.value;
                                                            setNewJob(prevState => { return { ...prevState, title: val } });
                                                        }}
                                                        placeholder=""
                                                        disabled={published}
                                                    />
                                                    {newJob?.title?.length > 200 && (
                                                        <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                    )}
                                                </div>
                                                {currentEmployer ?
                                                    (
                                                        <>
                                                            <div className="form-group col-md-12">
                                                                <label>Nom de la société *</label>
                                                                <input
                                                                    value={newJob?.companyName}
                                                                    className="form-control"
                                                                    name="companyName"
                                                                    placeholder=""
                                                                    disabled
                                                                />
                                                            </div>
                                                        </>
                                                    ) :
                                                    (
                                                        <>
                                                            <div class="form-group col-md-12">
                                                                <label>Nom de la société *</label>
                                                                <Select
                                                                    options={employersListOptions}
                                                                    loadingMessage="En cours de chargement"
                                                                    onChange={(option) => {
                                                                        setNewJob(prevState => { return { ...prevState, employerId: option?.value, companyName: option.label, applicationEmail: option.email } });
                                                                    }}
                                                                    isDisabled={published}
                                                                />
                                                                <p style={{ marginTop: "9px", marginBottom: "0px", fontStyle: "italic" }}>Si votre société ne se trouve pas dans cette liste, veuillez cliquez sur <Link to={"/employers/signup?redirect=/jobs/post"}>lien</Link> pour créer un nouveau compte</p>
                                                            </div>
                                                        </>
                                                    )}
                                                <div className="form-group col-md-12">
                                                    <label>Email *</label>
                                                    <input
                                                        value={newJob?.applicationEmail}
                                                        type="email"
                                                        className="form-control"
                                                        name="applicationEmail"
                                                        onChange={(e) => {
                                                            let val = e.target?.value;
                                                            let isValide = validator.isEmail(e.target.value);
                                                            setNewJob(prevState => { return { ...prevState, applicationEmail: val } });
                                                            if (invalideEmail) {
                                                                setInvalideEmail(!isValide);
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            let val = validator.isEmail(e.target.value);
                                                            setInvalideEmail(!val);
                                                        }}
                                                        placeholder=""
                                                        disabled={published}
                                                    />
                                                    {(newJob.email != "" && invalideEmail) && (
                                                        <p style={{ color: "red" }}>Format d'email invalide</p>
                                                    )}
                                                    {newJob?.applicationEmail?.length > 200 && (
                                                        <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                    )}
                                                </div>
                                                <div className="form-group col-sm-6 col-xl-6">
                                                    <label>Catégorie *</label>
                                                    <Select
                                                        options={categories}
                                                        loadingMessage="En cours de chargement"
                                                        onChange={(option) => {
                                                            setNewJob(prevState => { return { ...prevState, category: option?.label } });
                                                        }}
                                                        isDisabled={published}
                                                    />
                                                </div>
                                                <div className="form-group col-sm-6 col-xl-6">
                                                    <label>Type d'emploi</label>
                                                    <Select
                                                        options={types}
                                                        loadingMessage="En cours de chargement"
                                                        onChange={(option) => {
                                                            setNewJob(prevState => { return { ...prevState, type: option?.value } });
                                                        }}
                                                        isDisabled={published}
                                                    />
                                                </div>
                                                <div className="form-group col-sm-6">
                                                    <label>Date limite de dépot</label>
                                                    <div className="input-group date">
                                                        <input
                                                            value={newJob?.closingDate}
                                                            type="date"
                                                            className="form-control"
                                                            name="closingDate"
                                                            onChange={(e) => {
                                                                let val = e.target?.value;
                                                                setNewJob(prevState => { return { ...prevState, closingDate: val } });
                                                            }}
                                                            disabled={published}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-sm-6">
                                                    <label>Date de prise en fonction</label>
                                                    <div className="input-group date">
                                                        <input
                                                            value={newJob?.startingDate}
                                                            type="date"
                                                            className="form-control"
                                                            name="startingDate"
                                                            onChange={(e) => {
                                                                let val = e.target?.value;
                                                                setNewJob(prevState => { return { ...prevState, startingDate: val } });
                                                            }}
                                                            disabled={published}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label>Ville *</label>
                                                    <input
                                                        value={newJob?.location}
                                                        type="text"
                                                        className="form-control"
                                                        name="location"
                                                        placeholder=""
                                                        onChange={(e) => {
                                                            let val = e.target?.value;
                                                            setNewJob(prevState => { return { ...prevState, location: val } });
                                                        }}
                                                        disabled={published}
                                                    />
                                                    {newJob.location.length > 200 && (
                                                        <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                    )}
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label>Description *</label>
                                                    <CustomHtmlEditor
                                                        onChange={(value, editor) => {
                                                            setNewJob(prevState => { return { ...prevState, description: descriptionHtmlEditor.current.getContent() } });
                                                        }}
                                                        ref={descriptionHtmlEditor}
                                                        initialValue=""
                                                        disabled={published}
                                                    />
                                                </div>
                                                <div className="form-group col-12 mt-3">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            id="accepts-02"
                                                            type="checkbox"
                                                            checked={newJob.receiveEmailNotification}
                                                            className="custom-control-input"
                                                            name="receiveEmailNotification"
                                                            onClick={(e) => {
                                                                let val = e.target?.checked;
                                                                setNewJob(prevState => {
                                                                    return { ...prevState, receiveEmailNotification: val }
                                                                });
                                                            }}
                                                            disabled={published}
                                                        />
                                                        <label className="custom-control-label" for="accepts-02">Recevoir les candidatures par e-mail (les candidats pourront postuler directement à partir du site)</label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            id="accepts-03"
                                                            type="checkbox"
                                                            checked={newJob.showEmailAddress}
                                                            className="custom-control-input"
                                                            name="showEmailAddress"
                                                            onClick={(e) => {
                                                                let val = e.target?.checked;
                                                                setNewJob(prevState => {
                                                                    return { ...prevState, showEmailAddress: val }
                                                                });
                                                            }}
                                                            disabled={published}
                                                        />
                                                        <label className="custom-control-label" for="accepts-03">Afficher mon adresse email sur la description de l'offre d'emploi</label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            id="accepts-04"
                                                            type="checkbox"
                                                            checked={newJob.showPhoneNumber}
                                                            className="custom-control-input"
                                                            name="showPhoneNumber"
                                                            onClick={(e) => {
                                                                let val = e.target?.checked;
                                                                setNewJob(prevState => {
                                                                    return { ...prevState, showPhoneNumber: val }
                                                                });
                                                            }}
                                                            disabled={published}
                                                        />
                                                        <label className="custom-control-label" for="accepts-04">Afficher mon numéro de téléphone sur la description de l'offre d'emploi</label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            id="accepts-05"
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            checked={newJob.hasAdditionalQuestions}
                                                            // name="additionalQuestions"
                                                            onClick={(e) => {
                                                                let val = e.target?.checked;
                                                                setNewJob(prevState => {
                                                                    return { ...prevState, hasAdditionalQuestions: val }
                                                                });
                                                                if (val == true && newJob.additionalQuestions?.length == 0) {
                                                                    setNewJob(prevState => {
                                                                        return { ...prevState, additionalQuestions: [{ label: "", type: "", required: false }] }
                                                                    });
                                                                }
                                                                else {
                                                                    setNewJob(prevState => {
                                                                        return { ...prevState, additionalQuestions: [] }
                                                                    });
                                                                }
                                                            }}
                                                            disabled={published}
                                                        />
                                                        <label className="custom-control-label" for="accepts-05">Afficher des questions supplémentaires</label>
                                                    </div>
                                                </div>
                                                {newJob.hasAdditionalQuestions == true && (
                                                    newJob.additionalQuestions?.map((quiz, index) => (
                                                        <>
                                                            <div style={{ marginTop: "15px" }} className="form-group col-md-12">
                                                                <label>Question {index + 1} </label>
                                                                {index >= 0 && (
                                                                    <a style={{ float: "right" }} href="assets/#" className="text-danger" data-toggle="modal" title="" data-target="#deleteJob" data-original-title="Delete">
                                                                        <i
                                                                            className="far fa-trash-alt"
                                                                            onClick={() => {
                                                                                let indexOf = newJob.additionalQuestions?.indexOf(quiz);
                                                                                let additionalQuestionsCopy = newJob.additionalQuestions;
                                                                                additionalQuestionsCopy?.splice(indexOf, 1);
                                                                                setNewJob(prevState => {
                                                                                    return { ...prevState, additionalQuestions: additionalQuestionsCopy }
                                                                                });
                                                                            }}>
                                                                        </i>
                                                                    </a>
                                                                )}
                                                                <input
                                                                    value={quiz.label}
                                                                    type="text"
                                                                    className="form-control"
                                                                    // name={quiz.label}
                                                                    placeholder=""
                                                                    disabled={published}
                                                                    onChange={(e) => {
                                                                        let val = e.target.value;
                                                                        let additionalQuestionsCopy = newJob.additionalQuestions;
                                                                        additionalQuestionsCopy[index].label = val;
                                                                        setNewJob(prevState => {
                                                                            return { ...prevState, additionalQuestions: additionalQuestionsCopy }
                                                                        });                                                                        
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-12">
                                                                <label>Type de réponse {index + 1} </label>
                                                                <Select
                                                                    options={[
                                                                        { label: "Champs libre", value: "FreeText" },
                                                                        { label: "Choix unique", value: "SingleValue" },
                                                                        { label: "Choix multiple", value: "MultiValue" },
                                                                        { label: "Date", value: "DateTime" },
                                                                        { label: "Numérique", value: "Numeric" },
                                                                        { label: "Vrai ou faux", value: "YesNo" },
                                                                    ]}
                                                                    loadingMessage="En cours de chargement"
                                                                    isDisabled={published}
                                                                    onChange={(option) => {
                                                                        let additionalQuestionsCopy = newJob.additionalQuestions;
                                                                        additionalQuestionsCopy[index].type = option?.value;
                                                                        if (option.value == "SingleValue" || option.value == "MultiValue") {
                                                                            additionalQuestionsCopy[index].options = "";
                                                                        }
                                                                        setNewJob(prevState => {
                                                                            return { ...prevState, additionalQuestions: additionalQuestionsCopy }
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                            {(quiz.type == "SingleValue" || quiz.type == "MultiValue") && (
                                                                <div className="form-group col-md-12">
                                                                    <label>Options </label>
                                                                    <textarea
                                                                        className="form-control"
                                                                        value={quiz.options}
                                                                        onChange={e => {
                                                                            let additionalQuestionsCopy = newJob.additionalQuestions;
                                                                            additionalQuestionsCopy[index].options = e.target.value;
                                                                            setNewJob(prevState => {
                                                                                return { ...prevState, additionalQuestions: additionalQuestionsCopy }
                                                                            });
                                                                        }}
                                                                        onKeyDown={e => {
                                                                            if (e.key == "Enter") {
                                                                                let additionalQuestionsCopy = newJob.additionalQuestions;
                                                                                additionalQuestionsCopy[index].options = e.target.value + "\n";
                                                                                setNewJob(prevState => {
                                                                                    return { ...prevState, additionalQuestions: additionalQuestionsCopy }
                                                                                });
                                                                            }
                                                                        }}
                                                                    ></textarea>
                                                                    <p style={{ marginTop: "5px", marginBottom: "0px", fontStyle: "italic" }}>Entrez un choix de réponse par ligne séparé par la touche Entrer</p>
                                                                </div>
                                                            )}
                                                            <div style={{ marginLeft: "5px" }} className="custom-control custom-checkbox mb-4">
                                                                <input
                                                                    id={"accepts-" + index}
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    checked={quiz.required}
                                                                    name="additionalQuestions"
                                                                    onClick={(e) => {
                                                                        let val = e.target.checked;
                                                                        let additionalQuestionsCopy = newJob.additionalQuestions;
                                                                        additionalQuestionsCopy[index].required = val;
                                                                        setNewJob(prevState => {
                                                                            return { ...prevState, additionalQuestions: additionalQuestionsCopy }
                                                                        });
                                                                    }}
                                                                />
                                                                <label className="custom-control-label" for={"accepts-" + index}>Requis</label>
                                                            </div>
                                                        </>
                                                    ))
                                                )}
                                                {newJob.hasAdditionalQuestions == true && (
                                                    <div className="col-12 custom-control">
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={e => {
                                                                setNewJob(prevState => {
                                                                    let val = newJob.additionalQuestions;
                                                                    val.push({ label: "", type: "", required: false });
                                                                    return { ...prevState, additionalQuestions: val }
                                                                });
                                                            }}
                                                            disabled={""}
                                                            style={{ width: "100%" }}

                                                        >
                                                            Ajouter une question
                                                        </button>
                                                    </div>
                                                )}
                                                <div className="col-12 mt-4">
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={handleSubmit}
                                                        disabled={isSaving || hasMissingValue || published}
                                                    >
                                                        Publiez &nbsp; {isSaving ? <CircularProgress size={15} /> : <span></span>}
                                                    </button>
                                                </div>
                                                {published && (
                                                    <div ref={confirmationContainerRef} className="col-12 mt-4">
                                                        <div className="alert alert-success" style={{ textAlign: "justify" }}>
                                                            <strong>Félicitations! </strong>
                                                            Votre offre a été créé avec succès. Cependant, l'offre nécessite l'approbation d'un modérateur du site.
                                                            Elle sera visible sur le site dès qu'un modérateur l'aura approuvé et aura confirmé les informations du paiement.
                                                            Voici le numéro de référence de l'offre : <strong>{createdJobNumber}</strong>
                                                            <br />
                                                            <br />
                                                            <strong>Tarif: 55.000 FCFA (HT) - Avec envoi de SMS et email aux candidats </strong>
                                                            <br />
                                                            <br />
                                                            <strong>Mode de paiement</strong>
                                                            <ol class="pl-3 mb-0">
                                                                <li style={{ marginTop: "10px" }}>
                                                                    Comptant <br />
                                                                    Veuillez nous contacter au +223 44 90 50 70 / 91 25 21 14 ou contact@malibaara.com
                                                                </li>
                                                                <li style={{ marginTop: "10px" }}>
                                                                    Orange Sarali<br />
                                                                    Vous pouvez effectuer le paiement à partir de votre compte Orange Money en composant le :
                                                                    #144#8*189532*montant*code secret# OK</li>
                                                                <li style={{ marginTop: "10px" }}>
                                                                    Mobi Cash <br />
                                                                    Le numéro à utiliser pour la transaction est : 61 48 72 21
                                                                </li>
                                                                <li style={{ marginTop: "10px" }}>
                                                                    Abonnement <br />
                                                                    Si vous désirez un abonnement pour une durée déterminée, veuillez-nous contacter au +223 44 90 50 70 / 91 25 21 14 ou contact@malibaara.com
                                                                </li>
                                                            </ol>
                                                        </div>


                                                    </div>
                                                )}
                                                {creationFailed && (
                                                    <div className="col-12 mt-4">
                                                        <CustomAlert content="Une erreur s'est produite lors de cette opération. Veuillez réessayer ou contacter l'administrateur du système." type="alert-danger" />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 mt-4 mt-xl-0">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="sidebar mb-0">
                                                <div className="widget border-0">
                                                    <div className="jobster-company-view">
                                                        <ul className="list-unstyled">
                                                            <li>
                                                                <div className="widget-box">
                                                                    <div className="d-flex">
                                                                        <div>
                                                                            <Link to={"/jobs/post"}>
                                                                                <img className="img-fluid" src={postBannerImage} alt="" />
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </>
            )
            }
        </React.Fragment >
    );
}

JobCreate.propTypes = {
    getJobList: PropTypes.func.isRequired,
    createJob: PropTypes.func.isRequired,
    getEmployerList: PropTypes.func.isRequired,
    storeJob: PropTypes.object.isRequired,
    storeAds: PropTypes.object.isRequired,
    getAdsList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    getJobList: PropTypes.func.isRequired,
    getEmployerList: PropTypes.func.isRequired,
    createJob: PropTypes.func.isRequired,
    storeJob: state.job,
    storeEmployer: state.employer,
    getAdsList: PropTypes.func.isRequired,
    storeAds: state.ads,
});

export default connect(mapStateToProps,
    {
        getJobList,
        createJob,
        getEmployerList,
        getAdsList
    }
)(JobCreate)