import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import Pdf from "react-to-pdf";
import { getTenderList, tenderSearch, downloadTenderPdf } from "../../redux/actions/TenderActions";
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactLoading from "react-loading";

const ref = React.createRef();

export const TenderExport = (props) => {
    const {
        tenderSearch,
        storeTender = {},
        tender = {},
        downloadTenderPdf,
    } = props;
    const closeExportTendersModal = React.useRef();
    const exportTenders = React.useRef();
    const [tenderNumber, setTenderNumber] = React.useState("");

    const downloadPdf = () => {
        downloadTenderPdf(tender.tenderId).then(resp => {
            closeExportTendersModal.current.click();
            window.open(resp.url, '_blank');
        })
    }

    return (
        <>
            <div>
                <li>
                    <a
                        href="assets/#"
                        data-toggle="modal"
                        title=""
                        data-target="#exportTender"
                        data-original-title="Export"
                        onClick={downloadPdf}
                    >
                        <i class="fas fa-download fa-fw"></i><span className="pl-2">Télécharger cet appel offres</span>
                    </a>
                </li>
            </div>

            {/* Start Export tender modale */}
            <div className="modal fade mt-5" id="exportTender" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header p-4" hidden>
                            <button
                                ref={closeExportTendersModal}
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="mt-2">
                            <div className="modal-body">
                                <div ref={ref}>
                                    <div className="container">
                                        <div className="row align-items-center justify-content-center">
                                            <div className="col-">
                                                <h4>
                                                    Téléchargement en cours
                                                </h4>
                                            </div>
                                            <div className="col-2">
                                                <ReactLoading type="bubbles" color="#007cb5" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Export tender modale */}
        </>
    );
}

TenderExport.propTypes = {
    getTenderList: PropTypes.func.isRequired,
    tenderSearch: PropTypes.func.isRequired,
    storeTender: PropTypes.object.isRequired,
    downloadTenderPdf: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    getTenderList: PropTypes.func.isRequired,
    tenderSearch: PropTypes.func.isRequired,
    storeTender: state.tender,
    downloadTenderPdf: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, { getTenderList, tenderSearch, downloadTenderPdf })(TenderExport)