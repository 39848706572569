import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CircularProgress } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import { changePassword } from "../../../redux/actions/admin/UserActions";
import { Helmet } from "react-helmet";
import localStorageService from '../../../services/localStorageService';
import CustomAlert from '../../shared/views/CustomAlert';

export const ChangePassword = (props) => {

    // const [isLoading, setIsLoading] = React.useState(false);

    const {
        changePassword
    } = props;

    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [isSubmetting, setIsSubmetting] = React.useState(false);
    const [areNotSame, setAreNotSame] = React.useState(false);
    const [changed, setChanged] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertType, setAlertType] = React.useState("");
    const [changePasswordRequest, setChangePasswordRequest] = React.useState(
        {
            id: "",
            currentPassword: "",
            newPassword: "",
            newPasswordConfirmation: ""
        });

    useEffect(() => {
        let match = props.match;
        if (match !== undefined) {
            let params = match.params;
            if (params !== undefined && params.t !== undefined) {
                setChangePasswordRequest(prevState => { return { ...prevState, id: params.t } });
            }
        };
        let user = localStorageService.getItem("malibaara_auth_user");
        if (user) {
            setChangePasswordRequest(prevState => { return { ...prevState, id: user?.id } });
        }
    }, []);

    useEffect(() => {

        if (changePasswordRequest.newPassword === ""
            || changePasswordRequest.newPassword === undefined
            || changePasswordRequest.newPasswordConfirmation === ""
            || changePasswordRequest.newPasswordConfirmation === undefined
            || changePasswordRequest.currentPassword === ""
            || changePasswordRequest.currentPassword === undefined
        ) {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);
        }
    }, [changePasswordRequest]);

    let handleSubmit = () => {
        setIsSubmetting(true);
        changePassword(changePasswordRequest).then(data => {
            if (data) {
                setAlertMessage("votre mot de passe a été changé avec succès.")
                setAlertType("alert-success");
                setChangePasswordRequest({
                    id: "",
                    currentPassword: "",
                    newPassword: "",
                    newPasswordConfirmation: ""
                });
            }
            else {
                setAlertMessage("L'ancien mot de passe est incorrect.");
                setAlertType("alert-danger");
            }
            setChanged(true);
            setIsSubmetting(false);
        });
    }


    return (

        <React.Fragment>
            <Helmet>
                <title> Administration | Changer le mot de passe</title>                
            </Helmet>

            <>
                <div className="row">
                    <div className="col-lg-12 mt-4 bg-white pt-0 mt-lg-0">
                        <div className="bg-light-2 p-4">
                            <div className="row mb-4">
                                <h2 className="mb-0"><span>Changer le mot de passe</span></h2>
                            </div>
                            <div>
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label>Ancien mot de passe *</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="currentPassword"
                                            value={changePasswordRequest?.currentPassword}
                                            onChange={(e) => {
                                                let val = e.target?.value;
                                                setChangePasswordRequest(prevState => { return { ...prevState, currentPassword: val } });
                                            }}
                                        />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>Nouveau mot de passe *</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="newPassword"
                                            value={changePasswordRequest?.newPassword}
                                            onChange={(e) => {
                                                let val = e.target?.value;
                                                if (changePasswordRequest.newPasswordConfirmation !== "" && changePasswordRequest.conformPassword !== undefined) {
                                                    setAreNotSame(changePasswordRequest.newPasswordConfirmation.normalize() !== val.normalize());
                                                }
                                                setChangePasswordRequest(prevState => { return { ...prevState, newPassword: val } });
                                            }}
                                        />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>Confirmer le nouveau mot de passe *</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="newPasswordConfirmation"
                                            value={changePasswordRequest?.newPasswordConfirmation}
                                            onChange={(e) => {
                                                let val = e.target?.value;
                                                setAreNotSame(changePasswordRequest.newPassword.normalize() !== val.normalize());
                                                setChangePasswordRequest(prevState => { return { ...prevState, newPasswordConfirmation: val } });
                                            }}
                                        />
                                        {areNotSame && (
                                            <div style={{ color: "red", marginTop: "8px" }}>Les deux mots de passe sont différents.</div>
                                        )}
                                    </div>
                                    <div className="col-12 mt-4">
                                        <button
                                            className="btn btn-primary"
                                            onClick={handleSubmit}
                                            disabled={isSubmetting || hasMissingValue}
                                        >
                                            Sauvegarder &nbsp; {isSubmetting ? <CircularProgress size={15} /> : <span></span>}
                                        </button>
                                    </div>
                                    {changed && (
                                        <div className="col-12 mt-4">
                                            <CustomAlert content={alertMessage} type={alertType} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </React.Fragment>


    )
}

ChangePassword.propTypes = {
    changePassword: PropTypes.func.isRequired,
    storeUsers: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    changePassword: PropTypes.func.isRequired,
    storeUsers: state.users,
});

export default withRouter(connect(mapStateToProps, { changePassword })(ChangePassword));