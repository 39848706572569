import {
    ACTION_LOADING,
    ACTION_SUCCESS,
    ACTION_ERROR,
    ACTION_SUBMITING,
    ACTION_SUBMITED,
    GET_COURSE_LIST,
    GET_COURSE_LIST_INCLUDED_DESCRIPTION,
    COURSE_SEARCH,
} from "../actions/CourseActions";

const initialState = {
    courseList: [],
    courseListIncludedDescription: [],
    courseSearchResult: {},
    loading: false,
    success: false,
    failed: false,
    submitting: false
};

const CourseReducer = function (state = initialState, action) {
    switch (action.type) {

        case ACTION_LOADING: {
            return {
                ...state,
                loading: true,
                success: false,
                failed: false
            };
        }
        case ACTION_SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                failed: false,
            };
        }
        case ACTION_ERROR: {
            return {
                ...state,
                failed: true,
                success: false,
                loading: false,
                showAlert: true,
                alertMessage: action.data,
            };
        }
        case ACTION_SUBMITING: {
            return {
                ...state,
                submitting: true
            };
        }
        case ACTION_SUBMITED: {
            return {
                ...state,
                submitting: false
            };
        }
        case GET_COURSE_LIST: {
            return {
                ...state,
                courseList: [...action.data]
            };
        }
        case GET_COURSE_LIST_INCLUDED_DESCRIPTION: {
            return {
                ...state,
                courseListIncludedDescription: [...action.data]
            };
        }
        case COURSE_SEARCH: {
            return {
                ...state,
                courseSearchResult: { ...action.data }
            };
        }
        default: {
            return state;
        }
    }
};

export default CourseReducer;
