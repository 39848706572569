import {
    GET_ROLE_LIST,
    ROLE_ACTION_LOADING,
    ROLE_ACTION_SUCCESS,
    ROLE_ACTION_ERROR,
    ROLE_ACTION_SUBMITING,
    ROLE_ACTION_SUBMITED,
    SHOW_ALERT,
    HIDE_ALERT,
    ROLE_IS_EDITING,
    ROLE_IS_EDITED,
    GET_PERMISSION_LIST,
    ROLE_TABLE_CONTENT_LOADING,
    ROLE_TABLE_CONTENT_LOADED
} from "../../actions/admin/RoleActions";

const initialState = {
    roleList: [],
    permissionList: [],
    loading: false,
    success: false,
    failed: false,
    submitting: false,
    errorMessage: "",
    showAlert: false,
    alertMessage: "",
    alertVariant: "",
    someRoleisEditing: false,
    rolesPermissionId: "",
    roleListTableContentLoading: false
};

const roleReducer = function (state = initialState, action) {
    switch (action.type) {
        // Statut des actions
        case ROLE_ACTION_LOADING: {
            return {
                ...state,
                loading: true,
                success: false,
                failed: false
            };
        }
        case ROLE_ACTION_SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                failed: false,
            };
        }
        case ROLE_ACTION_SUBMITING: {
            return {
                ...state,
                submitting: true
            };
        }
        case ROLE_ACTION_SUBMITED: {
            return {
                ...state,
                submitting: false
            };
        }
        case ROLE_IS_EDITING: {
            return {
                ...state,
                someRoleisEditing: true,
                rolesPermissionId: action.data
            };
        }
        case ROLE_IS_EDITED: {
            return {
                ...state,
                someRoleisEditing: false,
                rolesPermissionId: ""
            };
        }
        case ROLE_TABLE_CONTENT_LOADING: {
            return {
                ...state,
                roleListTableContentLoading: true
            };
        }
        case ROLE_TABLE_CONTENT_LOADED: {
            return {
                ...state,
                roleListTableContentLoading: false
            };
        }
        case ROLE_ACTION_ERROR: {
            return {
                ...state,
                failed: true,
                success: false,
                loading: false,
                showAlert: true,
                alertMessage: action.data,
                alertVariant: "error"
            };
        }
        // Les différentes actions permissionList

        case GET_ROLE_LIST: { // Ce cas de reducer est appelé pour 4 actions: getRoleList, createRole, updateRole et removeRole car le service repond toujours avec la list mise à jour
            return {
                ...state,
                roleList: [...action.data],
                someRoleisEditing: false,
                rolesPermissionId: ""
            };
        }
        case GET_PERMISSION_LIST: {
            return {
                ...state,
                permissionList: [...action.data],
                someRoleisEditing: false,
                rolesPermissionId: ""
            };
        }
        // Alert action
        case SHOW_ALERT: {
            return {
                ...state,
                showAlert: true,
                alertMessage: action.data,
                alertVariant: "success"
            };
        }
        case HIDE_ALERT: {
            return {
                ...state,
                showAlert: false,
                alertMessage: "",
                alertVariant: ""
            };
        }
        default: {
            return state;
        }
    }
};

export default roleReducer;
