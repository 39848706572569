import React, { useEffect, useRef } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { deleteJob, getJobList, createJob, } from "../../../redux/actions/JobActions"
import { CircularProgress } from '@material-ui/core';
// import darkblue from '../../../assets/images/bg/dark-blue.jpg';
import { Link, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from 'moment';
import 'moment/locale/fr';
import Select from 'react-select';
import { categories, types } from "../../../views/shared/Helper";
import { getEmployerList } from "../../../redux/actions/admin/EmployerActions";
import CustomHtmlEditor from '../../shared/views/CustomHtmlEditor';
import validator from 'validator';
import CustomAlert from '../../shared/views/CustomAlert';


let JobListing = (props) => {
    const descriptionHtmlEditor = useRef();
    const { getJobList, deleteJob, createJob, getEmployerList, storeJob = {}, storeEmployer = {}, } = props;

    const [isLoading, setIsLoading] = React.useState(true);
    const [jobSearchResult, setJobSearchResult] = React.useState(null);
    const [displayingJobList, setDisplayingJobList] = React.useState([]);
    const [isSubmetting, setIsSubmetting] = React.useState(false);
    const [filterValues, setFilterValues] = React.useState([]);
    const [jobTodelete, setJobTodelete] = React.useState(null);
    const [jobsList, setJobsList] = React.useState([]);
    const [initialJobsList, setInitialJobsList] = React.useState([]);
    const [isDeletting, setIsDeletting] = React.useState(false);
    const closeDeleteJobsModal = React.useRef();
    const closeCreateJobModal = React.useRef();
    const [employersListOptions, setEmployersListOptions] = React.useState([]);
    const [invalideEmail, setInvalideEmail] = React.useState(false);
    const [validateDscription, setValidateDescription] = React.useState("");
    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [creationFailed, setCreationFailed] = React.useState(false);
    const [newJob, setNewJob] = React.useState(
        {
            title: "",
            companyName: "",
            employerId: "",
            category: "",
            type: "",
            count: "",
            location: "",
            closingDate: new Date(),
            startingDate: new Date(),
            description: "",
            showEmailAddress: false,
            showPhoneNumber: false,
            receiveEmailNotification: false,
            postedDateInFrench: "",
            isActive: false,
            // receiveEmailNotification: "",
        });

    useEffect(() => {
        if (jobsList.length == 0 && storeJob.jobList.length == 0) {
            getJobList(true).then(resp => {
                setJobsList(resp.jobsList);
                setInitialJobsList(resp.jobsList);
                setIsLoading(false);
            });
        }
        else if (jobsList.length == 0 && storeJob.jobList.length > 0) {
            setJobsList(storeJob.jobList);
            setInitialJobsList(storeJob.jobList);
            setIsLoading(false);
        }
        if (storeEmployer.employersList.length > 0 && employersListOptions.length == 0) {
            getEmployersListOptions(storeEmployer.employersList);
        }
        else if (storeEmployer.employersList.length == 0 && employersListOptions.length == 0) {
            getEmployerList(false).then(response => {
                if (response.success) {
                    getEmployersListOptions(response.employersList);
                };
            });
        }
        // window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (newJob.title == ""
            || newJob.title == undefined
            || newJob.companyName == ""
            || newJob.companyName == undefined
            || newJob.category == ""
            || newJob.category == undefined
            || newJob.location == ""
            || newJob.location == undefined
            || newJob.description == ""
            || newJob.description == undefined
        ) {
            setHasMissingValue(true);
        }
        else if ((newJob.applicationEmail != "" && !validator.isEmail(newJob.applicationEmail))
            || newJob.location?.length > 200
        ) {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);
        }
    }, [newJob, validateDscription])


    const checkboxHandleChange = (event) => {
        if (event.target.checked) {
            let filterValuesCopy = filterValues;
            filterValuesCopy.push(event.target.value);
            setFilterValues(filterValuesCopy)
            let documents = jobSearchResult.documents;
            let result = [];
            filterValues.forEach(element => {
                let jobs = documents.filter(doc => doc.category == element);
                if (jobs != null) {
                    result = result.concat(jobs);
                }
            });
            setDisplayingJobList(result);

        }
        else {
            let values = filterValues.filter(item => item != event.target.value);
            let documents = jobSearchResult.documents;
            let result = [];
            if (filterValues.length > 0) {
                filterValues.forEach(element => {
                    let jobs = documents.filter(doc => doc.category == element);
                    if (jobs != null) {
                        result = result.concat(jobs);
                    }
                });
            }
            else {
                result = documents;
            }
            setDisplayingJobList(result);
        }
    }

    const handleDeleteSubmit = () => {
        setIsDeletting(true);
        deleteJob(jobTodelete.id).then(resp => {
            setJobsList(resp.jobsList);
            setInitialJobsList(resp.jobList);
            setJobTodelete(undefined);
            closeDeleteJobsModal.current.click();
            setIsDeletting(false);
        });
    }

    const handleCreateSubmit = () => {
        setIsSubmetting(true);
        createJob(newJob).then(data => {
            if (data !== undefined && data !== null) {
                setIsSubmetting(false);
                closeCreateJobModal.current.click();
                initializeNewJob();
                setCreationFailed(false);
                setIsLoading(true);
                getJobList(true).then(resp => {
                    setJobsList(resp.jobsList);
                    setInitialJobsList(resp.jobsList);
                    setIsLoading(false);
                });
            }
            else {
                setCreationFailed(true);
                setIsSubmetting(false);
            }
        });
    }

    const handleSearchBoxChange = (event) => {
        let searchTerm = event.target.value;
        let filteredData = initialJobsList.filter(value => {
            return (
                value.jobNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                value.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                value.employer?.companyName?.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });
        setJobsList(filteredData);
    }

    const initializeNewJob = () => {
        setNewJob({
            title: "",
            companyName: "",
            employerId: "",
            category: "",
            type: "",
            count: "",
            location: "",
            closingDate: new Date(),
            startingDate: new Date(),
            description: "",
            showEmailAddress: false,
            showPhoneNumber: false,
            receiveEmailNotification: false,
            postedDateInFrench: "",
            isActive: false,
            // receiveEmailNotification: "",
        })
    }

    const getEmployersListOptions = (employers) => {
        let result = [];
        employers.forEach(employer => {
            result.push({ label: employer.companyName, id: employer.id, value: employer.id, email: employer.companyEmailContact });
        });
        setEmployersListOptions(result);
    }

    return (
        <React.Fragment>
            <Helmet>
                <title> Administration | Offres d'emploi  | Malibaara</title>
            </Helmet>
            {isLoading ? (
                <div className="row bg-white pt-4 mt-lg-0">
                    <div className="col-lg-12">
                        <div className="loading-container bg-white">
                            <CircularProgress />
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="row bg-white pt-4 mt-lg-0">
                        <div className="col-lg-12 mt-4 mt-lg-0">
                            <div className="row mb-4">
                                <div className="col-md-7 col-sm-5 d-flex align-items-center">
                                    <div className="section-title-02 mb-0 ">
                                        <h4 className="mb-0" style={{ marginLeft: "10px" }}>{jobsList?.length} offres d'emploi</h4>
                                    </div>
                                </div>
                                <div className="col-md-5 col-sm-7 mt-3 mt-sm-0" style={{ textAlign: "right" }}>
                                    <a className="btn btn-md ml-sm-auto btn-primary" data-toggle="modal" data-target="#createJob">Ajouter une offre d'emploi</a>
                                </div>

                            </div>
                            <div className="row mb-4">
                                <div className="col-md-5 col-sm-7 mt-3 mt-sm-0">
                                    <div className="search">
                                        <i className="fas fa-search"></i>
                                        <input type="text" className="form-control" placeholder="Rechercher..." onChange={handleSearchBoxChange} />
                                    </div>
                                </div>
                            </div>
                            <table className="table table-striped" id="table"
                                data-toggle="table"
                                data-height="460"
                                data-pagination="true"
                                data-pagination-h-align="left"
                                data-pagination-detail-h-align="right">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ width: "10%" }}>Numéro</th>
                                        <th scope="col" style={{ width: "45%" }}>Titre</th>
                                        <th scope="col" style={{ width: "25%" }}>Employeur</th>
                                        <th scope="col" style={{ width: "15%" }}>Date</th>
                                        <th scope="col" style={{ width: "5%" }}>Statut</th>
                                        <th scope="col" style={{ width: "5%" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {jobsList?.map((job, index) => (
                                        <tr key={index}>
                                            <td>
                                                {job.jobNumber}
                                            </td>
                                            <td>
                                                {job.title}
                                            </td>
                                            <td>
                                                {job?.employer?.companyName}
                                            </td>
                                            <td>
                                                {moment(job.postedDate).format('Do MMMM YYYY')}
                                            </td>
                                            <td>
                                                {job.isActive ? (<div className="active">Actif</div>) : (<div className="inactive">Inactif</div>)}
                                            </td>
                                            <td>
                                                <ul className="list-unstyled mb-0 d-flex justify-content-end">
                                                    <li><Link to={"/admin/job/details/" + job.id}><i className="fas fa-pencil-alt"></i></Link></li>
                                                    <li><a href="assets/#" className="text-danger" data-toggle="modal" title="" data-target="#deleteJob" data-original-title="Delete">
                                                        <i className="far fa-trash-alt" onClick={() => setJobTodelete(job)}></i>
                                                    </a>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>

                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>


                    {/* Start Delete user modale */}
                    <div className="modal fade" id="deleteJob" tabIndex="-1" role="dialog" aria-modal="true">
                        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header p-4">
                                    <h4 className="mb-0 text-center">Suppression d'une offre d'emploi: {jobTodelete?.title}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <form className="mt-2">
                                    <div className="modal-body">
                                        <div>
                                            <div className="form-row">
                                                <p>Voulez vous supprimer cette offre?</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            data-dismiss="modal"
                                            className="btn btn-primary"
                                            ref={closeDeleteJobsModal}
                                        >
                                            NON
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={handleDeleteSubmit}
                                            disabled={isDeletting}
                                        >
                                            OUI &nbsp; {isDeletting ? <CircularProgress size={15} /> : <span></span>}
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                    {/* End Delete user modale */}

                    {/* Start Create job modale */}
                    <div className="modal fade" id="createJob" tabIndex="-1" role="dialog" aria-modal="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header p-4">
                                    <h4 className="mb-0 text-center">Création d'une offre d'emploi</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div>
                                        <div className="form-row">
                                            <div className="form-group col-12">
                                                <label>Titre du poste *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="title"
                                                    value={newJob?.title}
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setNewJob(prevState => { return { ...prevState, title: val } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-12">
                                                <label>Nom de la société *</label>
                                                <Select
                                                    value={employersListOptions.find(opt => opt?.value == newJob?.employerId)}
                                                    options={employersListOptions}
                                                    loadingMessage="En cours de chargement"
                                                    onChange={(option) => {
                                                        setNewJob(prevState => { return { ...prevState, employerId: option?.value, companyName: option.label, applicationEmail: option.email } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-12">
                                                <label>Email *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="title"
                                                    value={newJob?.applicationEmail}
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        let isValide = validator.isEmail(e.target.value);
                                                        setNewJob(prevState => { return { ...prevState, applicationEmail: val } });
                                                        if (invalideEmail) {
                                                            setInvalideEmail(!isValide);
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        let val = validator.isEmail(e.target.value);
                                                        setInvalideEmail(!val);
                                                    }}
                                                />
                                                {(newJob.applicationEmail != "" && invalideEmail) && (
                                                    <p style={{ color: "red" }}>Format d'email invalide</p>
                                                )}
                                            </div>
                                            <div className="form-group col-sm-6 col-xl-6">
                                                <label>Catégorie *</label>
                                                <Select
                                                    value={categories.find(opt => opt?.value == newJob.category)}
                                                    options={categories}
                                                    loadingMessage="En cours de chargement"
                                                    onChange={(option) => {
                                                        setNewJob(prevState => { return { ...prevState, category: option?.label } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-sm-6 col-xl-6">
                                                <label>Type d'emploi</label>
                                                <Select
                                                    value={types.find(opt => opt.value == newJob?.type)}
                                                    options={types}
                                                    loadingMessage="En cours de chargement"
                                                    onChange={(option) => {
                                                        setNewJob(prevState => { return { ...prevState, type: option?.value } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <label>Date limite de dépot</label>
                                                <div className="input-group date">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={moment(newJob?.closingDate).format('YYYY-MM-DD')}
                                                        onChange={(e) => {
                                                            let val = e.target?.value;
                                                            setNewJob(prevState => { return { ...prevState, closingDate: val } });
                                                        }}

                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-6 datetimepickers">
                                                <label>Date de prise en fonction</label>
                                                <div className="input-group date">
                                                    <input
                                                        type="date"
                                                        className="form-control datetimepicker-input"
                                                        value={moment(newJob?.startingDate).format('YYYY-MM-DD')}
                                                        onChange={(e) => {
                                                            let val = e.target?.value;
                                                            setNewJob(prevState => { return { ...prevState, startingDate: val } });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Ville *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="location"
                                                    value={newJob?.location}
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setNewJob(prevState => { return { ...prevState, location: val } });
                                                    }}
                                                />
                                                {newJob.location?.length > 200 && (
                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                )}
                                            </div>
                                            <div className="form-group col-12">
                                                <label>Description *</label>
                                                <CustomHtmlEditor
                                                    onChange={(value, editor) => {
                                                        setNewJob(prevState => { return { ...prevState, description: descriptionHtmlEditor.current.getContent() } });
                                                    }}
                                                    ref={descriptionHtmlEditor}
                                                    initialValue=""
                                                />
                                            </div>
                                            <div className="form-group col-12 mt-3">
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        checked={newJob?.isActive}
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="accepts-02"
                                                        onChange={(e) => {
                                                            let val = e.target?.checked;
                                                            setNewJob(prevState => { return { ...prevState, isActive: val } });
                                                        }}
                                                    />
                                                    <label className="custom-control-label" htmlFor="accepts-02">Actif</label>
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        id="accepts-07"
                                                        type="checkbox"
                                                        defaultChecked={newJob?.receiveEmailNotification}
                                                        className="custom-control-input"
                                                        name="receiveEmailNotification"
                                                        onClick={(e) => {
                                                            let val = e.target?.checked;
                                                            setNewJob(prevState => {
                                                                return { ...prevState, receiveEmailNotification: val }
                                                            });
                                                        }}
                                                    />
                                                    <label className="custom-control-label" htmlFor="accepts-07">Recevoir les candidatures par e-mail (les candidats pourront postuler directement à partir du site)</label>
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        id="accepts-03"
                                                        type="checkbox"
                                                        defaultChecked={newJob?.showEmailAddress}
                                                        className="custom-control-input"
                                                        name="showEmailAddress"
                                                        onClick={(e) => {
                                                            let val = e.target?.checked;
                                                            setNewJob(prevState => {
                                                                return { ...prevState, showEmailAddress: val }
                                                            });
                                                        }}
                                                    />
                                                    <label className="custom-control-label" htmlFor="accepts-03">Afficher mon adresse email sur la description de l'offre d'emploi</label>
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        id="accepts-04"
                                                        type="checkbox"
                                                        defaultChecked={newJob?.showPhoneNumber}
                                                        className="custom-control-input"
                                                        name="showPhoneNumber"
                                                        onClick={(e) => {
                                                            let val = e.target?.checked;
                                                            setNewJob(prevState => {
                                                                return { ...prevState, showPhoneNumber: val }
                                                            });
                                                        }}
                                                    />
                                                    <label className="custom-control-label" htmlFor="accepts-04">Afficher mon numéro de téléphone sur la description de l'offre d'emploi</label>
                                                </div>
                                            </div>

                                            {creationFailed && (
                                                <div className="col-12 mt-4">
                                                    <CustomAlert content="Une erreur s'est produite lors de cette opération. Veuillez réessayer ou contacter l'administrateur du système." type="alert-danger" />
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                        ref={closeCreateJobModal}
                                    >
                                        Annuler
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={handleCreateSubmit}
                                        disabled={isSubmetting || hasMissingValue}
                                    >
                                        Soumettre &nbsp; {isSubmetting ? <CircularProgress size={15} /> : <span></span>}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* End Create job modale */}
                </>
            )
            }
        </React.Fragment >
    );

}
JobListing.propTypes = {
    getJobList: PropTypes.func.isRequired,
    deleteJob: PropTypes.func.isRequired,
    createJob: PropTypes.func.isRequired,
    getEmployerList: PropTypes.func.isRequired,
    storeJob: PropTypes.object.isRequired,
    storeEmployer: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    getJobList: PropTypes.func.isRequired,
    deleteJob: PropTypes.func.isRequired,
    createJob: PropTypes.func.isRequired,
    getEmployerList: PropTypes.func.isRequired,
    storeJob: state.job,
    storeEmployer: state.employer,
});

export default withRouter(connect(mapStateToProps, { getJobList, deleteJob, createJob, getEmployerList })(JobListing));