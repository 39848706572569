import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CircularProgress } from '@material-ui/core';
import { Helmet } from "react-helmet";
import { loginWithEmailAndPassword, forgotPassword } from "../../../redux/actions/admin/UserActions";
import { withRouter } from "react-router";
import CustomAlert from '../../shared/views/CustomAlert';
import $ from 'jquery';
import siteService from '../../../services/siteService';

const LoginPage = (props) => {

  const {

    loginWithEmailAndPassword,
    forgotPassword
  } = props;

  const [hasMissingValue, setHasMissingValue] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [loginData, setLoginData] = React.useState({ email: "", password: "" });
  const [loginFailed, setLoginFailed] = React.useState(false);
  const [canReconnect, setCanReconnect] = React.useState(false);
  const [adminForgotPassword, setAdminForgotPassword] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [buttonMessage, setButtonMessage] = React.useState("Connexion");
  const currentSite = siteService.getCurrentSite();

  useEffect(() => {
    if (loginData.password == ""
      || loginData.password == undefined
      || loginData.email == ""
      || loginData.email == undefined
    ) {
      setHasMissingValue(true);
    }
    else {
      setHasMissingValue(false);
    }
  }, [loginData])

  let handleLoginUser = () => {
    setIsSubmitting(true);
    setLoginFailed(false);
    loginWithEmailAndPassword(loginData).then(response => {
      setIsSubmitting(false);
      if (response.success) {
        props.history.push({
          pathname: "/admin/dashboard"
        });
      }
      else {
        setLoginFailed(true);
        props.history.push({
          pathname: "/admin/signin"
        });
      }
    });
  }

  let handleForgotPassword = (email) => {
    setIsSubmitting(true);
    forgotPassword({ email: email }).then(response => {
      setIsSubmitting(false);
      setCanReconnect(true);
      setButtonMessage("Se reconnecter")
      if (response) {
        setAlertMessage("Un message vous a été envoyé à l'adresse: " + email + ". Veuillez cliquer sur le bouton <strong>Réinitialiser mon mot de passe</strong> dans le message et suivez les instructions")
        setAlertType("alert-success");
        // closeLoginModal.current.click();
      }
      else {
        setAlertMessage("L'email est incorrect.");
        setAlertType("alert-danger");
      }
    });
  }

  let handleResetformValues = () => {
    setAdminForgotPassword(false);
    setCanReconnect(false);
    setAlertMessage("");
    setAlertType("");
    setButtonMessage("Connexion")
  }

  // Start keydown mechanism
  let onKeydown = event => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      if (!hasMissingValue) {
        handleLoginUser();
      }
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeydown);
    return () => {
      document.removeEventListener("keydown", onKeydown);
    };
  }, [onKeydown]);
  //End keydown mechanism

  return (
    <React.Fragment>
      <Helmet>
        <title> Administration | {currentSite.fullName}</title>
      </Helmet>
      <>
        <section className="space-ptb ">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="login-register bg-light-2 p-4 admin-login-box-container">
                  <div className="section-title center text-center">
                    <h2 className="title"><span>Malibaara.com - Administration</span></h2>
                  </div>
                  <fieldset className="border-redush-0">
                    <div className="mt-4">
                      <div className="form-row">
                        <div className="form-group col-12">
                          <label for="email">Adresse email</label>
                          <input
                            type="text"
                            className="form-control"
                            id="email"
                            value={loginData.email}
                            required
                            onChange={(e) => {
                              let val = e.target?.value;
                              setLoginData(prevState => { return { ...prevState, email: val } });
                            }} />
                        </div>
                        {!adminForgotPassword && (
                          <>
                            <div className="form-group col-12">
                              <label for="password2">Mot de passe</label>
                              <input
                                type="password"
                                className="form-control"
                                id="password"
                                onChange={(e) => {
                                  let val = e.target?.value;
                                  setLoginData(prevState => { return { ...prevState, password: val } });
                                }}
                                value={loginData.password}
                                required
                              />
                              <div style={{ paddingTop: "6px" }}>
                                <a href="javascript:void(0)" onClick={(e) => {
                                  setAdminForgotPassword(true)
                                  setButtonMessage("Soumettre")
                                }}>Mot de passe oublié?</a>
                              </div>
                            </div>
                          </>
                        )}

                      </div>
                      <div className="form-row">
                        <div className="col-md-12">
                          <button className="btn btn-primary btn-block" onClick={(e) => {
                            if (adminForgotPassword && !canReconnect) {
                              handleForgotPassword(loginData.email);
                            }
                            else if (canReconnect) {
                              handleResetformValues();
                            }
                            else {
                              handleLoginUser();
                            }
                          }} disabled={isSubmitting || hasMissingValue}>
                            {buttonMessage} &nbsp; {isSubmitting ? <CircularProgress size={15} /> : <span></span>}
                          </button>
                        </div>
                        {loginFailed && (
                          <div className="col-md-12 mt-4">
                            <CustomAlert content="Email et/ou le mot de passe incorrect" type="alert-danger" />
                          </div>
                        )}
                        {(adminForgotPassword && alertMessage !== "") && (
                          <div className="col-md-12 mt-4">
                            <CustomAlert content={alertMessage} type={alertType} />
                          </div>
                        )}
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  )

}


LoginPage.propTypes = {
  loginWithEmailAndPassword: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  storeUsers: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  loginWithEmailAndPassword: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  storeUsers: state.users
});

export default withRouter(connect(mapStateToProps, { loginWithEmailAndPassword, forgotPassword })(LoginPage));