import React, { useEffect, useRef } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getTenderList, createTender } from "../../redux/actions/TenderActions"
import { getEmployerList } from "../../redux/actions/admin/EmployerActions"
import { CircularProgress } from '@material-ui/core';
import defaultBanner from '../../../assets/images/bg/default-banner.jpg';
import { categories, types } from "../../views/shared/Helper";
import CustomHtmlEditor from './../shared/views/CustomHtmlEditor';
import CustomAlert from './../shared/views/CustomAlert';
import { getAdsList } from "../../redux/actions/admin/AdsActions";
import Select from 'react-select';
import localStorageService from '../../services/localStorageService';
import validator from 'validator'
import { Link } from 'react-router-dom';
import postBannerImage from '../../../assets/images/pourquoi-deposer-une-offre.jpg';
import siteService from '../../services/siteService';

let TenderCreate = (props) => {
    const descriptionHtmlEditor = useRef();
    const {
        storeEmployer = {},
        createTender,
        getEmployerList,
        storeAds,
        getAdsList
    } = props;

    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [creationFailed, setCreationFailed] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isSaving, setIsSaving] = React.useState(false);
    const [published, setPublished] = React.useState(false);
    const [currentEmployer, setCurrentEmployer] = React.useState(null);
    const [employersListOptions, setEmployersListOptions] = React.useState([]);
    const [displayingActiveAdsList, setDisplayingActiveAdsList] = React.useState([]);
    const [newTender, setNewTender] = React.useState(
        {
            title: "",
            companyName: "",
            employerId: "",
            applicationEmail: "",
            category: "",
            type: "",
            count: "",
            location: "",
            closingDate: new Date(),
            startingDate: new Date(),
            description: "",
            showEmailAddress: false,
            showPhoneNumber: false,
            // receiveEmailNotification: "",
        });
    const [invalideEmail, setInvalideEmail] = React.useState(false);
    const [createdTenderNumber, setCreatedTenderNumber] = React.useState("");
    const currentSite = siteService.getCurrentSite();

    useEffect(() => {
        if (storeEmployer.employersList.length > 0 && employersListOptions.length == 0) {
            getEmployersListOptions(storeEmployer.employersList);
        }
        else if (storeEmployer.employersList.length == 0 && employersListOptions.length == 0) {
            getEmployerList(false).then(response => {
                if (response.success) {
                    getEmployersListOptions(response.employersList);
                };
            });
        }
        if (storeAds.adsList.length == 0) {
            getAdsList(false).then(resp => {
                setDisplayingActiveAdsList(resp.adsList);
            })
        }
        else {
            setDisplayingActiveAdsList(storeAds.adsList);
        }
        let employer = localStorageService.getItem("malibaara_auth_employer");
        if (employer) {
            setCurrentEmployer(employer);
            setNewTender(prevState => { return { ...prevState, employerId: employer.id, companyName: employer.companyName, applicationEmail: employer.email } });
        }
    }, [])

    useEffect(() => {
        if (newTender.title == ""
            || newTender.title == undefined
            || newTender.companyName == ""
            || newTender.companyName == undefined
            || newTender.applicationEmail == ""
            || newTender.applicationEmail == undefined
            || newTender.category == ""
            || newTender.category == undefined
            || newTender.location == ""
            || newTender.location == undefined
            || newTender.description == ""
            || newTender.description == undefined
        ) {
            setHasMissingValue(true);
        }
        else if (newTender.location?.length > 200
            || (newTender.applicationEmail != "" && !validator.isEmail(newTender.applicationEmail) && newTender.applicationEmail > 200)
            || newTender?.title?.length > 200
        ) {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);
        }
    }, [newTender])

    useEffect(() => {
        if (employersListOptions.length > 0 && isLoading) {
            setIsLoading(false);
        }
    }, [employersListOptions])

    const handleSubmit = () => {
        setIsSaving(true);
        createTender(newTender).then(data => {
            if (data !== undefined && data !== null) {
                setCreatedTenderNumber(data);
                setIsSaving(false);
                setPublished(true);
                setCreationFailed(false);
            }
            else {
                setCreationFailed(true);
                setIsSaving(false)
            }
        });
    }

    const getEmployersListOptions = (employers) => {
        let result = [];
        employers.forEach(employer => {
            result.push({ label: employer.companyName, id: employer.id, value: employer.id, email: employer.companyEmailContact });
        });
        setEmployersListOptions(result);
    }

    // Start keydown mechanism
    let onKeydown = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            if (!hasMissingValue) {
                handleSubmit();
            }
            event.preventDefault();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", onKeydown);
        return () => {
            document.removeEventListener("keydown", onKeydown);
        };
    }, [onKeydown]);
    //End keydown mechanism

    return (
        <React.Fragment>

            <div className="header-inner" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${defaultBanner})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 style={{ color: "white" }}>Publication</h2>
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><a href="/"> Accueil </a></li>
                                <li className="breadcrumb-item"><i className="fas fa-chevron-right"></i><a href="index.html"> Employeur </a></li>
                                <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> Publication d'un appel d'offres </span></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading ? (
                <div className="loading-container">
                    <CircularProgress />
                    <div>Chargement du formulaire en cours...</div>
                </div>
            ) : (
                <>
                    <section className="space-ptb" style={{ padding: "50px 0" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-8">
                                    <div className="bg-light-3 p-4">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="section-title">
                                                    <h2 className="title"><span>Publication d'un appel d'offres</span></h2>
                                                    <p>Remplissez le formulaire ci-dessous afin de publier votre appel d'offres. Cet appel d'offres sera visible sur le site une fois qu'il sera approuvé par un modérateur.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div _lpchecked="1">
                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label>Titre du poste *</label>
                                                    <input
                                                        value={newTender?.title}
                                                        type="text"
                                                        className="form-control"
                                                        name="title"
                                                        onChange={(e) => {
                                                            let val = e.target?.value;
                                                            setNewTender(prevState => { return { ...prevState, title: val } });
                                                        }}
                                                        placeholder=""
                                                        disabled={published}
                                                    />
                                                    {newTender?.title?.length > 200 && (
                                                        <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                    )}
                                                </div>
                                                {currentEmployer ?
                                                    (
                                                        <>
                                                            <div className="form-group col-md-12">
                                                                <label>Nom de la société *</label>
                                                                <input
                                                                    value={newTender?.companyName}
                                                                    className="form-control"
                                                                    name="email"
                                                                    placeholder=""
                                                                    disabled
                                                                />
                                                            </div>
                                                        </>
                                                    ) :
                                                    (
                                                        <>
                                                            <div class="form-group col-md-12">
                                                                <label>Nom de la société *</label>
                                                                <Select
                                                                    options={employersListOptions}
                                                                    loadingMessage="En cours de chargement"
                                                                    onChange={(option) => {
                                                                        setNewTender(prevState => { return { ...prevState, employerId: option?.value, companyName: option.label, applicationEmail: option.email } });
                                                                    }}
                                                                    isDisabled={published}
                                                                />
                                                                <p style={{ marginTop: "9px", marginBottom: "0px", fontStyle: "italic" }}>Si votre société ne se trouve pas dans cette liste, veuillez cliquez sur <Link to={"/employers/signup?redirect=/tenders/post"}>lien</Link> pour créer un nouveau compte</p>
                                                            </div>
                                                        </>
                                                    )}

                                                <div className="form-group col-md-12">
                                                    <label>Email *</label>
                                                    <input
                                                        value={newTender?.applicationEmail}
                                                        type="email"
                                                        className="form-control"
                                                        name="applicationEmail"
                                                        onChange={(e) => {
                                                            let val = e.target?.value;
                                                            let isValide = validator.isEmail(e.target.value);
                                                            setNewTender(prevState => { return { ...prevState, applicationEmail: val } });
                                                            if (invalideEmail) {
                                                                setInvalideEmail(!isValide);
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            let val = validator.isEmail(e.target.value);
                                                            setInvalideEmail(!val);
                                                        }}
                                                        placeholder=""
                                                        disabled={published}
                                                    />
                                                    {(newTender.email != "" && invalideEmail) && (
                                                        <p style={{ color: "red" }}>Format d'email invalide</p>
                                                    )}
                                                    {newTender?.applicationEmail?.length > 200 && (
                                                        <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                    )}
                                                </div>
                                                <div className="form-group col-sm-6 col-xl-6">
                                                    <label>Catégorie *</label>
                                                    <Select
                                                        options={categories}
                                                        loadingMessage="En cours de chargement"
                                                        onChange={(option) => {
                                                            setNewTender(prevState => { return { ...prevState, category: option?.label } });
                                                        }}
                                                        isDisabled={published}
                                                    />
                                                </div>
                                                <div className="form-group col-sm-6">
                                                    <label>Date limite de dépot</label>
                                                    <div className="input-group date">
                                                        <input
                                                            value={newTender?.closingDate}
                                                            type="date"
                                                            className="form-control"
                                                            name="closingDate"
                                                            onChange={(e) => {
                                                                let val = e.target?.value;
                                                                setNewTender(prevState => { return { ...prevState, closingDate: val } });
                                                            }}
                                                            disabled={published}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label>Ville *</label>
                                                    <input
                                                        value={newTender?.location}
                                                        type="text"
                                                        className="form-control"
                                                        name="location"
                                                        placeholder=""
                                                        onChange={(e) => {
                                                            let val = e.target?.value;
                                                            setNewTender(prevState => { return { ...prevState, location: val } });
                                                        }}
                                                        disabled={published}
                                                    />
                                                    {newTender.location.length > 200 && (
                                                        <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                    )}
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <label>Description *</label>
                                                    <CustomHtmlEditor
                                                        onChange={(value, editor) => {
                                                            setNewTender(prevState => { return { ...prevState, description: descriptionHtmlEditor.current.getContent() } });
                                                        }}
                                                        ref={descriptionHtmlEditor}
                                                        initialValue=""
                                                        disabled={published} />
                                                </div>
                                                <div className="form-group col-12 mt-3">
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            id="accepts-03"
                                                            type="checkbox"
                                                            checked={newTender.showEmailAddress}
                                                            className="custom-control-input"
                                                            name="showEmailAddress"
                                                            onClick={(e) => {
                                                                let val = e.target?.checked;
                                                                setNewTender(prevState => {
                                                                    return { ...prevState, showEmailAddress: val }
                                                                });
                                                            }}
                                                            disabled={published}
                                                        />
                                                        <label className="custom-control-label" for="accepts-03">Afficher mon adresse email sur la description de l'appel d'offre</label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            id="accepts-04"
                                                            type="checkbox"
                                                            checked={newTender.showPhoneNumber}
                                                            className="custom-control-input"
                                                            name="showPhoneNumber"
                                                            onClick={(e) => {
                                                                let val = e.target?.checked;
                                                                setNewTender(prevState => {
                                                                    return { ...prevState, showPhoneNumber: val }
                                                                });
                                                            }}
                                                            disabled={published}
                                                        />
                                                        <label className="custom-control-label" for="accepts-04">Afficher mon numéro de téléphone sur la description de l'appel d'offre</label>
                                                    </div>
                                                </div>
                                                <div className="col-12 mt-4">
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={handleSubmit}
                                                        disabled={isSaving || hasMissingValue || published}
                                                    >
                                                        Publiez &nbsp; {isSaving ? <CircularProgress size={15} /> : <span></span>}
                                                    </button>
                                                </div>
                                                {published && (
                                                    <div className="col-12 mt-4">
                                                        <CustomAlert content={"<strong>Félicitations!</strong> Votre offre a été créé avec succès. Cependant, l'offre nécessite l'approbation d'un modérateur du site. Elle sera visible sur le site dès qu'un modérateur l'aura approuvé et aura confirmé les informations du paiement. <br />Voici le numéro de référence de l'offre : <strong>" + createdTenderNumber + "</strong>"} />
                                                    </div>
                                                )}
                                                {creationFailed && (
                                                    <div className="col-12 mt-4">
                                                        <CustomAlert content="Une erreur s'est produite lors de cette opération. Veuillez réessayer ou contacter l'administrateur du système." type="alert-danger" />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 mt-4 mt-xl-0">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="sidebar mb-0">
                                                <div className="widget border-0">
                                                    <div className="jobster-company-view">
                                                        <ul className="list-unstyled">
                                                            <li>
                                                                <div className="widget-box">
                                                                    <div className="d-flex">
                                                                        <div>
                                                                            <Link to={"/jobs/post"}>
                                                                                <img className="img-fluid" src={postBannerImage} alt="" />
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </>
            )}
        </React.Fragment>
    );
}

TenderCreate.propTypes = {
    getTenderList: PropTypes.func.isRequired,
    createTender: PropTypes.func.isRequired,
    getEmployerList: PropTypes.func.isRequired,
    storeJob: PropTypes.object.isRequired,
    storeAds: PropTypes.object.isRequired,
    getAdsList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    getTenderList: PropTypes.func.isRequired,
    getEmployerList: PropTypes.func.isRequired,
    createTender: PropTypes.func.isRequired,
    storeJob: state.job,
    storeEmployer: state.employer,
    getAdsList: PropTypes.func.isRequired,
    storeAds: state.ads,
});

export default connect(mapStateToProps,
    {
        getTenderList,
        createTender,
        getEmployerList,
        getAdsList
    }
)(TenderCreate)