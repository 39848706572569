import React, { useEffect, useRef } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getJobList } from "../../redux/actions/JobActions"
import { signupEmployer, getEmployerList } from "../../redux/actions/admin/EmployerActions"
import { CircularProgress } from '@material-ui/core';
import defaultBanner from '../../../assets/images/bg/default-banner.jpg';
import CustomAlert from './../shared/views/CustomAlert';
import { getAdsList } from "../../redux/actions/admin/AdsActions";
import validator from 'validator'
import { loginEmployer } from "../../redux/actions/admin/EmployerActions";
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import avantagesMembresBannerImage from '../../../assets/images/avantages-membres-employers.jpg';
import siteService from '../../services/siteService';

let EmployersSignup = (props) => {
    const descriptionHtmlEditor = useRef();
    const {
        storeEmployer = {},
        signupEmployer,
        getEmployerList,
        loginEmployer,
        storeAds,
        getAdsList,
    } = props;

    const history = useHistory();

    const currentSite = siteService.getCurrentSite();
    const [isLoading, setIsLoading] = React.useState(true);
    const [signupFailed, setSignupFailed] = React.useState(false);
    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [areNotSame, setareNotSame] = React.useState(false);
    const [isCreating, setIsCreating] = React.useState(false);
    const [published, setPublished] = React.useState(false);
    const [employersListOptions, setEmployersListOptions] = React.useState([]);
    const [job, setJob] = React.useState(null);
    const [displayingActiveAdsList, setDisplayingActiveAdsList] = React.useState([]);
    const [newEmployer, setNewEmployer] = React.useState(
        {
            companyName: "",
            description: "",
            companyPhoneNumber1Contact: "",
            email: "",
            password: "",
            confirmPassword: "",
            companyPhoneNumber2Contact: "",
            showEmailAddress: false,
            showPhoneNumber: false,
            employerId: "",
            // receiveEmailNotification: "",
        });
    const [invalideEmail1, setInvalideEmail1] = React.useState(false);
    const [invalideEmail2, setInvalideEmail2] = React.useState(false);


    useEffect(() => {
        if (storeEmployer.employersList.length > 0 && employersListOptions.length == 0) {
            getEmployersListOptions(storeEmployer.employersList);
        }
        else if (storeEmployer.employersList.length == 0 && employersListOptions.length == 0) {
            getEmployerList(false).then(response => {
                if (response.success) {
                    getEmployersListOptions(response.employersList);
                };
            });
        }
        if (storeAds.adsList.length == 0) {
            getAdsList(false).then(resp => {
                setDisplayingActiveAdsList(resp.adsList);
            })
        }
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (newEmployer?.companyName == ""
            || newEmployer?.companyName == undefined
            || newEmployer?.companyPhoneNumber1Contact == ""
            || newEmployer?.companyPhoneNumber1Contact == undefined
            || newEmployer?.email == ""
            || newEmployer?.email == undefined
            || newEmployer?.password == ""
            || newEmployer?.password == undefined
            || newEmployer?.confirmPassword == ""
            || newEmployer?.confirmPassword == undefined

        ) {
            setHasMissingValue(true);
        }
        else if (newEmployer?.companyName?.length > 200
            || newEmployer?.companyPhoneNumber1Contact?.length > 15
            || newEmployer?.companyPhoneNumber2Contact?.length > 15
            || newEmployer?.email?.length > 200
            || newEmployer?.companyEmailContact?.length > 200
            || (newEmployer?.companyPhoneNumber1Contact != "" && !(new RegExp(/^[0-9\b]+$/)).test(newEmployer?.companyPhoneNumber1Contact))
            || (newEmployer?.companyPhoneNumber2Contact != "" && !(new RegExp(/^[0-9\b]+$/)).test(newEmployer?.companyPhoneNumber2Contact))
            || (newEmployer?.email != "" && newEmployer?.email != undefined && !validator.isEmail(newEmployer?.email))
            || (newEmployer?.companyEmailContact != "" && newEmployer?.companyEmailContact != undefined && !validator.isEmail(newEmployer?.companyEmailContact))
        ) {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);
        }

    }, [newEmployer])

    useEffect(() => {
        if (employersListOptions.length > 0 && isLoading) {
            setIsLoading(false);
        }
    }, [employersListOptions])

    const handleSubmit = () => {
        setIsCreating(true);
        signupEmployer(newEmployer).then(data => {
            if (data == true) {
                setIsCreating(false);
                setPublished(true);
                setSignupFailed(false);
                loginEmployer({ email: newEmployer?.email, password: newEmployer?.password }).then(resp => {
                    if (resp.success) {
                        var queryStringParsed = queryString.parse(window.location.search);
                        var queryStringRedirect = queryStringParsed.redirect;
                        if (queryStringRedirect !== undefined && queryStringRedirect !== '') {
                            history.push(queryStringRedirect);
                        }
                        else {
                            history.push(`/employers/profile`);
                        }
                    }
                });
            }
            else {
                setSignupFailed(true);
                setIsCreating(false);
            }
        });
    }

    const getEmployersListOptions = (employers) => {
        let result = [];
        employers.forEach(employer => {
            result.push({ label: employer.companyName, id: employer.id });
        });
        setEmployersListOptions(result);
    }

    // Start keydown mechanism
    let onKeydown = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            if (!hasMissingValue) {
                handleSubmit();
            }
            event.preventDefault();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", onKeydown);
        return () => {
            document.removeEventListener("keydown", onKeydown);
        };
    }, [onKeydown]);
    //End keydown mechanism

    return (
        <React.Fragment>
            <div className="header-inner" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${defaultBanner})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 style={{ color: "white" }}>Nouveau compte</h2>
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><a href="/"> Accueil </a></li>
                                <li className="breadcrumb-item"><i className="fas fa-chevron-right"></i><a href="index.html"> Employeur </a></li>
                                <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> Création d'un employeur </span></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <section className="space-ptb" style={{ padding: "50px 0" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8">
                            <div className="bg-light-3 p-4">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title">
                                            <h2 className="title"><span>Création de compte employeur !</span></h2>
                                            <p>Inscrivez-vous gratuitement pour publier des offre d’emploi et recevoir les candidatures</p>
                                        </div>
                                    </div>
                                </div>
                                <div _lpchecked="1">
                                    <div className="form-row">
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label>Nom de la société *</label>
                                                <input
                                                    value={newEmployer?.companyName}
                                                    type="text"
                                                    className="form-control"
                                                    name="title"
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setNewEmployer(prevState => { return { ...prevState, companyName: val } });
                                                    }}
                                                    placeholder=""
                                                />
                                                {newEmployer?.companyName?.length > 200 && (
                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                )}
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Description</label>
                                                <textarea
                                                    value={newEmployer?.description}
                                                    type="text"
                                                    className="form-control"
                                                    id="description"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setNewEmployer(prevState => { return { ...prevState, description: val } });
                                                    }}
                                                ></textarea>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Adresse email de la personne à contacter</label>
                                                <input
                                                    value={newEmployer?.companyEmailContact}
                                                    type="text"
                                                    className="form-control"
                                                    id="Username"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        let isValide = validator.isEmail(e.target.value);
                                                        setNewEmployer(prevState => { return { ...prevState, companyEmailContact: val } });
                                                        if (invalideEmail1) {
                                                            setInvalideEmail1(!isValide);
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        let val = validator.isEmail(e.target.value);
                                                        setInvalideEmail1(!val);
                                                    }}
                                                />
                                                {newEmployer?.companyEmailContact?.length > 50 && (
                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                )}
                                                {(newEmployer?.companyEmailContact != "" && invalideEmail1) && (
                                                    <p style={{ color: "red" }}>Format d'email invalide</p>
                                                )}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Numéro de téléphone *</label>
                                                <input
                                                    value={newEmployer?.companyPhoneNumber1Contact}
                                                    type="text"
                                                    className="form-control"
                                                    id="Username"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setNewEmployer(prevState => { return { ...prevState, companyPhoneNumber1Contact: val } });
                                                    }}
                                                />
                                                {newEmployer?.companyPhoneNumber1Contact?.length > 15 && (
                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                )}
                                                {(newEmployer?.companyPhoneNumber1Contact != "" && !(new RegExp(/^[0-9\b]+$/)).test(newEmployer?.companyPhoneNumber1Contact)) && (
                                                    <p style={{ color: "red" }}>Format invalide</p>
                                                )}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Autre numéro de téléphone</label>
                                                <input type="text"
                                                    value={newEmployer?.companyPhoneNumber2Contact}
                                                    className="form-control"
                                                    id="Username"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setNewEmployer(prevState => { return { ...prevState, companyPhoneNumber2Contact: val } });
                                                    }}
                                                />
                                                {newEmployer?.companyPhoneNumber2Contact?.length > 15 && (
                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                )}
                                                {(newEmployer?.companyPhoneNumber2Contact != "" && !(new RegExp(/^[0-9\b]+$/)).test(newEmployer?.companyPhoneNumber2Contact)) && (
                                                    <p style={{ color: "red" }}>Format invalide</p>
                                                )}
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Adresse email pour accéder au site *</label>
                                                <input
                                                    value={newEmployer?.email}
                                                    type="text"
                                                    className="form-control"
                                                    id="Username"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        let isValide = validator.isEmail(e.target.value);
                                                        setNewEmployer(prevState => { return { ...prevState, email: val } });
                                                        if (invalideEmail2) {
                                                            setInvalideEmail2(!isValide);
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        let val = validator.isEmail(e.target.value);
                                                        setInvalideEmail2(!val);
                                                    }}
                                                />
                                                {newEmployer?.email?.length > 50 && (
                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                )}
                                                {(newEmployer?.email != "" && invalideEmail2) && (
                                                    <p style={{ color: "red" }}>Format d'email invalide</p>
                                                )}
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Mot de passe *</label>
                                                <input
                                                    value={newEmployer?.password}
                                                    type="password"
                                                    className="form-control"
                                                    id="Username"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        if (newEmployer?.confirmPassword != '' && newEmployer?.conformPassword != undefined) {
                                                            setareNotSame(newEmployer?.confirmPassword.normalize() !== val.normalize());
                                                        }
                                                        setNewEmployer(prevState => { return { ...prevState, password: val } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Confirmation du mot de passe *</label>
                                                <input
                                                    valuek={newEmployer?.confirmPassword}
                                                    type="password"
                                                    className="form-control"
                                                    id="confirmPassword"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setareNotSame(newEmployer?.password.normalize() !== val.normalize());
                                                        setNewEmployer(prevState => { return { ...prevState, confirmPassword: val } });
                                                    }}
                                                />
                                                {areNotSame && (
                                                    <div style={{ color: "red", marginTop: "8px" }}>Les deux mots de passe sont différents.</div>
                                                )}
                                            </div>
                                            <div className="col-12 mt-4">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={handleSubmit}
                                                    disabled={isCreating || hasMissingValue || areNotSame}
                                                >
                                                    Soumettre &nbsp; {isCreating ? <CircularProgress size={15} /> : <span></span>}
                                                </button>
                                            </div>
                                            {published && (
                                                <div className="col-12 mt-4">
                                                    <CustomAlert content={"<strong>Félicitations!</strong> Ce compte employeur a été créé avec succès. Un email de confirmation a été envoyé à l'adresse suivante :" + " <strong>" + newEmployer?.email + "</strong>"} />
                                                </div>
                                            )}
                                            {signupFailed && (
                                                <div className="col-12 mt-4">
                                                    <CustomAlert content="Email et/ou le mot de passe incorrect" type="alert-danger" />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 mt-4 mt-xl-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="sidebar mb-0">
                                        <div className="widget border-0">
                                            <div className="jobster-company-view">
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <div className="widget-box">
                                                            <div className="d-flex">
                                                                <div>
                                                                    <Link to={"/employers/signup"}>
                                                                        <img className="img-fluid" src={avantagesMembresBannerImage} alt="" />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

EmployersSignup.propTypes = {
    getJobList: PropTypes.func.isRequired,
    signupEmployer: PropTypes.func.isRequired,
    getEmployerList: PropTypes.func.isRequired,
    storeJob: PropTypes.object.isRequired,
    storeAds: PropTypes.object.isRequired,
    getAdsList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    getJobList: PropTypes.func.isRequired,
    getEmployerList: PropTypes.func.isRequired,
    signupEmployer: PropTypes.func.isRequired,
    loginEmployer: PropTypes.func.isRequired,
    storeJob: state.job,
    storeEmployer: state.employer,
    getAdsList: PropTypes.func.isRequired,
    storeAds: state.ads,
});

export default connect(mapStateToProps,
    {
        getJobList,
        signupEmployer,
        getEmployerList,
        getAdsList,
        loginEmployer,
        // EmployersSignup,
    }
)(EmployersSignup)