import React, { useEffect, useRef } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import { employerSearch } from "../../redux/actions/EmployerActions"
import { CircularProgress } from '@material-ui/core';
import { Link } from "react-router-dom";
import { getEmployerList, deleteEmployer, createEmployer } from "../../../redux/actions/admin/EmployerActions";
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import validator from 'validator';
import $ from 'jquery';
import siteService from '../../../services/siteService';

let EmployerListing = (props) => {

  const {
    getEmployerList,
    deleteEmployer,
    storeEmployer = {},
    createEmployer
  } = props;

  const descriptionHtmlEditor = useRef();
  const closeEmployerCreateModal = useRef();
  const closeEmployerDeleteModal = useRef();
  const history = useHistory();

  const currentSite = siteService.getCurrentSite();
  const [isLoading, setIsLoading] = React.useState(true);
  const [employersList, setEmployersList] = React.useState([]);
  const [initialEmployersList, setInitialEmployersList] = React.useState([]);
  const [employerTodelete, setEmployerTodelete] = React.useState(null);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [hasMissingValue, setHasMissingValue] = React.useState(true);
  const [employer, setEmployer] = React.useState(
    {
      companyName: "",
      companyPhoneNumber1Contact: "",
      email: "",
      companyPhoneNumber2Contact: "",
      companyEmailContact: "",
      order: "",
      description: ""
    });
  const [invalideEmail1, setInvalideEmail1] = React.useState(false);
  const [invalideEmail2, setInvalideEmail2] = React.useState(false);


  useEffect(() => {
    if (employersList.length == 0 && storeEmployer.employersList.length == 0) {
      getEmployerList(true).then(resp => {
        setEmployersList(resp.employersList);
        setInitialEmployersList(resp.employersList);
        setIsLoading(false);
      });
    }
    else if (employersList.length == 0 && storeEmployer.employersList.length > 0) {
      setEmployersList(storeEmployer.employersList);
      setInitialEmployersList(storeEmployer.employersList);
      setIsLoading(false);
    }
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    if (employer.companyName == ""
      || employer.companyName == undefined
      || employer.companyPhoneNumber1Contact == ""
      || employer.companyPhoneNumber1Contact == undefined
      || employer.email == ""
      || employer.email == undefined
    ) {
      setHasMissingValue(true);
    }
    else if (employer.companyName?.length > 200
      || employer.companyEmailContact?.length > 50
      || employer.companyPhoneNumber1Contact?.length > 50
      || employer.companyPhoneNumber2Contact?.length > 50
      || (employer.email != "" && !validator.isEmail(employer.email))
      || (employer.companyEmailContact != "" && !validator.isEmail(employer.companyEmailContact))
      || (employer?.companyPhoneNumber1Contact != "" && !(new RegExp(/^[0-9\b]+$/)).test(employer?.companyPhoneNumber1Contact))
      || (employer?.companyPhoneNumber2Contact != "" && !(new RegExp(/^[0-9\b]+$/)).test(employer?.companyPhoneNumber2Contact))
    ) {
      setHasMissingValue(true);
    }
    else {
      setHasMissingValue(false);
    }
  }, [employer]);

  const handleUpdateSubmit = () => {
    setIsUpdating(true);
    let employerCopy = employer;
    if (!employerCopy.order || employerCopy.order == "") {
      employerCopy.order = 0;
    }
    createEmployer(employerCopy).then(res => {
      if (res.success) {
        setEmployersList(res.employersList);
      }
      setIsUpdating(false);
      closeEmployerCreateModal.current.click();
      setEmployer({
        companyName: "",
        companyPhoneNumber1Contact: "",
        email: "",
        companyPhoneNumber2Contact: "",
        companyEmailContact: ""
      });
    });
  }

  const handleDeleteSubmit = () => {
    setIsDeleting(true);
    deleteEmployer(employerTodelete?.id).then(resp => {
      setEmployerTodelete(null);
      setEmployersList(resp.employersList);
      closeEmployerDeleteModal.current.click();
      setIsDeleting(false);
    });
  }

  const handleSearchBoxChange = (event) => {
    let searchTerm = event.target.value;
    let filteredData = initialEmployersList.filter(value => {
      return (
        value.companyName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        value.companyPhoneNumber1Contact?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setEmployersList(filteredData);
  }

  // Start keydown mechanism
  let onKeydown = event => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      if ($('#createEmployerModal').attr('class')?.includes("show")) {
        if (!hasMissingValue) {
          handleUpdateSubmit();
        }
      }
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeydown);
    return () => {
      document.removeEventListener("keydown", onKeydown);
    };
  }, [onKeydown]);
  //End keydown mechanism


  return (
    <>
      <Helmet>
        <title> Administration | Employeurs | {currentSite.fullName}</title>
      </Helmet>
      {isLoading ? (

        <div className="row bg-white pt-4 mt-lg-0">
          <div className="col-lg-12">
            <div className="loading-container bg-white">
              <CircularProgress />
            </div>
          </div>
        </div>
      ) :
        (
          <>
            <div className="row bg-white pt-4 mt-lg-0">
              <div className="col-lg-12 mt-4 mt-lg-0">
                <div className="row mb-4">
                  <div className="col-md-7 col-sm-5 d-flex align-items-center">
                    <div className="section-title-02 mb-0 ">
                      <h4 className="mb-0">{employersList?.length} employeurs</h4>
                    </div>
                  </div>
                  <div className="col-md-5 col-sm-7 mt-3 mt-sm-0" style={{ textAlign: "right" }}>
                    <a className="btn btn-md ml-sm-auto btn-primary" data-toggle="modal" data-target="#createEmployerModal">Ajouter un employeur</a>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-5 col-sm-7 mt-3 mt-sm-0" >
                    <div className="search">
                      <i className="fas fa-search"></i>
                      <input type="text" className="form-control" placeholder="Rechercher..." onChange={handleSearchBoxChange} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="user-dashboard-info-box table-responsive mt-1 bg-white">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Nom de la compagnie</th>
                            <th>Email de contact</th>
                            <th>Téléphone</th>
                            <th>Statut</th>
                            <th className="action text-right"></th>
                          </tr>
                        </thead>

                        <tbody>
                          {employersList.map((employer, index) => (
                            <tr className="candidates-list" key={employer.id}>
                              <td>{employer.companyName}</td>
                              <td className="">
                                {employer.companyEmailContact}
                              </td>
                              <td>{employer.companyPhoneNumber1Contact}</td>
                              <td>{employer.isActive ? (<div className="active">Actif</div>) : (<div className="inactive">Inactif</div>)} </td>
                              <td>
                                <ul className="list-unstyled mb-0 d-flex justify-content-end">
                                  <li><Link to={"/admin/employers/details/" + employer.id}><i className="fas fa-pencil-alt"></i></Link></li>
                                  <li><a href="assets/#" className="text-danger" data-toggle="modal" title="" data-target="#deleteEmployer" data-original-title="Delete">
                                    <i className="far fa-trash-alt" onClick={() => setEmployerTodelete(employer)}></i>
                                  </a></li>
                                </ul>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {/* Start Delete user modale */}
            <div className="modal fade" id="deleteEmployer" tabIndex="-1" role="dialog" aria-modal="true">
              <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header p-4">
                    <h4 className="mb-0 text-center">Suppression d'un employeur: {employerTodelete?.companyName}</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <form className="mt-2">
                    <div className="modal-body">
                      <div>
                        <div className="form-row">
                          <p>Voulez vous supprimer cet employeur?</p>

                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button ref={closeEmployerDeleteModal} type="button" className="btn btn-secondary" data-dismiss="modal">NON</button>
                      <button type="button" className="btn btn-primary" onClick={handleDeleteSubmit}>
                        OUI &nbsp; {isDeleting ? <CircularProgress size={15} /> : <span></span>}</button>
                    </div>
                  </form>

                </div>
              </div>
            </div>
            {/* End Delete user modale */}

            {/* Start create employer modale */}
            <div className="modal fade" id="createEmployerModal" tabIndex="-1" role="dialog" aria-modal="true" data-backdrop="static" data-keyboard="false">
              <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header p-4">
                    <h4 className="mb-0 text-center">Création de compte employeur</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" disabled={isUpdating}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <div>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label>Nom de la société *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="companyName"
                            value={employer.companyName}
                            onChange={(e) => {
                              let val = e.target?.value;
                              setEmployer(prevState => {
                                return { ...prevState, companyName: val }
                              })
                            }}
                          />
                          {employer.companyName?.length > 200 && (
                            <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                          )}
                        </div>
                        <div className="form-group col-md-12">
                          <label>Adresse email de la personne à contacter</label>
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            value={employer.companyEmailContact}
                            onChange={(e) => {
                              let val = e.target?.value;
                              let isValide = validator.isEmail(e.target.value);
                              setEmployer(prevState => { return { ...prevState, companyEmailContact: val } });
                              if (invalideEmail1) {
                                setInvalideEmail1(!isValide);
                              }
                            }}
                            onBlur={(e) => {
                              let val = validator.isEmail(e.target.value);
                              setInvalideEmail1(!val);
                            }}
                          />
                          {(employer.companyEmailContact != "" && invalideEmail1) && (
                            <p style={{ color: "red" }}>Format d'email invalide</p>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label>Numéro de téléphone *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="companyPhoneNumber1Contact"
                            value={employer.companyPhoneNumber1Contact}
                            onChange={(e) => {
                              let val = e.target?.value;
                              setEmployer(prevState => {
                                return { ...prevState, companyPhoneNumber1Contact: val }
                              })
                            }}
                          />
                          {employer.companyPhoneNumber1Contact?.length > 50 && (
                            <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                          )}
                          {(employer?.companyPhoneNumber1Contact != "" && !(new RegExp(/^[0-9\b]+$/)).test(employer?.companyPhoneNumber1Contact)) && (
                            <p style={{ color: "red" }}>Format invalide</p>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label>Autre numéro de téléphone </label>
                          <input
                            type="text"
                            className="form-control"
                            name="companyPhoneNumber2Contact"
                            value={employer.companyPhoneNumber2Contact}
                            onChange={(e) => {
                              let val = e.target?.value;
                              setEmployer(prevState => {
                                return { ...prevState, companyPhoneNumber2Contact: val }
                              })
                            }}
                          />
                          {employer.companyPhoneNumber2Contact?.length > 50 && (
                            <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                          )}
                          {(employer?.companyPhoneNumber2Contact != "" && !(new RegExp(/^[0-9\b]+$/)).test(employer?.companyPhoneNumber2Contact)) && (
                            <p style={{ color: "red" }}>Format invalide</p>
                          )}
                        </div>
                        <div className="form-group col-md-12">
                          <label>Adresse email pour accéder au site *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            value={employer.email}
                            onChange={(e) => {
                              let val = e.target?.value;
                              let isValide = validator.isEmail(e.target.value);
                              setEmployer(prevState => { return { ...prevState, email: val } });
                              if (invalideEmail2) {
                                setInvalideEmail2(!isValide);
                              }
                            }}
                            onBlur={(e) => {
                              let val = validator.isEmail(e.target.value);
                              setInvalideEmail2(!val);
                            }}
                          />
                          {(employer.email != "" && invalideEmail2) && (
                            <p style={{ color: "red" }}>Format d'email invalide</p>
                          )}
                        </div>
                        <div className="form-group col-md-12">
                          <label>Description</label>
                          <textarea
                            value={employer?.description}
                            type="text"
                            className="form-control"
                            id="description"
                            placeholder=""
                            onChange={(e) => {
                              let val = e.target?.value;
                              setEmployer(prevState => { return { ...prevState, description: val } });
                            }}
                          ></textarea>
                        </div>
                        <div className="form-group col-md-12">
                          <label>Ordre d'affichage (Ils nous font confiance)</label>
                          <input
                            type="number"
                            className="form-control"
                            name="order"
                            value={employer.order}
                            onChange={(e) => {
                              let val = e.target?.value;
                              setEmployer(prevState => {
                                return { ...prevState, order: val }
                              })
                            }}
                          />
                        </div>
                        <div className="form-group col-12 mt-3">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="accepts-101"
                              checked={employer.isActive}
                              onChange={(e) => {
                                let val = e.target?.checked;
                                setEmployer(prevState => {
                                  return { ...prevState, isActive: val }
                                });
                              }}
                            />
                            <label className="custom-control-label" htmlFor="accepts-101">Actif</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      disabled={isUpdating}
                      ref={closeEmployerCreateModal}
                    >
                      Annuler
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleUpdateSubmit}
                      disabled={isUpdating || hasMissingValue}
                    >
                      Soumettre &nbsp; {isUpdating ? <CircularProgress size={15} /> : <span></span>}
                    </button>
                  </div>

                </div>
              </div>
            </div>

          </>
        )}

    </>
  )

}


EmployerListing.propTypes = {
  deleteEmployer: PropTypes.func.isRequired,
  getEmployerList: PropTypes.func.isRequired,
  createEmployer: PropTypes.func.isRequired,
  storeEmployer: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  deleteEmployer: PropTypes.func.isRequired,
  getEmployerList: PropTypes.func.isRequired,
  createEmployer: PropTypes.func.isRequired,
  storeEmployer: state.employer
});

export default connect(mapStateToProps, { getEmployerList, deleteEmployer, createEmployer })(EmployerListing)