import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import defaultBanner from '../../../assets/images/bg/default-banner.jpg';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Switch from "react-switch";
import ContactInformations from './../shared/views/ContactInformations';
import siteService from '../../services/siteService';

export const PrivacyPolicy = (props) => {
    
    const currentSite = siteService.getCurrentSite();
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <React.Fragment>
                <Helmet>
                    <title> Politique de confidentialité  | {currentSite.fullName}</title>
                </Helmet>
                <div className="header-inner" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${defaultBanner})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 style={{ color: "white" }}>Politique de confidentialité</h2>
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item"><NavLink tag={Link} to="/">Accueil</NavLink></li>
                                    <li className="breadcrumb-item"><i className="fas fa-chevron-right"></i>Pages</li>
                                    <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> Politique de confidentialité </span></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="space-ptb" style={{ paddingTop: "40px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 p-4" style={{textAlign: "justify"}}>
                                <p>
                                    <strong>31 Janvier 2019</strong>
                                    <br /><br />
                                    Votre vie privée nous tient à coeur à {currentSite.name}.
                                    Cette déclaration de confidentialité fournit des détails sur les informations personnelles que {currentSite.name} collecte, et sur la manière dont {currentSite.name} les utilise.
                                    <br /><br />
                                </p>
                                <div className="section-title mb-0">
                                    <h2 className="title"><span>COLLECTE D'INFORMATIONS PERSONNELLES</span></h2>
                                    <p>{currentSite.name} collecte les informations personnelles suivants à travers un formulaire d'adhésion sur le site:</p>
                                </div>                                
                                <div className="pricing-plan-01">
                                    <ul className="list-unstyled pricing-list">
                                        <li><i className="fas fa-check"></i>Nom</li>
                                        <li><i className="fas fa-check"></i>Prénom</li>
                                        <li><i className="fas fa-check"></i>Age</li>
                                        <li><i className="fas fa-check"></i>Email</li>
                                        <li><i className="fas fa-check"></i>Numéros de téléphones</li>
                                        <li><i className="fas fa-check"></i>Adresse</li>
                                        <li><i className="fas fa-check"></i>Scolarité</li>
                                        <li><i className="fas fa-check"></i>Expériences professionnelles</li>
                                        <li><i className="fas fa-check"></i>CVs, lettres de motivations, diplômes, permis de conduire, etc.</li>
                                    </ul>
                                </div>

                                <div className="section-title mb-0">
                                    <h2 className="title"><span>UTILISATION D'INFORMATIONS PERSONNELLES</span></h2>
                                    <p>{currentSite.name} collecte les informations personnelles dans le but de :</p>
                                </div>                                
                                <div className="pricing-plan-01 mb-0">
                                    <ul className="list-unstyled pricing-list">
                                        <li><i className="fas fa-check"></i>Notifier par email et sms les candidats sur les offres d'emplois disponibles</li>
                                        <li><i className="fas fa-check"></i>De proposer des informations et des offres publicitaires aux candidats</li>
                                    </ul>
                                </div>
                                <div style={{marginBottom:"30px"}}>
                                    <i>Les candidats ont la possibilité en tout moment d'accéder à leurs informations ou encore de les supprimer. Cette procédure se fait manuellement pour le moment en nous contactant par email ou par téléphone.</i>
                                </div>

                                <div className="section-title">
                                    <h2 className="title"><span>COOKIES</span></h2>
                                    <p>{currentSite.name} dépose des cookies sur son site afin de garantir son bon fonctionnement et de vous fournir une meilleure expérience de navigation.</p>
                                    {/* Certains de ces cookies peuvent être désactivés dans la section ci-dessous: */}
                                </div>                                
                                <div style={{marginBottom: "10px"}}>
                                    <strong>Authentification</strong>
                                </div>
                                <div>
                                    <label htmlFor="disabled-switch">                                        
                                        <Switch
                                            onChange={() => {}}
                                            checked
                                            disabled
                                            className="react-switch"
                                            id="disabled-switch"
                                        />
                                        <span style={{position: "absolute", marginTop: "3px", marginLeft: "15px"}}>Ces cookies sont nécessaires pour pouvoir se connecter sur le site.</span>
                                    </label>                                            
                                </div>
                                <div style={{marginBottom: "10px", marginTop: "10px"}}>
                                    <strong>Suivi d'audience</strong>
                                </div>
                                <div>
                                    <label htmlFor="disabled-switch">                                        
                                        <Switch
                                            onChange={() => {}}
                                            checked
                                            disabled
                                            className="react-switch"
                                            id="disabled-switch"
                                        />
                                        <span style={{position: "absolute", marginTop: "3px", marginLeft: "15px"}}> Ces cookies permettent de connaître l’utilisation de notre site et d’en améliorer le fonctionnement.</span>
                                    </label>                                            
                                </div>
                                <div style={{marginBottom: "10px", marginTop: "10px"}}>
                                    <strong>Publicité</strong>
                                </div>
                                <div>
                                    <label htmlFor="disabled-switch">                                        
                                        <Switch
                                            onChange={() => {}}
                                            checked
                                            disabled
                                            className="react-switch"
                                            id="disabled-switch"
                                        />
                                        <span style={{position: "absolute", marginTop: "3px", marginLeft: "15px"}}> Ces cookies permettent de vous proposer des publicités en fonction de votre navigation sur Internet.</span>
                                    </label>                                            
                                </div>
                                <div>
                                    <hr />
                                    Pour toutes questions, veuillez nous contacter à <a href={`mailto:${currentSite.emailAddress}`}>{currentSite.emailAddress}</a> ou au <a href={`tel:${currentSite.phoneNumber1}`}>{currentSite.phoneNumber1}</a> {currentSite.phoneNumber2 && (<> / <a href={`tel:${currentSite.phoneNumber2}`}>{currentSite.phoneNumber2}</a></>)}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <ContactInformations />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>                    
            </React.Fragment>

        </>
    )
}

