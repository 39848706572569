import React, { useEffect, useRef } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getJobList, updateJob, addAttachmentFromJob, deleteAttachmentFromJob } from "../../../redux/actions/JobActions"
import { CircularProgress } from '@material-ui/core';
import CustomHtmlEditor from '../../shared/views/CustomHtmlEditor';
import { categories, types } from "../../../views/shared/Helper";
import { getEmployerList } from "../../../redux/actions/admin/EmployerActions";
import moment from 'moment';
import 'moment/locale/fr';
import CustomAlert from '../../shared/views/CustomAlert';
import $ from 'jquery';
import Select from 'react-select';
import validator from 'validator';
import { DropzoneArea } from 'material-ui-dropzone';

const JobDetail = (props) => {
    const descriptionHtmlEditor = useRef();
    const {
        getJobList,
        storeJob = {},
        storeEmployer = {},
        updateJob,
        getEmployerList,
        addAttachmentFromJob,
        deleteAttachmentFromJob,
    } = props;

    const [isLoading, setIsLoading] = React.useState(true);
    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [job, setJob] = React.useState(null);
    const [jobId, setJobId] = React.useState("");
    const [validateDscription, setValidateDescription] = React.useState("");
    const [isSubmetting, setIsSubmetting] = React.useState(false);
    const [employersListOptions, setEmployersListOptions] = React.useState([]);
    const [published, setPublished] = React.useState(false);
    const [invalideEmail, setInvalideEmail] = React.useState(false);
    const [attachmentToDelete, setAttachmentToDelete] = React.useState(null);
    const [isUploading, setIsUploading] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState();
    const [isDeletting, setIsDeletting] = React.useState(false);
    const closeFileDeleteModal = React.useRef();
    const [currentFile, setCurrentFile] = React.useState(undefined);
    const [updateNewJob, setUpdateNewJob] = React.useState(
        {
            title: "",
            companyName: "",
            employerId: "",
            category: "",
            type: "",
            count: "",
            location: "",
            closingDate: new Date(),
            startingDate: new Date(),
            description: "",
            showEmailAddress: false,
            showPhoneNumber: false,
            receiveEmailNotification: false,
            postedDateInFrench: "",
            isActive: false,
            // receiveEmailNotification: "",
        });

    useEffect(() => {
        let match = props.match;
        if (match != undefined) {
            let params = match.params;
            if (params != undefined && params.id != undefined) {
                setJobId(params.id);
            }
        };
        if (storeEmployer.employersList.length > 0 && employersListOptions.length == 0) {
            getEmployersListOptions(storeEmployer.employersList);
        }
        else if (storeEmployer.employersList.length == 0 && employersListOptions.length == 0) {
            getEmployerList(false).then(response => {
                if (response.success) {
                    getEmployersListOptions(response.employersList);
                };
            });
        }
    }, [])

    useEffect(() => {
        if (jobId != "") {
            if (storeJob.jobList.length == 0) {
                getJobList(true).then(resp => {
                    let queryResponse = resp.jobsList.find(c => c.id == jobId);
                    if (queryResponse) {
                        setJob(queryResponse)
                    }
                });
            }
            else if (storeJob.jobList.length > 0) {
                let queryResponse = storeJob.jobList.find(c => c.id == jobId);
                if (queryResponse) {
                    setJob(queryResponse)
                }
            }
        }
    }, [jobId])

    useEffect(() => {
        if (selectedFile) {
            handleAddAttachment();
        }
    }, [selectedFile]);

    const handleAddAttachment = () => {
        setIsUploading(true);
        let currentFile = selectedFile;
        addAttachmentFromJob(job?.id, currentFile).then(resp => {
            setSelectedFile(undefined)
            let jobFromStore = resp.jobsList.find(job => job.id === jobId);
            if (jobFromStore !== undefined) {
                setJob(jobFromStore);
                setIsUploading(false);
            }
        })
    }

    const handleDeleteSubmit = (e, id, value) => {
        setIsDeletting(true);
        deleteAttachmentFromJob(id, value).then(resp => {
            let jobFromStore = resp.jobsList.find(job => job.id === jobId);
            if (jobFromStore !== undefined) {
                setJob(jobFromStore);
                setCurrentFile(jobFromStore.attachments);
            }
            setAttachmentToDelete("");
            closeFileDeleteModal.current.click();
            setIsDeletting(false);
        });
    }

    useEffect(() => {
        if (updateNewJob.title == ""
            || updateNewJob.title == undefined
            || updateNewJob.companyName == ""
            || updateNewJob.companyName == undefined
            || updateNewJob.category == ""
            || updateNewJob.category == undefined
            || updateNewJob.location == ""
            || updateNewJob.location == undefined
            || validateDscription == ""
            || validateDscription == undefined
        ) {
            setHasMissingValue(true);
        }
        else if ((updateNewJob.applicationEmail != "" && !validator.isEmail(updateNewJob.applicationEmail))
            || updateNewJob.location?.length > 200
        ) {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);
        }
    }, [updateNewJob, validateDscription])

    useEffect(() => {
        if (job != null) {
            setUpdateNewJob(job);
            setValidateDescription(job?.description)
            setUpdateNewJob(prevState => {
                return { ...prevState, companyName: job?.employer?.companyName, employerId: job?.employer?.id }
            });
        }
    }, [job]);

    useEffect(() => {
        if (updateNewJob.companyName != "")
            setIsLoading(false);
    }, [updateNewJob])

    useEffect(() => {
        var select = $(".basic-select");
        if (select.length > 0) {
            /*eslint-disable no-undef*/
            intializeCustomSelect();
            /*eslint-enable no-undef*/
        }
    }, [isLoading])

    const handleSubmit = (value) => {
        setIsSubmetting(true);
        setPublished(false);
        let job = updateNewJob;
        job.description = descriptionHtmlEditor.current.getContent();
        if (job.closingDate == "") {
            job.closingDate = null
        }
        if (job.startingDate == "") {
            job.startingDate = null
        }
        updateJob(job).then(data => {
            if (data) {
                setIsSubmetting(false);
                setPublished(true);
            }
        });
    }

    const getEmployersListOptions = (employers) => {
        let result = [];
        employers.forEach(employer => {
            result.push({ label: employer.companyName, id: employer.id, value: employer.id });
        });
        setEmployersListOptions(result);
    }

    // Start keydown mechanism
    let onKeydown = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            if (!hasMissingValue) {
                handleSubmit();
            }
            event.preventDefault();
        }
    };

    // useEffect(() => {
    //     document.addEventListener("keydown", onKeydown);
    //     return () => {
    //         document.removeEventListener("keydown", onKeydown);
    //     };
    // }, [onKeydown]);
    //End keydown mechanism

    let getOptionLabel = (value) => {
        switch (value) {
            case "FreeText":
                return "Champs libre"
            case "SingleValue":
                return "Choix unique"
            case "MultiValue":
                return "Choix multiple"
            case "DateTime":
                return "Date"
            case "Numeric":
                return "Numérique"
            case "Boolean":
                return "Vrai ou faux"
            default:
                break;
        }
    }

    return (
        <>
            <React.Fragment>
                {isLoading ? (
                    <div className="row bg-white pt-4 mt-lg-0">
                        <div className="col-lg-12">
                            <div className="loading-container bg-white">
                                <CircularProgress />
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="row">
                            <div className="col-lg-12 mt-4 bg-white pt-0 mt-lg-0">
                                <div className="bg-light-2 p-4">
                                    <div className="row mb-4">
                                        <h2 className="mb-0"><span>{job.title} - {job.jobNumber}</span></h2>
                                    </div>
                                    <div style={{ marginLeft: "-12px" }}>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label>Titre du poste *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="title"
                                                    value={updateNewJob?.title}
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setUpdateNewJob(prevState => { return { ...prevState, title: val } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Nom de la société *</label>
                                                <Select
                                                    value={employersListOptions.find(opt => opt?.value == updateNewJob?.employerId)}
                                                    options={employersListOptions}
                                                    loadingMessage="En cours de chargement"
                                                    onChange={(option) => {
                                                        setUpdateNewJob(prevState => { return { ...prevState, employerId: option?.value, companyName: option.label } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Email *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="title"
                                                    value={updateNewJob?.applicationEmail}
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        let isValide = validator.isEmail(e.target.value);
                                                        setUpdateNewJob(prevState => { return { ...prevState, applicationEmail: val } });
                                                        if (invalideEmail) {
                                                            setInvalideEmail(!isValide);
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        let val = validator.isEmail(e.target.value);
                                                        setInvalideEmail(!val);
                                                    }}
                                                />
                                                {(updateNewJob.applicationEmail != "" && invalideEmail) && (
                                                    <p style={{ color: "red" }}>Format d'email invalide</p>
                                                )}
                                            </div>
                                            <div className="form-group col-sm-6 col-xl-6">
                                                <label>Catégorie *</label>
                                                <Select
                                                    value={categories.find(opt => opt?.value == updateNewJob.category)}
                                                    options={categories}
                                                    loadingMessage="En cours de chargement"
                                                    onChange={(option) => {
                                                        setUpdateNewJob(prevState => { return { ...prevState, category: option?.label } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-sm-6 col-xl-6">
                                                <label>Type d'emploi</label>
                                                <Select
                                                    value={types.find(opt => opt.value == updateNewJob?.type)}
                                                    options={types}
                                                    loadingMessage="En cours de chargement"
                                                    onChange={(option) => {
                                                        setUpdateNewJob(prevState => { return { ...prevState, type: option?.value } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <label>Date limite de dépot</label>
                                                <div className="input-group date">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={moment(updateNewJob?.closingDate).format('YYYY-MM-DD')}
                                                        onChange={(e) => {
                                                            let val = e.target?.value;
                                                            setUpdateNewJob(prevState => { return { ...prevState, closingDate: val } });
                                                        }}

                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-6 datetimepickers">
                                                <label>Date de prise en fonction</label>
                                                <div className="input-group date">
                                                    <input
                                                        type="date"
                                                        className="form-control datetimepicker-input"
                                                        value={moment(updateNewJob?.startingDate).format('YYYY-MM-DD')}
                                                        onChange={(e) => {
                                                            let val = e.target?.value;
                                                            setUpdateNewJob(prevState => { return { ...prevState, startingDate: val } });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Ville *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="location"
                                                    value={updateNewJob?.location}
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setUpdateNewJob(prevState => { return { ...prevState, location: val } });
                                                    }}
                                                />
                                                {updateNewJob.location?.length > 200 && (
                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                )}
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Description *</label>
                                                <CustomHtmlEditor
                                                    ref={descriptionHtmlEditor}
                                                    initialValue={updateNewJob?.description}
                                                    onChange={(value, editor) => {
                                                        if (value == "") {
                                                            setValidateDescription("");
                                                        }
                                                        else {
                                                            setValidateDescription(value);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-12 mt-3">
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        checked={updateNewJob?.isActive}
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="accepts-02"
                                                        onChange={(e) => {
                                                            let val = e.target?.checked;
                                                            setUpdateNewJob(prevState => { return { ...prevState, isActive: val } });
                                                        }}
                                                    />
                                                    <label className="custom-control-label" htmlFor="accepts-02">Actif</label>
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        id="accepts-07"
                                                        type="checkbox"
                                                        defaultChecked={updateNewJob?.receiveEmailNotification}
                                                        className="custom-control-input"
                                                        name="receiveEmailNotification"
                                                        onClick={(e) => {
                                                            let val = e.target?.checked;
                                                            setUpdateNewJob(prevState => {
                                                                return { ...prevState, receiveEmailNotification: val }
                                                            });
                                                        }}
                                                    />
                                                    <label className="custom-control-label" htmlFor="accepts-07">Recevoir les candidatures par e-mail (les candidats pourront postuler directement à partir du site)</label>
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        id="accepts-03"
                                                        type="checkbox"
                                                        defaultChecked={updateNewJob?.showEmailAddress}
                                                        className="custom-control-input"
                                                        name="showEmailAddress"
                                                        onClick={(e) => {
                                                            let val = e.target?.checked;
                                                            setUpdateNewJob(prevState => {
                                                                return { ...prevState, showEmailAddress: val }
                                                            });
                                                        }}
                                                    />
                                                    <label className="custom-control-label" htmlFor="accepts-03">Afficher mon adresse email sur la description de l'offre d'emploi</label>
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        id="accepts-04"
                                                        type="checkbox"
                                                        defaultChecked={updateNewJob?.showPhoneNumber}
                                                        className="custom-control-input"
                                                        name="showPhoneNumber"
                                                        onClick={(e) => {
                                                            let val = e.target?.checked;
                                                            setUpdateNewJob(prevState => {
                                                                return { ...prevState, showPhoneNumber: val }
                                                            });
                                                        }}
                                                    />
                                                    <label className="custom-control-label" htmlFor="accepts-04">Afficher mon numéro de téléphone sur la description de l'offre d'emploi</label>
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        id="accepts-05"
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        defaultChecked={updateNewJob?.hasAdditionalQuestions}
                                                        name="additionalQuestions"
                                                        onClick={(e) => {
                                                            let val = e.target?.checked;
                                                            setUpdateNewJob(prevState => {
                                                                return { ...prevState, hasAdditionalQuestions: val }
                                                            });
                                                            if (val == true && updateNewJob.additionalQuestions?.length == 0) {
                                                                setUpdateNewJob(prevState => {
                                                                    return { ...prevState, additionalQuestions: [{ label: "", type: "", required: false }] }
                                                                });
                                                            }
                                                            // else {
                                                            //     setUpdateNewJob(prevState => {
                                                            //         return { ...prevState, additionalQuestions: [] }
                                                            //     });
                                                            // }
                                                        }}
                                                    />
                                                    <label className="custom-control-label" htmlFor="accepts-05">Afficher des questions supplémentaires</label>
                                                </div>
                                            </div>
                                            {updateNewJob?.hasAdditionalQuestions == true && (
                                                updateNewJob?.additionalQuestions?.map((quiz, index) => (
                                                    <>
                                                        <div style={{ marginTop: "15px" }} className="form-group col-md-12">
                                                            <label>Question {index + 1} </label>
                                                            {index >= 0 && (
                                                                <a style={{ float: "right" }} href="assets/#" className="text-danger" data-toggle="modal" title="" data-target="#deleteJob" data-original-title="Delete">                                                                    <i
                                                                    className="far fa-trash-alt"
                                                                    onClick={() => {
                                                                        let indexOf = updateNewJob.additionalQuestions?.indexOf(quiz);
                                                                        let additionalQuestionsCopy = updateNewJob.additionalQuestions;
                                                                        additionalQuestionsCopy?.splice(indexOf, 1);
                                                                        setUpdateNewJob(prevState => {
                                                                            return { ...prevState, additionalQuestions: additionalQuestionsCopy }
                                                                        });
                                                                    }}>
                                                                </i>
                                                                </a>
                                                            )}
                                                            <input
                                                                value={quiz.label}
                                                                type="text"
                                                                className="form-control"
                                                                // name={quiz.label}
                                                                placeholder=""
                                                                // disabled={published}
                                                                onChange={(e) => {
                                                                    let val = e.target.value;
                                                                    let additionalQuestionsCopy = updateNewJob.additionalQuestions;
                                                                    additionalQuestionsCopy[index].label = val;
                                                                    setUpdateNewJob(prevState => {
                                                                        return { ...prevState, additionalQuestions: additionalQuestionsCopy }
                                                                    });
                                                                  
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-12">
                                                            <label>Type de réponse {index + 1} </label>
                                                            <Select
                                                                options={[
                                                                    { label: "Champs libre", value: "FreeText" },
                                                                    { label: "Choix unique", value: "SingleValue" },
                                                                    { label: "Choix multiple", value: "MultiValue" },
                                                                    { label: "Date", value: "DateTime" },
                                                                    { label: "Numérique", value: "Numeric" },
                                                                    { label: "Vrai ou faux", value: "Boolean" },
                                                                ]}
                                                                value={[{ label: getOptionLabel(quiz.type), value: quiz.type }]}
                                                                loadingMessage="En cours de chargement"
                                                                // isDisabled={published}
                                                                onChange={(option) => {
                                                                    let additionalQuestionsCopy = updateNewJob.additionalQuestions;
                                                                    additionalQuestionsCopy[index].type = option?.value;
                                                                    if (option.value == "SingleValue" || option.value == "MultiValue") {
                                                                        additionalQuestionsCopy[index].options = "";
                                                                    }
                                                                    setUpdateNewJob(prevState => { return { ...prevState, additionalQuestions: additionalQuestionsCopy } });
                                                                }}
                                                            />
                                                        </div>
                                                        {(quiz.type == "SingleValue" || quiz.type == "MultiValue") && (
                                                            <div className="form-group col-md-12">
                                                                <label>Options </label>
                                                                <textarea
                                                                    className="form-control"
                                                                    value={quiz.options}
                                                                    onChange={e => {
                                                                        let additionalQuestionsCopy = updateNewJob.additionalQuestions;
                                                                        additionalQuestionsCopy[index].options = e.target.value;
                                                                        setUpdateNewJob(prevState => { return { ...prevState, additionalQuestions: additionalQuestionsCopy } });
                                                                    }}
                                                                    onKeyDown={e => {
                                                                        if (e.key == "Enter") {
                                                                            let additionalQuestionsCopy = updateNewJob.additionalQuestions;
                                                                            additionalQuestionsCopy[index].options = e.target.value + "\n";
                                                                            setUpdateNewJob(prevState => { return { ...prevState, additionalQuestions: additionalQuestionsCopy } });
                                                                        }
                                                                    }}
                                                                ></textarea>
                                                                <p style={{ marginTop: "5px", marginBottom: "0px", fontStyle: "italic" }}>Entrez un choix de réponse par ligne séparé par la touche Entrer</p>
                                                            </div>
                                                        )}
                                                        <div className="form-group col-md-12">
                                                            <div className="custom-control custom-checkbox mb-4">
                                                                <input
                                                                    id={"accepts-" + index}
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    checked={quiz.required}
                                                                    name="additionalQuestions"
                                                                    onClick={(e) => {
                                                                        let val = e.target.checked;
                                                                        let additionalQuestionsCopy = updateNewJob.additionalQuestions;
                                                                        additionalQuestionsCopy[index].required = val;
                                                                        setUpdateNewJob(prevState => { return { ...prevState, additionalQuestions: additionalQuestionsCopy } });
                                                                    }}
                                                                />
                                                                <label className="custom-control-label" for={"accepts-" + index}>Requis</label>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))
                                            )}
                                            {updateNewJob?.hasAdditionalQuestions == true && (
                                                <div className="col-12 custom-control">
                                                    <button
                                                        className="btn btn-primary"
                                                        disabled={updateNewJob?.additionalQuestions?.some(q => q.label == "" || q.type == "")}
                                                        style={{ width: "100%" }}
                                                        onClick={e => {
                                                            setUpdateNewJob(prevState => {
                                                                let val = updateNewJob.additionalQuestions;
                                                                val.push({ label: "", type: "", required: false });
                                                                return { ...prevState, additionalQuestions: val }
                                                            });
                                                        }}

                                                    >
                                                        Ajouter une question
                                                    </button>
                                                </div>
                                            )}
                                            <div className="col-12 mt-4">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={handleSubmit}
                                                    disabled={isSubmetting || hasMissingValue}
                                                >
                                                    Sauvegarder &nbsp; {isSubmetting ? <CircularProgress size={15} /> : <span></span>}
                                                </button>
                                            </div>
                                            {published && (
                                                <div className="col-12 mt-4">
                                                    <CustomAlert content="L'offre d'emploi a été modifiée avec succès." />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 mt-4 bg-white mt-lg-10">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title" style={{ marginLeft: "12px" }}>
                                            <h4 className="title" style={{ paddingBottom: "15px" }}><span>Documents</span></h4>
                                            <p style={{ marginRight: "12px" }}>
                                                Veuillez déposer dans cette section les documents de l'offre d'emploi
                                                <strong> en cliquant à l'intérieur de la bande blanche ci-dessous</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div _lpchecked="2" style={{ marginLeft: "12px", marginRight: "20px" }}>
                                    {isUploading ?
                                        (
                                            <div className="loading-container">
                                                <CircularProgress />
                                                <div>Chargement des documents en cours...</div>
                                            </div>
                                        )
                                        :
                                        (
                                            <div className="form-group mb-5">
                                                <DropzoneArea
                                                    clearOnUnmount={true}
                                                    dropzoneText={isUploading ? "Chargement en cours..." : "Glisser ou cliquer ici pour déposer vos documents"}
                                                    showPreviewsInDropzone={false}
                                                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                    showAlerts={false}
                                                    filesLimit={1}
                                                    onChange={(files) => {
                                                        setSelectedFile(files[0])
                                                    }}
                                                />
                                            </div>
                                        )}
                                    <table className="table manage-candidates-top mb-4">
                                        <thead>
                                            <tr>
                                                <th>Nom</th>
                                                <th>Date</th>
                                                <th className="action text-right"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <>
                                                {job?.attachments?.map((file, index) => (
                                                    <tr key={index}>
                                                        <td>{file.name}</td>
                                                        <td>{file.dateCreated}</td>
                                                        <td style={{ textAlign: "right" }}>
                                                            <a href={file.url} className="text-primary" target="_blank">
                                                                <i className="fas fa-cloud-download-alt"></i>
                                                            </a>
                                                            <a style={{ marginLeft: "10px" }} className="text-primary" target="_blank">
                                                                <i class="far fa-copy" onClick={() => { navigator.clipboard.writeText(file.url) }}></i>
                                                            </a>
                                                            <a href="assets/#" className="text-danger" style={{ marginLeft: "10px" }} data-toggle="modal" title="" data-target="#deleteAdsModal" data-original-title="Delete">
                                                                <i className="far fa-trash-alt" onClick={() => setAttachmentToDelete(file)}></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        {/* Start Delete file modale */}
                        <div className="modal fade" id="deleteAdsModal" tabIndex="-1" role="dialog" aria-modal="true" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header p-4">
                                        <h4 className="mb-0 text-center">{"Suppression d'un fichier: " + attachmentToDelete?.name}</h4>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>

                                    <form className="mt-2">
                                        <div className="modal-body">
                                            <div>
                                                <div className="form-row">
                                                    <p>Êtes-vous sûr de vouloir supprimer ce fichier ?</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                data-dismiss="modal"
                                                className="btn btn-primary"
                                                ref={closeFileDeleteModal}
                                            >
                                                NON
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={(e) => handleDeleteSubmit(e, jobId, attachmentToDelete.id)}
                                                disabled={isDeletting}
                                            >
                                                OUI &nbsp; {isDeletting ? <CircularProgress size={15} /> : <span></span>}
                                            </button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                        {/* End Delete file modale */}
                    </>
                )}
            </React.Fragment>
        </>
    )
}

JobDetail.propTypes = {
    getJobList: PropTypes.func.isRequired,
    updateJob: PropTypes.func.isRequired,
    getEmployerList: PropTypes.func.isRequired,
    storeJob: PropTypes.object.isRequired,
    storeEmployer: PropTypes.object.isRequired,
    addAttachmentFromJob: PropTypes.func.isRequired,
    deleteAttachmentFromJob: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    getJobList: PropTypes.func.isRequired,
    getEmployerList: PropTypes.func.isRequired,
    updateJob: PropTypes.func.isRequired,
    storeJob: state.job,
    storeEmployer: state.employer,
    addAttachmentFromJob: PropTypes.func.isRequired,
    deleteAttachmentFromJob: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, { getJobList, updateJob, getEmployerList, addAttachmentFromJob, deleteAttachmentFromJob })(JobDetail)