import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { NavLink } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import localStorageService from '../../services/localStorageService';
import authentificationService from '../../services/authentificationService';
import siteService from '../../services/siteService';
import { candidateLogout } from "../../redux/actions/admin/CandidateActions";
import { employerLogout } from "../../redux/actions/admin/EmployerActions";
import $ from 'jquery';


let Header = (props) => {
  const {
    storeCandidates = {},
    candidateLogout,
    storeEmployers = {},
    employerLogout
  } = props;

  const navbarRef = useRef();

  const [hasMissingValue, setHasMissingValue] = React.useState(true);
  const [collapsed, setCollapsed] = React.useState(true);
  const [currrentPathName, setCurrrentPathName] = React.useState("/");
  const [currentUserName, setCurrentUserName] = React.useState("");
  const [currentCandidate, setCurrentCandidate] = React.useState(null);
  const [currentEmployer, setCurrentEmployer] = React.useState(null);
  const location = useLocation();

  useEffect(() => {
    let att = $('.navbar-toggler').attr('class');
    if (att.includes("open")) {
      navbarRef.current.click();
    }
  }, [location])

  useLayoutEffect(() => {
    toggleNavbar();
  }, []);

  useEffect(() => {
    getPathName();
    getCurrentUserName();
    let candidate = localStorageService.getItem("malibaara_auth_candidate");
    let employer = localStorageService.getItem("malibaara_auth_employer");
    if (candidate) {
      setCurrentCandidate(candidate);
    }
    if (employer) {
      setCurrentEmployer(employer);
    }

    // $('#navbar-toggler').click(function () {
    //   $('#nav').slideToggle(300);
    // });
  }, []);

  useEffect(() => {
    if (storeCandidates.currentCandidate == null) {
      let candidate = localStorageService.getItem("malibaara_auth_candidate");
      if (candidate === null) {
        setCurrentCandidate(null);
      }
    }
    if (storeCandidates.currentCandidate !== null) {
      let candidate = localStorageService.getItem("malibaara_auth_candidate");
      if (candidate !== null) {
        setCurrentCandidate(candidate);
      }
    }
  }, [storeCandidates])

  useEffect(() => {
    if (storeEmployers.currentEmployer === null) {
      let employer = localStorageService.getItem("malibaara_auth_employer");
      if (employer === null) {
        setCurrentEmployer(null);
      }
    }
    if (storeEmployers.currentEmployer !== null) {
      let employer = localStorageService.getItem("malibaara_auth_employer");
      if (employer !== null) {
        setCurrentEmployer(employer);
      }
    }
  }, [storeEmployers])

  let getCurrentUserName = () => {
    let user = localStorageService.getItem("malibaara_auth_user");
    if (user) {
      setCurrentUserName(user.firstName + " " + user.lastName);
    }
  }

  let logout = () => {
    authentificationService.logout("user");
    props.history.push({
      pathname: "/admin/signin"
    });
  }

  let disconnect = (sessionType) => {
    authentificationService.logout(sessionType);
    if (sessionType == "candidate") {
      candidateLogout();
      window.location = "/";
    }
    else if (sessionType == "employer") {
      employerLogout();
      window.location = "/";
    }

  }

  // let employerDisconnect = () => {
  //   authentificationService.logout("employer");
  //   employerLogout();
  // }

  let getPathName = () => {
    let pathName = window.location.pathname;
    setCurrrentPathName(pathName);
  }

  let toggleNavbar = () => {
    setCollapsed(!collapsed);
  }

  return (
    <>
      <header className="header header-default" style={{ backgroundColor: "#ffffff" }}>
        <nav className="navbar navbar-static-top navbar-expand-lg navbar-light header-sticky">
          <div className="container">
            <button
              id="nav-icon4"
              type="button"
              className="navbar-toggler"
              data-toggle="collapse"
              data-target=".navbar-collapse"
              ref={navbarRef}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
            <a className="navbar-brand" href="/">
              <img className="img-fluid" src={ siteService.getCurrentSite().logoUrl } alt="logo" />
            </a>
            {currrentPathName.toLowerCase().includes("/admin") ?
              (
                <></>
              )
              :
              (
                <div className="navbar-collapse collapse justify-content-start" data-toggle="collapse" data-target=".navbar-collapse collapse">
                  <ul className="nav navbar-nav">
                    <li className={currrentPathName == "/" ? "nav-item dropdown active" : "nav-item dropdown"} onClick={getPathName}>
                      <NavLink tag={Link} className="nav-link" to="/">Accueil</NavLink>
                    </li>
                    <li className={currrentPathName == "/jobs/search" ? "nav-item dropdown active" : "nav-item dropdown"} onClick={getPathName}>
                      <NavLink tag={Link} className="nav-link" to="/jobs/search">Offres d'emploi</NavLink>
                    </li>
                    <li className={currrentPathName == "/tenders/search" ? "nav-item dropdown active" : "nav-item dropdown"} onClick={getPathName}>
                      <NavLink tag={Link} className="nav-link" to="/tenders/search">Appels d'offre</NavLink>
                    </li>
                    <li className={currrentPathName == "/courses/search" ? "nav-item dropdown active" : "nav-item dropdown"} onClick={getPathName}>
                      <NavLink tag={Link} className="nav-link" to="/courses/search">Formations</NavLink>
                    </li>
                    <li className={currrentPathName == "/pages/services" ? "nav-item dropdown active" : "nav-item dropdown"} onClick={getPathName}>
                      <NavLink tag={Link} className="nav-link" to="/pages/services">Services</NavLink>
                    </li>
                    {currentCandidate == null && currentEmployer == null ?
                      (
                        <>
                          <li className="d-block d-md-block d-lg-none">
                            <a href="assets/login.html" data-toggle="modal" data-target="#loginAndForgotPasswordModal" className="nav-link">Connexion</a>
                          </li>
                        </>
                      )
                      :
                      (
                        <>
                          {currentCandidate != null && (
                            <li className="d-block d-md-block d-lg-none d-xl-none nav-item dropdown">
                              <a className="nav-link dropdown-toggle" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {currentCandidate.firstName + " " + currentCandidate.lastName} <i className="fas fa-chevron-down fa-xs"></i>
                              </a>
                              <ul className="dropdown-menu">
                                <li><NavLink tag={Link} className="dropdown-item" to={"/candidates/profile"}>Mon Compte</NavLink> </li>
                                <li><NavLink tag={Link} className="dropdown-item" to={"/candidates/changePassword"}>Changer mon mot de passe</NavLink> </li>
                                <li><a className="dropdown-item" href="javascript:void(0)" onClick={(e) => disconnect(currentCandidate != null ? "candidate" : "employer ")}>Déconnexion</a> </li>
                              </ul>
                            </li>
                          )}
                          {currentEmployer != null && (
                            <li className="d-block d-md-block d-lg-none d-xl-none nav-item dropdown">
                              <a className="nav-link dropdown-toggle" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {currentEmployer.companyName} <i className="fas fa-chevron-down fa-xs"></i>
                              </a>
                              <ul className="dropdown-menu">
                                <li><NavLink tag={Link} className="dropdown-item" to={"/jobs/post"}>Publier une offre d'emploi</NavLink> </li>
                                <li><NavLink tag={Link} className="dropdown-item" to={"/tenders/post"}>Publier un appel d'offre</NavLink> </li>
                                <li><NavLink tag={Link} className="dropdown-item" to={"/employers/jobs/list/" + currentEmployer.id}>Mes Offres D'emploi</NavLink> </li>
                                <li><NavLink tag={Link} className="dropdown-item" to={"/employers/tenders/list/" + currentEmployer.id}>Mes Appels D'offre</NavLink> </li>
                                <li><NavLink tag={Link} className="dropdown-item" to={"/employers/profile"}>Mon Compte</NavLink> </li>                                
                                <li><NavLink tag={Link} className="dropdown-item" to={"/employers/changePassword"}>Changer mot de passe</NavLink> </li>
                                <li><a className="dropdown-item" href="javascript:void(0)" onClick={(e) => disconnect(currentEmployer != null ? "employer" : "candidate ")}>Déconnexion</a> </li>
                              </ul>
                            </li>
                          )}
                        </>
                      )}
                    {currentCandidate == null && currentEmployer == null && (
                      <li className="d-block d-md-block d-lg-none d-xl-none nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          href="javascript:void(0)"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Publier <i className="fas fa-chevron-down fa-xs"></i>
                        </a>
                        <ul className="dropdown-menu">
                          <li><NavLink tag={Link} className="dropdown-item" to={"/jobs/post"}>Offre d'emploi</NavLink> </li>
                          <li><NavLink tag={Link} className="dropdown-item" to={"/tenders/post"}>Appel d'offre</NavLink> </li>
                        </ul>
                      </li>
                    )}
                  </ul>
                </div>
              )}
            {currrentPathName.toLowerCase().includes("/admin") ?
              (
                <div className="add-listing">
                  <div className="login d-inline-block" style={{ marginRight: "-20px" }}><ul className="nav navbar-nav">
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="far fa-user pr-2" style={{ fontWeight: "unset", fontSize: "14px" }}></i>{currentUserName} <i className="fas fa-chevron-down fa-xs"></i>
                      </a>
                      <ul className="dropdown-menu" style={{ marginLeft: "-140px" }}>
                        <li>
                          {/* <a className="dropdown-item" href="job-grid.html">Changer mon mot de passe</a> */}
                          <Link className="dropdown-item" to={"/admin/changePassword"}>Changer mon mot de passe</Link>
                        </li>
                        <li><a className="dropdown-item" href="javascript:void(0)" onClick={logout} >Déconnexion</a></li>
                      </ul>
                    </li>
                  </ul>
                  </div>
                </div>
              )
              :
              (
                <div className="add-listing">
                  {currentCandidate == null && currentEmployer == null ?
                    (
                      <>
                        <div className="login d-inline-block mr-4">
                          <a href="assets/login.html" data-toggle="modal" data-target="#loginAndForgotPasswordModal"><i className="far fa-user pr-2"></i>Connexion</a>
                        </div>
                        <div className="login d-inline-block mr-4">
                          <ul className="nav navbar-nav">
                            <li className="nav-item dropdown">
                              <a className="nav-link dropdown-toggle btn btn-primary btn-md" style={{ padding: "10px", color: "white", fontSize: "14px" }} href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Publier
                                <i className="fas fa-chevron-down fa-xs" style={{ color: "white" }}></i>
                              </a>
                              <ul className="dropdown-menu" style={{ marginLeft: "-120px" }}>
                                <li> <Link className="dropdown-item" to={"/jobs/post"}>Offre d'emploi</Link></li>
                                <li><Link className="dropdown-item" to={"/tenders/post"}>Appel d'offres</Link></li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </>
                    )
                    :
                    (
                      <>
                        <div className="add-listing">
                          <div className="login d-inline-block mr-4">
                            <ul className="nav navbar-nav">
                              <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i className="far fa-user pr-2" style={{ fontWeight: "unset", fontSize: "14px" }}></i>
                                  {currentCandidate != null ? currentCandidate.firstName + " " + currentCandidate.lastName : currentEmployer.companyName}
                                  <i className="fas fa-chevron-down fa-xs"></i>
                                </a>
                                {currentCandidate != null && (
                                  <ul className="dropdown-menu" style={{ marginLeft: "-50px" }}>
                                    <li>
                                      <Link className="dropdown-item" to={"/candidates/profile"}>Mon Compte</Link>
                                    </li>
                                    <li>
                                      <Link className="dropdown-item" to={"/candidates/changePassword"}>Changer mon mot de passe</Link>
                                    </li>
                                    <li>
                                      <a className="dropdown-item" href="javascript:void(0)" onClick={(e) => disconnect(currentCandidate != null ? "candidate" : "employer ")}>Déconnexion</a>
                                    </li>
                                  </ul>
                                )}
                                {currentEmployer != null && (
                                  <ul className="dropdown-menu" style={{ marginLeft: "-50px" }}>
                                    <li>
                                      <Link className="dropdown-item" to={"/jobs/post"}>Publier une offre d'emploi</Link>
                                    </li>
                                    <li>
                                      <Link className="dropdown-item" to={"/tenders/post"}>Publier un appel d'offre</Link>
                                    </li>
                                    <li>
                                      <Link className="dropdown-item" to={"/employers/jobs/list/" + currentEmployer.id}>Mes offres d'emploi</Link>
                                    </li>
                                    <li>
                                      <Link className="dropdown-item" to={"/employers/tenders/list/" + currentEmployer.id}>Mes appels d'offre</Link>
                                    </li>
                                    <li>
                                      {/* <a className="dropdown-item" href="javascript:void(0)">Mon Compte</a> */}
                                      <Link className="dropdown-item" to={"/employers/profile"}>Mon compte</Link>
                                    </li>
                                    <li>
                                      <Link className="dropdown-item" to={"/employers/changePassword"}>Changer mon mot de passe</Link>
                                    </li>
                                    <li>
                                      <a className="dropdown-item" href="javascript:void(0)" onClick={(e) => disconnect(currentEmployer != null ? "employer" : "candidate ")}>Déconnexion</a>
                                    </li>
                                  </ul>
                                )}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              )}

          </div>
        </nav>
      </header>
    </>
  );
}

const mapStateToProps = state => ({
  storeUsers: state.users,
  storeCandidates: state.candidate,
  storeEmployers: state.employer
});

export default (withRouter(connect(mapStateToProps, { candidateLogout, employerLogout })(Header)));
