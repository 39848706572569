import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import { jobSearch } from "../../redux/actions/JobActions"
import { CircularProgress } from '@material-ui/core';
import { Link } from "react-router-dom";
import { createAds, deleteAds, getAdsList } from "../../../redux/actions/admin/AdsActions";
import { Helmet } from "react-helmet";
import $ from 'jquery';
import { DropzoneArea } from 'material-ui-dropzone';
import siteService from '../../../services/siteService';

export const AdsListing = (props) => {
  const { createAds, deleteAds, getAdsList, storeAds = {} } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [isDeletting, setIsDeletting] = React.useState(false);
  const [isCreatting, setIsCreatting] = React.useState(false);
  const [newAds, setNewAds] = React.useState({ name: "", link: "", openInNewTab: false, order: "" });
  const [hasMissingValue, setHasMissingValue] = React.useState(true);
  const [selectedFile, setSelectedFile] = React.useState()
  const [preview, setPreview] = React.useState();
  const [adsTodelete, setAdsTodelete] = React.useState(null);
  const closeAdsCreateModal = React.useRef();
  const closeDeleteAdsModal = React.useRef();
  const [adsList, setAdsList] = React.useState(null);
  const currentSite = siteService.getCurrentSite();

  useEffect(() => {
    if (storeAds.adsList.length > 0 && adsList == null) {
      setAdsList(storeAds.adsList);
    }
    else if (storeAds.adsList.length === 0) {
      getAdsList(true).then(response => {
        if (response.success) {
          setAdsList(response.adsList);
        }
      })
    }
  }, [])

  useEffect(() => {
    checkRequiredFields();
  }, [newAds]);

  useEffect(() => {
    if (storeAds.loading != isLoading) {
      setIsLoading(storeAds.loading);
    }
  }, [storeAds]);

  useEffect(() => {
    checkRequiredFields();
    if (!selectedFile) {
      setPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile]);

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(e.target.files[0]);
      return
    }
    setSelectedFile(e.target.files[0]);
  }

  const handleSubmit = () => {
    setIsCreatting(true);
    let adsCopy = newAds;
    if (!adsCopy.order || adsCopy.order == "") {
      adsCopy.order = 0;
    }
    createAds(selectedFile, newAds).then(resp => {
      if (resp.success) {
        setAdsList(resp.adsList);
        setNewAds({ name: "", link: "", openInNewTab: false, order: "" });
        closeAdsCreateModal.current.click();
        setIsCreatting(false);
        setSelectedFile(undefined);
      }
    })
  }

  const handleDeleteSubmit = () => {
    setIsDeletting(true);
    deleteAds(adsTodelete?.id).then(resp => {
      setAdsTodelete(null);
      if (resp.success) {
        setAdsList(resp.adsList);
      }
      closeDeleteAdsModal.current.click();
      setIsDeletting(false);
    });
  }

  let checkRequiredFields = () => {
    if (newAds.name == ""
    ) {
      setHasMissingValue(true);
    }
    else if (newAds.link?.length > 200) {
      setHasMissingValue(true);
    }
    else {
      setHasMissingValue(false);
    }
  }

  // Start keydown mechanism
  let onKeydown = event => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      if ($('#createAdsModal').attr('class')?.includes("show")) {
        if (!hasMissingValue) {
          handleSubmit();
        }
      }
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeydown);
    return () => {
      document.removeEventListener("keydown", onKeydown);
    };
  }, [onKeydown]);
  //End keydown mechanism

  return (
    <React.Fragment>
      <Helmet>
        <title> Administration | Publicités | {currentSite.fullName}</title>
      </Helmet>
      {isLoading ? (
        <div className="row bg-white pt-4 mt-lg-0">
          <div className="col-lg-12">
            <div className="loading-container bg-white">
              <CircularProgress />
            </div>
          </div>
        </div>
      ) : (
        <>

          <div className="row bg-white pt-4 mt-lg-0">
            <div className="col-lg-12 mt-4 mt-lg-0">
              <div className="row mb-4">
                <div className="col-md-7 col-sm-5 d-flex align-items-center">
                  <div className="section-title-02 mb-0 ">
                    <h4 className="mb-0" style={{ marginLeft: "10px" }}>Liste des publicités</h4>
                  </div>
                </div>
                <div className="col-md-5 col-sm-7 mt-3 mt-sm-0" style={{ textAlign: "right" }}>
                  <a className="btn btn-md ml-sm-auto btn-primary" data-toggle="modal" data-target="#createAdsModal">Ajouter une publicité</a>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="user-dashboard-info-box table-responsive mt-1 bg-white">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th width="80%">Nom</th>
                          <th width="10%">Statut</th>
                          <th width="10%" className="action text-right"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {adsList?.map((ads, index) => (
                          <tr className="candidates-list" key={ads.id}>
                            <td>{ads.name}</td>
                            <td>
                              {ads.isActive ? (<div className="active">Actif</div>) : (<div className="inactive">Inactif</div>)}
                            </td>
                            <td>
                              <ul className="list-unstyled mb-0 d-flex justify-content-end">
                                <li><Link to={"/admin/ads/details/" + ads.id} ><i className="fas fa-pencil-alt"></i></Link></li>
                                <li>
                                  <a href="javascript:void(0)" className="text-danger" data-toggle="modal" title="" data-target="#deleteAdsModal" data-original-title="Delete">
                                    <i className="far fa-trash-alt" onClick={() => setAdsTodelete(ads)}></i>
                                  </a>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>


          {/* Start Delete ads modale */}
          <div className="modal fade" id="deleteAdsModal" tabIndex="-1" role="dialog" aria-modal="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header p-4">
                  <h4 className="mb-0 text-center">Suppression d'une publicité: {adsTodelete?.name} </h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <form className="mt-2">
                  <div className="modal-body">
                    <div>
                      <div className="form-row">
                        <p>Voulez vous supprimer cette publicité?</p>

                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      ref={closeDeleteAdsModal}
                    // disabled={isDeletting}
                    >
                      Non
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleDeleteSubmit}
                      disabled={isDeletting}

                    >
                      Oui &nbsp; {isDeletting ? <CircularProgress size={15} /> : <span></span>}
                    </button>
                  </div>
                </form>

              </div>
            </div>
          </div>
          {/* End Delete ads modale */}

          {/* Start create ads modale */}
          <div className="modal fade" id="createAdsModal" tabIndex="-1" role="dialog" aria-modal="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header p-4">
                  <h4 className="mb-0 text-center">Création d'une nouvelle publicité</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" disabled={storeAds.submitting}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <div>
                    <div className="form-row">
                      <div className="form-group col-12">
                        <label htmlFor="Email2">Nom *</label>
                        <input
                          value={newAds?.name}
                          type="text"
                          className="form-control"
                          name="Name"
                          required
                          onChange={(e) => {
                            let val = e.target?.value;
                            setNewAds(prevState => { return { ...prevState, name: val } });
                          }}
                        />
                        {newAds?.name?.length > 200 && (
                          <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                        )}
                      </div>
                      <div className="form-group col-12">
                        <label htmlFor="Email2">Lien</label>
                        <input
                          value={newAds?.link}
                          type="text"
                          className="form-control"
                          name="Link"
                          onChange={(e) => {
                            let val = e.target?.value;
                            setNewAds(prevState => { return { ...prevState, link: val } });
                          }}
                        />
                        {newAds.link?.length > 50 && (
                          <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                        )}
                      </div>
                      <div className="form-group col-md-12">
                        <label>Ordre d'affichage</label>
                        <input
                          type="number"
                          className="form-control"
                          name="order"
                          value={newAds.order}
                          onChange={(e) => {
                            let val = e.target?.value;
                            setNewAds(prevState => {
                              return { ...prevState, order: val }
                            })
                          }}
                        />
                      </div>

                      <div className="form-group col-12">
                        <label htmlFor="Email2">Image</label>
                        <DropzoneArea
                          clearOnUnmount={true}
                          dropzoneText={"Glisser ou cliquer ici pour déposer un fichier"}
                          showPreviewsInDropzone={true}
                          previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                          showAlerts={false}
                          maxFileSize={5000000}
                          filesLimit={1}
                          onChange={(files) => {
                            setSelectedFile(files[0])
                          }}
                        />
                        <p style={{ marginTop: "9px", marginBottom: "0px", fontStyle: "italic" }}>La taille maximale du fichier est de 5MB</p>
                      </div>
                      <div className="form-group col-12 mt-3">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="accepts-01"
                            checked={newAds.openInNewTab}
                            onChange={(e) => {
                              let val = e.target.checked;
                              setNewAds(prevState => {
                                return { ...prevState, openInNewTab: val }
                              });
                            }}
                          />
                          <label className="custom-control-label" htmlFor="accepts-01">Ouvrir dans un nouvel onglet?</label>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    // disabled={isCreatting}
                    ref={closeAdsCreateModal}
                  >
                    Annuler
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    disabled={isCreatting || hasMissingValue}
                  >
                    Soumettre &nbsp; {isCreatting ? <CircularProgress size={15} /> : <span></span>}
                  </button>
                </div>

              </div>
            </div>
          </div>

        </>
      )}
    </React.Fragment>
  );
}


AdsListing.propTypes = {
  getAdsList: PropTypes.func.isRequired,
  createAds: PropTypes.func.isRequired,
  deleteAds: PropTypes.func.isRequired,
  storeAds: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  getAdsList: PropTypes.func.isRequired,
  createAds: PropTypes.func.isRequired,
  deleteAds: PropTypes.func.isRequired,
  storeAds: state.ads,
});

export default connect(mapStateToProps, {
  getAdsList, createAds, deleteAds
})(AdsListing)