import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import defaultBanner from '../../../assets/images/bg/default-banner.jpg';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import ContactInformations from './../shared/views/ContactInformations';
import siteService from '../../services/siteService';

export const Services = (props) => {
    const currentSite = siteService.getCurrentSite();
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <>
            <React.Fragment>
                <Helmet>
                    <title> Services  | {currentSite.fullName} </title>
                </Helmet>
                <div className="header-inner" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${defaultBanner})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 style={{ color: "white" }}>Services</h2>
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item"><NavLink tag={Link} to="/">Accueil</NavLink></li>
                                    <li className="breadcrumb-item"><i className="fas fa-chevron-right"></i>Pages</li>
                                    <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> Services </span></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="space-ptb" style={{ paddingTop: "40px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 p-4" style={{textAlign: "justify"}}>
                                <div className=" text-center justify-content-center">
                                    <div className="text-justify">
                                    <p style={{fontSize: "16px", marginBottom: "20px"}}>
                                        <a href="www.malibaara.com">www.{currentSite.fullName.toLowerCase()}</a> est un site de recherche d’emplois {currentSite.countryPrefix} {currentSite.countryName} où les employeurs peuvent déposer leurs offres d’emploi et les candidats peuvent consulter les offres d’emplois. Ces derniers peuvent aussi recevoir les annonces d’offres d’emploi par e-mail et par SMS en s’enregistrant.
                                    </p>                                    
                                    </div>
                                </div>
                                <div>
                                    <div className="section-title mb-0">
                                        <h2 className="title"><span>EMPLOYEURS</span></h2>                                                                       
                                    </div>
                                    <h4 style={{marginTop:"25px"}}>Publications des offres d’emploi</h4>    
                                    <div className="pricing-plan-01" style={{marginTop: "0px"}}>
                                        <ul className="list-unstyled pricing-list">
                                            <li><i className="fas fa-check"></i>Bénéficiez de notre vaste répertoire de candidats déjà enregistrés</li>
                                            <li><i className="fas fa-check"></i>Les candidats pourront recevoir les offres par SMS et par e-mail</li>
                                            <li><i className="fas fa-check"></i>Trouvez plus rapidement votre candidat idéal</li>                                        
                                        </ul>
                                    </div>
                                    <h4 style={{marginTop:"25px"}}>Réception des dossiers de candidature</h4>    
                                    <div className="pricing-plan-01" style={{marginTop: "0px"}}>
                                        <ul className="list-unstyled pricing-list">
                                            <li><i className="fas fa-check"></i>Réception des dossiers de candidature (CV, Lettre de motivation, diplômes, etc.)</li>                                                                        
                                        </ul>
                                    </div>
                                    <h4 style={{marginTop:"25px"}}>Tri des dossiers de candidature et Présélection des candidats</h4>    
                                    <div className="pricing-plan-01" style={{marginTop: "0px"}}>
                                        <ul className="list-unstyled pricing-list">
                                            <li><i className="fas fa-check"></i>Plus besoin de lire et évaluer des centaines de CVs et LMs.</li>                                                                        
                                            <li><i className="fas fa-check"></i>Nous vous enverrons uniquement les candidatures correspondant au profil que vous recherchez</li>                                                                        
                                        </ul>
                                    </div>
                                    <h4 style={{marginTop:"25px"}}>Publication des appels d’Offres</h4>    
                                    <div className="pricing-plan-01" style={{marginTop: "0px"}}>
                                        <ul className="list-unstyled pricing-list">
                                            <li><i className="fas fa-check"></i>Bénéficiez de notre vaste répertoire d’entreprises déjà enregistrées</li>                                                                        
                                            <li><i className="fas fa-check"></i>Les soumissionnaires pourront recevoir les appels d’offres par SMS et par e-mail</li>       
                                            <li><i className="fas fa-check"></i>Trouvez plus rapidement votre prestataire idéal</li>                                                                        
                                        </ul>
                                    </div>
                                    <div className="section-title mb-0">
                                        <h2 className="title"><span>CANDIDATS</span></h2>                                                                       
                                    </div>                                       
                                    <div className="pricing-plan-01" style={{marginTop: "10px"}}>
                                        <ul className="list-unstyled pricing-list">
                                            <li><i className="fas fa-check"></i>Réception des annonces d’offres d’emploi par e-mail</li>
                                            <li><i className="fas fa-check"></i>Réception d’alertes d’offres d’emploi par SMS</li>
                                            <li><i className="fas fa-check"></i>Votre profil et CVs seront disponibles aux employeurs</li>                                        
                                        </ul>
                                    </div>
                                    <div className="section-title mb-0">
                                        <h2 className="title"><span>VENTE ESPACE PUBLICITAIRE</span></h2>                                                                       
                                    </div> 
                                    <div className="pricing-plan-01" style={{fontSize: "16px", marginTop: "10px", marginBottom: "20px"}}>
                                        <p>Vous pouvez aussi faire la publicité de vos services et produits sur notre siteweb qui est visité par des milliers de personnes quotidiennement Pour toutes questions concernant nos tarifs, veuillez nous contacter à <a href={`mailto:${currentSite.emailAddress}`}>{currentSite.emailAddress}</a> ou au <a href={`tel:${currentSite.phoneNumber1}`}>{currentSite.phoneNumber1}</a> {currentSite.phoneNumber2 && (<> / <a href={`tel:${currentSite.phoneNumber2}`}>{currentSite.phoneNumber2}</a></>)}</p>
                                    </div> 
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <ContactInformations />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>                    
            </React.Fragment>

        </>
    )
}

