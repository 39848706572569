class siteService {
    hostname = window.location.hostname;

    getCurrentSite() {        
        switch (this.hostname) {
            case "localhost":
                return new Site(KnownSites.Malibaara);
    
            case "malibaara-demo.s3-website.eu-west-3.amazonaws.com":
                return new Site(KnownSites.Malibaara);
    
            case "malibaara-001-site1.htempurl.com":    // Production internal site
                    return new Site(KnownSites.Malibaara);

            case "malibaara-001-site5.htempurl.com":    // Demo internal site
                return new Site(KnownSites.Malibaara);

            case "guineebaara-001-site1.htempurl.com":
                return new Site(KnownSites.Guineebaara);
    
            case "malibaara.com":
                return new Site(KnownSites.Malibaara);

            case "guineebaara.com":
                return new Site(KnownSites.Guineebaara);
    
            default:
                //do nothing
        }
    }

}

class Site {
    name;
    fullName;
    logoUrl;
    address;
    phoneNumber1;
    phoneNumber2;
    emailAddress;
    countryName;
    countryPrefix;
    shortDescription;
    fullDescription;
    keywords;
    googleCaptchaSiteKey;
    operatorName1;
    operatorName2;

    constructor(name) {
        this.name = name;
        this.fullName = name + ".com";
        this.countryName = this.name.replace("baara", "");
        this.logoUrl = "assets/images/logo-transparent-" + name + ".png";

        if (this.name === KnownSites.Malibaara) {
            this.address = "Boulevard Des Armées Sotuba ACI - Bamako, Mali";
            this.phoneNumber1 = "+223 44 90 50 70";
            this.phoneNumber2 = "91 25 21 14";
            this.emailAddress = "contact@malibaara.com";  
            this.countryPrefix = "au";
            this.shortDescription = "Le site par excellence de recherche d'emplois au Mali";
            this.fullDescription = "Le site par excellence de la recherche d’emplois au Mali. Inscrivez-vous pour trouver les meilleurs candidats et votre emploi de rêve. Recevez les offres par SMS et par e-mail."
            this.keywords = "mali, bamako, afficher emploi, recherche emploi, rechercher emploi mali, postuler emploi, malibaara, baara."
            this.googleCaptchaSiteKey = "6LcBH8UUAAAAANxOdXgDkAnE93jamJywImM0u-Ar";
            this.operatorName1 = "Malitel";
            this.operatorName2 = "Orange";
        }    
        else if(this.name === KnownSites.Guineebaara) {
            this.address = "Immeuble BANKO WOULADA, 2ème étage, Commune de Ratoma - Conakry, Guinée";
            this.phoneNumber1 = "+224 ";
            this.phoneNumber2 = "";
            this.emailAddress = "contact@guineebaara.com";
            this.countryPrefix = "en";
            this.shortDescription = "Le site par excellence de recherche d'emplois en Guinée";
            this.fullDescription = "Le site par excellence de la recherche d’emplois en Guinée. Inscrivez-vous pour trouver les meilleurs candidats et votre emploi de rêve. Recevez les offres par SMS et par e-mail."
            this.keywords = "guinee, conakry, afficher emploi, recherche emploi, rechercher emploi mali, postuler emploi, guineebaara, baara."
            this.googleCaptchaSiteKey = "6LcY6cckAAAAAGqyNLaGYaueetxYXL1KI4EV56rx";
            this.operatorName1 = "MTN";
            this.operatorName2 = "Orange";
        }        
    }
    
    isMaliVersion() {
        return this.name === KnownSites.Malibaara;
    }
}

const KnownSites = {
	Malibaara: "Malibaara",
	Guineebaara: "Guineebaara"
}

export default new siteService();