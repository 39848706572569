import React, { Component } from 'react';
import axios from "axios";
import { Route } from 'react-router';
import { Layout } from './views/shared/Layout';
import { AdminLayout } from './views/shared/AdminLayout';
import { api } from "./services/apiHelpers";
import Home from './views/home/Home';
import JobDetail from './views/jobs/JobDetail';
// import JobExport from './views/jobs/JobExport';
import JobPostulation from './views/jobs/JobPostulation';
import CourseDetail from './views/course/CourseDetail';
import JobCreate from './views/jobs/JobCreate';
import EmployerSignup from './views/employers/EmployerSignup';
import EmployerProfile from './views/employers/EmployerProfile';
import EmployerResetPassword from './views/employers/EmployerResetPassword';
import EmployerChangePassword from './views/employers/EmployerChangePassword';
import JobListing from './views/jobs/JobListing';
import JobArchiveListing from './views/jobs/JobArchiveListing';
import JobArchiveDetail from './views/jobs/JobArchiveDetail';
import CourseListing from './views/course/CourseListing';
import TenderListing from './views/tenders/TenderListing';
import TenderArchiveListing from './views/tenders/TenderArchiveListing';
import TenderArchiveDetail from './views/tenders/TenderArchiveDetail';
import TenderDetail from './views/tenders/TenderDetail';
import Dashboard from './views/admin/dashboard/Dashboard';
import LoginPage from './views/admin/account/loginPage';
import UserListing from './views/admin/users/UserListing';
import ResetPassword from './views/admin/account/ResetPassword';
import ChangePassword from './views/admin/account/ChangePassword';
import RoleListing from './views/admin/roles/RoleListing';
import adsListing from './views/admin/ads/AdsListing';
import smsNotificationListing from './views/admin/smsNotifications/SmsNotificationListing';
import CandidateListing from './views/admin/candidates/CandidateListing';
import EmployerListing from './views/admin/employers/EmployerListing';
import ApplicationListing from './views/admin/applications/ApplicationListing';
import ApplicationDetail from './views/admin/applications/ApplicationDetail';
import EmployerDetail from './views/admin/employers/EmployerDetail';
import JobListingAdmin from './views/admin/job/JobListing';
import CourseListingAdmin from './views/admin/course/CourseListing';
import JobDetailAdmin from './views/admin/job/JobDetail';
import CourseDetailAdmin from './views/admin/course/CourseDetail';
import TendersListing from './views/admin/tenders/TendersListing';
import TenderDetailAdmin from './views/admin/tenders/TenderDetail';
import AdsDetail from './views/admin/ads/AdsDetail';
// import SmsNotificationDetail from './views/admin/smsNotification/SmsNotificationDetail';
import TenderCreate from './views/tenders/TenderCreate';
import * as signalR from "@microsoft/signalr";
import CandidateSignup from './views/candidates/CandidateSignup';
import CandidateProfile from './views/candidates/CandidateProfile';
import CandidateResetPassword from './views/candidates/CandidateResetPassword';
import CandidateChangePassword from './views/candidates/CandidateChangePassword';
import RefreshOperation from './views/admin/Operations/RefreshOperation';
import { Services } from './views/pages/Services';
import { PrivacyPolicy } from './views/pages/PrivacyPolicy';
import Contact from './views/pages/Contact';
import SmsNotificationDetail from './views/admin/smsNotifications/SmsNotificationDetail';
import EmployerJobs from './views/employers/EmployerJobs';
import EmployerTenders from './views/employers/EmployerTenders';
import authentificationService from './services/authentificationService';

// import './custom.css'

export default class App extends Component {
  static displayName = App.name;
  constructor(props) {
    super(props);
    this.state = {
      currrentPathName: "/",
      breadCrumInformation: { level1: "", level2: "" },
      bookingHubConnection: null
    };

    this.getPathName = this.getPathName.bind(this);
    this.getBreadCrumInformation = this.getBreadCrumInformation.bind(this);
  }

  componentDidMount() {
    
    var user = authentificationService.getUser();
    if(user !== undefined && user !== null) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    }
    
    axios.interceptors.response.use(function (response) {      
      return response;
    }, function (error) {            
      if (error !== undefined && error.response !== undefined && error.response.status === 401) {        
        if(user !== undefined && user !== null) {
          authentificationService.logout("user");
          window.location = "/admin/signin";
        }
      }
      return Promise.reject(error);
    });

    this.getPathName();

    /* Uncomment this when signalr implementation will be supported
    let hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(api + "/signalr/notification", { skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets })
      .configureLogging(signalR.LogLevel.Information)
      .build();

    hubConnection.start()
      .then(() => console.log('SignalR Started - Connected :D :D :D'))
      .catch(err => console.log('Error connecting SignalR - ' + err));

    hubConnection.on('newMessage', (actionName, messageContent) => {

      var data = JSON.parse(messageContent);
      console.log(data);
    });*/
  }

  getPathName = () => {
    let pathName = window.location.pathname;
    this.setState({ currrentPathName: pathName }, () => {
      this.getBreadCrumInformation(this.state.currrentPathName);
    });
  }

  getBreadCrumInformation = (pathName) => {
    if (pathName.toLowerCase().includes("/users")) {
      this.setState({ breadCrumInformation: { level1: "Utilisateurs", level2: "" } });
    }
    else if (pathName.toLowerCase().includes("/job") && pathName.toLowerCase().includes("/details")) {
      this.setState({ breadCrumInformation: { level1: "Offres d'emploi", level1Path: "/admin/jobs", level2: "123" } });
    }
    else if (pathName.toLowerCase().includes("/job")) {
      this.setState({ breadCrumInformation: { level1: "Offres d'emploi", level2: "" } });
    }
    else if (pathName.toLowerCase().includes("/tender") && pathName.toLowerCase().includes("/details")) {
      this.setState({ breadCrumInformation: { level1: "Appels d'offres", level1Path: "/admin/tenders", level2: "321" } });
    }
    else if (pathName.toLowerCase().includes("/tender")) {
      this.setState({ breadCrumInformation: { level1: "Appels d'offres", level2: "" } });
    }
    else if (pathName.toLowerCase().includes("/ads") && pathName.toLowerCase().includes("/details")) {
      this.setState({ breadCrumInformation: { level1: "Publicité", level1Path: "/admin/ads", level2: "321" } });
    }
    else if (pathName.toLowerCase().includes("/ads")) {
      this.setState({ breadCrumInformation: { level1: "Publicité", level2: "" } });
    }
    else if (pathName.toLowerCase().includes("/smsNotifications") && pathName.toLowerCase().includes("/details")) {
      this.setState({ breadCrumInformation: { level1: "Notification des Sms", level1Path: "/admin/smsNotifications", level2: "321" } });
    }
    else if (pathName.toLowerCase().includes("/smsNotifications")) {
      this.setState({ breadCrumInformation: { level1: "Notification des Sms", level2: "" } });
    }
    else if (pathName.toLowerCase().includes("/employers") && pathName.toLowerCase().includes("/details")) {
      this.setState({ breadCrumInformation: { level1: "Employeurs", level1Path: "/admin/employers", level2: "555" } });
    }
    else if (pathName.toLowerCase().includes("/employers")) {
      this.setState({ breadCrumInformation: { level1: "Employeurs", level2: "" } });
    }
    else if (pathName.toLowerCase().includes("/roles")) {
      this.setState({ breadCrumInformation: { level1: "Rôles", level2: "" } });
    }
    else if (pathName.toLowerCase().includes("/employersSingup")) {
      this.setState({ breadCrumInformation: { level1: "EmployersSingup", level2: "" } });
    }
    else if (pathName.toLowerCase().includes("/candidatesSignup")) {
      this.setState({ breadCrumInformation: { level1: "CandidatesSignup", level2: "" } });
    }
    else if (pathName.toLowerCase().includes("/refreshOperations")) {
      this.setState({ breadCrumInformation: { level1: "RefreshOperations", level2: "" } });
    }
    else if (pathName.toLowerCase().includes("/applications") && pathName.toLowerCase().includes("/details")) {
      this.setState({ breadCrumInformation: { level1: "Applications", level1Path: "/admin/applications", level2: "505" } });
    }
    else if (pathName.toLowerCase().includes("/applications")) {
      this.setState({ breadCrumInformation: { level1: "Applications", level2: "" } });
    }
  }



  render() {
    return (
      this.state.currrentPathName.toLowerCase().includes("/admin") ?
        (
          <AdminLayout breadCrumInformation={this.state.breadCrumInformation}>
            <Route path='/admin/dashboard' component={Dashboard} />
            <Route path='/admin/' component={Dashboard} exact={true} />
            <Route path='/admin/users' component={UserListing} />
            <Route path='/admin/resetPassword/:t' component={ResetPassword} />
            <Route path='/admin/changePassword' component={ChangePassword} />
            <Route path='/admin/signin' component={LoginPage} exact={true} />
            <Route path="/admin/roles/" component={RoleListing} />
            <Route path="/admin/ads/" component={adsListing} exact={true} />
            <Route path="/admin/smsNotifications/" component={smsNotificationListing} exact={true} />
            {/* <Route path="/admin/smsNotification/details/:id" component={SmsNotificationDetail} exact={true} /> */}
            <Route path="/admin/jobs" component={JobListingAdmin} />
            <Route path="/admin/tenders" component={TendersListing} />
            <Route path="/admin/tender/details/:id" component={TenderDetailAdmin} />
            <Route path="/admin/job/details/:id" component={JobDetailAdmin} />
            <Route path="/admin/employers" component={EmployerListing} exact={true} />
            <Route path="/admin/applications/list" component={ApplicationListing} exact={true} />
            <Route path="/admin/applications/details/:id" component={ApplicationDetail} />
            <Route path='/admin/employers/details/:employerId' component={EmployerDetail} />
            <Route path="/admin/candidates" component={CandidateListing} exact={true} />
            <Route path='/admin/ads/details/:id' component={AdsDetail} />
            <Route path='/admin/smsNotification/details/:id' component={SmsNotificationDetail} />
            <Route path="/admin/courses" component={CourseListingAdmin} />
            <Route path="/admin/course/details/:id" component={CourseDetailAdmin} />
            <Route path="/admin/operations" component={RefreshOperation} />
          </AdminLayout>
        )
        :
        (
          <Layout>
            <Route exact path='/' component={Home} />
            <Route path='/jobs/search' component={JobListing} />
            <Route path='/jobs/archive/list' component={JobArchiveListing} />
            <Route path='/jobs/details/:jobNumber' component={JobDetail} />
            {/* <Route path='/jobs/exports/:jobNumber' component={JobExport} /> */}
            <Route path='/jobs/apply/:jobNumber' component={JobPostulation} />
            <Route path='/jobs/archive/details/:jobNumber' component={JobArchiveDetail} />
            <Route path='/jobs/post' component={JobCreate} />
            <Route path='/tenders/search' component={TenderListing} />
            <Route path='/tenders/archive/list' component={TenderArchiveListing} />
            <Route path='/tenders/details/:tenderNumber' component={TenderDetail} />
            <Route path='/tenders/archive/details/:tenderNumber' component={TenderArchiveDetail} />
            <Route path='/courses/search' component={CourseListing} />
            <Route path='/courses/details/:courseNumber' component={CourseDetail} />
            <Route path='/employers/signup' component={EmployerSignup} />
            <Route path='/employers/profile' component={EmployerProfile} />
            <Route path='/employers/resetPassword/:t' component={EmployerResetPassword} />
            <Route path='/employers/changePassword' component={EmployerChangePassword} />
            <Route path='/candidates/signup' component={CandidateSignup} />
            <Route path='/candidates/profile' component={CandidateProfile} />
            <Route path='/candidates/resetPassword/:t' component={CandidateResetPassword} />
            <Route path='/candidates/changePassword' component={CandidateChangePassword} />
            <Route path='/tenders/post' component={TenderCreate} />
            <Route path='/employers/jobs/list/:employerId' component={EmployerJobs} />
            <Route path='/employers/tenders/list/:employerId' component={EmployerTenders} />
            <Route path='/pages/services' component={Services} />
            <Route path='/pages/privacyPolicy' component={PrivacyPolicy} />
            <Route path='/pages/contact' component={Contact} />
            <Route path="/admin/applications" component={ApplicationListing} />
            <Route path="/admin/applications/details/id" component={ApplicationDetail} />
          </Layout>
        )

    );
  }
}
