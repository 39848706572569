import React, { Component  } from 'react'

class CustomGoogleAdsense extends Component {
    
    constructor(props) {
        super(props);
        if (props.location === "") {
            this.state = {
                slot: 'Hello!'
            };
        }
        if (props.location == "Wide1") {
            this.state = {
                slot: '8851456843!'
            };
        }
        else if (props.location == "Wide2") {
            this.state = {
                slot: '5817516233'
            };
        }
        else if(props.location == "Wide3") {
            this.state = {
                slot: '7126389163'
            };
        }
        else if(props.location == "Side1") {
            this.state = {
                slot: '5207142144'
            };
        }
        else if (props.location == "Side2") {
            this.state = {
                slot: '7780995289'
            };
        }
        else if (props.location == "Side3") {
            this.state = {
                slot: '1120557476'
            };
        }
    }

    componentDidMount () {
        if(this.isProductionSite()) {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
    }
    
    isProductionSite (){
        return window.location.hostname.toLowerCase() === "malibaara.com"
    }

    render () {
        return (
            <div className="google-ads">
                {this.isProductionSite() ? (
                    <ins className="adsbygoogle"
                        style={{ display: "block"}}
                        data-ad-format="fluid"
                        data-ad-layout-key="-fb+5w+4e-db+86"
                        data-ad-client="ca-pub-2104723957718137"
                        data-ad-slot={this.state.slot}>
                    </ins>
                    ) : (
                        <div className="google-ads"></div>
                    )}
            </div>
        );
    }
}

export default CustomGoogleAdsense