import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { setUserData } from "../redux/actions/admin/UserActions";
import { withRouter } from "react-router";

class Auth extends Component {
    state = {};

    constructor(props) {
        super(props);

        // Check current token is valid on page load/reload
        this.checkJwtAuth();
    }


    checkJwtAuth = () => {
        let pathname = window.location.pathname;
        if (pathname.toLowerCase().includes("/admin")) {
            let user = localStorage.getItem("malibaara_auth_user");
            if (!user && !pathname.toLowerCase().includes("resetpassword")) {
                this.props.history.push({
                    pathname: "/admin/signin"
                });
            }
        }
    };

    render() {
        const { children } = this.props;
        return <Fragment>{children}</Fragment>;
    }
}

const mapStateToProps = state => ({
    setUserData: PropTypes.func.isRequired,
    login: state.login
});

// export default connect(mapStateToProps,{ setUserData })(Auth);

export default withRouter(connect(mapStateToProps, { setUserData })(Auth));
