import React, { useEffect, useRef } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getCourseList, deleteAttachmentFromCourse, addAttachmentFromCourse, updateCourse } from "../../../redux/actions/CourseActions"
import { CircularProgress } from '@material-ui/core';
import { getEmployerList } from "../../../redux/actions/admin/EmployerActions";
import CustomHtmlEditor from '../../shared/views/CustomHtmlEditor';
import moment from 'moment';
import 'moment/locale/fr';
import CustomAlert from '../../shared/views/CustomAlert';
import { categories } from "../../../views/shared/Helper";
import $ from 'jquery';
import Select from 'react-select';
import validator from 'validator';
import { DropzoneArea } from 'material-ui-dropzone';


export const CourseDetail = (props) => {
    const descriptionHtmlEditor = useRef();
    const {
        getCourseList,
        storeCourse,
        deleteAttachmentFromCourse,
        addAttachmentFromCourse,
        updateCourse = {},
        storeEmployer = {},
        getEmployerList
    } = props;

    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [isUploading, setIsUploading] = React.useState(false);
    const [course, setCourse] = React.useState(null);
    const [courseId, setCourseId] = React.useState("");
    const [attachmentToDelete, setAttachmentToDelete] = React.useState("");
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const closeFileDeleteModal = React.useRef();
    const [isDeletting, setIsDeletting] = React.useState(false);
    const [employersListOptions, setEmployersListOptions] = React.useState([]);
    const [published, setPublished] = React.useState(false);
    const [invalideEmail, setInvalideEmail] = React.useState(false);
    const [validateDscription, setValidateDescription] = React.useState("");
    const [updateNewCourse, setUpdateNewCourse] = React.useState(
        {
            id: "",
            title: "",
            employerId: "",
            startDate: new Date(),
            endDate: new Date(),
            location: "",
            description: "",
            isActive: false,
        });

    useEffect(() => {
        let match = props.match;
        if (match !== undefined) {
            let params = match.params;
            if (params !== undefined && params.id !== undefined) {
                setCourseId(params.id);
            }
        };
        if (storeEmployer.employersList.length > 0 && employersListOptions.length == 0) {
            getEmployersListOptions(storeEmployer.employersList);
        }
        else if (storeEmployer.employersList.length == 0 && employersListOptions.length == 0) {
            getEmployerList(false).then(response => {
                if (response.success) {
                    getEmployersListOptions(response.employersList);

                };
            });
        }
    }, []);

    useEffect(() => {
        if (updateNewCourse.title == ""
            || updateNewCourse.title === undefined
            || updateNewCourse.companyName == ""
            || updateNewCourse.companyName === undefined
            || updateNewCourse.location == ""
            || updateNewCourse.location === undefined
            || validateDscription == ""
            || validateDscription == undefined
        ) {
            setHasMissingValue(true);
        }
        else if (
            // (updateNewCourse?.applicationEmail != "" && !validator.isEmail(updateNewCourse?.applicationEmail))
            updateNewCourse.location?.length > 200
        ) {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);
        }
    }, [updateNewCourse, validateDscription]);

    useEffect(() => {
        if (employersListOptions.length > 0 && isLoading) {
            setIsLoading(false);
        }
    }, [employersListOptions]);

    useEffect(() => {
        if (courseId != "") {
            if (storeCourse.courseList.length == 0) {
                getCourseList(true).then(resp => {
                    let queryResponse = resp.coursesList.find(c => c.id == courseId);
                    if (queryResponse) {
                        setCourse(queryResponse)
                    }
                });
            }
            else if (storeCourse.courseList.length > 0) {
                let queryResponse = storeCourse.courseList.find(c => c.id == courseId);
                if (queryResponse) {
                    setCourse(queryResponse)
                }
            }
        }
    }, [courseId]);

    useEffect(() => {
        if (course !== null) {
            setUpdateNewCourse(course);
            setValidateDescription(course?.description);
            setUpdateNewCourse(prevState => {
                return { ...prevState, companyName: course?.employer?.companyName, employerId: course?.employer?.id }
            });
            setIsLoading(false);
            setCourseId(course.id);
        }

    }, [course]);

    useEffect(() => {
        if (selectedFiles?.length > 0) {
            handleAddAttachments();
        }
    }, [selectedFiles]);

    useEffect(() => {
        var select = $(".basic-select");
        if (select.length > 0) {
            /*eslint-disable no-undef*/
            intializeCustomSelect();
            /*eslint-enable no-undef*/
        }
    }, [isLoading]);

    const handleAddAttachments = () => {
        setIsUploading(true);
        let currentFile = selectedFiles;
        addAttachmentFromCourse(course?.id, currentFile[0]).then(resp => {
            setSelectedFiles(undefined)
            let courseFromStore = resp.coursesList.find(course => course.id == courseId);
            if (courseFromStore != undefined) {
                setCourse(courseFromStore);
                setIsUploading(false);
            }
        })
    };

    const handleUpdateSubmit = () => {
        setIsUpdating(true);
        setPublished(false);
        let course = updateNewCourse;
        course.description = descriptionHtmlEditor.current.getContent();
        updateCourse(course).then(res => {
            setIsUpdating(false);
            setPublished(true);

        })
    };

    const getEmployersListOptions = (employers) => {
        let result = [];
        employers.forEach(employer => {
            result.push({ label: employer.companyName, id: employer.id, value: employer.id });
        });
        setEmployersListOptions(result);
    };

    const handleDeleteSubmit = (e, id, value) => {
        setIsDeletting(true);
        deleteAttachmentFromCourse(id, value).then(resp => {
            let courseFromStore = resp.coursesList.find(course => course.id == courseId);
            if (courseFromStore != undefined) {
                setCourse(courseFromStore);
            }
            setAttachmentToDelete(null);
            closeFileDeleteModal.current.click();
            setIsDeletting(false);
        });
    };

    // Start keydown mechanism
    let onKeydown = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            if (!hasMissingValue) {
                handleUpdateSubmit();
            }
            event.preventDefault();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", onKeydown);
        return () => {
            document.removeEventListener("keydown", onKeydown);
        };
    }, [onKeydown]);
    //End keydown mechanism


    return (
        <>
            <React.Fragment>
                {isLoading ? (
                    <div className="row bg-white pt-4 mt-lg-0">
                        <div className="col-lg-12">
                            <div className="loading-container bg-white">
                                <CircularProgress />
                                <div>Chargement de la formation en cours...</div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="row">
                            <div className="col-lg-12 mt-4 bg-white pt-0 mt-lg-0">
                                <div className="bg-light-2 p-4">
                                    <div className="row mb-4">
                                        <h2 className="mb-0"><span>{course?.title} - {course.courseNumber}</span></h2>
                                    </div>
                                    <div style={{ marginLeft: "-12px" }}>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label>Titre de la formation *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="title"
                                                    value={updateNewCourse.title}
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setUpdateNewCourse(prevState => {
                                                            return { ...prevState, title: val }
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Nom de la société *</label>
                                                <Select
                                                    value={employersListOptions.find(opt => opt?.value == updateNewCourse?.employerId)}
                                                    options={employersListOptions}
                                                    loadingMessage="En cours de chargement"
                                                    onChange={(option) => {
                                                        setUpdateNewCourse(prevState => { return { ...prevState, employerId: option?.value, companyName: option.label } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Email *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="title"
                                                    value={updateNewCourse?.applicationEmail}
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        let isValide = validator.isEmail(e.target.value);
                                                        setUpdateNewCourse(prevState => { return { ...prevState, applicationEmail: val } });
                                                        if (invalideEmail) {
                                                            setInvalideEmail(!isValide);
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        let val = validator.isEmail(e.target.value);
                                                        setInvalideEmail(!val);
                                                    }}
                                                />
                                                {(updateNewCourse.applicationEmail != "" && invalideEmail) && (
                                                    <p style={{ color: "red" }}>Format d'email invalide</p>
                                                )}
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Catégorie *</label>
                                                <Select
                                                    value={categories.find(opt => opt?.value == updateNewCourse.category)}
                                                    options={categories}
                                                    loadingMessage="En cours de chargement"
                                                    onChange={(option) => {
                                                        setUpdateNewCourse(prevState => { return { ...prevState, category: option?.label } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-sm-6 datetimepickers">
                                                <label>Date de début</label>
                                                <div className="input-group date" name="datetimepicker-01" data-target-input="nearest">
                                                    <input
                                                        type="date"
                                                        className="form-control datetimepicker-input"
                                                        value={moment(updateNewCourse?.startDate).format('YYYY-MM-DD')}
                                                        onChange={(e) => {
                                                            let val = e.target?.value;
                                                            setUpdateNewCourse(prevState => {
                                                                return { ...prevState, startDate: val }
                                                            });
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                            <div className="form-group col-sm-6 datetimepickers">
                                                <label>Date de fin</label>
                                                <div className="input-group date" name="datetimepicker-02" data-target-input="nearest">
                                                    <input
                                                        type="date"
                                                        className="form-control datetimepicker-input"
                                                        value={moment(updateNewCourse?.endDate).format('YYYY-MM-DD')}
                                                        onChange={(e) => {
                                                            let val = e.target?.value;
                                                            setUpdateNewCourse(prevState => {
                                                                return { ...prevState, endDate: val }
                                                            });
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Ville *</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="location"
                                                    value={updateNewCourse?.location}
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setUpdateNewCourse(prevState => { return { ...prevState, location: val } });
                                                    }}
                                                />
                                                {updateNewCourse.location?.length > 200 && (
                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                )}
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Description *</label>
                                                <CustomHtmlEditor
                                                    ref={descriptionHtmlEditor}
                                                    initialValue={updateNewCourse?.description}
                                                    onChange={(value, editor) => {
                                                        if (value == "") {
                                                            setValidateDescription("");
                                                        }
                                                        else {
                                                            setValidateDescription(value);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-12 mt-3">
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="accepts-02"
                                                        defaultChecked={updateNewCourse.isActive}
                                                        onClick={(e) => {
                                                            let val = e.target?.checked;
                                                            setUpdateNewCourse(prevState => {
                                                                return { ...prevState, isActive: val }
                                                            });
                                                        }}
                                                    />
                                                    <label className="custom-control-label" htmlFor="accepts-02">Actif</label>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-4">
                                                <button
                                                    className="btn btn-md ml-sm-auto btn-primary"
                                                    onClick={handleUpdateSubmit}
                                                    disabled={isUpdating || hasMissingValue}
                                                >
                                                    Sauvegarder &nbsp; {isUpdating ? <CircularProgress size={15} /> : <span></span>}
                                                </button>
                                            </div>
                                            {published && (
                                                <div className="col-12 mt-4">
                                                    <CustomAlert content="La formation a été modifiée avec succès." />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Mes documents */}
                            <div className="col-lg-12 mt-4 bg-white mt-lg-10">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title" style={{ marginLeft: "12px" }}>
                                            <h4 className="title" style={{ paddingBottom: "15px" }}><span>Images</span></h4>
                                            <p style={{ marginRight: "12px" }}>
                                                Veuillez déposer dans cette section les images de la formation
                                                <strong> en cliquant à l'intérieur de la bande blanche ci-dessous</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div _lpchecked="2" style={{ marginLeft: "12px", marginRight: "20px" }}>
                                    {isUploading ?
                                        (
                                            <div className="loading-container">
                                                <CircularProgress />
                                                <div>Chargement des documents en cours...</div>
                                            </div>
                                        )
                                        :
                                        (
                                            <div className="form-group mb-5">
                                                <DropzoneArea
                                                    clearOnUnmount={true}
                                                    dropzoneText={isUploading ? "Chargement en cours..." : "Glisser ou cliquer ici pour déposer vos documents"}
                                                    showPreviewsInDropzone={false}
                                                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                    showAlerts={false}
                                                    filesLimit={1}
                                                    onChange={(files) => {
                                                        setSelectedFiles(files)
                                                    }}
                                                />
                                            </div>
                                        )}
                                    <table className="table manage-candidates-top mb-4">
                                        <thead>
                                            <tr>
                                                <th>Nom</th>
                                                <th>Date</th>
                                                <th className="action text-right"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <>
                                                {course?.attachments?.map((file, index) => (
                                                    <tr key={index}>
                                                        <td>{file.name}</td>
                                                        <td>{file.dateCreated}</td>
                                                        <td style={{ textAlign: "right" }}>
                                                            <a href={file.url} className="text-primary" target="_blank">
                                                                <i className="fas fa-cloud-download-alt"></i>
                                                            </a>
                                                            <a href="assets/#" className="text-danger" style={{ marginLeft: "10px" }} data-toggle="modal" title="" data-target="#deleteAdsModal" data-original-title="Delete">
                                                                <i className="far fa-trash-alt" onClick={() => setAttachmentToDelete(file)}></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        {/* Start Delete file modale */}
                        <div className="modal fade" id="deleteAdsModal" tabIndex="-1" role="dialog" aria-modal="true" data-backdrop="static" data-keyboard="false">
                            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header p-4">
                                        <h4 className="mb-0 text-center">{"Suppression d'un fichier: " + attachmentToDelete?.name}</h4>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>

                                    <form className="mt-2">
                                        <div className="modal-body">
                                            <div>
                                                <div className="form-row">
                                                    <p>Êtes-vous sûr de vouloir supprimer ce fichier ?</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                data-dismiss="modal"
                                                ref={closeFileDeleteModal}
                                            >
                                                NON
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                disabled={isDeletting}
                                                onClick={(e) => handleDeleteSubmit(e, courseId, attachmentToDelete?.id)}
                                            >
                                                OUI &nbsp; {isDeletting ? <CircularProgress size={15} /> : <span></span>}
                                            </button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                        {/* End Delete file modale */}
                    </>
                )}

            </React.Fragment>
        </>
    )
}

CourseDetail.propTypes = {
    getCourseList: PropTypes.func.isRequired,
    addAttachmentFromCourse: PropTypes.func.isRequired,
    deleteAttachmentFromCourse: PropTypes.func.isRequired,
    updateCourse: PropTypes.func.isRequired,
    storeCourse: PropTypes.object.isRequired,
    storeEmployer: PropTypes.object.isRequired,
    getEmployerList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    getCourseList: PropTypes.func.isRequired,
    addAttachmentFromCourse: PropTypes.func.isRequired,
    deleteAttachmentFromCourse: PropTypes.func.isRequired,
    updateCourse: PropTypes.func.isRequired,
    storeCourse: state.course,
    storeEmployer: state.employer,
    getEmployerList: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, { getCourseList, addAttachmentFromCourse, deleteAttachmentFromCourse, updateCourse, getEmployerList })(CourseDetail)