import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { refrechJobSearchIndex } from "../../../redux/actions/JobActions";
import { refrechTenderSearchIndex } from "../../../redux/actions/TenderActions";
import { refrechCourseSearchIndex } from "../../../redux/actions/CourseActions";
import { clearAdminCache } from "../../../redux/actions/admin/EmployerActions";
// import darkblue from '../../../assets/images/bg/dark-blue.jpg';
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import 'moment/locale/fr';
import siteService from '../../../services/siteService';

let RefreshOperation = (props) => {
    const {
        refrechJobSearchIndex,
        refrechTenderSearchIndex,
        refrechCourseSearchIndex,
        clearAdminCache
    } = props;

    // const [isLoading, setIsLoading] = React.useState(true);
    // const [progressValue, setProgressValue] = React.useState(0);
    const [operationToUpdate, setOperationToUpdate] = React.useState("");
    const [operationToUpdateTitle, setOperationToUpdateTitle] = React.useState("");
    // const [operationsList, setOperationsList] = React.useState([]);
    const closeUpdateOperationModal = React.useRef();

    // refreshProgressValues
    const [refreshJobSearchIndexProgressValue, setRefreshJobSearchIndexProgressValue] = React.useState(0);
    const [refreshTenderSearchIndexProgressValue, setRefreshTenderSearchIndexprogressValue] = React.useState(0);
    const [refreshCourseSearchIndexProgressValue, setRefreshCourseSearchIndexProgressValue] = React.useState(0);
    const [refreshAdsSearchIndexProgressValue, setRefreshAdsSearchIndexProgressValue] = React.useState(0);
    const [refresheEmployerSearchIndexProgressValue, setRefreshEmployerSearchIndexProgressValue] = React.useState(0);
    const [clearAdminCacheProgressValue, setClearAdminCacheProgressValue] = React.useState(0);
    // const [progressValue, setProgressValue] = React.useState(0);
    // const [progressValue, setProgressValue] = React.useState(0);
    const currentSite = siteService.getCurrentSite();

    useEffect(() => {

    }, [])

    let handleRefreshJobSearchIndex = () => {
        // setRefreshJobSearchIndexProgressValue(5);
        closeUpdateOperationModal.current.click();
        switch (operationToUpdate) {
            case "refreshJobSearchIndex":
                setRefreshJobSearchIndexProgressValue(25);
                refrechJobSearchIndex().then(response => {
                    if (response) {

                        setRefreshJobSearchIndexProgressValue(100);
                    }
                })
                break;
            case "refreshTenderSearchIndex":
                setRefreshTenderSearchIndexprogressValue(25);
                refrechTenderSearchIndex().then(response => {
                    if (response) {

                        setRefreshTenderSearchIndexprogressValue(100);
                    }
                })
                break;
            case "refreshCourseSearchIndex":
                setRefreshCourseSearchIndexProgressValue(25);
                refrechCourseSearchIndex().then(response => {
                    if (response) {

                        setRefreshCourseSearchIndexProgressValue(100);
                    }
                })
                break; //
            case "clearAdminCache":
                setClearAdminCacheProgressValue(25);
                clearAdminCache().then(response => {
                    if (response) {

                        setClearAdminCacheProgressValue(100);
                    }
                })
                break;
            case "refreshAdsSearchIndexProgressValue":
                setRefreshAdsSearchIndexProgressValue(25);
                clearAdminCache().then(response => {
                    if (response) {

                        setRefreshAdsSearchIndexProgressValue(100);
                    }
                })
                break;
            case "refresheEmployerSearchIndexProgressValue":
                setRefreshEmployerSearchIndexProgressValue(25);
                clearAdminCache().then(response => {
                    if (response) {

                        setRefreshEmployerSearchIndexProgressValue(100);
                    }
                })
                break;
            default:
                break;
        }

    }


    return (
        <React.Fragment>
            <Helmet>
                <title> Administration | Offres d'emploi  | {currentSite.fullName}</title>
            </Helmet>
            {/* Start operations listing */}
            <div className="row bg-white pt-4 mt-lg-0">
                <div className="col-lg-12 mt-4 mt-lg-0">
                    <div className="row mb-4">
                        <div className="col-md-7 col-sm-5 d-flex align-items-center">
                            <div className="section-title-02 mb-0 ">
                                <h4 className="mb-0" style={{ marginLeft: "10px" }}>Opérations</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="user-dashboard-info-box table-responsive mt-1 bg-white">
                                <table className="table manage-candidates-top mb-4">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th className="action text-right"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="candidates-list">
                                            <td>
                                                Rafraichir la liste des offres d'emploi sur le site public
                                            </td>
                                            <td>
                                                <progress value={refreshJobSearchIndexProgressValue} max="100"> {refreshJobSearchIndexProgressValue}% </progress>
                                            </td>
                                            <td>
                                                <ul className="list-unstyled mb-0 d-flex justify-content-end">
                                                    <li>
                                                        <a
                                                            href="#confirmRefresh"
                                                            className="text-success"
                                                            data-toggle="modal"
                                                            title=""
                                                            data-original-title="Edit"
                                                            onClick={(e) => {
                                                                setOperationToUpdate("refreshJobSearchIndex")
                                                                setOperationToUpdateTitle("Rafraichir la liste des offres d'emploi sur le site public")
                                                            }}
                                                        >
                                                            <i className="fas fa-play"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr className="candidates-list">
                                            <td>
                                                Rafraichir la liste des appels d'offre sur le site public
                                            </td>
                                            <td>
                                                <progress value={refreshTenderSearchIndexProgressValue} max="100"> {refreshTenderSearchIndexProgressValue}% </progress>
                                            </td>
                                            <td>
                                                <ul className="list-unstyled mb-0 d-flex justify-content-end">
                                                    <li>
                                                        <a
                                                            href="#confirmRefresh"
                                                            className="text-success"
                                                            data-toggle="modal"
                                                            title=""
                                                            data-original-title="Edit"
                                                            onClick={(e) => {
                                                                setOperationToUpdate("refreshTenderSearchIndex")
                                                                setOperationToUpdateTitle("Rafraichir la liste des appels d'offre sur le site public")
                                                            }}
                                                        >
                                                            <i className="fas fa-play"></i></a></li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr className="candidates-list">
                                            <td>
                                                Rafraichir la liste des formations sur le site public
                                            </td>
                                            <td>
                                                <progress value={refreshCourseSearchIndexProgressValue} max="100"> {refreshCourseSearchIndexProgressValue}% </progress>
                                            </td>
                                            <td>
                                                <ul className="list-unstyled mb-0 d-flex justify-content-end">
                                                    <li>
                                                        <a
                                                            href="#confirmRefresh"
                                                            className="text-success"
                                                            data-toggle="modal"
                                                            title=""
                                                            data-original-title="Edit"
                                                            onClick={(e) => {
                                                                setOperationToUpdate("refreshCourseSearchIndex")
                                                                setOperationToUpdateTitle("Rafraichir la liste des formations sur le site public")
                                                            }}
                                                        >
                                                            <i className="fas fa-play"></i></a></li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr className="candidates-list">
                                            <td>
                                                Rafraichir la liste des CVs sur le site public
                                            </td>
                                            <td>
                                                <progress value={operationToUpdate === "3" ? 0 : 0} max="100"> {0}% </progress>
                                            </td>
                                            <td>
                                                <ul className="list-unstyled mb-0 d-flex justify-content-end">
                                                    <li>
                                                        <a
                                                            href="#confirmRefresh"
                                                            className="text-success"
                                                            data-toggle="tooltip"
                                                            title=""
                                                            data-original-title="Edit"
                                                            onClick={(e) => {
                                                                setOperationToUpdate("")
                                                                setOperationToUpdateTitle("Rafraichir la liste des CVs sur le site public")
                                                            }}
                                                        >
                                                            <i className="fas fa-play"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr className="candidates-list">
                                            <td>
                                                Rafraichir la liste des publicités sur le site public
                                            </td>
                                            <td>
                                                <progress value={refreshAdsSearchIndexProgressValue} max="100"> {refreshAdsSearchIndexProgressValue}% </progress>
                                            </td>
                                            <td>
                                                <ul className="list-unstyled mb-0 d-flex justify-content-end">
                                                    <li>
                                                        <a
                                                            href="#confirmRefresh"
                                                            className="text-success"
                                                            data-toggle="modal"
                                                            title=""
                                                            data-original-title="Edit"
                                                            onClick={(e) => {
                                                                setOperationToUpdate("refreshAdsSearchIndexProgressValue")
                                                                setOperationToUpdateTitle("Rafraichir la liste des publicités sur le site public")
                                                            }}
                                                        >
                                                            <i className="fas fa-play"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr className="candidates-list">
                                            <td>
                                                Rafraichir la liste des employeurs de confiance sur le site public
                                            </td>
                                            <td>
                                                <progress value={refresheEmployerSearchIndexProgressValue} max="100"> {refresheEmployerSearchIndexProgressValue}% </progress>
                                            </td>
                                            <td>
                                                <ul className="list-unstyled mb-0 d-flex justify-content-end">
                                                    <li>
                                                        <a
                                                            href="#confirmRefresh"
                                                            className="text-success"
                                                            data-toggle="modal"
                                                            title=""
                                                            data-original-title="Edit"
                                                            onClick={(e) => {
                                                                setOperationToUpdate("refresheEmployerSearchIndexProgressValue")
                                                                setOperationToUpdateTitle("Rafraichir la liste des employeurs de confiance sur le site public")
                                                            }}
                                                        >
                                                            <i className="fas fa-play"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr className="candidates-list">
                                            <td>
                                                Vider la cache du serveur
                                            </td>
                                            <td>
                                                <progress value={clearAdminCacheProgressValue} max="100"> {clearAdminCacheProgressValue}% </progress>
                                            </td>
                                            <td>
                                                <ul className="list-unstyled mb-0 d-flex justify-content-end">
                                                    <li><a
                                                        href="#confirmRefresh"
                                                        className="text-success"
                                                        data-toggle="modal"
                                                        title=""
                                                        data-original-title="Edit"
                                                        onClick={(e) => {
                                                            setOperationToUpdate("clearAdminCache")
                                                            setOperationToUpdateTitle("Vider la cache du serveur")
                                                        }}
                                                    >
                                                        <i className="fas fa-play"></i></a></li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr className="candidates-list">
                                            <td>
                                                Rafraichir la cache de Cloudflare
                                            </td>
                                            <td>
                                                <progress value={operationToUpdate == "5" ? 0 : 0} max="100"> {0}% </progress>
                                            </td>
                                            <td>
                                                <ul className="list-unstyled mb-0 d-flex justify-content-end">
                                                    <li>
                                                        <a
                                                            href="javascript:void(0)"
                                                            className="text-success"
                                                            data-toggle="tooltip"
                                                            title=""
                                                            data-original-title="Edit"
                                                        >
                                                            <i className="fas fa-play"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* End operations listing */}

            {/* Start Refresh Operation Modal */}
            <div className="modal fade" id="confirmRefresh" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header p-4">
                            <h4 className="mb-0 text-center">{operationToUpdateTitle}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <form className="mt-2">
                            <div className="modal-body">
                                <div>
                                    <div className="form-row">
                                        <p>Voulez vous vraiment rafraichir?</p>

                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    className="btn btn-primary"
                                    ref={closeUpdateOperationModal}
                                >
                                    NON
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={handleRefreshJobSearchIndex}
                                >
                                    OUI
                                    {/* &nbsp; {isDeletting ? <CircularProgress size={15} /> : <span></span>} */}
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
            {/* End Refresh Operation Modal */}
        </React.Fragment >
    );

}
RefreshOperation.propTypes = {
    refrechJobSearchIndex: PropTypes.func.isRequired,
    refrechTenderSearchIndex: PropTypes.func.isRequired,
    refrechCourseSearchIndex: PropTypes.func.isRequired,
    clearAdminCache: PropTypes.func.isRequired,
    storeJob: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    storeJob: state.job,
});

export default withRouter(connect(mapStateToProps, { refrechJobSearchIndex, refrechTenderSearchIndex, refrechCourseSearchIndex, clearAdminCache })(RefreshOperation));