import React, { Component, useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { courseSearch } from "../../redux/actions/CourseActions";
import { getEmployerList } from "../../redux/actions/admin/EmployerActions"
import { CircularProgress } from '@material-ui/core';
import defaultBanner from '../../../assets/images/bg/default-banner.jpg';
import { Link, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Sidebar from '../shared/views/Sidebar';
import createAccountImage from '../../../assets/images/pourquoi-creer-un-compte.jpg';
import CustomGoogleAdsense from '../shared/views/CustomGoogleAdsense';
import siteService from '../../services/siteService';


export const CourseListing = (props) => {
    const { courseSearch} = props;

    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState(true);
    const [facetsLoading, setFacetsLoading] = React.useState(false);
    const [courseSearchResult, setCourseSearchResult] = React.useState(null);
    const [displayingCourseList, setDisplayingCourseList] = React.useState([]);
    const [selectedRootFacetName, setSelectedRootFacetName] = React.useState([]);
    const [filterValues, setFilterValues] = React.useState([]);
    const [facetsList, setFacetsList] = React.useState([]);
    const [sortedBy, setSortedBy] = React.useState("postedDate");
    const location = useLocation();
    const currentSite = siteService.getCurrentSite();  
      const [searchTerm, setSearchTerm] = React.useState('');


    useEffect(() => {
        if (location.pathname == "/courses/search" && (location.search == null || location.search == "")) {
            setDisplayingCourseList(courseSearchResult?.documents);
            setFilterValues([]);
            setFacetsLoading(true);
            setTimeout(() => {
                setFacetsLoading(false);
            }, 200);

        }
    }, [location]);

    useEffect(() => {
        if (courseSearchResult == null) {
            courseSearch(false).then(resp => {
                setCourseSearchResult(resp);
                setDisplayingCourseList(resp.documents);
                getFacetesList(resp.rootFacets);
                setIsLoading(false);
            });
        }

        var queryStringParsed = queryString.parse(window.location.search);
        var queryStringCategory = queryStringParsed.category;
        var queryStringCompanyId = queryStringParsed.companyId;
        var queryStringType = queryStringParsed.type;
        var queryStringLocation = queryStringParsed.location;
        if (queryStringCategory != null || queryStringCompanyId != null || queryStringType != null || queryStringLocation != null) {
            let result = [];
            if (queryStringCategory != null) {
                setSelectedRootFacetName([...selectedRootFacetName, "Category"]);
                let array = queryStringCategory.split('|');
                result = result.concat(array);
            }
            if (queryStringCompanyId != null) {
                setSelectedRootFacetName([...selectedRootFacetName, "CompanyId"]);
                let array = queryStringCompanyId.split('|');
                result = result.concat(array);
            }
            if (queryStringType != null) {
                setSelectedRootFacetName([...selectedRootFacetName, "Type"]);
                let array = queryStringType.split('|');
                result = result.concat(array);
            }
            if (queryStringLocation != null) {
                setSelectedRootFacetName([...selectedRootFacetName, "Location"]);
                let array = queryStringLocation.split('|');
                result = result.concat(array);
            }
            const unique = [...new Set(result.map(item => item))];            
            setFilterValues(unique);
        }
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        if (courseSearchResult != null && isLoading == true) {

            var queryStringParsed = queryString.parse(window.location.search);
            var queryStringCategory = queryStringParsed.category;
            var queryStringCompanyId = queryStringParsed.companyId;
            var queryStringType = queryStringParsed.type;
            var queryStringLocation = queryStringParsed.location;
            if (queryStringCategory != null || queryStringCompanyId != null || queryStringType != null || queryStringLocation != null) {
                let result = [];
                let rootresult = [];
                if (queryStringCategory != null) {
                    rootresult.push("Category");
                    let array = queryStringCategory.split('|');
                    result = result.concat(array);
                }
                if (queryStringCompanyId != null) {
                    rootresult.push("CompanyId");
                    let array = queryStringCompanyId.split('|');
                    result = result.concat(array);
                }
                if (queryStringType != null) {
                    rootresult.push("Type");
                    let array = queryStringType.split('|');
                    result = result.concat(array);
                }
                if (queryStringLocation != null) {
                    rootresult.push("Location");
                    let array = queryStringLocation.split('|');
                    result = result.concat(array);
                }
                const unique = [...new Set(result.map(item => item))];
                setFilterValues(unique);
                setSelectedRootFacetName(rootresult);
            }

            setIsLoading(false);
        }
        // if (Object.keys(storeCourse.courseSearchResult).length !== 0 && storeCourse.courseSearchResult.constructor == Object && courseSearchResult == null) {
        //     setCourseSearchResult(storeCourse.courseSearchResult);
        //     setDisplayingCourseList(storeCourse.courseSearchResult.documents);
        // }
    }, [courseSearchResult]);

    useEffect(() => {
        if (filterValues && filterValues.length > 0) {
            let documents = courseSearchResult?.documents;
            let result = [];
            selectedRootFacetName.forEach(rootName => {
                if (rootName == "Category") {
                    filterValues.forEach(element => {
                        let courses = documents?.filter(doc => doc.category == element);
                        if (courses != null) {
                            result = result.concat(courses);
                        }
                    });
                }
                else if (rootName == "Location") {
                    filterValues.forEach(element => {
                        let courses = documents?.filter(doc => doc.location.toLowerCase().includes(element.toLowerCase()));
                        if (courses != null) {
                            result = result.concat(courses);
                        }
                    });
                }
                else if (rootName == "Type") {
                    filterValues.forEach(element => {
                        let courses = documents?.filter(doc => doc.type.toLowerCase() == element.toLowerCase());
                        if (courses != null) {
                            result = result.concat(courses);
                        }
                    });
                }
                else if (rootName == "CompanyId") {
                    filterValues.forEach(element => {
                        let courses = documents?.filter(doc => doc.companyId.toLowerCase() == element.toLowerCase());
                        if (courses != null) {
                            result = result.concat(courses);
                        }
                    });
                }
            });
            let distinctArray = [...new Map(result.map(item => [item["courseNumber"], item])).values()];

            setDisplayingCourseList(distinctArray);
            window.scrollTo(0, 0);
        }
        else if (!filterValues || filterValues?.length == 0) {
            let documents = courseSearchResult?.documents;
            setDisplayingCourseList(documents);
            window.scrollTo(0, 0);
        }

    }, [filterValues]);

    const clearFilterValues = () => {
        setFilterValues([]);
        reloadFacets();
        history.push(`/courses/search`);
    }

    const reloadFacets = () => {
        setFacetsLoading(true);
        setTimeout(() => {
            setFacetsLoading(false);
        }, 200);
    }

    const deleteFilterValue = (e, value) => {
        let filterValuesCopy = [...filterValues];
        let values = filterValuesCopy.filter(item => item != value);
        setFilterValues(values);
        reloadFacets();
    }

    let getFacetesList = (rootFacets) => {
        if (rootFacets?.length > 0) {
            let result = [];
            rootFacets.forEach(root => {
                result = result.concat(root?.facets);
            });
            setFacetsList(result);
        }
    }

    let updateUrl = (parameterName, value, isChecked) => {
        var queryStringParsed = queryString.parse(window.location.search);
        var queryStringCategory = queryStringParsed.category;
        var queryStringCompanyId = queryStringParsed.companyId;
        var queryStringType = queryStringParsed.type;
        var queryStringLocation = queryStringParsed.location;

        let newValue = '';
        switch (parameterName) {
            case "Category":
                newValue = updateSplitedValue(queryStringCategory, value, isChecked);
                var finalQs = queryString.stringify({ category: newValue, type: queryStringType, location: queryStringLocation, companyId: queryStringCompanyId }, {
                    skipNull: true
                });
                history.push(`/courses/search?${finalQs}`);
                break;
            case "CompanyId":
                newValue = updateSplitedValue(queryStringCompanyId, value, isChecked);
                if (newValue != '') {
                    var finalQs = queryString.stringify({ category: queryStringCategory, type: queryStringType, location: queryStringLocation, companyId: newValue }, {
                        skipNull: true
                    });
                    history.push(`/courses/search?${finalQs}`);
                }
                break;
            case "Type":
                newValue = updateSplitedValue(queryStringType, value, isChecked);
                if (newValue != '') {
                    var finalQs = queryString.stringify({ category: queryStringCategory, type: newValue, location: queryStringLocation, companyId: queryStringCompanyId }, {
                        skipNull: true
                    });
                    history.push(`/courses/search?${finalQs}`);
                }
                break;
            case "Location":
                newValue = updateSplitedValue(queryStringLocation, value, isChecked);
                if (newValue != '') {
                    var finalQs = queryString.stringify({ category: queryStringCategory, type: queryStringType, location: newValue, companyId: queryStringCompanyId }, {
                        skipNull: true
                    });
                    history.push(`/courses/search?${finalQs}`);
                }
                break;
            default:
                break;
        }
    }

    let updateSplitedValue = (currentValue, value, isChecked) => {
        if (isChecked) {
            let result = '';
            if (currentValue == undefined || currentValue == "undefined") {

                return value;
            }
            else if (currentValue?.split('|')?.length > 0) {
                result = currentValue.concat('|' + value);
                return result;
            }
            else {
                result = currentValue?.concat(value);
                return result;
            }
        }
        else {
            let result = '';
            // if (currentValue == null) {
            //     return result;
            // }
            // else 
            if (currentValue.includes('|' + value)) {
                result = currentValue.replace('|' + value, '');
                return result != '' ? result : null;
            }
            else if (currentValue.includes(value + '|')) {
                result = currentValue.replace(value + '|', '');
                return result != '' ? result : null;
            }
            else {
                result = currentValue.replace(value, '');
                return result != '' ? result : null;
            }
        }
    }

    


    const handleSearchBox = () => {
        courseSearch(false, searchTerm).then(resp => {
                setIsLoading(true);
                setCourseSearchResult(resp);
                setDisplayingCourseList(resp.documents);
                getFacetesList(resp.rootFacets);
                setIsLoading(false);
            });
        
        
    }
   
    const handleEnterPress = (e) => {        
        if(e.key === 'Enter')
        {
            handleSearchBox();
        }
    }


    return (

        <React.Fragment>
            {isLoading ? (
                <div className="loading-container">
                    <CircularProgress />
                    <div>Chargement des formations en cours...</div>
                </div>
            ) : (
                <><Helmet>
                    <title> Formations | {currentSite.fullName}</title>
                </Helmet>
                    <div className="header-inner" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${defaultBanner})` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h2 style={{ color: "white" }}>Formations</h2>
                                    <ol className="breadcrumb mb-0 p-0">
                                        <li className="breadcrumb-item"><Link to={"/"}>Accueil</Link></li>
                                        <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> Formations </span></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="space-ptb bg-light" style={{ paddingTop: "40px" }}>
                        <div className="container  user-dashboard-info-box">
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="job-filter mb-4 d-sm-flex align-items-center">
                                        <div className="job-alert-bt"> <h6 className="mb-0">Affichage de <span className="text-primary">{displayingCourseList?.length} formations</span></h6> </div>
                                        <div className="job-shortby ml-sm-auto d-flex align-items-center">
                                            <form className="form-inline">
                                                <div className="search">
                                                    <i className="fas fa-search" onClick={handleSearchBox} ></i>
                                                    <input type="text" className="form-control"
                                                        placeholder="Tapez un mot clefs..."
                                                        value={searchTerm}
                                                        onKeyDown={(e) => handleEnterPress(e)}
                                                        onChange={(e) => setSearchTerm(e.target.value)}
                                                        />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    {filterValues?.length > 0 && (
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="job-filter-tag">
                                                    <ul className="list-unstyled">
                                                        {filterValues.map((item, index) => (
                                                            <li>
                                                                <a href="javascript:void(0);" onClick={(e) => {
                                                                    deleteFilterValue(e, item);
                                                                    let facet = facetsList?.find(fa => fa?.value == item);
                                                                    if (facet) {
                                                                        updateUrl(facet?.rootFacetName, facet?.value, false);
                                                                    }
                                                                }}>
                                                                    {facetsList?.find(fa => fa?.value == item)?.diplayName} &nbsp; <i className="fas fa-times-circle"></i>
                                                                </a>
                                                            </li>
                                                        ))}
                                                        <li>
                                                            <a href="javascript:void(0);" className="filter-clear" onClick={clearFilterValues}>
                                                                Réinitialiser la recherche &nbsp; <i className="fas fa-redo-alt"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div>
                                        {displayingCourseList?.sort((a, b) => sortedBy == "endDate" ? (a.endDate < b.endDate ? 1 : -1) : (a.postedDate < b.postedDate ? 1 : -1))?.map((course, index) => (
                                            <div className="job-list border-bottom" key={course.courseNumber}>
                                                <div className="job-list-logo" style={{ flex: "0 0 375px", height: "unset", width: "unset" }}>
                                                    <Link to={"/courses/details/" + course.courseNumber}><img className="img-fluid" src={course.imageUrl != "" ? course.imageUrl : "assets/images/default-course.png"} alt="" /></Link>
                                                </div>
                                                <div className="job-list-details">
                                                    <div className="job-list-info">
                                                        <div className="job-list-title">
                                                            <h5 className="mb-0"><Link to={"/courses/details/" + course.courseNumber}>{course.title}</Link></h5>
                                                        </div>
                                                        <div className="job-list-option">
                                                            <ul className="list-unstyled">
                                                                <li><i className="fas fa-envelope pr-1"></i>Email: {course.companyEmailContact}</li>
                                                                <li><i className="fas fa-phone pr-1"></i>Tel: {course.companyPhoneNumber1Contact}</li>
                                                            </ul>
                                                        </div>
                                                        <div style={{ textAlign: "justify", margin: "10px 0px 10px 0px" }}>
                                                            {course.shortDescription}
                                                        </div>
                                                        <div className="job-list-option">
                                                            <ul className="list-unstyled">
                                                                <li>
                                                                    <span>Par </span>
                                                                    <a href={"javascript:void(0)"}
                                                                        onClick={(e) => {
                                                                            setFilterValues([course.companyId]);
                                                                            setSelectedRootFacetName(["CompanyId"]);
                                                                            updateUrl("CompanyId", course.companyId, true);
                                                                            setFacetsLoading(true);
                                                                            setTimeout(() => {
                                                                                setFacetsLoading(false);
                                                                            }, 200);
                                                                        }}
                                                                    >
                                                                        {course.companyName}
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <CustomGoogleAdsense location="Wide2" />
                                </div>
                                <div className="col-lg-3">
                                    <div className="sidebar">
                                        <Sidebar />
                                        {courseSearchResult?.rootFacets.map((rootFacet, index) => (
                                            <div className="widget" key={rootFacet.name}>
                                                <div className="widget-title widget-collapse">
                                                    <h6>{rootFacet.displayName}</h6>
                                                    <a className="ml-auto" data-toggle="collapse" href={`#${rootFacet.name}`} role="button" aria-expanded="false" aria-controls={rootFacet.name}> <i className="fas fa-chevron-down"></i> </a>
                                                </div>
                                                <div className="collapse show" id={rootFacet.name}>
                                                    {facetsLoading ?
                                                        (
                                                            <></>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <div className="widget-content">
                                                                    {rootFacet.facets.map((facet, index2) => (
                                                                        <div className="custom-control custom-checkbox" key={facet.value}>
                                                                            <input
                                                                                type="checkbox"
                                                                                className="custom-control-input"
                                                                                name={facet.diplayName} id={facet.value}
                                                                                defaultChecked={filterValues.some(item => item == facet.value)}
                                                                                value={facet.value}
                                                                                onChange={(event) => {
                                                                                    if (event.target.checked) {
                                                                                        setFilterValues([...filterValues, event.target?.value]);
                                                                                        setSelectedRootFacetName([...selectedRootFacetName, rootFacet?.name]);
                                                                                    }
                                                                                    else {
                                                                                        let filterValuesCopy = [...filterValues];
                                                                                        let values = filterValuesCopy.filter(item => item != event.target.value);
                                                                                        setFilterValues(values);

                                                                                        let selectedRootFacetNameCopy = [...selectedRootFacetName];
                                                                                        let values2 = selectedRootFacetNameCopy.filter(item => item != selectedRootFacetName);
                                                                                        setSelectedRootFacetName(values2);
                                                                                    }
                                                                                    updateUrl(rootFacet?.name, event.target?.value, event.target.checked);
                                                                                }}
                                                                            />
                                                                            <label
                                                                                className="custom-control-label"
                                                                                style={{ width: "100%" }}
                                                                                htmlFor={facet.value}
                                                                            >
                                                                                {facet.diplayName}
                                                                                <span className="facet-value-count">{facet.count}</span>
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                        <div className="widget border-0">
                                            <div className="jobster-company-view">
                                            <ul className="list-unstyled">
                                                    <li>
                                                        <div className="widget-box">
                                                            <div className="d-flex">
                                                                <div>
                                                                    <Link to={"/candidates/signup"}>
                                                                        <img className="img-fluid" src={createAccountImage} alt="" />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <CustomGoogleAdsense location="Side1" />
                                        {/* <CustomGoogleAdsense location="Side2" />
                                       <CustomGoogleAdsense location="Side3" /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>

            )}
        </React.Fragment>
    )
}

CourseListing.propTypes = {
    courseSearch: PropTypes.func.isRequired,
    storeCourse: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    courseSearch: PropTypes.func.isRequired,
    
});

export default connect(mapStateToProps, { courseSearch })(CourseListing)