import axios from "axios";
import { api } from "../../../services/apiHelpers";



export const GET_DASHBOARD_TILES = "GET_DASHBOARD_TILES";
export const GET_DASHBOARD_CHART = "GET_DASHBOARD_CHART";
export const DASHBOARD_ACTION_ERROR = "DASHBOARD_ACTION_ERROR";


export const getDashboardTiles = () => dispatch => {
    return new Promise((resolve, reject) => {
        axios.get(api + "/Admin/dashboard/tiles").then(res => {
            if (res.data) {
                dispatch({
                    type: GET_DASHBOARD_TILES,
                    data: res.data
                });
                resolve(res.data);
            }
        }).catch(error => {
            return dispatch({
                type: DASHBOARD_ACTION_ERROR,
                data: error
            });
        });

    })

};

export const getDashboardChart = () => dispatch => {

    return new Promise((resolve, reject) => {
        axios.get(api + "/Admin/dashboard/jobs/chart").then(res => {
            if (res.data) {
                dispatch({
                    type: GET_DASHBOARD_CHART,
                    data: res.data
                });
                resolve(res.data);
            }
        }).catch(error => {
            return dispatch({
                type: DASHBOARD_ACTION_ERROR,
                data: error
            });
        });

    })

};