import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { loginCandidate, forgotPassword } from "../../redux/actions/admin/CandidateActions";
import { loginEmployer, forgotedPassword } from "../../redux/actions/admin/EmployerActions";
import { CircularProgress } from '@material-ui/core';
import CustomAlert from '../../views/shared/views/CustomAlert';
import { withRouter } from "react-router";
import localStorageService from '../../services/localStorageService';
import authentificationService from '../../services/authentificationService';
import $ from 'jquery';
import siteService from '../../services/siteService';

let Footer = (props) => {

  const {
    loginCandidate,
    loginEmployer,
    forgotPassword,
    forgotedPassword
  } = props;

  const currentSite = siteService.getCurrentSite();
  const closeLoginModal = React.useRef();

  const [hasMissingValue, setHasMissingValue] = React.useState(true);
  const [candidateForgotPassword, setCandidateForgotPassword] = React.useState(false);
  const [employerForgotPassword, setEmployerForgotPassword] = React.useState(false);
  const [isConnecting, setIsConnecting] = React.useState(false);
  const [currrentPathName, setCurrrentPathName] = React.useState("/");
  const [currentCandidate, setCurrentCandidate] = React.useState(null);
  const [currentEmployer, setCurrentEmployer] = React.useState(null);
  const [accountType, setAccountType] = React.useState("candidate");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [candidateLoginFailed, setCandidateLoginFailed] = React.useState(false);
  const [employerloginFailed, setEmployerLoginFailed] = React.useState(false);
  const [loginData, setLoginData] = React.useState(
    {
      email: "",
      password: "",
    });

  useEffect(() => {
    getPathName();
    validateLoginData();
    let candidate = localStorageService.getItem("malibaara_auth_candidate");
    let employer = localStorageService.getItem("malibaara_auth_employer");
    if (candidate) {
      setCurrentCandidate(candidate);
    }
    if (employer) {
      setCurrentEmployer(employer);
    }

  }, []);

  useEffect(() => {
    if (candidateForgotPassword === true || employerForgotPassword === true){
      if (loginData == null ||
        loginData.email == null ||
        loginData.email == ""
      ) {
        setHasMissingValue(true);
      }
      else {
        setHasMissingValue(false);
      }
    }
    else if (loginData == null ||
      loginData.email == null ||
      loginData.email == "" ||
      loginData.password == null ||
      loginData.password == ""
    ){      
        setHasMissingValue(true);      
    }
    else {
      setHasMissingValue(false);
    }
  }, [loginData])

  // useEffect(() => {
  //   validateLoginData();
  // }, [loginData]);


  let getPathName = () => {
    let pathName = window.location.pathname;
    setCurrrentPathName(pathName);
  }

  let handleLoginCandidate = () => {
    setIsConnecting(true);
    setCandidateLoginFailed(false);
    loginCandidate(loginData).then(data => {
      setIsConnecting(false);
      if (data.success) {
        closeLoginModal.current.click();
        window.location = "candidates/profile";
      }
      else {
        setCandidateLoginFailed(true);
        props.history.push({
          pathname: "/"
        });
      }
    });
  }

  let handleLoginEmployer = () => {
    setIsConnecting(true);
    setEmployerLoginFailed(false);
    loginEmployer(loginData).then(data => {
      setIsConnecting(false);
      if (data.success) {
        closeLoginModal.current.click();
        window.location = "employers/profile";
      }
      else {
        setEmployerLoginFailed(true);
        props.history.push({
          pathname: "/"
        });
      }
    });
  }

  let handleCandidateForgotPassword = (email) => {
    setIsConnecting(true);
    setCandidateLoginFailed(false);
    forgotPassword({ email: email }).then(data => {
      setIsConnecting(false);
      if (data) {
        setEmployerForgotPassword(false);
        setAlertMessage("Un lien vous a été envoyé à l'adresse: " + email + ". Cliquez sur le bouton <strong>Réinitialiser mon mot de passe et suivez les instructions</strong>")
        setAlertType("alert-success");
        // closeLoginModal.current.click();
      }
      else {
        setAlertMessage("L'email est incorrect.");
        setAlertType("alert-danger");
      }
    });
  };

  let handleEmployerForgotPassword = (email) => {
    setIsConnecting(true);
    setEmployerLoginFailed(false);
    forgotedPassword({ email: email }).then(data => {
      setIsConnecting(false);
      if (data) {
        setCandidateForgotPassword(false);
        setAlertMessage("Un lien vous a été envoyé à l'adresse: " + email + ". Cliquez sur le bouton <strong>Réinitialiser mon mot de passe et suivez les instructions</strong>")
        setAlertType("alert-success");
        // closeLoginModal.current.click();
      }
      else {
        setAlertMessage("L'email est incorrect.");
        setAlertType("alert-danger");
      }
    });
  }

  let validateLoginData = () => {
    


      // if (loginData == null ||
      //   loginData.email == null ||
      //   loginData.email == ""
      // ) {
      //   setHasMissingValue(true);
      // }
      // else {
      //   if (loginData.password == null || loginData.password == "") {
      //     if (candidateForgotPassword || employerForgotPassword) {
      //       setHasMissingValue(true);
      //     }
      //     else {
      //       if (loginData.password == null || loginData.password == "") {
      //         if (employerForgotPassword || candidateForgotPassword) {
      //           setHasMissingValue(false);
      //         }
      //         else {
      //           setHasMissingValue(true);
      //         }
      //       }
      //     }
      //   }
      //   else {
      //     setHasMissingValue(false);
      //   }
      // }
    
  }

  // Start keydown mechanism
  let onKeydown = event => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      let att = $('#loginAndForgotPasswordModal').attr('class');
      if (att && att.includes("show")) {
        if (!hasMissingValue) {
          setIsConnecting(true);
          if (accountType == "candidate") {
            handleLoginCandidate();
          }
          else if (accountType == "employer") {
            handleLoginEmployer();
          }
        }
      }
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeydown);
    return () => {
      document.removeEventListener("keydown", onKeydown);
    };
  }, [onKeydown]);
  //End keydown mechanism

  return (
    <>
      {
        currrentPathName.toLowerCase().includes("/admin") ? (
          <></>
        ) : (
          <>
            <footer className="footer bg-light-3 pt-5">
              <div className="container mb-5">
                <div className="row">
                  <div className="col-lg-3 col-md-6 mt-lg-0">
                    <div className="footer-contact-info">
                      <h5 className="text-dark mb-4">Contactez-Nous</h5>
                      <ul className="list-unstyled mb-0">
                        <li> <i className="fas fa-map-marker-alt text-primary"></i><span>{currentSite.address}</span> </li>
                        <li> <i className="fas fa-mobile-alt text-primary"></i><span><a className="text-color" href={`tel:${currentSite.phoneNumber1}`}>{currentSite.phoneNumber1}</a> {currentSite.phoneNumber2 && (<> / <a className="text-color" href={`tel:${currentSite.phoneNumber2}`}>{currentSite.phoneNumber2}</a></>)}</span> </li>
                        <li> <i className="far fa-envelope text-primary"></i><span><a className="text-color" href={`mailto:${currentSite.emailAddress}`}>{currentSite.emailAddress}</a></span> </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="footer-link">
                      <h5 className="text-dark mb-4">Candidats</h5>
                      <ul className="list-unstyled">
                        <li><a href="/jobs/search">Trouver un emploi</a></li>
                        <li><a href="/candidates/signup">Créer un compte</a></li>
                        <li><a href="assets/#" data-toggle="modal" data-target="#loginAndForgotPasswordModal">Se connecter à son compte</a></li>
                        <li>
                          {currentCandidate ?
                            (
                              <a href="/candidates/profile">Mettre à jour mon CV</a>
                            ) :
                            (
                              <a href="javascript:void(0)" data-toggle="modal" data-target="#loginAndForgotPasswordModal">Mettre à jour mon CV</a>
                            )}
                        </li>
                        <li><a href="assets/login.html">Conseils & Astuces</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-4 mt-md-0">
                    <div className="footer-link">
                      <h5 className="text-dark mb-4">Employeurs</h5>
                      <ul className="list-unstyled">
                        <li><a href="/tenders/post">Déposer une offre</a></li>
                        <li>
                          {currentEmployer ?
                            (
                              <a href="/employers/resumes">Trouver un candidat</a>
                            ) :
                            (
                              <a href="javascript:void(0)" data-toggle="modal" data-target="#loginAndForgotPasswordModal">Trouver un candidat</a>
                            )}
                        </li>
                        <li><a href="/employers/signup">Créer un compte</a></li>
                        <li><a href="assets/#" data-toggle="modal" data-target="#loginAndForgotPasswordModal">Se connecter à son compte</a></li>
                        <li>
                          {currentEmployer ?
                            (
                              <a href="/employers/resumes">Liste des CVs</a>
                            ) :
                            (
                              <a href="javascript:void(0)" data-toggle="modal" data-target="#loginAndForgotPasswordModal">Liste des CVs</a>
                            )}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-4 mt-lg-0">
                    <div className="footer-link">
                      <h5 className="text-dark mb-4">Liens utiles</h5>
                      <ul className="list-unstyled">
                        <li><Link to={"/jobs/archive/list"}>Archives - Offres d'emploi</Link></li>
                        <li><a href="/tenders/archive/list">Archives - Appel d'offres</a></li>
                        <li><Link to={"/pages/privacyPolicy"}>Politique de confidentialité</Link></li>
                        <li><Link to={"/pages/services"}>Services</Link></li>
                        <li><Link to={"/pages/contact"}>Nous Joindre</Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-bottom bg-dark">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <p className="mb-0 text-white"> &copy; Tous droits réservés<span id="copyright"> <script>document.getElementById('copyright').appendChild(document.createTextNode(new Date().getFullYear()))</script> - </span> <a href="assets/#"> D3K Inc. </a> </p>
                    </div>
                  </div>
                </div>
              </div>
            </footer>

            {/* Employer and candidate login modal start */}
            <div className="modal fade" id="loginAndForgotPasswordModal" tabIndex="-1" role="dialog" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header p-4">
                    <h4 className="mb-0 text-center">{candidateForgotPassword ? "Mot de passe oublié?" : "Connexion à votre compte"}</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" ref={closeLoginModal} onClick={(e) => {
                      setCandidateForgotPassword(false);
                      setEmployerForgotPassword(false);
                      setAlertMessage("");
                      setAlertType("");
                    }}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="login-register">
                      <fieldset>
                        <legend className="px-2">Choisissez votre type de compte</legend>
                        <ul className="nav nav-tabs nav-tabs-border d-flex" role="tablist">
                          <li className="nav-item mr-4" style={employerForgotPassword ? { pointerEvents: "none", opacity: "0.6" } : {}}>
                            <a
                              className="nav-link active"
                              data-toggle="tab"
                              href={employerForgotPassword ? "" : "#candidate"}
                              role="tab"
                              aria-selected="false"
                              onClick={(e) => setAccountType("candidate")}
                            >
                              <div className="d-flex">
                                <div className="tab-icon">
                                  <i className="flaticon-users"></i>
                                </div>
                                <div className="ml-3">
                                  <h6 className="mb-0">Candidats</h6>
                                  <p className="mb-0">Connexion en tant que candidat</p>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li className="nav-item" style={candidateForgotPassword ? { pointerEvents: "none", opacity: "0.6" } : {}}>
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href={candidateForgotPassword ? "" : "#employer"}
                              role="tab"
                              aria-selected="false"
                              onClick={(e) => setAccountType("employer")}
                            >
                              <div className="d-flex">
                                <div className="tab-icon">
                                  <i className="flaticon-suitcase"></i>
                                </div>
                                <div className="ml-3">
                                  <h6 className="mb-0">Employeur</h6>
                                  <p className="mb-0">Connexion en tant que employeur</p>
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </fieldset>
                      <div className="tab-content">
                        <div className="tab-pane active" id="candidate" role="tabpanel">
                          <form className="mt-4">
                            <div className="form-row">
                              <div className="form-group col-12">
                                <label htmlFor="Email2">Address email *</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="Email21"
                                  value={loginData?.email}
                                  onChange={(e) => {
                                    let val = e.target?.value;
                                    setLoginData(prevState => { return { ...prevState, email: val } })
                                  }}
                                />
                              </div>
                              {(candidateForgotPassword && alertMessage != "") && (
                                <>
                                  <CustomAlert content={alertMessage} type={alertType} />
                                </>
                              )}
                              {!candidateForgotPassword && (
                                <div className="form-group col-12">
                                  <label htmlFor="password2">Mot de passe *</label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="password31"
                                    value={loginData?.password}
                                    onChange={(e) => {
                                      let val = e.target?.value;
                                      setLoginData(prevState => { return { ...prevState, password: val } })
                                    }}
                                  />
                                  <div style={{ paddingTop: "6px" }}>
                                    <a href="javascript:void(0)" onClick={(e) => {
                                      setCandidateForgotPassword(true)
                                      setEmployerForgotPassword(false)
                                    }}>Mot de passe oublié?</a>
                                  </div>
                                  <div className="custom-control custom-checkbox mt-2">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="Remember-01"
                                    />
                                    <label className="custom-control-label" htmlFor="Remember-01">Garder ma session ouverte</label>
                                  </div>
                                </div>
                              )}

                            </div>
                            <div className="form-row">
                              <div className="col-md-12">
                                <button
                                  className="btn btn-primary btn-block"
                                  type="button"
                                  onClick={(e) => {
                                    if (candidateForgotPassword) {
                                      handleCandidateForgotPassword(loginData?.email)
                                    }
                                    else {
                                      handleLoginCandidate();
                                    }
                                  }}
                                  disabled={isConnecting || hasMissingValue}
                                >
                                  {candidateForgotPassword ? "Soumettre" : "Se connecter"}  &nbsp; {isConnecting ? <CircularProgress size={15} /> : <span></span>}
                                </button>
                              </div>
                              {candidateLoginFailed && (
                                <div className="col-md-12 mt-4">
                                  <CustomAlert content="Email et/ou le mot de passe incorrect" type="alert-danger" />
                                </div>
                              )}
                            </div>
                          </form>
                          {!candidateForgotPassword && (
                            <div className="row" style={{ paddingTop: "10px" }}>
                              <div className="col-12 text-center">
                                <p className="mt-1">Vous n'avez pas de compte? <a href="/candidates/signup">Inscrivez-vous</a></p>
                              </div>
                            </div>
                          )}

                        </div>
                        <div className="tab-pane fade" id="employer" role="tabpanel">
                          <form className="mt-4">
                            <div className="form-row">
                              <div className="form-group col-12">
                                <label htmlFor="Email2">Address email *</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="Email22"
                                  value={loginData?.email}
                                  onChange={(e) => {
                                    let val = e.target?.value;
                                    setLoginData(prevState => { return { ...prevState, email: val } })
                                  }}
                                />
                              </div>
                              {(employerForgotPassword && alertMessage != "") && (
                                <>
                                  <CustomAlert content={alertMessage} type={alertType} />
                                </>
                              )}
                              {!employerForgotPassword && (
                                <div className="form-group col-12">
                                  <label htmlFor="password2">Mot de passe *</label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="password32"
                                    value={loginData?.password}
                                    onChange={(e) => {
                                      let val = e.target?.value;
                                      setLoginData(prevState => { return { ...prevState, password: val } })
                                    }}
                                  />
                                  <div style={{ paddingTop: "6px" }}>
                                    <a href="javascript:void(0)" onClick={(e) => {
                                      setEmployerForgotPassword(true)
                                      setCandidateForgotPassword(false)
                                    }}>Mot de passe oublié?</a>
                                  </div>
                                  <div className="custom-control custom-checkbox mt-2">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="Remember-02"
                                    />
                                    <label className="custom-control-label" htmlFor="Remember-02">Garder ma session ouverte</label>
                                  </div>
                                </div>
                              )}

                            </div>
                            <div className="form-row">
                              <div className="col-md-12">
                                <button
                                  className="btn btn-primary btn-block"
                                  onClick={(e) => {
                                    if (employerForgotPassword) {
                                      handleEmployerForgotPassword(loginData?.email)
                                    }
                                    else {
                                      handleLoginEmployer();
                                    }
                                  }}
                                  disabled={isConnecting || hasMissingValue}
                                >
                                  {employerForgotPassword ? "Soumettre" : "Se connecter"}  &nbsp; {isConnecting ? <CircularProgress size={15} /> : <span></span>}
                                </button>
                              </div>
                              {employerloginFailed && (
                                <div className="col-md-12 mt-4">
                                  <CustomAlert content="Email et/ou le mot de passe incorrect" type="alert-danger" />
                                </div>
                              )}
                            </div>
                          </form>
                          <div className="row" style={{ paddingTop: "10px" }}>
                            <div className="col-12 text-center">
                              <p className="mt-1">Vous n'avez pas de compte? <a href="/employers/signup">Inscrivez-vous</a></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Employer and candidate login modal end */}
          </>
        )
      }
    </>
  )

}

Footer.propTypes = {
  loginCandidate: PropTypes.func.isRequired,
  loginEmployer: PropTypes.func.isRequired,
  storeUsers: PropTypes.object.isRequired,

};

const mapStateToProps = state => ({
  loginCandidate: PropTypes.func.isRequired,
  loginEmployer: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  forgotedPassword: PropTypes.func.isRequired,
  storeUsers: state.users,
});

export default withRouter(connect(mapStateToProps,
  {
    loginCandidate,
    loginEmployer,
    forgotPassword,
    forgotedPassword,
  }
)(Footer))
