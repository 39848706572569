
import axios from "axios";
import { api } from "../../../services/apiHelpers";
import authentificationService from "../../../services/authentificationService"

export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const GET_USER_LIST = "GET_USER_LIST";
export const USER_ACTION_LOADING = "USER_ACTION_LOADING";
export const USER_ACTION_SUCCESS = "USER_ACTION_SUCCESS";
export const USER_ACTION_ERROR = "USER_ACTION_ERROR";
export const USER_ACTION_SUBMITING = "USER_ACTION_SUBMITING"; //
export const USER_ACTION_SUBMITED = "USER_ACTION_SUBMITED";
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";
export const USER_TABLE_CONTENT_LOADING = "USER_TABLE_CONTENT_LOADING";
export const USER_TABLE_CONTENT_LOADED = "USER_TABLE_CONTENT_LOADED";



export function setUserData(user) {
  return dispatch => {
    dispatch({
      type: SET_USER_DATA,
      data: user
    });
  };
};

export const getUserList = () => dispatch => {
  dispatch({
    type: USER_ACTION_LOADING
  });
  return new Promise((resolve, reject) => {
    axios.get(api + "/Users/list").then(res => {
      if (res.data != null) {
        dispatch({
          type: GET_USER_LIST,
          data: res.data.usersList
        });
        dispatch({
          type: USER_ACTION_SUCCESS
        });
        resolve(res.data.usersList);
      }
    }).catch(error => {
      return dispatch({
        type: USER_ACTION_ERROR,
        data: error
      });
    });

  })

};

export function logoutUser() {
  return dispatch => {
    this.logout();

    // history.push({
    //   pathname: "/session/signin"
    // });

    dispatch({
      type: USER_LOGGED_OUT
    });
    dispatch({
      type: REMOVE_USER_DATA
    });
  };
};

export const loginWithEmailAndPassword = (loginData) => dispatch => {
  dispatch({ // informe le reducer de user qu'un formulaire le concernant est en cours de soumission 
    type: USER_ACTION_SUBMITING
  });
  return new Promise((resolve, reject) => {
    axios.post(api + "/Users/login", loginData).then(res => {
      if (res.data.success) { // Vérifie que le service a fournit une réponse souhaitée
        dispatch({ // Fournit la nouvelle liste d'utilisateur au reducer pour sa mise à jour 
          type: SET_USER_DATA,
          data: res.data.user
        });
        dispatch({ // informe le reducer de user que le formulaire le concernant est traité
          type: USER_ACTION_SUBMITED
        });
        authentificationService.setSession(res.data.user.id);
        authentificationService.setUser(res.data.user);
        
        axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.user.token}`;
      }
      resolve(res.data);
    }).catch(error => {
      return dispatch({
        type: USER_ACTION_ERROR,
        data: error
      });
    });
  })
};

export const forgotPassword = (email) => dispatch => {
  dispatch({
    type: USER_ACTION_SUBMITING
  });
  return new Promise((resolve, reject) => {
    axios.post(api + "/Users/forgotPassword", email).then(res => {
      resolve(res.data);
    }).catch(error => {
      return dispatch({
        type: USER_ACTION_ERROR,
        data: error
      });
    });
  })
};

export const changePassword = (requestData) => dispatch => {
  dispatch({
    type: USER_ACTION_SUBMITING
  });
  return new Promise((resolve, reject) => {
    axios.post(api + "/Users/changePassword", requestData).then(res => {
      resolve(res.data);
    }).catch(error => {
      resolve(false);
      return dispatch({
        type: USER_ACTION_ERROR,
        data: error
      });

    });
  })
};

export const resetPassword = (request) => dispatch => {
  dispatch({
    type: USER_ACTION_SUBMITING
  });
  return new Promise((resolve, reject) => {
    axios.post(api + "/Users/resetPassword", request).then(res => {
      resolve(res.data);
    }).catch(error => {
      return dispatch({
        type: USER_ACTION_ERROR,
        data: error
      });
    });
  })
};

export const logout = () => {

  this.removeUser();
}
export const removeUser = () => {
  localStorage.removeItem("auth_user");
}

export const createUser = (user) => dispatch => {
  dispatch({ // informe le reducer de user qu'un formulaire le concernant est en cours de soumission 
    type: USER_ACTION_SUBMITING
  });

  return new Promise((resolve, reject) => {
    axios.post(api + "/Users/create", user).then(res => {
      if (res.data.success) { // Vérifie que le service a fournit une réponse souhaitée
        dispatch({ // Fournit la nouvelle liste d'utilisateur au reducer pour sa mise à jour 
          type: GET_USER_LIST,
          data: res.data.usersList
        });

      }
      resolve(res.data);
    }).catch(error => {
      return dispatch({
        type: USER_ACTION_ERROR,
        data: error
      });
    });
  }).then(data => {
    if (data) {
      dispatch({ // informe le reducer de user que le formulaire le concernant est traité
        type: USER_ACTION_SUBMITED
      });
      dispatch({ // informe le reducer de user qu'une alerte doit être affiché'
        type: SHOW_ALERT,
        data: "Un utilisateur a été supprimé avec succès"
      });
    }
    else {
      dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
        type: USER_ACTION_ERROR,
        data: data.message
      });
    }
    return data;
  });
};

export const updateUser = (user) => dispatch => {
  dispatch({ // informe le reducer de user qu'un formulaire le concernant est en cours de soumission 
    type: USER_ACTION_SUBMITING
  });

  return new Promise((resolve, reject) => {
    axios.post(api + "/Users/update", user).then(res => {
      if (res.data.success) { // Vérifie que le service a fournit une réponse souhaitée
        dispatch({ // Fournit la nouvelle liste d'utilisateur au reducer pour sa mise à jour 
          type: GET_USER_LIST,
          data: res.data.usersList
        });

      }
      resolve(res.data);
    }).catch(error => {
      return dispatch({
        type: USER_ACTION_ERROR,
        data: error
      });
    });
  }).then(data => {
    if (data) {
      dispatch({ // informe le reducer de user que le formulaire le concernant est traité
        type: USER_ACTION_SUBMITED
      });
      dispatch({ // informe le reducer de user qu'une alerte doit être affiché'
        type: SHOW_ALERT,
        data: "Un utilisateur a été supprimé avec succès"
      });
    }
    else {
      dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
        type: USER_ACTION_ERROR,
        data: data.message
      });
    }
    return data;
  });
};

export const deleteUser = (userId) => dispatch => {
  dispatch({ // informe le reducer de user qu'un formulaire le concernant est en cours de soumission 
    type: USER_ACTION_SUBMITING
  });
  return new Promise((resolve, reject) => {
    axios.get(api + "/Users/delete/" + userId).then(res => {
      if (res.data.success) { // Vérifie que le service a fournit une réponse souhaitée
        dispatch({ // Fournit la nouvelle liste d'utilisateur au reducer pour sa mise à jour 
          type: GET_USER_LIST,
          data: res.data.usersList
        });
      }
      resolve(res.data);
    }).catch(error => {
      return dispatch({
        type: USER_ACTION_ERROR,
        data: error
      });
    });
  }).then(data => {
    if (data) {
      dispatch({ // informe le reducer de user que le formulaire le concernant est traité
        type: USER_ACTION_SUBMITED
      });
      dispatch({ // informe le reducer de user qu'une alerte doit être affiché'
        type: SHOW_ALERT,
        data: "Un utilisateur a été supprimé avec succès"
      });
    }
    else {
      dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
        type: USER_ACTION_ERROR,
        data: data.message
      });
    }
    return data;
  });
};

export const hideAlert = () => dispatch => {
  dispatch({
    type: HIDE_ALERT
  });
};