import React, { useEffect, useRef } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getJobList } from "../../redux/actions/JobActions"
import { createCandidate, getCandidateList } from "../../redux/actions/admin/CandidateActions"
import { CircularProgress } from '@material-ui/core';
import defaultBanner from '../../../assets/images/bg/default-banner.jpg';
import CustomAlert from './../shared/views/CustomAlert';
import { getAdsList } from "../../redux/actions/admin/AdsActions";
import isMatch from 'date-fns/isMatch'
import validator from 'validator'
import { useHistory } from 'react-router-dom';
import { loginCandidate } from "../../redux/actions/admin/CandidateActions";
import { Link } from 'react-router-dom';
import avantagesMembresBannerImage from '../../../assets/images/avantages-membres-candidats.jpg';
import siteService from '../../services/siteService';

let CandidatesSignup = (props) => {
    const {
        storeEmployer = {},
        createCandidate,
        getCandidateList,
        storeAds,
        getAdsList,
        loginCandidate,
    } = props;

    const birthDayRef = useRef();
    const emailRef = useRef();
    const history = useHistory();

    const currentSite = siteService.getCurrentSite();
    const [isLoading, setIsLoading] = React.useState(true);
    const [creationFailed, setCreationFailed] = React.useState(false);
    const [areNotSame, setareNotSame] = React.useState(false);
    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [isCreating, setIsCreating] = React.useState(false);
    const [published, setPublished] = React.useState(false);
    const [employersListOptions, setEmployersListOptions] = React.useState([]);
    const [displayingActiveAdsList, setDisplayingActiveAdsList] = React.useState([]);
    const [newCandidat, setNewCandidat] = React.useState(
        {
            firstName: "",
            lastName: "",
            phoneNumber1: "",
            phoneNumber2: "",
            email: "",
            password: "",
            confirmPassword: "",
            birthDay: "",
            birthPlace: ""
        });
    const [invalideDate, setInvalideDate] = React.useState(false);
    const [invalideEmail, setInvalideEmail] = React.useState(false);
    const [invalidePhoneNumber1, setInvalidePhoneNumber1] = React.useState(false);
    const [invalidePhoneNumber2, setInvalidePhoneNumber2] = React.useState(false);
    const [firstNameInvalideInputLength, setFirstNameInvalideInputLength] = React.useState(false);
    const [lastNameInvalideInputLength, setLastNameInvalideInputLength] = React.useState(false);
    const [birthPlaceInvalideInputLength, setBirthPlaceInvalideInputLength] = React.useState(false);


    useEffect(() => {
        if (storeEmployer.employersList.length > 0 && employersListOptions.length == 0) {
            getEmployersListOptions(storeEmployer.employersList);
        }
        else if (storeEmployer.employersList.length == 0 && employersListOptions.length == 0) {
            getCandidateList(false).then(response => {
                if (response.success) {
                    getEmployersListOptions(response.employersList);
                };
            });
        }
        if (storeAds.adsList.length == 0) {
            getAdsList(false).then(resp => {
                setDisplayingActiveAdsList(resp.adsList);
            })
        }


    }, []);


    useEffect(() => {
        if (newCandidat.firstName == ""
            || newCandidat.firstName === undefined
            || newCandidat.lastName == ""
            || newCandidat.lastName === undefined
            || newCandidat.email == ""
            || newCandidat.email === undefined
            || newCandidat.password == ""
            || newCandidat.password === undefined
            || newCandidat.confirmPassword == ""
            || newCandidat.confirmPassword === undefined
        ) {
            setHasMissingValue(true);
        }
        else if (newCandidat.firstName?.length > 50
            || newCandidat.lastName?.length > 50
            || newCandidat.birthPlace?.length > 50
            || newCandidat.phoneNumber1?.length > 15
            || newCandidat.phoneNumber2?.length > 15
            || newCandidat.email?.length > 200
            || (newCandidat?.birthDay != "" && newCandidat?.birthDay != null && !isMatch(newCandidat?.birthDay, 'yyyy-MM-dd'))
            || (newCandidat.phoneNumber1 != "" && !(new RegExp(/^[0-9\b]+$/)).test(newCandidat.phoneNumber1))
            || (newCandidat.phoneNumber2 != "" && !(new RegExp(/^[0-9\b]+$/)).test(newCandidat.phoneNumber2))
            || (newCandidat.email != "" && !validator.isEmail(newCandidat.email))
        ) {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);
        }
        
    }, [newCandidat]);

    useEffect(() => {
        if (employersListOptions.length > 0 && isLoading) {
            setIsLoading(false);
        }
    }, [employersListOptions]);

    const handleSubmit = () => {
        setIsCreating(true);
        let candidat = newCandidat;
        if (candidat.birthDay == "") {
            candidat.birthDay = null;
        }
        createCandidate(candidat).then(data => {
            if (data == true) {
                setIsCreating(false);
                setPublished(true);
                setCreationFailed(false);
                loginCandidate({ email: newCandidat.email, password: newCandidat.password }).then(resp => {
                    if (resp.success) {
                        history.push(`/candidates/profile`); //window.location = "candidates/profile";
                    }
                });
            }
            else {
                setCreationFailed(true);
                setIsCreating(false)
            }
        });
    }

    const getEmployersListOptions = (employers) => {
        let result = [];
        employers.forEach(employer => {
            result.push({ label: employer.companyName, id: employer.id });
        });
        setEmployersListOptions(result);
    }

    // Start keydown mechanism
    let onKeydown = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            if (!hasMissingValue) {
                handleSubmit();
            }
            event.preventDefault();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", onKeydown);
        return () => {
            document.removeEventListener("keydown", onKeydown);
        };
    }, [onKeydown]);
    //End keydown mechanism

    return (
        <React.Fragment>
            <div className="header-inner" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${defaultBanner})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 style={{ color: "white" }}>Nouveau compte</h2>
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><a href="/"> Accueil </a></li>
                                <li className="breadcrumb-item"><i className="fas fa-chevron-right"></i><a href="index.html"> Candidat </a></li>
                                <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> Création d'un candidat </span></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <section className="space-ptb" style={{ padding: "50px 0" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8">
                            <div className="bg-light-3 p-4">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title">
                                            <h2 className="title"><span>Création de compte candidat !</span></h2>
                                            <p>Inscrivez-vous gratuitement pour recevoir les offres d’emplois par SMS, par e-mail et par Messenger et aussi pour être vus par les employeurs</p>
                                        </div>
                                    </div>
                                </div>
                                <div _lpchecked="1">
                                    <div className="form-row">
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label>Prénom *</label>
                                                <input
                                                    value={newCandidat?.firstName}
                                                    type="text"
                                                    className="form-control"
                                                    id="firstName"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setNewCandidat(prevState => { return { ...prevState, firstName: val } });
                                                    }}
                                                />
                                                {newCandidat.firstName.length > 50 && (
                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                )}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Nom *</label>
                                                <input type="text"
                                                    value={newCandidat?.lastName}
                                                    className="form-control"
                                                    id="lastName"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setNewCandidat(prevState => { return { ...prevState, lastName: val } });
                                                    }}
                                                />
                                                {newCandidat.lastName.length > 50 && (
                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                )}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Date de naissance</label>
                                                <input
                                                    ref={birthDayRef}
                                                    value={newCandidat?.birthDay}
                                                    type="date"
                                                    className="form-control"
                                                    id="birthDay"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        let match = isMatch(e.target.value, 'yyyy-MM-dd');
                                                        setNewCandidat(prevState => { return { ...prevState, birthDay: val } });
                                                        if (match && val != "") {
                                                            setInvalideDate(!match);
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        let val = isMatch(e.target.value, 'yyyy-MM-dd');
                                                        if (e.target.value != "") {
                                                            setInvalideDate(!val);
                                                        }
                                                        if (!val && e.target.value != "") {
                                                            setNewCandidat(prevState => { return { ...prevState, birthDay: null } });
                                                        }
                                                    }}
                                                />
                                                {invalideDate && (
                                                    <p style={{ color: "red" }}>Format invalide</p>
                                                )}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Lieu de naissance</label>
                                                <input type="text"
                                                    value={newCandidat?.birthPlace}
                                                    className="form-control"
                                                    id="birthPlace"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setNewCandidat(prevState => { return { ...prevState, birthPlace: val } });
                                                    }}
                                                />
                                                {newCandidat.birthPlace?.length > 50 && (
                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                )}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Numero de téléphone ({siteService.getCurrentSite().operatorName1})</label>
                                                <input
                                                    type="text"
                                                    value={newCandidat?.phoneNumber1}
                                                    className="form-control"
                                                    id="phoneNumber1"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setNewCandidat(prevState => { return { ...prevState, phoneNumber1: val } });
                                                    }}
                                                />
                                                {newCandidat.phoneNumber1?.length > 15 && (
                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                )}
                                                {(newCandidat.phoneNumber1 != "" && !(new RegExp(/^[0-9\b]+$/)).test(newCandidat.phoneNumber1)) && (
                                                    <p style={{ color: "red" }}>Format invalide</p>
                                                )}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Numero de téléphone ({siteService.getCurrentSite().operatorName2})</label>
                                                <input
                                                    type="text"
                                                    value={newCandidat?.phoneNumber2}
                                                    className="form-control"
                                                    id="phoneNumber2"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setNewCandidat(prevState => { return { ...prevState, phoneNumber2: val } });
                                                    }}
                                                />
                                                {newCandidat.phoneNumber2?.length > 15 && (
                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                )}
                                                {(newCandidat.phoneNumber2 != "" && !(new RegExp(/^[0-9\b]+$/)).test(newCandidat.phoneNumber2)) && (
                                                    <p style={{ color: "red" }}>Format invalide</p>
                                                )}
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Adresse email *</label>
                                                <input
                                                    ref={emailRef}
                                                    value={newCandidat?.email}
                                                    type="text"
                                                    className="form-control"
                                                    id="email"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        let isValide = validator.isEmail(e.target.value);
                                                        setNewCandidat(prevState => { return { ...prevState, email: val } });
                                                        if (invalideEmail) {
                                                            setInvalideEmail(!isValide);
                                                        }
                                                        if (creationFailed) setCreationFailed(false)
                                                    }}
                                                    onBlur={(e) => {
                                                        let val = validator.isEmail(e.target.value);                                                        
                                                        setInvalideEmail(!val);
                                                        if (!val) {
                                                            // emailRef.current.focus();
                                                        }
                                                    }}
                                                />
                                                {newCandidat.email?.length > 200 && (
                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                )}
                                                {(newCandidat.email != "" && invalideEmail) && (
                                                    <p style={{ color: "red" }}>Format d'email invalide</p>
                                                )}
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Mot de passe *</label>
                                                <input
                                                    value={newCandidat?.password}
                                                    type="password"
                                                    className="form-control"
                                                    id="password"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        if (newCandidat.confirmPassword != '' && newCandidat.conformPassword != undefined) {
                                                            setareNotSame(newCandidat.confirmPassword.normalize() !== val.normalize());
                                                        }
                                                        setNewCandidat(prevState => { return { ...prevState, password: val } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Confirmation du mot de passe *</label>
                                                <input
                                                    value={newCandidat?.confirmPassword}
                                                    type="password"
                                                    className="form-control"
                                                    id="confirmPassword"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setareNotSame(newCandidat.password.normalize() !== val.normalize());
                                                        setNewCandidat(prevState => { return { ...prevState, confirmPassword: val } });
                                                    }}
                                                />
                                                {areNotSame && (
                                                    <div style={{ color: "red", marginTop: "8px" }}>Les deux mots de passe sont différents.</div>
                                                )}
                                            </div>
                                            <div className="col-12 mt-4">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={handleSubmit}
                                                    disabled={isCreating
                                                        || hasMissingValue
                                                        || areNotSame
                                                        || creationFailed
                                                    }
                                                >
                                                    Soumettre &nbsp; {isCreating ? <CircularProgress size={15} /> : <span></span>}
                                                </button>
                                            </div>
                                            {published && (
                                                <div className="col-12 mt-4">
                                                    <CustomAlert content={"<strong>Félicitations!</strong> Ce compte candidat a été créé avec succès. Un email de confirmation a été envoyé à l'adresse suivante :" + " <strong>" + newCandidat?.email + "</strong>"} />
                                                </div>
                                            )}
                                            {creationFailed && (
                                                <div className="col-12 mt-4">
                                                    <CustomAlert content="Email et/ou le mot de passe incorrect" type="alert-danger" />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 mt-4 mt-xl-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="sidebar mb-0">
                                        <div className="widget border-0">
                                            <div className="jobster-company-view">
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <div className="widget-box">
                                                            <div className="d-flex">
                                                                <div>
                                                                    <Link to={"/candidates/signup"}>
                                                                        <img className="img-fluid" src={avantagesMembresBannerImage} alt="" />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

CandidatesSignup.propTypes = {
    getJobList: PropTypes.func.isRequired,
    createCandidate: PropTypes.func.isRequired,
    getCandidateList: PropTypes.func.isRequired,
    storeJob: PropTypes.object.isRequired,
    storeAds: PropTypes.object.isRequired,
    getAdsList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    getJobList: PropTypes.func.isRequired,
    getCandidateList: PropTypes.func.isRequired,
    createCandidate: PropTypes.func.isRequired,
    loginCandidate: PropTypes.func.isRequired,
    storeJob: state.job,
    storeEmployer: state.employer,
    getAdsList: PropTypes.func.isRequired,
    storeAds: state.ads,
});

export default connect(mapStateToProps,
    {
        getJobList,
        createCandidate,
        getCandidateList,
        getAdsList,
        loginCandidate,

        // CandidatesSignup,
    }
)(CandidatesSignup)