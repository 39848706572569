import {
    GET_DASHBOARD_TILES,
    GET_DASHBOARD_CHART,
    DASHBOARD_ACTION_ERROR

} from "../../actions/admin/DashboardActions";

const initialState = {
    dashboardTiles: [],
    dashboardChartData: [],
    failed: false,
    showAlert: false,
    alertMessage: "",
    alertVariant: "",
};

const DashboardReducer = function (state = initialState, action) {
    switch (action.type) {

        case GET_DASHBOARD_TILES: {
            return {
                ...state,
                dashboardTiles: [...action.data]
            };
        }
        case GET_DASHBOARD_CHART: {
            return {
                ...state,
                dashboardChartData: [...action.data]
            };
        }
        case DASHBOARD_ACTION_ERROR: {
            return {
                ...state,
                failed: true,
                showAlert: true,
                alertMessage: action.data,
            };
        }
        default: {
            return state;
        }
    }
};

export default DashboardReducer;