import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { jobSearch } from "../../redux/actions/JobActions"
import { getEmployerJobs } from "../../redux/actions/admin/EmployerActions";
import { CircularProgress } from '@material-ui/core';
import defaultBanner from '../../../assets/images/bg/default-banner.jpg';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getAdsList } from "../../redux/actions/admin/AdsActions";
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import $ from 'jquery';
import Sidebar from '../shared/views/Sidebar';
import createAccountImage from '../../../assets/images/pourquoi-creer-un-compte.jpg';
import CustomGoogleAdsense from '../shared/views/CustomGoogleAdsense';
import siteService from '../../services/siteService';

let EmployerJobs = (props) => {
    const { jobSearch, getEmployerJobs } = props;

    const history = useHistory();
    const [isLoading, setIsLoading] = React.useState(true);
    const [employerId, setEmployerId] = React.useState("");
    const [jobSearchResult, setJobSearchResult] = React.useState(null);
    const [displayingJobList, setDisplayingJobList] = React.useState([]);
    const [filterValues, setFilterValues] = React.useState([]);
    const [facetsList, setFacetsList] = React.useState([]);
    const [sortedBy, setSortedBy] = React.useState("postedDate");
    const currentSite = siteService.getCurrentSite();

    

    useEffect(() => {

        let match = props.match;
        if (match !== undefined) {
            let params = match.params;
            if (params !== undefined && params.employerId !== undefined) {
                setEmployerId(params.employerId);
            }
        };


        window.scrollTo(0, 0);
        $('#nav').slideToggle(300);
        console.log("slideToggle");
    }, []);

    useEffect(() => {
        if (employerId != "") {
            getEmployerJobs(employerId).then(response => {
                setDisplayingJobList(response.documents);
                setIsLoading(false);
            })           
        }
    }, [employerId]);

    
    return (
        <React.Fragment>
            <Helmet>
                <title> Offres d'emploi  | {currentSite.fullName}</title>
            </Helmet>
            {isLoading ? (
                <div className="loading-container">
                    <CircularProgress />
                    <div>Chargement des offres d'emploi en cours...</div>
                </div>
            ) : (
                <>
                    <div className="header-inner" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${defaultBanner})` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h2 style={{ color: "white" }}>Offres d'emploi</h2>
                                    <ol className="breadcrumb mb-0 p-0">
                                        <li className="breadcrumb-item"><Link to={"/"}>Accueil</Link>  </li>
                                        <li className="breadcrumb-item"><i className="fas fa-chevron-right"></i><a href="index.html"> Employeur </a></li>
                                        <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> Offres d'emploi</span></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="space-ptb bg-light" style={{ paddingTop: "40px" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="job-filter mb-4 d-sm-flex align-items-center">
                                    <div className="section-title mb-0">
                                        <h2 className="title"><span>Mes offres d'emploi</span></h2>                                                                       
                                    </div>                              
                                    </div>
                                    
                                    <div>
                                        {displayingJobList?.sort((a, b) => sortedBy == "closingDate" ? (a.closingDate < b.closingDate ? 1 : -1) : (a.postedDate < b.postedDate ? 1 : -1))?.map((job, index) => (
                                            <div className="job-list border-bottom" key={job.jobNumber}>
                                                <div className="job-list-logo">
                                                    <Link to={"/jobs/details/" + job.jobNumber}><img className={job.imageUrl != "" ? "img-fluid": "img-fluid default-job"} src={job.imageUrl != "" ? job.imageUrl : "assets/images/default-job.png"} alt="" /></Link>
                                                </div>
                                                <div className="job-list-details">
                                                    <div className="job-list-info">
                                                        <div className="job-list-title">
                                                            <h5 className="mb-0"><Link to={"/jobs/details/" + job.jobNumber}>{job.title}</Link></h5>
                                                        </div>
                                                        <div className="job-list-option">
                                                            <ul className="list-unstyled">
                                                                <li><i className="fas fa-calendar-alt pr-1"></i>Date de Publication: {job.postedDateInFrench}</li>
                                                                <li><i className="fas fa-calendar-alt pr-1"></i>Date Limite: {job.closingDateInFrench}</li>
                                                                <li><i className="fas fa-folder pr-1"></i>{job.categoryDisplayName}</li>
                                                            </ul>
                                                        </div>
                                                        <div style={{ textAlign: "justify", margin: "10px 0px 10px 0px" }}>
                                                            {job.shortDescription}
                                                        </div>
                                                        <div className="job-list-option">
                                                            <ul className="list-unstyled">
                                                                <li>
                                                                    <span>Par </span>
                                                                    <a href={"javascript:void(0)"}>
                                                                        {job.companyName}
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <CustomGoogleAdsense location="Wide2" />
                                </div>
                                <div className="col-lg-3">
                                    <div className="sidebar">
                                        <Sidebar />                                        
                                        <div className="widget border-0">
                                            <div className="jobster-company-view">
                                            <ul className="list-unstyled">
                                                    <li>
                                                        <div className="widget-box">
                                                            <div className="d-flex">
                                                                <div>
                                                                    <Link to={"/candidates/signup"}>
                                                                        <img className="img-fluid" src={createAccountImage} alt="" />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <CustomGoogleAdsense location="Side1" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}
        </React.Fragment>
    );

}
EmployerJobs.propTypes = {
    getEmployerJobs: PropTypes.func.isRequired,
    jobSearch: PropTypes.func.isRequired,
    storeJob: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    getEmployerJobs: PropTypes.func.isRequired,
    jobSearch: PropTypes.func.isRequired,
    storeJob: state.job,
});

export default connect(mapStateToProps, { jobSearch, getAdsList, getEmployerJobs })(EmployerJobs)