import axios from "axios";
import { api } from "../../../services/apiHelpers";

export const ACTION_LOADING = "ACTION_LOADING";
export const ACTION_SUCCESS = "ACTION_SUCCESS";
export const ACTION_ERROR = "ACTION_ERROR";
export const ACTION_SUBMITING = "ACTION_SUBMITING";
export const ACTION_SUBMITED = "ACTION_SUBMITED";

export const GET_ADS_LIST = "GET_ADS_LIST";
export const GET_ADSLIST_INCLUDED_INACTIVE = "GET_ADSLIST_INCLUDED_INACTIVE";
export const GET_ADS = "GET_ADS";
export const ADS_SEARCH = "ADS_SEARCH";
// export const ACTION_SUBMITED = "ACTION_SUBMITED";

export const getAdsList = (includeInactive) => dispatch => {
    dispatch({
        type: ACTION_LOADING
    });
    return new Promise((resolve, reject) => {
        axios.get(api + "/Ads/list?includeInactive=" + includeInactive).then(res => {
            if (res.data) {
                dispatch({
                    type: GET_ADS_LIST,
                    data: res.data.adsList
                });
                dispatch({
                    type: ACTION_SUCCESS
                });
                resolve(res.data);
            }
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });

    })

};

export const createAds = (file, newAds) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    let formData = new FormData();
    formData.append("file", file);
    formData.append("data", JSON.stringify(newAds));
    return new Promise((resolve, reject) => {
        axios.post(api + "/Ads/create", formData).then(res => {
            if (res.data.Success) {
                dispatch({
                    type: GET_ADS_LIST,
                    data: res.data.adsList
                });
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        if (data) {
            dispatch({ // informe le reducer de user que le formulaire le concernant est traité
                type: ACTION_SUBMITED
            });
            dispatch({ // informe le reducer de user qu'une alerte doit être affiché'
                type: ACTION_ERROR,
                data: "Un utilisateur a été supprimé avec succès"
            });
        }
        else {
            dispatch({// En cas d'erreur du service on affiche une alerte avec le message de service
                type: ACTION_ERROR,
                data: data.message
            });
        }
        return data;
    });
};

export const updateAds = (ads) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });

    return new Promise((resolve, reject) => {
        axios.post(api + "/Ads/update", ads).then(res => {
            if (res.data.Success) {
                dispatch({
                    type: GET_ADS_LIST,
                    data: res.data.adsList
                });
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;
    });
};

export const deleteAds = (id) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        axios.get(api + "/Ads/delete/" + id).then(res => {
            if (res.data.Success) {
                dispatch({
                    type: GET_ADS_LIST,
                    data: res.data.adsList
                });
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;
    });
};

export const addAttachmentFromAds = (id, currentFile) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    let formData = new FormData();
    formData.append("file", currentFile);
    formData.append("adId", id);
    return new Promise((resolve, reject) => {
        axios.post(api + "/Ads/attachments/add", formData).then(res => {
            if (res.data.Success) {
                dispatch({
                    type: GET_ADS_LIST,
                    data: res.data.adsList
                });
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;

    });
}

export const deleteAttachmentFromAds = (id, attachment) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        axios.get(api + "/Ads/attachments/delete/" + id + "/" + attachment).then(res => {
            if (res.data) {
                dispatch({
                    type: GET_ADS_LIST,
                    data: res.data.adsList
                });

                resolve(res.data);
            }
            else {
                resolve(null);
            }

        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;
    });
}