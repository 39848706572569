import axios from "axios";
import { api } from "../../services/apiHelpers";

export const ACTION_LOADING = "ACTION_LOADING";
export const ACTION_SUCCESS = "ACTION_SUCCESS";
export const ACTION_ERROR = "ACTION_ERROR";
export const ACTION_SUBMITING = "ACTION_SUBMITING";
export const ACTION_SUBMITED = "ACTION_SUBMITED";

export const GET_TENDER_LIST = "GET_TENDER_LIST";
export const GET_ARCHIVETENDERS_LIST = "GET_ARCHIVETENDERS_LIST";
export const GET_TENDER = "GET_TENDER";
export const TENDER_SEARCH = "TENDER_SEARCH";
export const GET_TENDER_LIST_INCLUDED_DESCRIPTION = "GET_TENDER_LIST_INCLUDED_DESCRIPTION";


export const getTenderList = (includeInactive) => dispatch => {
    dispatch({
        type: ACTION_LOADING
    });
    return new Promise((resolve, reject) => {
        axios.get(api + "/Tenders/list?includeInactive=" + includeInactive).then(res => {
            if (res.data) {
                dispatch({
                    type: GET_TENDER_LIST,
                    data: res.data.tendersList
                });
                dispatch({
                    type: ACTION_SUCCESS
                });
                resolve(res.data);
            }
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });

    })

};

export const tenderSearch = (includeDescription, searchTerm) => dispatch => {
    dispatch({
        type: ACTION_LOADING
    });

    return new Promise((resolve, reject) => {
         let url = searchTerm != undefined ? "/tenders/search?includeDescription=" + includeDescription + "&searchTerm="+ searchTerm : "/tenders/search?includeDescription=" + includeDescription;
        axios.get(api + url).then(res => {
            if (res.data != null) {
                if (includeDescription) {
                    dispatch({
                        type: GET_TENDER_LIST_INCLUDED_DESCRIPTION,
                        data: res.data.documents
                    });
                    dispatch({
                        type: ACTION_SUCCESS
                    });
                }
                else {
                    dispatch({
                        type: TENDER_SEARCH,
                        data: res.data
                    });
                    dispatch({
                        type: ACTION_SUCCESS
                    });
                }
                resolve(res.data);
            }
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    })
};

export const getArchiveTendersList = () => dispatch => {
    dispatch({
        type: ACTION_LOADING
    });
    return new Promise((resolve, reject) => {
        axios.get(api + "/Tenders/archive/list").then(res => {
            if (res.data != null) {
                dispatch({
                    type: GET_ARCHIVETENDERS_LIST,
                    data: res.data.archiveTendersList
                });
                dispatch({
                    type: ACTION_SUCCESS
                });
                resolve(res.data.archiveTendersList);
            }
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    })
};

export const getArchiveTender = (tenderId) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        axios.get(api + "/Tenders/archive/details/" + tenderId).then(res => {
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUCCESS
        });
        return data;
    });
};

// export const updateUser = (tender) => dispatch => {
//     dispatch({
//         type: ACTION_SUBMITING
//     });
//     return new Promise((resolve, reject) => {
//         setTimeout(() => {
//             axios.post(api + "tenders/update", tender).then(res => {
//                 if (res.data.Success) {
//                     dispatch({
//                         type: GET_TENDER_LIST,
//                         data: res.data.TenderList
//                     });
//                     resolve(res.data);
//                 }
//                 else {
//                     resolve(res.data);
//                 }
//             }).catch(error => {
//                 return dispatch({
//                     type: ACTION_ERROR,
//                     data: error
//                 });
//             });
//         }, 500);
//     }).then(data => {
//         if (data.Success) {
//             dispatch({
//                 type: ACTION_SUBMITED
//             });


//         }

//         return data;
//     });
// };

export const createTender = (tender) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {

        axios.post(api + "/Tenders/post", tender).then(res => {
            if (res.data.success) {
                // dispatch({
                //     type: GET_TENDER_LIST,
                //     data: res.data.TenderList
                // });
            }
            resolve(res.data);
        }).catch(error => {
            dispatch({
                type: ACTION_ERROR,
                data: error
            });
            resolve({ tender: null, tendersList: null, success: false, message: "error.message" })
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;
    });
};

export const updateTender = (tenders) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {

        axios.post(api + "/Tenders/update", tenders).then(res => {
            if (res.data.Success) {
                dispatch({
                    type: GET_TENDER_LIST,
                    data: res.data.tendersList
                });
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;
    });
};

export const deleteTender = (id) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });

    return new Promise((resolve, reject) => {
        axios.get(api + "/Tenders/delete/" + id).then(res => {
            if (res.data.Success) {
                dispatch({
                    type: GET_TENDER_LIST,
                    data: res.data.JobList
                });
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;
    });
};

export const refrechTenderSearchIndex = () => dispatch => {
    return new Promise((resolve, reject) => {
        axios.get(api + "/Tenders/search/refreshIndex").then(res => {
            if (res.data) {
                resolve(res.data);
            }
        });
    })
};
// /tenders/update

export const addAttachmentFromTender = (id, currentFile) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    let formData = new FormData();
    formData.append("file", currentFile);
    formData.append("tenderId", id);
    return new Promise((resolve, reject) => {
        axios.post(api + "/Tenders/attachments/add", formData).then(res => {
            if (res.data.Success) {
                dispatch({
                    type: GET_TENDER_LIST,
                    data: res.data.tendersList
                });
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;

    });
}

export const deleteAttachmentFromTender = (id, attachment) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        axios.get(api + "/Tenders/attachments/delete/" + id + "/" + attachment).then(res => {
            if (res.data) {
                dispatch({
                    type: GET_TENDER_LIST,
                    data: res.data.tendersList
                });

                resolve(res.data);
            }
            else {
                resolve(null);
            }

        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;
    });
}

export const downloadTenderPdf = (id) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });

    return new Promise((resolve, reject) => {
        axios.post(api + "/Tenders/export/" + id).then(res => {
            if (res.data.success) {
            }
            resolve(res.data);
        }).catch(error => {
            resolve({ tender: null, tendersList: null, success: false, message: "error.message" })
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;
    });
};