import axios from "axios";
import localStorageService from "./localStorageService";
import { api } from "./apiHelpers"

class AuthentificationService {

    loginWithToken = () => {
        return new Promise((resolve, reject) => {
            axios.get(api + "/Users/getuserbytoken/" + localStorage.getItem("malibaara_dc_token")).then(res => {
                if (res.success && res.user && res.user.id) {
                    if (res.user.id.toString() !== localStorage.getItem("malibaara_dc_token").toString()) {
                        this.setSession(res.user.id);
                        this.setUser(res.user);
                    }
                }
                resolve(res.data);
            }).catch(error => {
                resolve(null);
            });
        });
    };

    logout = (sessionType) => {
        this.setSession(null);
        if (sessionType.toString() === "user") {
            this.removeUser();
        }
        else if (sessionType.toString() === "candidate") {
            this.removeCandidate();
        }
        else if (sessionType.toString() === "employer") {
            this.removeEmployer();
        }

    }

    setSession = token => {
        if (token) {
            localStorage.setItem("malibaara_dc_token", token);
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        } else {
            localStorage.removeItem("malibaara_dc_token");
            delete axios.defaults.headers.common["Authorization"];
        }
    };

    getUser = () => {
        return localStorageService.getItem("malibaara_auth_user");
    }

    setUser = (user) => {
        localStorageService.setItem("malibaara_auth_user", user);
    }

    setCandidate = (candidate) => {
        localStorageService.setItem("malibaara_auth_candidate", candidate);
    }

    setEmployer = (employer) => {
        localStorageService.setItem("malibaara_auth_employer", employer);
    }

    removeUser = () => {
        localStorage.removeItem("malibaara_auth_user");
    }

    removeCandidate = () => {
        localStorage.removeItem("malibaara_auth_candidate");
    }

    removeEmployer = () => {
        localStorage.removeItem("malibaara_auth_employer");
    }
}

export default new AuthentificationService();
