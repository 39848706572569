import axios from "axios";
import { api } from "../../services/apiHelpers";

export const ACTION_LOADING = "ACTION_LOADING";
export const ACTION_SUCCESS = "ACTION_SUCCESS";
export const ACTION_ERROR = "ACTION_ERROR";
export const ACTION_SUBMITING = "ACTION_SUBMITING";
export const ACTION_SUBMITED = "ACTION_SUBMITED";

export const GET_COURSE_LIST = "GET_COURSE_LIST";
export const GET_COURSE = "GET_COURSE";
export const COURSE_SEARCH = "COURSE_SEARCH";
export const GET_COURSE_LIST_INCLUDED_DESCRIPTION = "GET_COURSE_LIST_INCLUDED_DESCRIPTION";


export const getCourseList = (includeInactive) => dispatch => {
    dispatch({
        type: ACTION_LOADING
    });
    return new Promise((resolve, reject) => {
        axios.get(api + "/Courses/list?includeInactive=" + includeInactive).then(res => {
            if (res.data) {
                dispatch({
                    type: GET_COURSE_LIST,
                    data: res.data.coursesList
                });
                dispatch({
                    type: ACTION_SUCCESS
                });
                resolve(res.data);
            }
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });

    })

};

export const courseSearch = (includeDescription, searchTerm) => dispatch => {
    dispatch({
        type: ACTION_LOADING
    });
    return new Promise((resolve, reject) => {
         let url = searchTerm != undefined ? "/courses/search?includeDescription=" + includeDescription + "&searchTerm="+ searchTerm : "/courses/search?includeDescription=" + includeDescription;
        axios.get(api + url).then(res => {
            if (res.data != null) {
                if (includeDescription) {
                    dispatch({
                        type: GET_COURSE_LIST_INCLUDED_DESCRIPTION,
                        data: res.data.documents
                    });
                    dispatch({
                        type: ACTION_SUCCESS
                    });
                }
                else {
                    dispatch({
                        type: COURSE_SEARCH,
                        data: res.data
                    });
                    dispatch({
                        type: ACTION_SUCCESS
                    });
                }
                resolve(res.data)
            }
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });

        })
    })
};

export const getCourse = (jobId) => dispatch => {
};


export const createCourse = (course) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        axios.post(api + "/Courses/create", course).then(res => {
            if (res.data.success) {
                dispatch({
                    type: GET_COURSE_LIST,
                    data: res.data.coursesList
                });
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        if (data) {
            dispatch({
                type: ACTION_SUBMITED
            });
            dispatch({
                type: ACTION_ERROR,
                data: "Un a été supprimé avec succès"
            });
        }
        else {
            dispatch({
                type: ACTION_ERROR,
                data: data.message
            });
        }
        return data;
    });
};


export const updateCourse = (courses) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {

        axios.post(api + "/Courses/update", courses).then(res => {
            if (res.data.Success) {
                dispatch({
                    type: GET_COURSE_LIST,
                    data: res.data.courseList
                });
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;
    });
};

export const deleteCourse = (id) => dispatch => {    
    dispatch({
        type: ACTION_SUBMITING
    });

    return new Promise((resolve, reject) => {
        axios.get(api + "/Courses/delete/" + id).then(res => {
            if (res.data.Success) {
                dispatch({
                    type: GET_COURSE_LIST,
                    data: res.data.CourseList
                });
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;
    });
};

export const addAttachmentFromCourse = (id, currentFile) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    let formData = new FormData();
    formData.append("file", currentFile);
    formData.append("courseId", id);
    return new Promise((resolve, reject) => {
        axios.post(api + "/Courses/attachments/add", formData).then(res => {
            if (res.data.success) {
                dispatch({
                    type: GET_COURSE_LIST,
                    data: res.data.coursesList
                });
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;

    });
}

export const deleteAttachmentFromCourse = (id, attachment) => dispatch => {
    dispatch({
        type: ACTION_SUBMITING
    });
    return new Promise((resolve, reject) => {
        axios.get(api + "/Courses/attachments/delete/" + id + "/" + attachment).then(res => {
            if (res.data.Success) {
                dispatch({
                    type: GET_COURSE_LIST,
                    data: res.data.coursesList
                });
            }
            resolve(res.data);
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });
    }).then(data => {
        dispatch({
            type: ACTION_SUBMITED
        });
        return data;

    });
}

export const refrechCourseSearchIndex = () => dispatch => {
    return new Promise((resolve, reject) => {
        axios.get(api + "/Courses/search/refreshIndex").then(res => {
            if (res.data) {
                resolve(res.data);
            }
        });
    })
};

