import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import $ from 'jquery';
import { CircularProgress } from '@material-ui/core';
import { Helmet } from "react-helmet";
import ApexCharts from 'apexcharts'
import { getDashboardTiles, getDashboardChart } from "../../../redux/actions/admin/DashboardActions"
import siteService from '../../../services/siteService';

let Dashboard = (props) => {

    const {
        getDashboardTiles,
        getDashboardChart,
        storeDashboard = {},
    } = props;

    const [isLoading, setIsLoading] = React.useState(true);
    const [tiles, setTiles] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const currentSite = siteService.getCurrentSite();

    useEffect(() => {
        if (storeDashboard.dashboardTiles?.length === 0) {
            getDashboardTiles().then(resp => {
                setTiles(resp);
                setIsLoading(false);
            });
        }
        else if (storeDashboard.dashboardTiles?.length > 0) {
            setTiles(storeDashboard.dashboardTiles);
            setIsLoading(false);
        }

        if (storeDashboard.dashboardChartData?.length === 0) {
            getDashboardChart().then(resp => {
                const names = [...new Set(resp.map(item => item.name))];
                const values = [...new Set(resp.map(item => item.value))];
                setData(values);
                setCategories(names);
            });
        }
        else if (storeDashboard.dashboardChartData?.length > 0) {
            const names = [...new Set(storeDashboard.dashboardChartData.map(item => item.name))];
            const values = [...new Set(storeDashboard.dashboardChartData.map(item => item.value))];
            setData(values);
            setCategories(names);
        }

    }, []);

    useEffect(() => {
        if (data.length > 0 && categories.length > 0) {
            initializeChart();
           
        }

    }, [data, categories])

    let initializeChart = () => {
        var options = {
            chart: {
                height: 800,
                type: 'bar',
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    barHeight: '40%',
                    horizontal: true,
                }
            },
            legend: {
                show: true,
                position: "top",
                containerMargin: {
                    top: -10
                }
            },
            grid: {
                show: true,
                borderColor: '#ebedf2',
            },
            colors: ['#21c87a'],
            dataLabels: {
                enabled: false
            },
            series: [{
                data: data //[400, 430, 448, 470, 540, 580, 690, 1100, 1200]
            }],
            xaxis: {
                categories: categories, //['Sale associate', 'Architecture', 'Python engineer', 'IT Contractor', 'Front-end developer', 'Python engineer', 'Engineering', 'Closed projects', 'Education'],
                axisBorder: {
                    show: false,
                },
            },

            responsive: [{
                breakpoint: 800,
                options: {
                    chart: {
                        height: 800,
                        type: 'bar',
                        toolbar: {
                            show: false,
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                        }
                    },

                },
            }]
        }

        var chart = new ApexCharts(
            document.querySelector("#chart"),
            options
        );

        chart.render();
    }


    return (
        <>
            <Helmet>
                <title> Administration | {currentSite.fullName}</title>
            </Helmet>
            {isLoading ? (

                <div className="row bg-white pt-4 mt-lg-0">
                    <div className="col-lg-12">
                        <div className="loading-container bg-white">
                            <CircularProgress />
                        </div>
                    </div>
                </div>
            ) :
                (
                    <>
                        <div className="row mb-3 mb-lg-4 mt-3 mt-lg-0">
                            {tiles?.map((item, index) => (
                                <div key={index} className="col-sm-6 col-lg-4 mb-4 mb-lg-0">
                                    <div className="candidates-feature-info shadow-sm border-0">
                                        <div className="candidates-info-icon text-primary">
                                            <i className="fas fa-globe-asia"></i>
                                        </div>
                                        <div className="candidates-info-content">
                                            <h6 className="candidates-info-title">{item.name}</h6>
                                        </div>
                                        <div className="candidates-info-count">
                                            <h3 className="mb-0">{item.value}</h3>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {(data?.length > 0 && categories?.length > 0) && (
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="user-dashboard-info-box bg-white shadow-sm p-4 pb-0">
                                        <div id="chart">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
        </>
    )
}


Dashboard.propTypes = {
    getDashboardTiles: PropTypes.func.isRequired,
    getDashboardChart: PropTypes.func.isRequired,
    storeDashboard: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    getDashboardTiles: PropTypes.func.isRequired,
    getDashboardChart: PropTypes.func.isRequired,
    storeDashboard: state.dashboard
});

export default connect(mapStateToProps, {
    getDashboardTiles, getDashboardChart
})(Dashboard)