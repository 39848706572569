
import axios from "axios";
import { api } from "../../../services/apiHelpers"
import authentificationService from "../../../services/authentificationService"


export const EMPLOYER_LOGGED_OUT = "EMPLOYER_LOGGED_OUT";
export const SET_EMPLOYER_DATA = "SET_EMPLOYER_DATA";
export const REMOVE_EMPLOYER_DATA = "REMOVE_EMPLOYER_DATA";
export const GET_EMPLOYER_LIST = "GET_EMPLOYER_LIST";
export const GET_EMPLOYER_JOB_LIST = "GET_EMPLOYER_JOB_LIST";
export const GET_EMPLOYER_TENDER_LIST = "GET_EMPLOYER_TENDER_LIST";
export const EMPLOYER_ACTION_LOADING = "EMPLOYER_ACTION_LOADING";
export const EMPLOYER_ACTION_SUCCESS = "EMPLOYER_ACTION_SUCCESS";
export const EMPLOYER_ACTION_ERROR = "EMPLOYER_ACTION_ERROR";
export const EMPLOYER_ACTION_SUBMITING = "EMPLOYER_ACTION_SUBMITING"; //
export const EMPLOYER_ACTION_SUBMITED = "EMPLOYER_ACTION_SUBMITED";
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";
export const EMPLOYER_TABLE_CONTENT_LOADING = "EMPLOYER_TABLE_CONTENT_LOADING";
export const EMPLOYER_TABLE_CONTENT_LOADED = "EMPLOYER_TABLE_CONTENT_LOADED";
export const GET_EMPLOYER_LIST_INCLUDED_INACTIVE = "GET_EMPLOYER_LIST_INCLUDED_INACTIVE";
export const GET_EMPLOYER_JOB_LIST_INCLUDED_INACTIVE = "GET_EMPLOYER_LIST_INCLUDED_INACTIVE";
export const GET_EMPLOYER_TENDER_LIST_INCLUDED_INACTIVE = "GET_EMPLOYER_LIST_INCLUDED_INACTIVE";

export const getEmployerList = (includeInactive) => dispatch => {
  dispatch({
    type: EMPLOYER_ACTION_LOADING
  });
  return new Promise((resolve, reject) => {
    axios.get(api + "/Employers/list?includeInactive=" + includeInactive).then(res => {
      if (res.data != null) {
        if (includeInactive) {
          dispatch({
            type: GET_EMPLOYER_LIST_INCLUDED_INACTIVE,
            data: res.data.employersList
          });
          dispatch({
            type: EMPLOYER_ACTION_SUCCESS
          });
        }
        else {
          dispatch({
            type: GET_EMPLOYER_LIST,
            data: res.data.employersList
          });
          dispatch({
            type: EMPLOYER_ACTION_SUCCESS
          });
        }
        resolve(res.data);
      }
    }).catch(error => {
      return dispatch({
        type: EMPLOYER_ACTION_ERROR,
        data: error
      });
    });
  })
};

export const employerSearch = () => dispatch => {
  dispatch({
    type: EMPLOYER_ACTION_LOADING
  });
  return new Promise((resolve, reject) => {
    axios.get(api + "/Employers/list").then(res => {
      if (res.data != null) {


        dispatch({
          type: GET_EMPLOYER_LIST,
          data: res.data.employersList
        });
        dispatch({
          type: EMPLOYER_ACTION_SUCCESS
        });



        resolve(res.data)
      }
    }).catch(error => {
      return dispatch({
        type: EMPLOYER_ACTION_ERROR,
        data: error
      });

    })
  })
};

export const signupEmployer = (job) => dispatch => {
  dispatch({
    type: EMPLOYER_ACTION_SUBMITING
  });
  return new Promise((resolve, reject) => {

    axios.post(api + "/Employers/signup", job).then(res => {
      if (res.data.success) {
        dispatch({
          type: GET_EMPLOYER_LIST,
          data: res.data.employerList
        });
      }
      resolve(res.data);
    }).catch(error => {
      dispatch({
        type: EMPLOYER_ACTION_ERROR,
        data: error
      });
      resolve({ employer: null, employersList: null, success: false, message: "error.message" })
    });
  }).then(data => {
    dispatch({
      type: EMPLOYER_ACTION_SUBMITED
    });
    return data;
  });
};

export const createEmployer = (job) => dispatch => {
  dispatch({
    type: EMPLOYER_ACTION_SUBMITING
  });
  return new Promise((resolve, reject) => {

    axios.post(api + "/Employers/create", job).then(res => {
      if (res.data.Success) {
        dispatch({
          type: GET_EMPLOYER_LIST,
          data: res.data.employerList
        });
      }
      resolve(res.data);
    }).catch(error => {
      return dispatch({
        type: EMPLOYER_ACTION_ERROR,
        data: error
      });
    });
  }).then(data => {
    dispatch({
      type: EMPLOYER_ACTION_SUBMITED
    });
    return data;
  });
};

export const updateEmployer = (employers) => dispatch => {

  dispatch({
    type: EMPLOYER_ACTION_SUBMITING
  });
  return new Promise((resolve, reject) => {

    axios.post(api + "/Employers/update", employers).then(res => {
      if (res.data.Success) {
        dispatch({
          type: GET_EMPLOYER_LIST,
          data: res.data.employersList
        });
        // authentificationService.setSession(res.data.employer.id);
        // authentificationService.setEmployer(res.data.employer);
      }
      resolve(res.data);
    }).catch(error => {
      return dispatch({
        type: EMPLOYER_ACTION_ERROR,
        data: error
      });
    })
  }).then(data => {
    dispatch({
      type: EMPLOYER_ACTION_SUBMITED
    });
    return data;
  });
};

export const deleteEmployer = (employerId) => dispatch => {
  dispatch({
    type: EMPLOYER_ACTION_LOADING
  });
  return new Promise((resolve, reject) => {
    axios.get(api + "/Employers/delete/" + employerId).then(res => {
      if (res.data.success) {
        dispatch({
          type: GET_EMPLOYER_LIST,
          data: res.data.employersList
        });
      }
      dispatch({
        type: EMPLOYER_ACTION_SUCCESS
      });
      resolve(res.data);
    }).catch(error => {
      return dispatch({
        type: EMPLOYER_ACTION_ERROR,
        data: error
      });
    });
  })
};

export const hideAlert = () => dispatch => {
  dispatch({
    type: HIDE_ALERT
  });
};


// export const signupEmployer = (employer) => dispatch => {
//   dispatch({
//     type: EMPLOYER_ACTION_SUBMITING
//   });
//   return new Promise((resolve, reject) => {

//     axios.post(api + "/Employers/signup", employer).then(res => {

//       if (res.data.Success) {
//         dispatch({
//           type: GET_EMPLOYER_LIST,
//           data: res.data.EmployerList
//         });

//       }
//       resolve(res.data);
//     }).catch(error => {
//       return dispatch({
//         type: EMPLOYER_ACTION_ERROR,
//         data: error
//       });
//     });
//   }).then(data => {
//     dispatch({
//       type: EMPLOYER_ACTION_SUBMITED
//     });
//     return data;
//   });
// };

export const addAttachmentFromEmployer = (id, currentFile) => dispatch => {
  dispatch({
    type: EMPLOYER_ACTION_SUBMITING
  });
  let formData = new FormData();
  formData.append("file", currentFile);
  formData.append("employerId", id);
  return new Promise((resolve, reject) => {
    axios.post(api + "/Employers/attachments/add", formData).then(res => {
      if (res.data.success) {
        dispatch({
          type: GET_EMPLOYER_LIST,
          data: res.data.employersList
        });
      }
      resolve(res.data);
    }).catch(error => {
      return dispatch({
        type: EMPLOYER_ACTION_ERROR,
        data: error
      });
    });
  }).then(data => {
    dispatch({
      type: EMPLOYER_ACTION_SUBMITED
    });
    return data;
  });
}


export const deleteAttachmentFromEmployer = (employerId, attachment) => dispatch => {
  dispatch({
    type: EMPLOYER_ACTION_SUBMITING
  });
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      axios.get(api + "/Employers/attachments/delete/" + employerId + "/" + attachment).then(res => {
        if (res.data) {
          dispatch({
            type: GET_EMPLOYER_LIST,
            data: res.data.employersList
          });
          dispatch({
            type: EMPLOYER_ACTION_SUBMITED
          });
          resolve(res.data);
        }
        else {
          resolve(null);
        }

      }).catch(error => {
        return dispatch({
          type: EMPLOYER_ACTION_ERROR,
          data: error
        });
      });
    }, 1000);
  }).then(data => {
    dispatch({
      type: EMPLOYER_ACTION_SUBMITED
    });
    return data;
  });
}

export const loginEmployer = (loginData) => dispatch => {
  dispatch({ // informe le reducer de user qu'un formulaire le concernant est en cours de soumission 
    type: EMPLOYER_ACTION_SUBMITING
  });
  return new Promise((resolve, reject) => {
    axios.post(api + "/Employers/login", loginData).then(res => {
      if (res.data.success) { // Vérifie que le service a fournit une réponse souhaitée
        dispatch({ // Fournit la nouvelle liste d'utilisateur au reducer pour sa mise à jour 
          type: SET_EMPLOYER_DATA,
          data: res.data.employer
        });
        dispatch({ // informe le reducer de user que le formulaire le concernant est traité
          type: EMPLOYER_ACTION_SUBMITED
        });
        authentificationService.setSession(res.data.employer.id);
        authentificationService.setEmployer(res.data.employer);
      }
      resolve(res.data);
    }).catch(error => {
      return dispatch({
        type: EMPLOYER_ACTION_ERROR,
        data: error
      });
    });
  })
};

export const employerLogout = () => dispatch => {
  dispatch({
    type: REMOVE_EMPLOYER_DATA
  });

};

export const forgotedPassword = (email) => dispatch => {
  dispatch({
    type: EMPLOYER_ACTION_SUBMITING
  });
  return new Promise((resolve, reject) => {
    axios.post(api + "/Employers/forgotPassword", email).then(res => {
      dispatch({
        type: EMPLOYER_ACTION_SUBMITED
      });
      resolve(res.data);
    }).catch(error => {
      return dispatch({
        type: EMPLOYER_ACTION_ERROR,
        data: error
      });
    });
  })
};

export const changePassword = (requestData) => dispatch => {
  dispatch({
    type: EMPLOYER_ACTION_SUBMITING
  });
  return new Promise((resolve, reject) => {
    axios.post(api + "/Employers/changePassword", requestData).then(res => {
      dispatch({
        type: EMPLOYER_ACTION_SUBMITED
      });
      resolve(res.data);
    }).catch(error => {
      resolve(false);
      return dispatch({
        type: EMPLOYER_ACTION_ERROR,
        data: error
      });

    });
  })
};

export const resetPassword = (request) => dispatch => {
  dispatch({
    type: EMPLOYER_ACTION_SUBMITING
  });
  return new Promise((resolve, reject) => {
    axios.post(api + "/Employers/resetPassword", request).then(res => {
      dispatch({
        type: EMPLOYER_ACTION_SUBMITED
      });
      resolve(res.data);
    }).catch(error => {
      return dispatch({
        type: EMPLOYER_ACTION_ERROR,
        data: error
      });
    });
  })
};

export const clearAdminCache = () => dispatch => {
  return new Promise((resolve, reject) => {
    axios.get(api + "/Admin/cache/clear").then(res => {
      if (res.data) {
        resolve(res.data);
      }
    });
  })
};

export const getEmployerJobs = (employerId) => dispatch => {
  dispatch({
    type: EMPLOYER_ACTION_LOADING
  });
  return new Promise((resolve, reject) => {
    axios.get(api + "/Employers/jobs/list/" + employerId).then(res => {
      if (res.data != null) {
        dispatch({
          type: GET_EMPLOYER_JOB_LIST,
          data: res.data.documents
        });
        dispatch({
          type: EMPLOYER_ACTION_SUCCESS
        });
        resolve(res.data);
      }
    }).catch(error => {
      return dispatch({
        type: EMPLOYER_ACTION_ERROR,
        data: error
      });
    });
  })
};

export const getEmployerTenders = (employerId) => dispatch => {
  dispatch({
    type: EMPLOYER_ACTION_LOADING
  });
  return new Promise((resolve, reject) => {
    axios.get(api + "/Employers/tenders/list/" + employerId).then(res => {
      if (res.data != null) {
        dispatch({
          type: GET_EMPLOYER_TENDER_LIST,
          data: res.data.documents
        });
        dispatch({
          type: EMPLOYER_ACTION_SUCCESS
        });
        resolve(res.data);
      }
    }).catch(error => {
      return dispatch({
        type: EMPLOYER_ACTION_ERROR,
        data: error
      });
    });
  })
};