import axios from "axios";
import { api } from "../../services/apiHelpers";


export const GET_TRUSTED_EMPLOYER_ITEMS_LIST = "GET_TRUSTED_EMPLOYER_ITEMS_LIST";
export const ACTION_ERROR = "ACTION_ERROR";

export const getTrustedEmployerItemsList = () => dispatch => {
    return new Promise((resolve, reject) => {
        axios.get(api + "/Pages/trustedEmployers").then(res => {
            if (res.data) {
                dispatch({
                    type: GET_TRUSTED_EMPLOYER_ITEMS_LIST,
                    data: res.data.items
                });
                resolve(res.data);
            }
        }).catch(error => {
            return dispatch({
                type: ACTION_ERROR,
                data: error
            });
        });

    })

};

