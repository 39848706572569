import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CircularProgress } from '@material-ui/core';
import CustomAlert from '../../shared/views/CustomAlert';
import { getSmsNotificationList, updateSmsNotification, addAttachmentFromAds, deleteAttachmentFromAds } from "../../../redux/actions/admin/SmsNotificationActions"
import { DropzoneArea } from 'material-ui-dropzone';
import { categoriesExtended, types, NetworkOperator } from "../../../views/shared/Helper";
import Select from 'react-select';

let SmsNotificationDetail = (props) => {

    const { getSmsNotificationList, updateSmsNotification, storeSmsNotification = {} } = props;
    const [isLoading, setIsLoading] = React.useState(true);
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [isUploading, setIsUploading] = React.useState(false);
    const [smsNotification, setSmsNotification] = React.useState({ id: "", title: "", content: "", category: "", networkOperator: "", status: "", additionalNumbers: "" });
    const [smsNotificationId, SetSmsNotificationId] = React.useState("");
    const [preview, setPreview] = React.useState();
    const closeFileUploadModal = React.useRef();
    const closeFileDeleteModal = React.useRef();
    const [isDeletting, setIsDeletting] = React.useState(false);
    const [published, setPublished] = React.useState(false);
    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [count, setCount] = React.useState(0);



    useEffect(() => {
        let match = props.match;
        if (match !== undefined) {
            let params = match.params;
            if (params !== undefined && params.id !== undefined) {
                SetSmsNotificationId(params.id);
            }
        };
    }, []);

    useEffect(() => {
        if (smsNotificationId != "") {
            if (storeSmsNotification.smsNotificationList.length === 0 || !storeSmsNotification.smsNotificationList.some(a => a.id == smsNotificationId)) {
                getSmsNotificationList(true).then(resp => {
                    let queryResponse = resp.smsNotificationsList?.find(c => c.id == smsNotificationId);
                    if (queryResponse) {
                        if (queryResponse.additionalNumbers?.length) {                            
                            let result = "";
                            for (let index = 0; index < queryResponse.additionalNumbers?.length; index++) {
                                result += queryResponse.additionalNumbers[index];
                                result += index < queryResponse.additionalNumbers?.length - 1 ? "\n" : "";

                            }
                            queryResponse.additionalNumbers = result
                        }
                        setSmsNotification(queryResponse)
                        setCount(queryResponse.content.length)
                    }
                });
            }
            else if (storeSmsNotification.smsNotificationList.length > 0) {
                let queryResponse = storeSmsNotification.smsNotificationList.find(c => c.id == smsNotificationId);
                if (queryResponse) {                    
                    setSmsNotification(queryResponse)
                }
            }
        }
    }, [smsNotificationId]);

    useEffect(() => {
        
        if (smsNotification != null) {                
            setIsLoading(false);
        }
        else {
            setHasMissingValue(false);
        }
    }, [smsNotification])




    const handleUpdateSubmit = () => {
        setIsUpdating(true);
        setPublished(false);
        let smsNotificationCopy = smsNotification;
        if (!smsNotificationCopy.order || smsNotificationCopy.order == "") {
            smsNotificationCopy.order = 0;
        }
        smsNotificationCopy.lastSentRecipientIndex = null;
        if(smsNotification.additionalNumbers?.length === 0) {
            smsNotification.additionalNumbers = "";
        }
        updateSmsNotification(smsNotificationCopy).then(res => {
            if (res.success) {
                setIsUpdating(false);
                setPublished(true);
                let querySms = res.smsNotificationsList.find(smsNotification => smsNotification.id == smsNotificationId);
                if (querySms) {
                    if (querySms.additionalNumbers?.length) {                            
                            let result = "";
                            for (let index = 0; index < querySms.additionalNumbers?.length; index++) {
                                result += querySms.additionalNumbers[index];
                                result += index < querySms.additionalNumbers?.length - 1 ? "\n" : "";

                            }
                            querySms.additionalNumbers = result
                        }
                    setSmsNotification(querySms);
                }
            }
        });
    }

    return (
        <React.Fragment>
            {isLoading ? (
                <div className="row bg-white pt-4 mt-lg-0" >
                    <div className="col-lg-12">
                        <div className="loading-container bg-white">
                            <CircularProgress />
                        </div>
                    </div>
                </div >
            ) : (
                <>
                    <div className="row">
                        <div className="col-lg-12 mt-4 bg-white pt-0 mt-lg-0">
                            <div className="row mb-2">
                                <h4 className="mt-5" style={{ marginLeft: "25px" }}>Mis à jour d'un SMS</h4>
                            </div>
                            <div className="bg-light-2 p-4">
                                <div className="row mb-0">
                                    <h2 className="mb-0"><span>{updateSmsNotification.title}</span></h2>
                                </div>
                                <div style={{ marginLeft: "-12px" }}>
                                    <div className="form-row">
                                        <div className="form-group col-12">
                                            <label htmlFor="Email2">Titre</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="title"
                                                value={smsNotification?.title}
                                                onChange={(e) => {
                                                    let val = e.target?.value;
                                                    setSmsNotification(prevState => {
                                                        return { ...prevState, title: val }
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="form-group col-12">
                                            <label htmlFor="Email2">Contenu</label>
                                                <textarea
                                            maxLength={160}
                                                type="text"
                                                className="form-control"
                                                name="content"
                                                value={smsNotification.content}
                                                onChange={(e) => {
                                                    setCount(e.target.value.length)
                                                    let val = e.target?.value;
                                                    setSmsNotification(prevState => { return { ...prevState, content: val } });
                                                }}
                                            ></textarea>
                                                <div style={{ textAlign: "right" }}>{count} caractères sur 160</div>
                                            
                                        </div>
                                        <div className="form-group col-12"  >
                                            <label>Catégorie *</label>
                                            <Select
                                                options={categoriesExtended}
                                                loadingMessage="En cours de chargement"
                                                value={categoriesExtended.find(opt => opt?.value == smsNotification.category)}
                                                onChange={(option) => {
                                                    setSmsNotification(prevState => { return { ...prevState, category: option?.value } });
                                                }}
                                                isDisabled={""}
                                            />
                                        </div>
                                        <div className="form-group col-12">
                                            <label htmlFor="Email2">Numeros supplémentaires</label>
                                            <textarea
                                                type="textarea"
                                                value={smsNotification?.additionalNumbers}
                                                className="form-control"
                                                name="additionalNumbers"
                                                required
                                                onChange={(e) => {
                                                    let additionalNumbersCopy = smsNotification.additionalNumbers;
                                                    additionalNumbersCopy = e.target.value;
                                                    setSmsNotification(prevState => { return { ...prevState, additionalNumbers: additionalNumbersCopy } });
                                                }}
                                                onKeyDown={e => {
                                                    if (e.key == "Enter") {
                                                        let additionalNumbersCopy = smsNotification.additionalNumbers;
                                                        additionalNumbersCopy = e.target.value + "\n";
                                                        setSmsNotification(prevState => { return { ...prevState, additionalNumbers: additionalNumbersCopy } });
                                                    }
                                                }}
                                            ></textarea>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label>Opérateur *</label>

                                            <Select
                                                value={NetworkOperator.find(opt => opt?.value == smsNotification.networkOperator)}
                                                options={NetworkOperator}
                                                loadingMessage="En cours de chargement"
                                                onChange={(option) => {
                                                    setSmsNotification(prevState => { return { ...prevState, networkOperator: option?.value } });
                                                }}
                                                isDisabled={""}
                                            />
                                        </div>
                                        <div className="col-12 mt-4">
                                            <button
                                                className="btn btn-primary"
                                                onClick={handleUpdateSubmit}
                                                disabled={isUpdating}
                                            >
                                                Sauvegarder &nbsp; {isUpdating ? <CircularProgress size={15} /> : <span></span>}
                                            </button>
                                        </div>
                                        {published && (
                                            <div className="col-12 mt-4">
                                                <CustomAlert content="Le sms a été modifié avec succès." />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
            }
        </React.Fragment>
    )

}


SmsNotificationDetail.propTypes = {
    getSmsNotificationList: PropTypes.func.isRequired,
    updateSmsNotification: PropTypes.func.isRequired,
    storeSmsNotification: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    getSmsNotificationList: PropTypes.func.isRequired,
    updateSmsNotification: PropTypes.func.isRequired,
    storeSmsNotification: state.smsNotifications,
});

export default connect(mapStateToProps, { getSmsNotificationList, updateSmsNotification })(SmsNotificationDetail)