import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getJobList } from "../../redux/actions/JobActions"
import { createCandidate, getCandidateList, updateCandidate, addCandidateAttachments, deleteAttachment } from "../../redux/actions/admin/CandidateActions"
import { CircularProgress } from '@material-ui/core';
import defaultBanner from '../../../assets/images/bg/default-banner.jpg';
import { categories } from "../shared/Helper";
import CustomAlert from '../shared/views/CustomAlert';
import { getAdsList } from "../../redux/actions/admin/AdsActions";
import moment from 'moment';
import 'moment/locale/fr';
import localStorageService from '../../services/localStorageService';
import { DropzoneArea } from 'material-ui-dropzone';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import isMatch from 'date-fns/isMatch'
import validator from 'validator'
import siteService from '../../services/siteService';

const useStyles = makeStyles(theme => createStyles({
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
}));

let CandidateProfile = (props) => {

    const {

        updateCandidate,
        addCandidateAttachments,
        deleteAttachment,
        storeAds,
        getAdsList,
    } = props;

    const classes = useStyles();

    const closeFileDeleteModal = React.useRef();

    const currentSite = siteService.getCurrentSite();
    const [isLoading, setIsLoading] = React.useState(true);
    const [isDeletting, setIsDeletting] = React.useState(false);
    const [someTypeChanged, setSomeTypeChanged] = React.useState(false);
    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [isUploading, setIsUploading] = React.useState(false);
    const [updated, setUpdated] = React.useState(false);
    const [attachmentToDelete, setAttachmentToDelete] = React.useState(null);
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [displayingActiveAdsList, setDisplayingActiveAdsList] = React.useState([]);
    const [candidatToUpdate, setCandidatToUpdate] = React.useState(
        {
            firstName: "",
            lastName: "",
            phoneNumber1: "",
            phoneNumber2: "",
            email: "",
            password: "",
            confirmPassword: "",
            attachments: [
                {
                    id: "",
                    externalRepositoryId: "",
                    fullName: "",
                    name: "",
                    dateCreated: "",
                    dateModified: "",
                    modificationDate: null,
                    fileInfo: null,
                    url: "",
                    metadata: {},
                    parent: null
                }
            ]
        });
    const [invalideEmail, setInvalideEmail] = React.useState(false);
    const [invalideDate, setInvalideDate] = React.useState(false);


    useEffect(() => {
        let candidate = localStorageService.getItem("malibaara_auth_candidate");
        if (candidate) {
            setCandidatToUpdate(candidate);
        }
        if (storeAds.adsList.length == 0) {
            getAdsList(false).then(resp => {
                setDisplayingActiveAdsList(resp.adsList);
            })
        }
        else {
            setDisplayingActiveAdsList(storeAds.adsList);
        }
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        if (selectedFiles.length > 0) {
            handleAddAttachments();
        }
    }, [selectedFiles])


    useEffect(() => {
        if (candidatToUpdate !== undefined && candidatToUpdate.id !== undefined && candidatToUpdate.id != "" && isLoading == true) {
            setIsLoading(false);
        }
        if (candidatToUpdate?.firstName == ""
            || candidatToUpdate?.firstName === undefined
            || candidatToUpdate?.lastName == ""
            || candidatToUpdate?.lastName === undefined
            || candidatToUpdate?.email == ""
            || candidatToUpdate?.email === undefined
        ) {
            setHasMissingValue(true);
        }
        else if (candidatToUpdate?.firstName?.length > 50
            || candidatToUpdate?.lastName?.length > 50
            || candidatToUpdate?.birthPlace?.length > 50
            || candidatToUpdate?.phoneNumber1?.length > 50
            || candidatToUpdate?.phoneNumber2?.length > 50
            || candidatToUpdate?.email?.length > 50
            // || !isMatch(candidatToUpdate?.birthDay, 'yyyy-MM-dd')
            || (candidatToUpdate?.phoneNumber1 != "" && !(new RegExp(/^[0-9\b]+$/)).test(candidatToUpdate?.phoneNumber1))
            || (candidatToUpdate?.phoneNumber2 != "" && !(new RegExp(/^[0-9\b]+$/)).test(candidatToUpdate?.phoneNumber2))
            || (candidatToUpdate?.email != "" && !validator.isEmail(candidatToUpdate?.email))
        ) {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);
        }
        
    }, [candidatToUpdate]);

    const handleSubmit = () => {
        setIsUpdating(true);
        let candidat = candidatToUpdate;
        if (candidat.birthDay == "") {
            candidat.birthDay = null;
        }
        updateCandidate(candidat).then(data => {
            if (data.success) {
                setUpdated(true);
                setCandidatToUpdate(data.candidate);
            }
            setIsUpdating(false);
        });
    }

    const handleAddAttachments = () => {
        setIsUploading(true);
        let candidate = localStorageService.getItem("malibaara_auth_candidate");
        addCandidateAttachments(candidate?.id, selectedFiles).then(data => {
            if (data.success) {
                setCandidatToUpdate(data.candidate);
            }
            setIsUploading(false);
        });
    }

    const handleDeleteAttachment = (attachmentId) => {
        setIsDeletting(true);
        let candidate = localStorageService.getItem("malibaara_auth_candidate");
        deleteAttachment(candidate?.id, attachmentId).then(data => {
            if (data.success) {
                setCandidatToUpdate(data.candidate);
            }
            setAttachmentToDelete("");
            closeFileDeleteModal.current.click();
            setIsDeletting(false);
        });
    }

    const handleUpdateAttachmentsType = () => {
    }

    // Start keydown mechanism
    let onKeydown = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            if (!hasMissingValue) {
                handleSubmit();
            }
            event.preventDefault();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", onKeydown);
        return () => {
            document.removeEventListener("keydown", onKeydown);
        };
    }, [onKeydown]);
    //End keydown mechanism

    return (
        <React.Fragment>
            <div className="header-inner" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${defaultBanner})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 style={{ color: "white" }}>Profil</h2>
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><a href="/"> Accueil </a></li>
                                <li className="breadcrumb-item"><i className="fas fa-chevron-right"></i><a href="index.html"> Candidat </a></li>
                                <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> Profil </span></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading ? (
                <div className="loading-container">
                    <CircularProgress />
                    <div>Chargement du formulaire en cours...</div>
                </div>
            ) : (
                <>
                    <section className="space-ptb" style={{ padding: "50px 0" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-8">
                                    <div className="row">
                                        {/* Informations de base */}
                                        <div className="col-xl-12">
                                            <div className="bg-light-3 p-4">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="section-title">
                                                            <h2 className="title"><span>Mes informations</span></h2>
                                                            {/* <p></p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div _lpchecked="1">
                                                    <div className="form-row">
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label>Prénom *</label>
                                                                <input
                                                                    value={candidatToUpdate?.firstName}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="firstName"
                                                                    placeholder=""
                                                                    onChange={(e) => {
                                                                        let val = e.target?.value;
                                                                        setCandidatToUpdate(prevState => { return { ...prevState, firstName: val } });
                                                                    }}
                                                                />
                                                                {candidatToUpdate?.firstName?.length > 50 && (
                                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                                )}
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label>Nom *</label>
                                                                <input type="text"
                                                                    value={candidatToUpdate?.lastName}
                                                                    className="form-control"
                                                                    id="lastName"
                                                                    placeholder=""
                                                                    onChange={(e) => {
                                                                        let val = e.target?.value;
                                                                        setCandidatToUpdate(prevState => { return { ...prevState, lastName: val } });
                                                                    }}
                                                                />
                                                                {candidatToUpdate?.lastName?.length > 50 && (
                                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                                )}
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label>Date de naissance</label>
                                                                <input
                                                                    value={moment(candidatToUpdate?.birthDay)?.format('YYYY-MM-DD')}
                                                                    type="date"
                                                                    className="form-control"
                                                                    id="birthDay"
                                                                    placeholder=""
                                                                    onChange={(e) => {
                                                                        let val = e.target?.value;
                                                                        let match = isMatch(e.target.value, 'yyyy-MM-dd');
                                                                        setCandidatToUpdate(prevState => { return { ...prevState, birthDay: val } });
                                                                        if (match && val != "") {
                                                                            setInvalideDate(!match);
                                                                        }
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        let val = isMatch(e.target.value, 'yyyy-MM-dd');
                                                                        if (e.target.value != "") {
                                                                            setInvalideDate(!val);
                                                                        }
                                                                        if (!val) {
                                                                            setCandidatToUpdate(prevState => { return { ...prevState, birthDay: "" } });
                                                                        }
                                                                    }}
                                                                />
                                                                {invalideDate && (
                                                                    <p style={{ color: "red" }}>Format invalide</p>
                                                                )}
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label>Lieu de naissance</label>
                                                                <input type="text"
                                                                    value={candidatToUpdate?.birthPlace}
                                                                    className="form-control"
                                                                    id="birthPlace"
                                                                    placeholder=""
                                                                    onChange={(e) => {
                                                                        let val = e.target?.value;
                                                                        setCandidatToUpdate(prevState => { return { ...prevState, birthPlace: val } });
                                                                    }}
                                                                />
                                                                {candidatToUpdate?.birthPlace?.length > 50 && (
                                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                                )}
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label>Numero de téléphone ({siteService.getCurrentSite().operatorName1})</label>
                                                                <input
                                                                    type="text"
                                                                    value={candidatToUpdate?.phoneNumber1}
                                                                    className="form-control"
                                                                    id="phoneNumber1"
                                                                    placeholder=""
                                                                    onChange={(e) => {
                                                                        let val = e.target?.value;
                                                                        setCandidatToUpdate(prevState => { return { ...prevState, phoneNumber1: val } });
                                                                    }}
                                                                />
                                                                {candidatToUpdate?.phoneNumber1?.length > 50 && (
                                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                                )}
                                                                {(candidatToUpdate?.phoneNumber1 != "" && !(new RegExp(/^[0-9\b]+$/)).test(candidatToUpdate?.phoneNumber1)) && (
                                                                    <p style={{ color: "red" }}>Format invalide</p>
                                                                )}
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label>Numero de téléphone ({siteService.getCurrentSite().operatorName2})</label>
                                                                <input
                                                                    type="text"
                                                                    value={candidatToUpdate?.phoneNumber2}
                                                                    className="form-control"
                                                                    id="phoneNumber2"
                                                                    placeholder=""
                                                                    onChange={(e) => {
                                                                        let val = e.target?.value;
                                                                        setCandidatToUpdate(prevState => { return { ...prevState, phoneNumber2: val } });
                                                                    }}
                                                                />
                                                                {candidatToUpdate?.phoneNumber2?.length > 50 && (
                                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                                )}
                                                                {(candidatToUpdate?.phoneNumber2 != "" && !(new RegExp(/^[0-9\b]+$/)).test(candidatToUpdate?.phoneNumber2)) && (
                                                                    <p style={{ color: "red" }}>Format invalide</p>
                                                                )}
                                                            </div>
                                                            <div className="form-group col-md-12">
                                                                <label>Adresse email *</label>
                                                                <input
                                                                    value={candidatToUpdate?.email}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="email"
                                                                    placeholder=""
                                                                    onChange={(e) => {
                                                                        let val = e.target?.value;
                                                                        let isValide = validator.isEmail(e.target.value);
                                                                        setCandidatToUpdate(prevState => { return { ...prevState, email: val } });
                                                                        if (invalideEmail) {
                                                                            setInvalideEmail(!isValide);
                                                                        }
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        let val = validator.isEmail(e.target.value);
                                                                        setInvalideEmail(!val);
                                                                    }}
                                                                />
                                                                {candidatToUpdate?.email?.length > 50 && (
                                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                                )}
                                                                {(candidatToUpdate?.email != "" && invalideEmail) && (
                                                                    <p style={{ color: "red" }}>Format d'email invalide</p>
                                                                )}
                                                            </div>
                                                            <div className="form-group col-md-12">
                                                                <label>Catégorie</label>
                                                                <Select
                                                                    value={categories.filter(opt => candidatToUpdate?.categories?.some(cat => cat == opt.value))}
                                                                    options={categories}
                                                                    loadingMessage="En cours de chargement"
                                                                    isMulti
                                                                    onChange={(option) => {
                                                                        const unique = [...new Set(option.map(item => item.value))];
                                                                        setCandidatToUpdate(prevState => {
                                                                            return { ...prevState, categories: unique }
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-12">
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        checked={candidatToUpdate?.receiveEmailNotification}
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        id="accepts-01"
                                                                        onChange={(e) => {
                                                                            let val = e.target?.checked;
                                                                            setCandidatToUpdate(prevState => { return { ...prevState, receiveEmailNotification: val } });
                                                                        }}
                                                                    />
                                                                    <label className="custom-control-label" for="accepts-01">Recevoir les offres d'empoi par email</label>
                                                                </div>
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        checked={candidatToUpdate?.receiveSmsNotification}
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        id="accepts-02"
                                                                        onChange={(e) => {
                                                                            let val = e.target?.checked;
                                                                            setCandidatToUpdate(prevState => { return { ...prevState, receiveSmsNotification: val } });
                                                                        }}
                                                                    />
                                                                    <label className="custom-control-label" for="accepts-02">Recevoir les offres d'emploi par SMS</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-4">
                                                                <button
                                                                    className="btn btn-primary"
                                                                    onClick={handleSubmit}
                                                                    disabled={isUpdating || hasMissingValue}
                                                                >
                                                                    Soumettre &nbsp; {isUpdating ? <CircularProgress size={15} /> : <span></span>}
                                                                </button>
                                                            </div>
                                                            {updated && (
                                                                <div className="col-12 mt-4">
                                                                    <CustomAlert content={"<strong>Félicitations!</strong> Ce compte candidat a été modifié avec succès. "} />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Mes documents */}
                                        <div className="col-xl-12 pt-4 mt-lg-10">
                                            <div className="bg-light-3 p-4">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="section-title">
                                                            <h2 className="title"><span>Mes documents</span></h2>
                                                            <p>
                                                                Veuillez déposer dans cette section vos documents (CV, lettres de présentation, diplômes, attestations, permis de conduire, etc.)
                                                                <strong> en cliquant à l'intérieur de la bande blanche ci-dessous</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row" _lpchecked="2">
                                                    {isUploading ?
                                                        (
                                                            <div className="loading-container">
                                                                <CircularProgress />
                                                                <div>Chargement des documents en cours...</div>
                                                            </div>
                                                        )
                                                        :
                                                        (
                                                            <div className="col-lg-12">
                                                                <div className="form-group">
                                                                    <DropzoneArea
                                                                        clearOnUnmount={true}
                                                                        dropzoneText={isUploading ? "Chargement en cours..." : "Glisser ou cliquer ici pour déposer vos documents"}
                                                                        showPreviewsInDropzone={false}
                                                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                                        showAlerts={false}
                                                                        maxFileSize={5000000}
                                                                        filesLimit={10}
                                                                        onChange={(files) => {
                                                                            setSelectedFiles(files)
                                                                        }}
                                                                        onDelete={(file) => {
                                                                            let filteredFiles = [...selectedFiles.filter(fi => fi?.name != file?.name)];
                                                                            setSelectedFiles(filteredFiles);
                                                                            
                                                                        }}
                                                                    />
                                                                    <p style={{ marginTop: "9px", marginBottom: "0px", fontStyle: "italic" }}>Vous pouvez ajouter jusqu'à 10 fichiers dont la taille ne dépasse pas 5MB</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    <div className="col-5">Nom</div>
                                                    <div className="col-4">Date</div>
                                                    <div className="col-3"></div>

                                                    {candidatToUpdate?.attachments?.map((file, index) => (
                                                        <>
                                                            <div className="col-5">
                                                                <a href={file?.url} target="_blank">
                                                                    {file?.name}
                                                                </a>
                                                            </div>
                                                            <div className="col-4">{moment(file?.lastModifiedDate).format('Do MM YYYY')}</div>
                                                            <div className="col-3 text-right">
                                                                <a href={file.url} className="text-primary" target="_blank">
                                                                    <i className="fas fa-cloud-download-alt"></i>
                                                                </a>
                                                                <a href="javascript:void(0)" className="text-danger" style={{ marginLeft: "10px" }} data-toggle="modal" title="" data-target="#deleteAdsModal" data-original-title="Delete">
                                                                    <i className="far fa-trash-alt" onClick={() => setAttachmentToDelete(file)}></i>
                                                                </a>
                                                            </div></>
                                                    ))}
                                                    {someTypeChanged && (
                                                        <>
                                                            <div className="col-12 mt-4">
                                                                <button
                                                                    className="btn btn-primary"
                                                                    onClick={handleUpdateAttachmentsType}
                                                                >
                                                                    Soumettre &nbsp; {isUpdating ? <CircularProgress size={15} /> : <span></span>}
                                                                </button>
                                                            </div>
                                                            {updated && (
                                                                <div className="col-12 mt-4">
                                                                </div>
                                                            )}
                                                        </>
                                                    )}

                                                </div>
                                            </div>
                                        </div>

                                        {/* Start Delete file modale */}
                                        <div className="modal fade" id="deleteAdsModal" tabindex="-1" role="dialog" aria-modal="true" data-backdrop="static" data-keyboard="false">
                                            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header p-4">
                                                        <h4 className="mb-0 text-center">{"Suppression d'un fichier: " + attachmentToDelete?.name}</h4>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>

                                                    <form className="mt-2">
                                                        <div className="modal-body">
                                                            <div>
                                                                <div className="form-row">
                                                                    <p>Êtes-vous sûr de vouloir supprimer ce fichier ?</p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button
                                                                type="button"
                                                                data-dismiss="modal"
                                                                className="btn btn-primary"
                                                                ref={closeFileDeleteModal}
                                                            >
                                                                NON
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                onClick={(e) => handleDeleteAttachment(attachmentToDelete.id)}
                                                                disabled={isDeletting}
                                                            >
                                                                OUI &nbsp; {isDeletting ? <CircularProgress size={15} /> : <span></span>}
                                                            </button>
                                                        </div>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 mt-4 mt-xl-0">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="sidebar mb-0">
                                                <div className="widget border-0">
                                                    <div className="jobster-company-view">
                                                        <ul className="list-unstyled">
                                                            {
                                                                displayingActiveAdsList.map((ads, index) => (
                                                                    ads.attachments.map((attachment, index) => (
                                                                        <li>
                                                                            <div className="widget-box">
                                                                                <div className="d-flex">
                                                                                    <div>
                                                                                        <a href={ads.link} target={ads.openInNewTab ? "_blank" : ""}>

                                                                                            <img className="img-fluid" src={attachment.url} alt="" />
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    ))
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )
            }
        </React.Fragment >
    );
}

CandidateProfile.propTypes = {
    getJobList: PropTypes.func.isRequired,
    createCandidate: PropTypes.func.isRequired,
    getCandidateList: PropTypes.func.isRequired,
    storeJob: PropTypes.object.isRequired,
    storeAds: PropTypes.object.isRequired,
    getAdsList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    getJobList: PropTypes.func.isRequired,
    getCandidateList: PropTypes.func.isRequired,
    createCandidate: PropTypes.func.isRequired,
    updateCandidate: PropTypes.func.isRequired,
    addCandidateAttachments: PropTypes.func.isRequired,
    deleteAttachment: PropTypes.func.isRequired,
    storeJob: state.job,
    storeEmployer: state.employer,
    getAdsList: PropTypes.func.isRequired,
    storeAds: state.ads,
});

export default connect(mapStateToProps,
    {
        getJobList,
        createCandidate,
        getCandidateList,
        getAdsList,
        updateCandidate,
        addCandidateAttachments,
        deleteAttachment,
    }
)(CandidateProfile)