export const categories = [
    {
        label: "Administration", id: "Administration", value: "Administration"
    },
    {
        label: "Banque", id: "Banque", value: "Banque"
    },
    {
        label: "Assurance", id: "Assurance", value: "Assurance"
    },
    {
        label: "BTP", id: "BTP", value: "BTP"
    },
    {
        label: "Construction", id: "Construction", value: "Construction"
    },
    {
        label: "Commerce", id: "Commerce", value: "Commerce"
    },
    {
        label: "Vente", id: "Vente", value: "Vente"
    },
    {
        label: "Communication", id: "Communication", value: "Communication"
    },
    {
        label: "Marketing", id: "Marketing", value: "Marketing"
    },
    {
        label: "Animation", id: "Animation", value: "Animation"
    },
    {
        label: "Comptabilité", id: "Comptabilité", value: "Comptabilité"
    },
    {
        label: "Finance", id: "Finance", value: "Finance"
    },
    {
        label: "Economie", id: "Economie", value: "Economie"
    },
    {
        label: "éducation", id: "éducation", value: "éducation"
    },
    {
        label: "Hotellerie", id: "Hotellerie", value: "Hotellerie"
    },
    {
        label: "Restauration", id: "Restauration", value: "Restauration"
    },
    {
        label: "Tourisme", id: "Tourisme", value: "Tourisme"
    },
    {
        label: "Immobilier", id: "Immobilier", value: "Immobilier"
    },
    {
        label: "Informatique", id: "Informatique", value: "Informatique"
    },
    {
        label: "Télécommunication", id: "Télécommunication", value: "Télécommunication"
    },
    {
        label: "Juridique", id: "Juridique", value: "Juridique"
    },
    {
        label: "Mines", id: "Mines", value: "Mines"
    },
    {
        label: "Ressources Humaines", id: "Ressources Humaines", value: "Ressources Humaines"
    },
    {
        label: "Santé", id: "Santé", value: "Santé"
    },
    {
        label: "Véterinaire", id: "Véterinaire", value: "Véterinaire"
    },
    {
        label: "Transport", id: "Transport", value: "Transport"
    },
    {
        label: "Transit", id: "Transit", value: "Transit"
    },
    {
        label: "Logistique", id: "Logistique", value: "Logistique"
    },
    {
        label: "Sciences Sociales", id: "Sciences Sociales", value: "Sciences Sociales"
    },
    {
        label: "Sociologie", id: "Sociologie", value: "Sociologie"
    },
    {
        label: "Agriculture", id: "Agriculture", value: "Agriculture"
    },
    {
        label: "Elevage", id: "Elevage", value: "Elevage"
    },
    {
        label: "Environnement", id: "Environnement", value: "Environnement"
    },
    {
        label: "Hydraulique", id: "Hydraulique", value: "Hydraulique"
    },
    {
        label: "Secteur Informel", id: "Secteur Informel", value: "Secteur Informel"
    }
];

export const categoriesExtended = [    
    {
        label: "Toutes les catégories", id: "All", value: "All"
    },
    {
        label: "Aucune catégorie", id: "None", value: "None"
    },
    ...categories
];

export const types = [
    {
        label: "CDI", id: "CDI", value: "CDI"
    },
    {
        label: "CDD", id: "CDD", value: "CDD"
    },
    {
        label: "Stage", id: "Stage", value: "Stage"
    },
    {
        label: "Interim", id: "Interim", value: "Interim"
    },
    {
        label: "Inconnu", id: "Inconnu", value: "Inconnu"
    }
];

export const NetworkOperator = [
    {
        label: "Orange", id: "2", value: "2"
    },
    {
        label: "Malitel", id: "1", value: "1"
    }
]