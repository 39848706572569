import React, { useEffect, useRef } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getTenderList, tenderSearch, deleteTender, createTender } from "../../../redux/actions/TenderActions"
import { CircularProgress } from '@material-ui/core';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from 'moment';
import 'moment/locale/fr';
import siteService from '../../../services/siteService';
import { categories, types } from "../../../views/shared/Helper";
import Select from 'react-select';
import { getEmployerList } from "../../../redux/actions/admin/EmployerActions"
import validator from 'validator';
import CustomHtmlEditor from '../../shared/views/CustomHtmlEditor';
import CustomAlert from '../../shared/views/CustomAlert';

let TendersListing = (props) => {
    const descriptionHtmlEditor = useRef();
    const {
        tenderSearch,
        storeTender = {},
        getTenderList,
        deleteTender,
        createTender,
        storeEmployer = {},
        getEmployerList,
    } = props;

    const [isLoading, setIsLoading] = React.useState(true);
    const [tenderList, setTenderList] = React.useState([]);
    const [initialTenderList, setInitialTenderList] = React.useState([]);
    const closeDeleteTedersModal = React.useRef();
    const [isDeletting, setIsDeletting] = React.useState(false);
    const [tenderTodelete, setTenderTodelete] = React.useState(null);
    const currentSite = siteService.getCurrentSite();
    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [creationFailed, setCreationFailed] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);
    const [currentEmployer, setCurrentEmployer] = React.useState(null);
    const [employersListOptions, setEmployersListOptions] = React.useState([]);
    const [invalideEmail, setInvalideEmail] = React.useState(false);
    const closeCreateTenderModal = React.useRef();
    const [newTender, setNewTender] = React.useState(
        {
            title: "",
            companyName: "",
            employerId: "",
            applicationEmail: "",
            category: "",
            type: "",
            count: "",
            location: "",
            closingDate: new Date(),
            startingDate: new Date(),
            description: "",
            showEmailAddress: false,
            showPhoneNumber: false,
            // receiveEmailNotification: "",
        });


    // useEffect(() => {
    //     if (tenderSearchResult != null && isLoading == true) {
    //         setIsLoading(false);
    //     }
    //     if (Object.keys(storeTender.tenderSearchResult).length != 0 && storeTender.tenderSearchResult.constructor == Object && tenderSearchResult == null) {
    //         setTenderSearchResult(storeTender.tenderSearchResult);
    //         setDisplayingTenderList(storeTender.tenderSearchResult.documents);
    //     }
    // }, [tenderSearchResult]);

    useEffect(() => {
        if (tenderList.length == 0 && storeTender.tenderList.length == 0) {
            getTenderList(true).then(resp => {
                if (resp.success) {
                    setTenderList(resp.tendersList);
                    setInitialTenderList(resp.tendersList);
                    setIsLoading(false);
                }
            });
        }
        else if (tenderList.length == 0 && storeTender.tenderList.length > 0) {
            setTenderList(storeTender.tenderList);
            setInitialTenderList(storeTender.tenderList);
            setIsLoading(false);
        }
        //
        if (storeEmployer.employersList.length > 0 && employersListOptions.length == 0) {
            getEmployersListOptions(storeEmployer.employersList);
        }
        else if (storeEmployer.employersList.length == 0 && employersListOptions.length == 0) {
            getEmployerList(false).then(response => {
                if (response.success) {
                    getEmployersListOptions(response.employersList);
                };
            });
        }
    }, []);

    useEffect(() => {
        if (newTender.title == ""
            || newTender.title == undefined
            || newTender.companyName == ""
            || newTender.companyName == undefined
            || newTender.applicationEmail == ""
            || newTender.applicationEmail == undefined
            || newTender.category == ""
            || newTender.category == undefined
            || newTender.location == ""
            || newTender.location == undefined
            || newTender.description == ""
            || newTender.description == undefined
        ) {
            setHasMissingValue(true);
        }
        else if (newTender.location?.length > 200
            || (newTender.applicationEmail != "" && !validator.isEmail(newTender.applicationEmail) && newTender.applicationEmail > 200)
            || newTender?.title?.length > 200
        ) {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);
        }
    }, [newTender])

    // const checkboxHandleChange = (event) => {
    //     if (event.target.checked) {
    //         let filterValuesCopy = filterValues;
    //         filterValuesCopy.push(event.target.value);
    //         setFilterValues(filterValuesCopy)
    //         let documents = tenderSearchResult.documents;
    //         let result = [];
    //         filterValues.forEach(element => {
    //             let tenders = documents.filter(doc => doc.category == element);
    //             if (tenders != null) {
    //                 result = result.concat(tenders);
    //             }
    //         });
    //         setDisplayingTenderList(result);

    //     }
    //     else {
    //         let values = filterValues.filter(item => item != event.target.value);
    //         let documents = tenderSearchResult.documents;
    //         let result = [];
    //         if (filterValues.length > 0) {
    //             filterValues.forEach(element => {
    //                 let tenders = documents.filter(doc => doc.category == element);
    //                 if (tenders != null) {
    //                     result = result.concat(tenders);
    //                 }
    //             });
    //         }
    //         else {
    //             result = documents;
    //         }
    //         setDisplayingTenderList(result);
    //     }
    // }

    const handleSubmit = () => {
        setIsSaving(true);
        createTender(newTender).then(data => {
            if (data !== undefined && data !== null) {
                setIsSaving(false);
                setCreationFailed(false);
                closeCreateTenderModal.current.click();
                initializeNewTender();
                setIsLoading(true);
                getTenderList(true).then(resp => {
                    if (resp.success) {
                        setTenderList(resp.tendersList);
                        setInitialTenderList(resp.tendersList);
                        setIsLoading(false);
                    }
                });
            }
            else {
                setCreationFailed(true);
                setIsSaving(false)
            }
        });
    }

    const handleDeleteSubmit = () => {
        setIsDeletting(true);
        deleteTender(tenderTodelete.id).then(resp => {
            setTenderList(resp.tendersList);
            setInitialTenderList(resp.tendersList);
            setTenderTodelete(undefined);
            closeDeleteTedersModal.current.click();
            setIsDeletting(false);
        });
    }

    const handleSearchBoxChange = (event) => {
        let searchTerm = event.target.value;
        let filteredData = initialTenderList.filter(value => {
            return (
                value.tenderNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                value.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                value.employer?.companyName?.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });
        setTenderList(filteredData);
    }

    const initializeNewTender = () => {
        setNewTender({
            title: "",
            companyName: "",
            employerId: "",
            applicationEmail: "",
            category: "",
            type: "",
            count: "",
            location: "",
            closingDate: new Date(),
            startingDate: new Date(),
            description: "",
            showEmailAddress: false,
            showPhoneNumber: false,
            // receiveEmailNotification: "",
        })
    }

    const getEmployersListOptions = (employers) => {
        let result = [];
        employers.forEach(employer => {
            result.push({ label: employer.companyName, id: employer.id, value: employer.id, email: employer.companyEmailContact });
        });
        setEmployersListOptions(result);
    }

    return (
        <React.Fragment>
            <Helmet>
                <title> Administration | Appel d'offres   | {currentSite.fullName}</title>
            </Helmet>
            {isLoading ? (
                <div className="row bg-white pt-4 mt-lg-0">
                    <div className="col-lg-12">
                        <div className="loading-container bg-white">
                            <CircularProgress />
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="row bg-white pt-4 mt-lg-0">
                        <div className="col-lg-12 mt-4 mt-lg-0">
                            <div className="row mb-4">
                                <div className="col-md-7 col-sm-5 d-flex align-items-center">
                                    <div className="section-title-02 mb-0 ">
                                        <h4 className="mb-0" style={{ marginLeft: "10px" }}>{tenderList.length} Appel d'offres</h4>
                                    </div>
                                </div>
                                <div className="col-md-5 col-sm-7 mt-3 mt-sm-0" style={{ textAlign: "right" }}>
                                    <a className="btn btn-md ml-sm-auto btn-primary" data-toggle="modal" data-target="#createJob">Ajouter un appel d'offre</a>
                                </div>

                            </div>
                            <div className="row mb-4">
                                <div className="col-md-5 col-sm-7 mt-3 mt-sm-0">
                                    <div className="search">
                                        <i className="fas fa-search"></i>
                                        <input type="text" className="form-control" placeholder="Rechercher..." onChange={handleSearchBoxChange} />
                                    </div>
                                </div>
                            </div>
                            <table className="table table-striped" id="table"
                                data-toggle="table"
                                data-height="460"
                                data-pagination="true"
                                data-pagination-h-align="left"
                                data-pagination-detail-h-align="right">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ width: "10%" }}>Numéro</th>
                                        <th scope="col" style={{ width: "45%" }}>Titre</th>
                                        <th scope="col" style={{ width: "25%" }}>Employeur</th>
                                        <th scope="col" style={{ width: "15%" }}>Date</th>
                                        <th scope="col" style={{ width: "5%" }}>Statut</th>
                                        <th scope="col" style={{ width: "5%" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tenderList.map((tender, index) => (
                                        <tr key={index}>
                                            <td>{tender.tenderNumber}</td>
                                            <td>
                                                {tender.title}
                                            </td>
                                            <td>
                                                {tender.employer?.companyName}
                                            </td>
                                            <td>
                                                {moment(tender.postedDate).format('Do MMMM YYYY')}
                                            </td>
                                            <td>
                                                {tender.isActive ? (<div className="active">Actif</div>) : (<div className="inactive">Inactif</div>)}
                                            </td>
                                            <td>
                                                <ul className="list-unstyled mb-0 d-flex justify-content-end">
                                                    <li><Link to={"/admin/tender/details/" + tender.id}><i className="fas fa-pencil-alt"></i></Link></li>
                                                    <li><a href="assets/#" className="text-danger" data-toggle="modal" title="" data-target="#deleteTender" data-original-title="Delete"><i className="far fa-trash-alt" onClick={() => setTenderTodelete(tender)}></i></a></li>
                                                </ul>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* Start Delete user modale */}
                    <div className="modal fade" id="deleteTender" tabIndex="-1" role="dialog" aria-modal="true">
                        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header p-4">
                                    <h4 className="mb-0 text-center">Suppression d'un appel offre: {tenderTodelete?.title}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <form className="mt-2">
                                    <div className="modal-body">
                                        <div>
                                            <div className="form-row">
                                                <p>Voulez vous supprimer cette offre?</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-dismiss="modal"
                                            ref={closeDeleteTedersModal}
                                        >
                                            NON
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={handleDeleteSubmit}
                                            disabled={isDeletting}
                                        >
                                            OUI &nbsp; {isDeletting ? <CircularProgress size={15} /> : <span></span>}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* End Delete user modale */}

                    {/* Start Create tender modale */}
                    <div className="modal fade" id="createJob" tabIndex="-1" role="dialog" aria-modal="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header p-4">
                                    <h4 className="mb-0 text-center">Création d'un appel d'offre</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div>
                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <label>Titre du poste *</label>
                                                <input
                                                    value={newTender?.title}
                                                    type="text"
                                                    className="form-control"
                                                    name="title"
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setNewTender(prevState => { return { ...prevState, title: val } });
                                                    }}
                                                    placeholder=""
                                                />
                                                {newTender?.title?.length > 200 && (
                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                )}
                                            </div>
                                            {currentEmployer ?
                                                (
                                                    <>
                                                        <div className="form-group col-md-12">
                                                            <label>Nom de la société *</label>
                                                            <input
                                                                value={newTender?.companyName}
                                                                className="form-control"
                                                                name="email"
                                                                placeholder=""
                                                                disabled
                                                            />
                                                        </div>
                                                    </>
                                                ) :
                                                (
                                                    <>
                                                        <div class="form-group col-md-12">
                                                            <label>Nom de la société *</label>
                                                            <Select
                                                                options={employersListOptions}
                                                                loadingMessage="En cours de chargement"
                                                                onChange={(option) => {
                                                                    setNewTender(prevState => { return { ...prevState, employerId: option?.value, companyName: option.label, applicationEmail: option.email } });
                                                                }}
                                                            />
                                                            <p style={{ marginTop: "9px", marginBottom: "0px", fontStyle: "italic" }}>Si votre société ne se trouve pas dans cette liste, veuillez cliquez sur <Link to={"/employers/signup?redirect=/tenders/post"}>lien</Link> pour créer un nouveau compte</p>
                                                        </div>
                                                    </>
                                                )}

                                            <div className="form-group col-md-12">
                                                <label>Email *</label>
                                                <input
                                                    value={newTender?.applicationEmail}
                                                    type="email"
                                                    className="form-control"
                                                    name="applicationEmail"
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        let isValide = validator.isEmail(e.target.value);
                                                        setNewTender(prevState => { return { ...prevState, applicationEmail: val } });
                                                        if (invalideEmail) {
                                                            setInvalideEmail(!isValide);
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        let val = validator.isEmail(e.target.value);
                                                        setInvalideEmail(!val);
                                                    }}
                                                    placeholder=""
                                                />
                                                {(newTender.email != "" && invalideEmail) && (
                                                    <p style={{ color: "red" }}>Format d'email invalide</p>
                                                )}
                                                {newTender?.applicationEmail?.length > 200 && (
                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                )}
                                            </div>
                                            <div className="form-group col-sm-6 col-xl-6">
                                                <label>Catégorie *</label>
                                                <Select
                                                    options={categories}
                                                    loadingMessage="En cours de chargement"
                                                    onChange={(option) => {
                                                        setNewTender(prevState => { return { ...prevState, category: option?.label } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <label>Date limite de dépot</label>
                                                <div className="input-group date">
                                                    <input
                                                        value={newTender?.closingDate}
                                                        type="date"
                                                        className="form-control"
                                                        name="closingDate"
                                                        onChange={(e) => {
                                                            let val = e.target?.value;
                                                            setNewTender(prevState => { return { ...prevState, closingDate: val } });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Ville *</label>
                                                <input
                                                    value={newTender?.location}
                                                    type="text"
                                                    className="form-control"
                                                    name="location"
                                                    placeholder=""
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setNewTender(prevState => { return { ...prevState, location: val } });
                                                    }}
                                                />
                                                {newTender.location.length > 200 && (
                                                    <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                )}
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Description *</label>
                                                <CustomHtmlEditor
                                                    onChange={(value, editor) => {
                                                        setNewTender(prevState => { return { ...prevState, description: descriptionHtmlEditor.current.getContent() } });
                                                    }}
                                                    ref={descriptionHtmlEditor}
                                                    initialValue="" />
                                            </div>
                                            <div className="form-group col-12 mt-3">
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        id="accepts-03"
                                                        type="checkbox"
                                                        checked={newTender.showEmailAddress}
                                                        className="custom-control-input"
                                                        name="showEmailAddress"
                                                        onClick={(e) => {
                                                            let val = e.target?.checked;
                                                            setNewTender(prevState => {
                                                                return { ...prevState, showEmailAddress: val }
                                                            });
                                                        }}
                                                    />
                                                    <label className="custom-control-label" for="accepts-03">Afficher mon adresse email sur la description de l'appel d'offre</label>
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        id="accepts-04"
                                                        type="checkbox"
                                                        checked={newTender.showPhoneNumber}
                                                        className="custom-control-input"
                                                        name="showPhoneNumber"
                                                        onClick={(e) => {
                                                            let val = e.target?.checked;
                                                            setNewTender(prevState => {
                                                                return { ...prevState, showPhoneNumber: val }
                                                            });
                                                        }}
                                                    />
                                                    <label className="custom-control-label" for="accepts-04">Afficher mon numéro de téléphone sur la description de l'appel d'offre</label>
                                                </div>
                                            </div>

                                            {creationFailed && (
                                                <div className="col-12 mt-4">
                                                    <CustomAlert content="Une erreur s'est produite lors de cette opération. Veuillez réessayer ou contacter l'administrateur du système." type="alert-danger" />
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                        ref={closeCreateTenderModal}
                                    >
                                        Annuler
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={handleSubmit}
                                        disabled={isSaving || hasMissingValue}
                                    >
                                        Soumettre &nbsp; {isSaving ? <CircularProgress size={15} /> : <span></span>}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* End Create tender modale */}
                </>
            )
            }
        </React.Fragment >
    );

}
TendersListing.propTypes = {
    tenderSearch: PropTypes.func.isRequired,
    getTenderList: PropTypes.func.isRequired,
    storeTender: PropTypes.object.isRequired,
    deleteTender: PropTypes.func.isRequired,
    createTender: PropTypes.func.isRequired,
    getEmployerList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    tenderSearch: PropTypes.func.isRequired,
    getTenderList: PropTypes.func.isRequired,
    getEmployerList: PropTypes.func.isRequired,
    createTender: PropTypes.func.isRequired,
    deleteTender: PropTypes.func.isRequired,
    storeTender: state.tender,
    storeEmployer: state.employer,
});

export default connect(mapStateToProps, { tenderSearch, getTenderList, deleteTender, createTender, getEmployerList })(TendersListing)