import React, { useEffect, useRef } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getJobList } from "../../redux/actions/JobActions"
import { updateEmployer, addAttachmentFromEmployer, deleteAttachmentFromEmployer } from "../../redux/actions/admin/EmployerActions"
import { CircularProgress } from '@material-ui/core';
import defaultBanner from '../../../assets/images/bg/default-banner.jpg';
import CustomAlert from '../shared/views/CustomAlert';
import { getAdsList } from "../../redux/actions/admin/AdsActions";
import moment from 'moment';
import 'moment/locale/fr';
import localStorageService from '../../services/localStorageService';
import { DropzoneArea } from 'material-ui-dropzone';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import validator from 'validator'
import siteService from '../../services/siteService';

const useStyles = makeStyles(theme => createStyles({
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
}));

let EmployerProfile = (props) => {
    const descriptionHtmlEditor = useRef();
    const {
        updateEmployer,
        addAttachmentFromEmployer,
        deleteAttachmentFromEmployer,
        storeAds,
        getAdsList,
    } = props;


    const closeFileDeleteModal = React.useRef();

    const currentSite = siteService.getCurrentSite();
    const [isLoading, setIsLoading] = React.useState(true);
    const [isDeletting, setIsDeletting] = React.useState(false);
    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [updated, setUpdated] = React.useState(false);
    const [attachmentToDelete, setAttachmentToDelete] = React.useState(null);
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [initialFile, setInitialFile] = React.useState(null);
    const [displayingActiveAdsList, setDisplayingActiveAdsList] = React.useState([]);
    const [employerToUpdate, setEmployerToUpdate] = React.useState(
        {
            id: "",
            companyName: "",
            description: "",
            companyEmailContact: "",
            companyPhoneNumber1Contact: "",
            companyPhoneNumber2Contact: "",
            email: "",
            password: "",

            attachments: [
                {
                    id: "",
                    externalRepositoryId: "",
                    fullName: "",
                    name: "",
                    dateCreated: "",
                    dateModified: "",
                    modificationDate: null,
                    fileInfo: null,
                    url: "",
                    metadata: {},
                    parent: null
                }
            ]
        });
    const [invalideEmail1, setInvalideEmail1] = React.useState(false);
    const [invalideEmail2, setInvalideEmail2] = React.useState(false);


    useEffect(() => {
        let employer = localStorageService.getItem("malibaara_auth_employer");
        if (employer) {
            setEmployerToUpdate(employer);
            if (employer.attachments.length > 0) {
                let attachment = employer?.attachments[0];
                let file = new File([attachment?.name], attachment?.fullName, { type: "text/plain" });
                setInitialFile(file);
            }

        }
        if (storeAds.adsList.length == 0) {
            getAdsList(false).then(resp => {
                setDisplayingActiveAdsList(resp.adsList);
            })
        }
        else {
            setDisplayingActiveAdsList(storeAds.adsList);
        }
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        if (selectedFiles.length > 0) {
            handleAddAttachments();
        }
    }, [selectedFiles])

    

    useEffect(() => {
        if (employerToUpdate != undefined && employerToUpdate.id != undefined && employerToUpdate.id != "" && isLoading == true) {
            setIsLoading(false);
        }
        if (employerToUpdate?.companyName == ""
            || employerToUpdate?.companyName == undefined
            || employerToUpdate?.companyPhoneNumber1Contact == ""
            || employerToUpdate?.companyPhoneNumber1Contact == undefined
            || employerToUpdate?.email == ""
            || employerToUpdate?.email == undefined
        ) {
            setHasMissingValue(true);
        }
        else if (employerToUpdate?.companyName?.length > 200
            || employerToUpdate?.companyPhoneNumber1Contact?.length > 50
            || employerToUpdate?.companyPhoneNumber2Contact?.length > 50
            || employerToUpdate?.email?.length > 50
            || employerToUpdate?.companyEmailContact?.length > 50
            || (employerToUpdate?.companyPhoneNumber1Contact != "" && !(new RegExp(/^[0-9\b]+$/)).test(employerToUpdate?.companyPhoneNumber1Contact))
            || (employerToUpdate?.companyPhoneNumber2Contact != "" && !(new RegExp(/^[0-9\b]+$/)).test(employerToUpdate?.companyPhoneNumber2Contact))
            || (employerToUpdate?.email != "" && !validator.isEmail(employerToUpdate?.email))
            || (employerToUpdate?.companyEmailContact != "" && !validator.isEmail(employerToUpdate?.companyEmailContact))
        ) {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);
        }
    }, [employerToUpdate])

    const handleSubmit = () => {
        setIsUpdating(true);
        updateEmployer(employerToUpdate).then(data => {
            if (data.success) {
                setUpdated(true);
                setEmployerToUpdate(data.employer);
            }
            setIsUpdating(false);
        });
    }

    const handleAddAttachments = () => {
        let employer = localStorageService.getItem("malibaara_auth_employer");
        addAttachmentFromEmployer(employer?.id, selectedFiles).then(data => {
            if (data.success) {
                let val = data?.employersList?.find(emp => emp.id == employer.id);
                if (val) {
                    setEmployerToUpdate(val);                  
                }
            }
        });
    }

    const handleDeleteAttachment = (attachmentId) => {
        setIsDeletting(true);
        let employer = localStorageService.getItem("malibaara_auth_employer");
        deleteAttachmentFromEmployer(employer?.id, attachmentId).then(data => {
            if (data.success) {
                setEmployerToUpdate(data.employer);
            }
            setAttachmentToDelete("");
            closeFileDeleteModal.current.click();
            setIsDeletting(false);
        });
    }

    // Start keydown mechanism
    let onKeydown = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            if (!hasMissingValue) {
                handleSubmit();
            }
            event.preventDefault();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", onKeydown);
        return () => {
            document.removeEventListener("keydown", onKeydown);
        };
    }, [onKeydown]);
    //End keydown mechanism

    return (
        <React.Fragment>
            <div className="header-inner" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${defaultBanner})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 style={{ color: "white" }}>Profil</h2>
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><a href="/"> Accueil </a></li>
                                <li className="breadcrumb-item"><i className="fas fa-chevron-right"></i><a href="index.html"> Employer </a></li>
                                <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> Profil </span></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <section className="space-ptb" style={{ padding: "50px 0" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8">
                            <div className="row">
                                {/* Informations de base */}
                                <div className="col-xl-12">
                                    <div className="bg-light-3 p-4">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="section-title">
                                                    <h2 className="title"><span>Mes informations</span></h2>
                                                    {/* <p></p> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div _lpchecked="1">
                                            <div className="form-row">
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label>Nom de la société *</label>
                                                        <input
                                                            value={employerToUpdate?.companyName}
                                                            type="text"
                                                            className="form-control"
                                                            id="companyName"
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                let val = e.target?.value;
                                                                setEmployerToUpdate(prevState => { return { ...prevState, companyName: val } });
                                                            }}
                                                        />
                                                        {employerToUpdate?.companyName?.length > 200 && (
                                                            <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>Adresse email de la personne à contacter</label>
                                                        <input
                                                            value={employerToUpdate?.companyEmailContact}
                                                            type="email"
                                                            className="form-control"
                                                            id="companyEmailContact"
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                let val = e.target?.value;
                                                                setEmployerToUpdate(prevState => { return { ...prevState, companyEmailContact: val } });
                                                            }}
                                                            onBlur={(e) => {
                                                                let val = validator.isEmail(e.target.value);
                                                                setInvalideEmail1(!val);
                                                            }}
                                                        />
                                                        {employerToUpdate?.companyEmailContact?.length > 50 && (
                                                            <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                        )}
                                                        {(employerToUpdate?.companyEmailContact != "" && invalideEmail1) && (
                                                            <p style={{ color: "red" }}>Format d'email invalide</p>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>Adresse email pour accéder au site *</label>
                                                        <input type="text"
                                                            value={employerToUpdate?.email}
                                                            className="form-control"
                                                            id="email"
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                let val = e.target?.value;
                                                                setEmployerToUpdate(prevState => { return { ...prevState, email: val } });
                                                            }}
                                                            onBlur={(e) => {
                                                                let val = validator.isEmail(e.target.value);
                                                                setInvalideEmail2(!val);
                                                            }}
                                                        />
                                                        {employerToUpdate?.email?.length > 50 && (
                                                            <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                        )}
                                                        {(employerToUpdate?.email != "" && invalideEmail2) && (
                                                            <p style={{ color: "red" }}>Format d'email invalide</p>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>Numéro de téléphone *</label>
                                                        <input
                                                            type="text"
                                                            value={employerToUpdate?.companyPhoneNumber1Contact}
                                                            className="form-control"
                                                            id="companyPhoneNumber1Contact"
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                let val = e.target?.value;
                                                                setEmployerToUpdate(prevState => { return { ...prevState, companyPhoneNumber1Contact: val } });
                                                            }}
                                                        />
                                                        {employerToUpdate?.companyPhoneNumber1Contact?.length > 50 && (
                                                            <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                        )}
                                                        {(employerToUpdate?.companyPhoneNumber1Contact != "" && !(new RegExp(/^[0-9\b]+$/)).test(employerToUpdate?.companyPhoneNumber1Contact)) && (
                                                            <p style={{ color: "red" }}>Format invalide</p>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>Autre numéro de téléphone</label>
                                                        <input
                                                            type="text"
                                                            value={employerToUpdate?.companyPhoneNumber2Contact}
                                                            className="form-control"
                                                            id="companyPhoneNumber2Contact"
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                let val = e.target?.value;
                                                                setEmployerToUpdate(prevState => { return { ...prevState, companyPhoneNumber2Contact: val } });
                                                            }}
                                                        />
                                                        {employerToUpdate?.companyPhoneNumber2Contact?.length > 50 && (
                                                            <p style={{ color: "red" }}>le nombre maximum de caractères a été dépassé</p>
                                                        )}
                                                        {(employerToUpdate?.companyPhoneNumber2Contact != "" && !(new RegExp(/^[0-9\b]+$/)).test(employerToUpdate?.companyPhoneNumber2Contact)) && (
                                                            <p style={{ color: "red" }}>Format invalide</p>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <label>Description</label>
                                                        <textarea
                                                            value={employerToUpdate?.description}
                                                            type="text"
                                                            className="form-control"
                                                            id="description"
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                let val = e.target?.value;
                                                                setEmployerToUpdate(prevState => { return { ...prevState, description: val } });
                                                            }}
                                                        ></textarea>
                                                    </div>
                                                    <div className="col-12 mt-4">
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={handleSubmit}
                                                            disabled={isUpdating || hasMissingValue}
                                                        >
                                                            Soumettre &nbsp; {isUpdating ? <CircularProgress size={15} /> : <span></span>}
                                                        </button>
                                                    </div>
                                                    {updated && (
                                                        <div className="col-12 mt-4">
                                                            <CustomAlert content={"<strong>Félicitations!</strong> Ce compte employeur a été modifié avec succès. "} />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Mes documents */}
                                {/* <div className="col-xl-12 pt-4 mt-lg-10">
                                    <div className="bg-light-3 p-4">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="section-title">
                                                    <h2 className="title"><span>Mes documents</span></h2>
                                                    <p>
                                                        Veuillez déposer dans cette section le logo de la société

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div _lpchecked="2">
                                            <div className="form-group col-md-12">
                                                <DropzoneArea
                                                    initialFiles={[initialFile]}
                                                    // acceptedFiles={['image/*']}
                                                    clearOnUnmount={true}
                                                    dropzoneText="Glisser déposer un fichier ou cliquer"
                                                    showPreviewsInDropzone={true}
                                                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                    showAlerts={false}
                                                    filesLimit={1}
                                                    onChange={(files) => {
                                                        setSelectedFiles(files)
                                                        
                                                    }}
                                                    onDelete={(file) => {
                                                        let filteredFiles = [...selectedFiles.filter(fi => fi?.name != file?.name)];
                                                        setSelectedFiles(filteredFiles);
                                                     
                                                    }}
                                                />
                                            </div>
                                            <table className="table manage-candidates-top mb-4">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "50%" }}>Nom</th>
                                                        <th style={{ width: "20%" }}>Date</th>
                                                        <th style={{ width: "5%" }} className="action text-right"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <>
                                                        {employerToUpdate?.attachments?.map((file, index) => (
                                                            <tr key={index}>

                                                                <td>
                                                                    <a href={file?.url} target="_blank">
                                                                        {file?.name}
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    {moment(file?.lastModifiedDate).format('Do MM YYYY')}
                                                                </td>
                                                                <td>
                                                                    <a href="javascript:void(0)" className="text-danger text-right" data-toggle="modal" title="" data-target="#deleteEmployerAttachmentModal" data-original-title="Delete">
                                                                        <i className="far fa-trash-alt text-right" onClick={() => setAttachmentToDelete(file)}></i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div> */}

                                {/* Start Delete file modale */}
                                <div className="modal fade" id="deleteEmployerAttachmentModal" tabindex="-1" role="dialog" aria-modal="true" data-backdrop="static" data-keyboard="false">
                                    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header p-4">
                                                <h4 className="mb-0 text-center">{"Suppression d'un fichier: " + attachmentToDelete?.name}</h4>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>

                                            <form className="mt-2">
                                                <div className="modal-body">
                                                    <div>
                                                        <div className="form-row">
                                                            <p>Êtes-vous sûr de vouloir supprimer ce fichier ?</p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        data-dismiss="modal"
                                                        className="btn btn-primary"
                                                        ref={closeFileDeleteModal}
                                                    >
                                                        NON
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        onClick={(e) => handleDeleteAttachment(attachmentToDelete.id)}
                                                        disabled={isDeletting}
                                                    >
                                                        OUI &nbsp; {isDeletting ? <CircularProgress size={15} /> : <span></span>}
                                                    </button>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 mt-4 mt-xl-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="sidebar mb-0">
                                        <div className="widget border-0">
                                            <div className="jobster-company-view">
                                                <ul className="list-unstyled">
                                                    {
                                                        displayingActiveAdsList.map((ads, index) => (
                                                            ads.attachments.map((attachment, index) => (
                                                                <li>
                                                                    <div className="widget-box">
                                                                        <div className="d-flex">
                                                                            <div>
                                                                                <a href={ads.link} target={ads.openInNewTab ? "_blank" : ""}>

                                                                                    <img className="img-fluid" src={attachment.url} alt="" />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

EmployerProfile.propTypes = {
    getJobList: PropTypes.func.isRequired,
    storeJob: PropTypes.object.isRequired,
    storeAds: PropTypes.object.isRequired,
    getAdsList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    getJobList: PropTypes.func.isRequired,
    updateEmployer: PropTypes.func.isRequired,
    addAttachmentFromEmployer: PropTypes.func.isRequired,
    deleteAttachmentFromEmployer: PropTypes.func.isRequired,
    storeJob: state.job,
    storeEmployer: state.employer,
    getAdsList: PropTypes.func.isRequired,
    storeAds: state.ads,
});

export default connect(mapStateToProps,
    {
        getJobList,
        getAdsList,
        updateEmployer,
        addAttachmentFromEmployer,
        deleteAttachmentFromEmployer,
    }
)(EmployerProfile)