import React from 'react';

function CustomAlert(props) {
    return (
        <React.Fragment>
            <div className={`alert ${props.type !== undefined ? props.type : 'alert-success'}`}>
                <div dangerouslySetInnerHTML={{ __html: props.content }} />
            </div>
        </React.Fragment>
    )
}

export default CustomAlert;