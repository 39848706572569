import {
    SET_CANDIDATE_DATA,
    REMOVE_CANDIDATE_DATA,
    CANDIDATE_LOGGED_OUT,
    CANDIDATE_ACTION_ERROR,
    CANDIDATE_ACTION_LOADING, CANDIDATE_ACTION_SUBMITED,
    CANDIDATE_ACTION_SUBMITING,
    CANDIDATE_ACTION_SUCCESS,
    GET_CANDIDATE_LIST,

} from "../../actions/admin/CandidateActions";


const initialState = {
    currentCandidate: null,
    candidateList: [],
    loading: false,
    success: false,
    failed: false,
    submitting: false,
    errorMessage: "",
    showAlert: false,
    alertMessage: "",
    alertVariant: "",
};

const candidateReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_CANDIDATE_DATA: {
            return {
                ...state,
                currentCandidate: { ...action.data }
            };
        }
        case REMOVE_CANDIDATE_DATA: {
            return {
                ...state,
                currentCandidate: null
            };
        }
        case CANDIDATE_LOGGED_OUT: {
            return state;
        }
        case CANDIDATE_ACTION_LOADING: {
            return {
                ...state,
                loading: true,
                success: false,
                failed: false
            };
        }
        case CANDIDATE_ACTION_SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                failed: false,
            };
        }
        case CANDIDATE_ACTION_ERROR: {
            return {
                ...state,
                failed: true,
                success: false,
                loading: false,
                showAlert: true,
                alertMessage: action.data,
                alertVariant: "error"
            };
        }
        case CANDIDATE_ACTION_SUBMITING: {
            return {
                ...state,
                submitting: true
            };
        }
        case CANDIDATE_ACTION_SUBMITED: {
            return {
                ...state,
                submitting: false
            };
        }
        case GET_CANDIDATE_LIST: {
            return {
                ...state,
                candidateList: [...action.data]
            };
        }
        // Alert action
        //   case SHOW_ALERT: {
        //     return {
        //       ...state,
        //       showAlert: true,
        //       alertMessage: action.data,
        //       alertVariant: "success"
        //     };
        //   }
        //   case HIDE_ALERT: {
        //     return {
        //       ...state,
        //       showAlert: false,
        //       alertMessage: "",
        //       alertVariant: ""
        //     };
        //   }
        //   case USER_TABLE_CONTENT_LOADING: {
        //     return {
        //       ...state,
        //       userListTableContentLoading: true
        //     };
        //   }
        //   case USER_TABLE_CONTENT_LOADED: {
        //     return {
        //       ...state,
        //       userListTableContentLoading: false
        //     };
        //   }
        //   case CHANGE_USER_PASSWORD: {
        //     return {
        //       ...state,
        //       showUpdatedAlert: true,
        //       showAddedAlert: false,
        //       showDeletedAlert: false
        //     };
        //   }
        default: {
            return state;
        }
    }
};

export default candidateReducer;