import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CircularProgress } from '@material-ui/core';
import { Link } from "react-router-dom";
import { createSmsNotification, deleteSmsNotification, getSmsNotificationList, restartSmsNotification } from "../../../redux/actions/admin/SmsNotificationActions";
import { Helmet } from "react-helmet";
import $ from 'jquery';
import { categoriesExtended, types, NetworkOperator } from "../../../views/shared/Helper";
import Select from 'react-select';
import { DropzoneArea } from 'material-ui-dropzone';
import siteService from '../../../services/siteService';

export const SmsNotificationListing = (props) => {
    const { createSmsNotification, deleteSmsNotification, getSmsNotificationList, storeSmsNotification, restartSmsNotification = {} } = props;

    const [isLoading, setIsLoading] = React.useState(false);
    const [isDeletting, setIsDeletting] = React.useState(false);
    const [isRestarting, setIsRestarting] = React.useState(false);
    const [isCreatting, setIsCreatting] = React.useState(false);
    const [newSmsNotification, setNewSmsNotification] = React.useState({ id: "", title: "", content: "", category: "", networkOperator: "2", status: "", additionalNumbers: "" });
    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [smsNotificationTodelete, setSmsNotificationTodelete] = React.useState(null);
    const [smsNotificationToRestart, setSmsNotificationToRestart] = React.useState(null);
    const closeSmsNotificationCreateModal = React.useRef();
    const closedeleteSmsNotificationModal = React.useRef();
    const closeRestartSmsNotificationModal = React.useRef();
    const [smsNotificationList, setSmsNotificationList] = React.useState(null);
    const [count, setCount] = React.useState(0);
    const currentSite = siteService.getCurrentSite();

    useEffect(() => {
        getSmsNotificationList().then(response => {
            if (response.success) {
                setSmsNotificationList(response.smsNotificationsList)
            }
        })
    }, [])

    useEffect(() => {
        checkRequiredFields();
    }, [newSmsNotification]);

    useEffect(() => {
        if (storeSmsNotification.loading != isLoading) {
            setIsLoading(storeSmsNotification.loading);
        }
    }, [storeSmsNotification]);

    const handleSubmit = () => {
        setIsCreatting(true);
        let smsNotificationCopy = newSmsNotification;
        if (!smsNotificationCopy.order || smsNotificationCopy.order == "") {
            smsNotificationCopy.order = 0;
        }
        createSmsNotification(newSmsNotification).then(resp => {
            if (resp.success) {
                setSmsNotificationList(resp.smsNotificationsList);
                setNewSmsNotification({ id: "", title: "", content: "", category: "", networkOperator: "2", status: "", additionalNumbers: "" });
                closeSmsNotificationCreateModal.current.click();
                setIsCreatting(false);
            }
        })
    }

    const handleDeleteSubmit = () => {
        setIsDeletting(true);
        deleteSmsNotification(smsNotificationTodelete?.id).then(resp => {
            setSmsNotificationTodelete(null);
            if (resp.success) {
                setSmsNotificationList(resp.smsNotificationsList);
            }
            closedeleteSmsNotificationModal.current.click();
            setIsDeletting(false);
        });
    }

    const handleRestartSubmit = () => {
        setIsRestarting(true);
        restartSmsNotification(smsNotificationToRestart?.id).then(resp => {
            setSmsNotificationToRestart(null);
            if (resp.success) {
                setSmsNotificationList(resp.smsNotificationsList);
            }
            closeRestartSmsNotificationModal.current.click();
            setIsRestarting(false);
        });
    }

    let checkRequiredFields = () => {
        if (newSmsNotification.title == ""
        ) {
            setHasMissingValue(true);
        }
        else if (newSmsNotification.category == "") {
            setHasMissingValue(true);
        }
        else if (newSmsNotification.networkOperator == "") {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title> Administration | Publicités | {currentSite.fullName}</title>
            </Helmet>
            {isLoading ? (
                <div className="row bg-white pt-4 mt-lg-0">
                    <div className="col-lg-12">
                        <div className="loading-container bg-white">
                            <CircularProgress />
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="row bg-white pt-4 mt-lg-0">
                        <div className="col-lg-12 mt-4 mt-lg-0">
                            <div className="row mb-4">
                                <div className="col-md-7 col-sm-5 d-flex align-items-center">
                                    <div className="section-title-02 mb-0 ">
                                        <h4 className="mb-0" style={{ marginLeft: "10px" }}>Liste des SMS</h4>
                                    </div>
                                </div>
                                <div className="col-md-5 col-sm-7 mt-3 mt-sm-0" style={{ textAlign: "right" }}>
                                    <a className="btn btn-md ml-sm-auto btn-primary" data-toggle="modal" data-target="#createSmsNotificationModal">Ajouter un SMS</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="user-dashboard-info-box table-responsive mt-1 bg-white">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th width="50%">Titre</th>
                                                    <th style={{ textAlign: "center" }} width="20%">Messages envoyés</th>
                                                    <th width="20%" style={{ paddingLeft: "38px" }}>Statut</th>
                                                    <th width="10%" className="action text-right"></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {smsNotificationList?.map((smsNotification, index) => (
                                                    <tr className="candidates-list" key={smsNotification.id}>
                                                        <td>{smsNotification.title}</td>
                                                        <td style={{ textAlign: "center" }}>{smsNotification.lastSentRecipientIndex}</td>
                                                        <td>
                                                            <span
                                                                className={smsNotification.status == "InProgress" ? "sms-status-in-progress" : smsNotification.status == "Completed" ? "sms-status-completed" : "sms-status-new"}
                                                            >
                                                                {smsNotification.status == "InProgress" ? "Envoi en cours" : smsNotification.status == "New" ? "Nouveau" : "Completé"}
                                                            </span>
                                                        </td>
                                                        <td>

                                                            <ul className="list-unstyled mb-0 d-flex justify-content-end">
                                                                <li><Link to={"/admin/smsNotification/details/" + smsNotification.id} ><i className="fas fa-pencil-alt"></i></Link></li>
                                                                <li>
                                                                    <a href="javascript:void(0)" data-toggle="modal" title="Relancer" data-target="#restartSmsNotificationModal" data-original-title="Delete">
                                                                        <i className="fas fa-redo-alt" onClick={() => setSmsNotificationToRestart(smsNotification)}></i>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="javascript:void(0)" className="text-danger" data-toggle="modal" title="Supprimer" data-target="#deleteSmsNotificationModal" data-original-title="Delete">
                                                                        <i className="far fa-trash-alt" onClick={() => setSmsNotificationTodelete(smsNotification)}></i>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>



                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Start Delete smsNotification modale */}
                    <div className="modal fade" id="deleteSmsNotificationModal" tabIndex="-1" role="dialog" aria-modal="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header p-4">
                                    <h4 className="mb-0 text-center">Suppression d'un SMS: {smsNotificationTodelete?.title} </h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <form className="mt-2">
                                    <div className="modal-body">
                                        <div>
                                            <div className="form-row">
                                                <p>Voulez vous supprimer ce SMS ?</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-dismiss="modal"
                                            ref={closedeleteSmsNotificationModal}
                                        // disabled={isDeletting}
                                        >
                                            Non
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={handleDeleteSubmit}
                                            disabled={isDeletting}

                                        >
                                            Oui &nbsp; {isDeletting ? <CircularProgress size={15} /> : <span></span>}
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                    {/* End Delete smsNotification modale */}


                    {/* Start Restart smsNotification modale */}
                    <div className="modal fade" id="restartSmsNotificationModal" tabIndex="-1" role="dialog" aria-modal="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header p-4">
                                    <h4 className="mb-0 text-center">Relancement d'un SMS: {smsNotificationToRestart?.title} </h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <form className="mt-2">
                                    <div className="modal-body">
                                        <div>
                                            <div className="form-row">
                                                <p>Voulez vous relancer ce SMS ?</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-dismiss="modal"
                                            ref={closeRestartSmsNotificationModal}
                                        >
                                            Non
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={handleRestartSubmit}
                                            disabled={isRestarting}
                                        >
                                            Oui &nbsp; {isRestarting ? <CircularProgress size={15} /> : <span></span>}
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                    {/* End Restart smsNotification modale */}

                    {/* Start create smsNotification modale */}
                    <div className="modal fade" id="createSmsNotificationModal" tabIndex="-1" role="dialog" aria-modal="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header p-4">
                                    <h4 className="mb-0 text-center">Création d'un SMS</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" disabled={storeSmsNotification.submitting}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body">
                                    <div>
                                        <div className="form-row">
                                            <div className="form-group col-12">
                                                <label htmlFor="Email2">Titre</label>
                                                <input
                                                    value={newSmsNotification?.title}
                                                    type="text"
                                                    className="form-control"
                                                    name="title"
                                                    onChange={(e) => {
                                                        let val = e.target?.value;
                                                        setNewSmsNotification(prevState => { return { ...prevState, title: val } });
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-12">
                                                <label htmlFor="Email2">Contenu</label>
                                                    <textarea
                                                maxLength={160}
                                                    value={newSmsNotification?.content}
                                                    type="textarea"
                                                    className="form-control"
                                                    name="content"
                                                    required

                                                    onChange={(e) => {
                                                        setCount(e.target.value.length)
                                                        let content = newSmsNotification.content;
                                                        content = e.target.value;
                                                        let val = e.target?.value;
                                                        setNewSmsNotification(prevState => { return { ...prevState, content: val } });
                                                    }}

                                                    onKeyDown={e => {
                                                        if (e.key == "Enter") {
                                                            let content = newSmsNotification.content;
                                                            let val = e.target?.value + "\n";
                                                            setNewSmsNotification(prevState => { return { ...prevState, content: val } });
                                                        }
                                                    }}
                                                ></textarea>
                                                <div style={{ textAlign: "right" }}>{count} caractères sur 160</div>
                                            </div>

                                            <div className="form-group col-12"  >
                                                <label>Catégorie *</label>
                                                <Select
                                                    options={categoriesExtended}
                                                    loadingMessage="En cours de chargement"
                                                    onChange={(option) => {
                                                        setNewSmsNotification(prevState => { return { ...prevState, category: option?.value } });
                                                    }}
                                                    isDisabled={""}
                                                />
                                            </div>
                                            <div className="form-group col-12">
                                                <label htmlFor="Email2">Numeros supplémentaires</label>
                                                <textarea
                                                    value={newSmsNotification?.additionalNumbers}
                                                    type="textarea"
                                                    className="form-control"
                                                    name="additionalNumbers"
                                                    required
                                                    onChange={(e) => {
                                                        let additionalNumbers = newSmsNotification.additionalNumbers;
                                                        additionalNumbers = e.target.value;
                                                        let val = e.target?.value;
                                                        setNewSmsNotification(prevState => { return { ...prevState, additionalNumbers: val } });
                                                    }}
                                                    onKeyDown={e => {
                                                        if (e.key == "Enter") {
                                                            let additionalNumbers = newSmsNotification.additionalNumbers;
                                                            let val = e.target?.value + "\n";
                                                            setNewSmsNotification(prevState => { return { ...prevState, additionalNumbers: val } });
                                                        }
                                                    }}
                                                ></textarea>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Opérateur *</label>

                                                <Select
                                                    defaultValue={NetworkOperator.find(opt => opt?.value == "2")}
                                                    options={NetworkOperator}
                                                    loadingMessage="En cours de chargement"
                                                    onChange={(option) => {
                                                        setNewSmsNotification(prevState => { return { ...prevState, networkOperator: option?.value } });
                                                    }}
                                                    isDisabled={""}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                        // disabled={isCreatting}
                                        ref={closeSmsNotificationCreateModal}
                                    >
                                        Annuler
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={handleSubmit}
                                        disabled={isCreatting || hasMissingValue}
                                    >
                                        Soumettre &nbsp; {isCreatting ? <CircularProgress size={15} /> : <span></span>}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>

                </>
            )}
        </React.Fragment>
    );
}


SmsNotificationListing.propTypes = {
    getSmsNotificationList: PropTypes.func.isRequired,
    createSmsNotification: PropTypes.func.isRequired,
    deleteSmsNotification: PropTypes.func.isRequired,
    restartSmsNotification: PropTypes.func.isRequired,
    storeSmsNotification: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    getSmsNotificationList: PropTypes.func.isRequired,
    createSmsNotification: PropTypes.func.isRequired,
    deleteSmsNotification: PropTypes.func.isRequired,
    restartSmsNotification: PropTypes.func.isRequired,
    storeSmsNotification: state.smsNotifications,
});

export default connect(mapStateToProps, {
    getSmsNotificationList, createSmsNotification, deleteSmsNotification, restartSmsNotification
})(SmsNotificationListing)