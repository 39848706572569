import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getSidebarItemsList } from "../../../redux/actions/admin/SidebarActions";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


let Sidebar = (props) => {
    const {
        getSidebarItemsList,
        storeSidebar = {}
    } = props;

    const [displayingActiveAdsList, setDisplayingActiveAdsList] = React.useState([]);

    useEffect(() => {
        if (storeSidebar.sidebarItemsList.length == 0) {
            getSidebarItemsList(false).then(resp => {
                setDisplayingActiveAdsList(resp.items);
            })
        }
        else {
            setDisplayingActiveAdsList(storeSidebar.sidebarItemsList);
        }
        window.scrollTo(0, 0)
    }, [])

    let isYoutubeVideo = (item) => {        
        return item.link !== undefined && item.link !== null && item.link.toLowerCase().indexOf('https://www.youtube.com/embed') >= 0;
    }

    return (
        <>
            {displayingActiveAdsList?.map((item, index) => (                
                <div key={index} className="employers-grid mb-4" style={{ padding: "10px 10px" }}>
                    {!isYoutubeVideo(item) ? (
                        <div>
                            <a key={index} href={item.link} target={item.openInNewTab ? "_blank" : ""}>
                                <img className="img-fluid" src={item.imageUrl} alt="" />
                            </a>
                        </div>
                    ) : 
                    (
                        <div className="embed-responsive embed-responsive-4by3">
                            <iframe className="embed-responsive-item" src={item.link}></iframe>
                        </div>
                    )}  

                </div>
            ))
            }
        </>
    )
}

Sidebar.propTypes = {
    storeSidebar: PropTypes.object.isRequired,
    getSidebarItemsList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    getSidebarItemsList: PropTypes.func.isRequired,
    storeSidebar: state.sidebar,
});

export default connect(mapStateToProps, { getSidebarItemsList })(Sidebar)