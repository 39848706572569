import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getJobList, jobSearch } from "../../redux/actions/JobActions";
import { getTrustedEmployerItemsList } from "../../redux/actions/TrustedEmployerActions";
import { Link } from "react-router-dom";
import { CircularProgress } from '@material-ui/core';
import { Helmet } from "react-helmet";
import OwlCarousel from 'react-owl-carousel';
import Sidebar from '../shared/views/Sidebar';
import CustomGoogleAdsense from '../shared/views/CustomGoogleAdsense';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import createAccountImage from '../../../assets/images/pourquoi-creer-un-compte.jpg';
import AdSense from 'react-adsense';
import siteService from '../../services/siteService';

let Home = (props) => {
  const {
    storeJob,
    storeTrustedEmployer,
    jobSearch = {},
    getJobList,
    getTrustedEmployerItemsList
  } = props;

  const [isLoading, setIsLoading] = React.useState(true);
  const [jobSearchResult, setJobSearchResult] = React.useState(null);
  const [displayingJobList, setDisplayingJobList] = React.useState([]);
  const [filterValues, setFilterValues] = React.useState([]);
  const [trustedEmployersList, setTrustedEmployersList] = React.useState([]);
  const currentSite = siteService.getCurrentSite();

  useEffect(() => {
    if (isLoading && jobSearchResult !== null) {
      setIsLoading(false);
    }
    if (Object.keys(storeJob.jobSearchResult).length != 0 && storeJob.jobSearchResult.constructor == Object && jobSearchResult == null) {
      setJobSearchResult(storeJob.jobSearchResult);
      setDisplayingJobList(storeJob.jobSearchResult.documents);
    }
  }, [jobSearchResult]);

  useEffect(() => {
    if (jobSearchResult === null) {
      jobSearch(false).then(resp => {
        setJobSearchResult(resp);
        setDisplayingJobList(resp.documents);
      });
    }
    if (trustedEmployersList.length == 0 && storeTrustedEmployer.trustedEmployerItemsList.length == 0) {
      getTrustedEmployerItemsList().then(resp => {
        setTrustedEmployersList(resp.items);
      });
    }
    else if (trustedEmployersList.length == 0 && storeTrustedEmployer.trustedEmployerItemsList.length > 0) {
      setTrustedEmployersList(storeTrustedEmployer.trustedEmployerItemsList);
    }

    window.scrollTo(0, 0);
  }, [])

  const testimonialSliderOptions = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 500,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 2,
      }
    },
  };

  const trustedEmployersSliderOptions = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 500,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 6,
      }
    },
  };

  let isProductionSite = () => {
    return window.location.hostname.toLowerCase() === "malibaara.com"
  }

  return (
    <>
      <React.Fragment>
      <Helmet>
        <title>{currentSite.fullName} | {currentSite.shortDescription}</title>
        <meta name="description" content={currentSite.fullDescription}/>
        <meta name="keywords" content={currentSite.keywords}/>
      </Helmet>
        <section className="clearfix slider-banner d-none d-md-block">
          <div id="slider" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img className="img-fluid" src="assets/images/slider/New-Slider-Malibaara-Desktop-2021.jpg" alt="" />
                <div className="slider-content">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="space-ptb">
                        <div className="bg-overlay-black-80 p-5" style={{ padding: "2rem !important" }}>
                          <div className="position-relative">
                            <h3 className="text-white mb-2">Trouvez l'emploi de vos rêves!</h3>
                            <div className="mb-4 font-weight-normal text-white justify-content-center text-center">Recevez les avis de recrutement par SMS et e-mail</div>
                            <div className="justify-content-center text-center">
                              <Link to={"/candidates/signup"} className="btn btn-white btn-lg mt-3">Inscription</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="clearfix slider-banner d-lg-none d-md-none">
          <div id="slider" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              <div className="">
                <img className="img-fluid" src="assets/images/slider/New-Slider-Malibaara-mobile-2021.jpg" alt="" />
                <div className="" style={{ position: "absolute", bottom: "0px", width: "100%" }}>
                  <div className="" style={{ padding: "20px" }}>
                    <div className="" style={{ top: "120px", background: "white", borderRadius: "7px" }}>
                      <h3 className="text-center" style={{ fontSize: "17px", paddingTop: "10px" }}>Trouvez l'emploi de vos rêves!</h3>
                      <div className="font-weight-normal justify-content-center text-center" style={{ color: "black", fontSize: "13px" }}>Recevez les avis de recrutement par SMS et e-mail</div>
                      <div className="justify-content-center text-center" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                        <Link to={"/candidates/signup"} className="btn btn-primary">Inscription</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {isLoading ? (
          <div className="loading-container">
            <CircularProgress />
            <div>Chargement des offres d'emploi en cours...</div>
          </div>
        ) : (
          <>
            <section className="space-ptb bg-light" style={{ padding: "40px 0" }}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 mb-4 mb-lg-0">
                    <div className="section-title d-none d-md-block">
                      <h2 className="title"><span>Les offres d'emploi</span></h2>
                    </div>
                    <div className="section-title center  d-lg-none d-md-none">
                      <h2 className="title"><span>Les offres d'emploi</span></h2>
                    </div>
                    <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active" id="hot-jobs" role="tabpanel" aria-labelledby="hot-jobs-tab">
                        {displayingJobList.map((job, index) => (
                          <div className="job-list" key={index}>
                            <div className="job-list-logo">
                              <Link to={"/jobs/details/" + job.jobNumber}><img className={job.imageUrl != "" ? "img-fluid": "img-fluid default-job"} src={job.imageUrl != "" ? job.imageUrl : "assets/images/default-job.png"} alt="" /></Link>
                            </div>
                            <div className="job-list-details">
                              <div className="job-list-info">
                                <div className="job-list-title">
                                  <h5 className="mb-0"><Link to={"/jobs/details/" + job.jobNumber}>{job.title}</Link></h5>
                                </div>
                                <div className="job-list-option">
                                  <ul className="list-unstyled">
                                    <li><i className="fas fa-calendar-alt pr-1"></i>Date de Publication: {job.postedDateInFrench}</li>
                                    <li><i className="fas fa-calendar-alt pr-1"></i>Date Limite: {job.closingDateInFrench}</li>
                                  </ul>
                                </div>
                                <div style={{ textAlign: "justify", margin: "10px 0px 10px 0px" }}>
                                  {job.shortDescription}
                                </div>
                                <div className="job-list-option">
                                  <ul className="list-unstyled">
                                    <li>
                                      <span>Par </span>
                                      <Link to={"/jobs/search?companyId=" + job.companyId}>{job.companyName}</Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="row" style={{ marginTop: "93px" }}>
                      <div className="col-12">
                        <Sidebar />
                        <div className="widget border-0">
                            <div className="jobster-company-view">
                            <ul className="list-unstyled">
                                    <li>
                                        <div className="widget-box">
                                            <div className="d-flex">
                                                <div>
                                                    <Link to={"/candidates/signup"}>
                                                        <img className="img-fluid" src={createAccountImage} alt="" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <CustomGoogleAdsense location="Side1" />
                        {/* <CustomGoogleAdsense location="Side2" />
                        <CustomGoogleAdsense location="Side3" /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {currentSite.isMaliVersion() && (
              <>
                <section className="space-ptb" style={{ padding: "100px 0 150px 0px" }}>
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-8 col-md-10">
                        <div className="section-title center">
                          <h2 className="title"><span>Ce que disent nos clients?</span></h2>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col">
                        <OwlCarousel
                          className="owl-carousel owl-nav-bottom-center testimonial-style-02"
                          {...testimonialSliderOptions}>
                          <div className="item">
                            <div className="testimonial-content">
                              <p><i className="fas fa-quote-left quotes"></i>Malibaara s’est imposé comme le site de référence sur le marché de l’emploi au Mali. L’équipe de Malibaara conjugue professionnalisme, rigueur et disponibilité dans son travail au quotidien. En outre, les différents forums institués, en faveur de la promotion de l’emploi, démontrent de l’esprit innovateur, et dynamique de Malibaara. L’adaptabilité aux défis évolutions du secteur de l’emploi résume parfaitement l’état d’esprit de cette entreprise qui œuvre en faveur du développement socio-professionnel au Mali…</p>
                            </div>
                            <div className="testimonial-author">
                              <div className="testimonial-name">
                                <h6 className="mb-1">Seydou Semega</h6>
                                <span>Country Manager - Group Sustainability Advisor</span>
                              </div>
                            </div>
                          </div>
                          <div className="item">
                            <div className="testimonial-content">
                              <p style={{marginBottom:"43px"}}><i className="fas fa-quote-left quotes"></i>Depuis des années, Malibaara nous accompagne dans la publications de nos offres d’emploi.Une très belle collaboration, que j’affirme se passe dans le respect et la confiance, l’efficacité, l’écoute, le professionnalisme, la disponibilité, la rigueur, la transparence, les conseils, et la sympathie. Je les recommande fortement de par leur dynamisme et leur sens de l’écoute. Merci pour cette qualité de service qui est une réelle valeur ajoutée pour notre entreprise.</p>
                            </div>
                            <div className="testimonial-author">
                              <div className="testimonial-name">
                                <h6 className="mb-1">Yassine MBODJ</h6>
                                <span>Chargée de recrutement - SAER EMPLOI</span>
                              </div>
                            </div>
                          </div>
                        </OwlCarousel>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="bg-light py-4 py-lg-0">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-lg-6 text-center j-mt-sm-n7">
                        <img className="img-fluid" src="assets/images/smartmockups_kzctxpvh.png" alt="" />
                      </div>
                      <div className="col-lg-6">
                        <div className="section-title mobile-app-download-section-title">
                          <h2 className="title"><span>Application mobile</span></h2>
                          <p className="lead">Téléchargez sans plus tarder l'application mobile de Malibaara.</p>
                        </div>
                        <div className="d-block d-sm-flex mb-0 mb-lg-2 mobile-app-download-links">
                          <a className="btn btn-white btn-xl btn-app mr-0 mr-sm-4 mb-2 mb-sm-0 py-3" href="https://play.google.com/store/apps/details?id=com.d3k.malibaara" target="_blank">
                            <i className="fab fa-google-play"></i>
                            <div className="btn-text text-left">
                              <small>Disponible sur </small>
                              <span className="mb-0 d-block">Google Play</span>
                            </div>
                          </a>
                          <a className="btn btn-white btn-sm btn-app mb-2 mb-sm-0 py-3" href="#">
                            <i className="fab fa-apple"></i>
                            <div className="btn-text text-left">
                              <small>Bientôt disponible sur </small>
                              <span className="mb-0 d-block">App Store</span>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="our-clients">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-8 col-md-10">
                        <div className="section-title center">
                          <h2 className="title"><span>Ils nous font confiance?</span></h2>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <OwlCarousel
                          className="owl-carousel owl-nav-center"
                          {...trustedEmployersSliderOptions}>
                          {trustedEmployersList?.map((item, index) => (
                            <div  key={index} className="item trusted-employer">
                              <img className={'img-fluid ' + item.className} src={item.logoUrl} alt="" />
                            </div>
                          ))}
                        </OwlCarousel>
                      </div>
                      <CustomGoogleAdsense location="Wide2" />
                    </div>
                  </div>
                </section>                 
              </>
            )}                       
          </>
        )}

      </React.Fragment>


    </>
  )
}

Home.propTypes = {
  getJobList: PropTypes.func.isRequired,
  storeJob: PropTypes.object.isRequired,
  storeTrustedEmployer: PropTypes.object.isRequired,
  jobSearch: PropTypes.func.isRequired,
  getTrustedEmployerItemsList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  getJobList: PropTypes.func.isRequired,
  storeJob: state.job,
  storeTrustedEmployer: state.trustedEmployer,
  jobSearch: PropTypes.func.isRequired,
  getTrustedEmployerItemsList: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, { getJobList, jobSearch, getTrustedEmployerItemsList })(Home)

