import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import { useLocation } from "react-router-dom";

export const AdminLayout = (props) => {

    const location = useLocation();
    const [currrentPathName, setCurrrentPathName] = React.useState("");

    useEffect(() => {
        getPathName();
    }, [])

    useEffect(() => {
        setCurrrentPathName(location.pathname);
    }, [location])

    let getPathName = () => {
        let pathName = window.location.pathname;
        setCurrrentPathName(pathName);
    }

    return (
        <>
            {(currrentPathName != "/admin/signin" && !currrentPathName.toLowerCase().includes("resetpassword")) ?
                (
                    <>
                        <Header />
                        <React.Fragment>
                            <section className="space-ptb bg-light " style={{ padding: "50px 0" }}>
                                <div className="container user-dashboard-info-box">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <div className="bg-white p-4 shadow-sm">
                                                <div className="secondary-menu">
                                                    <ul className="list-unstyled mb-0">
                                                        <li><a className={(currrentPathName.includes("/admin/dashboard") || currrentPathName == "/admin") ? "active" : ""} href="/admin/dashboard" key={"dashboard"}>Dashboard</a></li>
                                                        <li><a className={(currrentPathName.includes("/admin/jobs") || currrentPathName.includes("/admin/job")) ? "active" : ""} href="/admin/jobs" key={"job"}>Offres d'emploi</a></li>
                                                        <li><a className={(currrentPathName.includes("/admin/tenders") || currrentPathName.includes("/admin/tender")) ? "active" : ""} href="/admin/tenders" key={"tenders"}>Appels d'offre</a></li>
                                                        <li><a className={(currrentPathName.includes("/admin/courses") || currrentPathName.includes("/admin/course")) ? "active" : ""} href="/admin/courses" key={"course"}>Formations</a></li>
                                                        <li><a className={currrentPathName.includes("/admin/applications") ? "active" : ""} href="/admin/applications/list" key={"applications"}>Postulations</a></li>
                                                        <li><a className={currrentPathName.includes("/admin/employers") ? "active" : ""} href="/admin/employers" key={"employers"}>Employeurs</a></li>
                                                        <li><a className={currrentPathName.includes("/admin/candidates") ? "active" : ""} href="/admin/candidates" key={"candidates"}>Candidats</a></li>
                                                        <li><a className={currrentPathName.includes("/admin/ads") ? "active" : ""} href="/admin/ads" key={"ads"}>Publicités</a></li>
                                                        <li><a className={(currrentPathName.includes("/admin/smsNotifications") || currrentPathName.includes("/admin/smsNotification/details")) ? "active" : ""} href="/admin/smsNotifications" key={"smsNotifications"}>SMS</a></li>
                                                        <li><a className={currrentPathName.includes("/admin/users") ? "active" : ""} href="admin/users" key={"users"}>Utilisateurs</a></li>
                                                        <li><a className={currrentPathName.includes("/admin/operations") ? "active" : ""} href="/admin/operations" key={"operations"}>Opérations</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-9">
                                            {props.children}
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </React.Fragment>
                        <Footer />
                    </>
                )
                :
                (
                    <>
                        {props.children}
                    </>
                )}

        </>
    );
}