import React, { useEffect, useRef } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { resetPassword } from "../../redux/actions/admin/CandidateActions"
import { CircularProgress } from '@material-ui/core';
import defaultBanner from '../../../assets/images/bg/default-banner.jpg';
import CustomAlert from '../shared/views/CustomAlert';
import $ from 'jquery';
import { getAdsList } from "../../redux/actions/admin/AdsActions";
import 'moment/locale/fr';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import siteService from '../../services/siteService';

const useStyles = makeStyles(theme => createStyles({
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
}));

let CandidateResetPassword = (props) => {

    const {
        storeAds,
        getAdsList,
        resetPassword
    } = props;

    const classes = useStyles();

    const currentSite = siteService.getCurrentSite();
    const [hasMissingValue, setHasMissingValue] = React.useState(true);
    const [resetPasswordFailed, setResetPasswordFailed] = React.useState(false);
    const [areNotSame, setAreNotSame] = React.useState(false);
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [published, setPublished] = React.useState(false);
    const [displayingActiveAdsList, setDisplayingActiveAdsList] = React.useState([]);
    const [resetPasswordRequest, setResetPasswordRequest] = React.useState(
        {
            token: "",
            email: "",
            password: "",
            confirmPassword: ""
        });


    useEffect(() => {
        let match = props.match;
        if (match !== undefined) {
            let params = match.params;
            if (params !== undefined && params.t !== undefined) {
                setResetPasswordRequest(prevState => { return { ...prevState, token: params.t } });
            }
        };
        if (storeAds.adsList.length == 0) {
            getAdsList(false).then(resp => {
                setDisplayingActiveAdsList(resp.adsList);
                
            })
        }
        else {
            setDisplayingActiveAdsList(storeAds.adsList);
        }
    }, []);

    useEffect(() => {

        if (resetPasswordRequest.password == ""
            || resetPasswordRequest.password === undefined
            || resetPasswordRequest.confirmPassword == ""
            || resetPasswordRequest.confirmPassword === undefined
            || resetPasswordRequest.email == ""
            || resetPasswordRequest.email === undefined
        ) {
            setHasMissingValue(true);
        }
        else {
            setHasMissingValue(false);
        }
    }, [resetPasswordRequest]);

    const handleSubmit = () => {
        setIsUpdating(true);
        resetPassword(resetPasswordRequest).then(data => {
            if (data == true) {
                setIsUpdating(false);
                setPublished(true);
                setResetPasswordFailed(false);
                setResetPasswordRequest({
                    token: "",
                    email: "",
                    password: "",
                    confirmPassword: ""
                });
            }
            else {
                setResetPasswordFailed(true);
                setIsUpdating(false);
            }
        });
    }

    return (
        <React.Fragment>
            <div className="header-inner" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${defaultBanner})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 style={{ color: "white" }}>Profil</h2>
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item"><a href="/"> Accueil </a></li>
                                <li className="breadcrumb-item"><i className="fas fa-chevron-right"></i><a href="index.html"> Candidat </a></li>
                                <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> Mot de passe </span></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>


            <section className="space-ptb" style={{ padding: "50px 0" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8">
                            <div className="row">
                                {/* Informations de base */}
                                <div className="col-xl-12">
                                    <div className="bg-light-3 p-4">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="section-title">
                                                    <h2 className="title"><span>Réinitialiser le Mot de passe</span></h2>
                                                    {/* <p></p> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div _lpchecked="1">
                                            <div className="form-row">
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label>Email *</label>
                                                        <input
                                                            value={resetPasswordRequest?.email}
                                                            type="email"
                                                            className="form-control"
                                                            id="password"
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                let val = e.target?.value;
                                                                setResetPasswordRequest(prevState => { return { ...prevState, email: val } });
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <label>Nouveau mot de passe *</label>
                                                        <input
                                                            value={resetPasswordRequest?.password}
                                                            type="password"
                                                            className="form-control"
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                let val = e.target?.value;
                                                                if (resetPasswordRequest.confirmPassword != "" && resetPasswordRequest.conformPassword != undefined) {
                                                                    setAreNotSame(resetPasswordRequest.confirmPassword.normalize() !== val.normalize());
                                                                }
                                                                setResetPasswordRequest(prevState => { return { ...prevState, password: val } });
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <label> Confirmer le nouveau mot de passe *</label>
                                                        <input
                                                            value={resetPasswordRequest?.confirmPassword}
                                                            type="password"
                                                            className="form-control"
                                                            placeholder=""
                                                            onChange={(e) => {
                                                                let val = e.target?.value;
                                                                setAreNotSame(resetPasswordRequest.password.normalize() !== val.normalize());
                                                                setResetPasswordRequest(prevState => { return { ...prevState, confirmPassword: val } });
                                                            }}
                                                        />
                                                        {areNotSame && (
                                                            <div style={{ color: "red", marginTop: "8px" }}>Les deux mots de passe sont différents.</div>
                                                        )}
                                                    </div>
                                                    <div className="col-12 mt-4">
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={handleSubmit}
                                                            disabled={isUpdating || hasMissingValue || areNotSame}
                                                        >
                                                            Soumettre &nbsp; {isUpdating ? <CircularProgress size={15} /> : <span></span>}
                                                        </button>
                                                    </div>
                                                    {published && (
                                                        <div className="col-12 mt-4">
                                                            <CustomAlert content={"<strong>Félicitations!</strong> votre mot de passe a été réinitialisé avec succès. Veuillez-vous reconnecter"} />
                                                        </div>
                                                    )}
                                                    {resetPasswordFailed && (
                                                        <div className="col-12 mt-4">
                                                            <CustomAlert content="Email et/ou le mot de passe incorrect" type="alert-danger" />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 mt-4 mt-xl-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="sidebar mb-0">
                                        <div className="widget border-0">
                                            <div className="jobster-company-view">
                                                <ul className="list-unstyled">
                                                    {
                                                        displayingActiveAdsList.map((ads, index) => (
                                                            ads.attachments.map((attachment, index) => (
                                                                <li>
                                                                    <div className="widget-box">
                                                                        <div className="d-flex">
                                                                            <div>
                                                                                <a href={ads.link} target={ads.openInNewTab ? "_blank" : ""}>

                                                                                    <img className="img-fluid" src={attachment.url} alt="" />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

CandidateResetPassword.propTypes = {
    storeAds: PropTypes.object.isRequired,
    getAdsList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    resetPassword: PropTypes.func.isRequired,
    getAdsList: PropTypes.func.isRequired,
    storeAds: state.ads,
});

export default connect(mapStateToProps,
    {
        getAdsList,
        resetPassword
    }
)(CandidateResetPassword)